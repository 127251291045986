import React, { useEffect, useState } from "react";
import { Box, Fade, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, RadioGroup } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import LabelComponent from "../../components/label/labelComponent";
import SelectComponent from "../../components/select/selectComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import useTestPlanTab3 from "../../hooks/testPlan/useTestPlanTab3";
import moment, { now } from "moment";
import { format } from "../../utils/utils";
import { useParams } from "react-router-dom";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import { get, post } from "../../api/api";
import ModalComponent from "../../components/modal/modalComponent";
import ModalReadPage from "../../components/modal/modalReadPage";
import useVehicle from "../../hooks/vehicle/useVehicle";

const style = {
    borderRadius: "8px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    boxShadow: 24,
    p: 4,
};

function ModalVehicleComponent(props: ParamsField) {

    const [choosedId, setChoosedId] = useState<string>("vds");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);
    const [vehicleChoosedData, setVehicleChoosedData] = useState({
        vds: '',
        positionVDS: '',
        carId: '',
        color: '',
        modelYear: '',
        motorization: '',
        engineDescription: '',
        chassis: '',
        transmissionDescription: '',
        doorNumber: '',
        fase: ''
    });

    const { openAlert, setAddVehicle,
        setOpenAlert,
        read, setRead,
        messageSave, setMessageSave,
        allSelectsList, fectchSubmit
    } = useVehicle();


    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open ? props.open : false}
            onClose={props.close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open ? props.open : false}>
                <Box sx={style}>
                    <ModalComponent text=""
                        open={openModal}
                        description={"Deseja realmente cancelar a criação de um novo veículo?"}
                        close={handleCloseModal}
                        onCancel={() => { setOpenModal(false) }}
                        onNext={(event) => {
                            setVehicleChoosedData({
                                ...vehicleChoosedData,
                                vds: '',
                                positionVDS: '',
                                carId: '',
                                color: '',
                                modelYear: '',
                                motorization: '',
                                engineDescription: '',
                                chassis: '',
                                transmissionDescription: '',
                                doorNumber: '',
                                fase: ''
                            });
                            setOpenModal(false);
                            setMessageSave("")
                            props.onCancel(event);
                        }} />
                    <ModalReadPage open={read} onCancel={() => setRead(false)} />
                    <Grid container sx={{ marginBottom: "32px" }}>
                        <LabelComponent text={"Cadastro "} fontSize="24px" />
                        <LabelComponent
                            text={"do veículo"}
                            fontSize="24px"
                            fontWeight="normal"
                        />
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={4} style={{ paddingRight: "20px" }}>
                            <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                                {choosedId == "id-prov" && <div style={{ padding: "3px 10px" }}>
                                    <FormControl>
                                        <RadioGroup value={choosedId} onChange={(event) => setChoosedId(event.target.value)} aria-labelledby="demo-radio-buttons-group-label" defaultValue="vds" name="radio-buttons-group">
                                            <FormControlLabel value="vds" control={<Radio />} label="Vds" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                }
                                {choosedId == "vds" && <TextFieldComponent
                                    text="Número do VDS"
                                    required={true}
                                    helperText={messageSave?.includes('VDS') ? messageSave : ""}
                                    error={messageSave?.includes('VDS')}
                                    value={vehicleChoosedData.vds}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setMessageSave("");
                                        setVehicleChoosedData({ ...vehicleChoosedData, vds: event.target.value, positionVDS: null });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4} style={{ paddingRight: "20px" }}>
                            <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                                {choosedId == "vds" && <div style={{ padding: "3px 10px" }}>
                                    <FormControl>
                                        <RadioGroup value={choosedId} onChange={(event) => setChoosedId(event.target.value)} aria-labelledby="demo-radio-buttons-group-label" defaultValue="vds" name="radio-buttons-group">
                                            <FormControlLabel value="id-prov" control={<Radio />} label="Identificação Provisória" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                }
                                {choosedId == "id-prov" && <TextFieldComponent
                                    text="Identificação Provisória"
                                    helperText={messageSave?.includes('Identificação') ? messageSave : ""}
                                    error={messageSave?.includes('Identificação')}
                                    required={true}
                                    value={vehicleChoosedData.positionVDS}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setMessageSave("");
                                        setVehicleChoosedData({ ...vehicleChoosedData, positionVDS: event.target.value, vds: null });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                                }
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <SelectPaginationComponent
                                    text={"Nome do modelo"}
                                    url="cars/all"
                                    required={true}
                                    value={vehicleChoosedData.carId}
                                    onSelect={(event: string) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, carId: event });
                                        if ((event !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <SelectComponent
                                    isFilter={true}
                                    text="Cor do veículo"
                                    values={allSelectsList?.colors}
                                    value={vehicleChoosedData.color}
                                    required={true}
                                    onSelect={(event: any) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, color: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <TextFieldComponent
                                    text="Ano do modelo"
                                    type="number"
                                    required={true}
                                    value={vehicleChoosedData.modelYear}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, modelYear: event.target.value.toString() });
                                        if ((event.target.value.toString() !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <TextFieldComponent
                                    text="Motorização"
                                    required={true}
                                    value={vehicleChoosedData.motorization}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, motorization: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <TextFieldComponent
                                    text="Versão do Veículo"
                                    required={true}
                                    value={vehicleChoosedData.engineDescription}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, engineDescription: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <TextFieldComponent
                                    text="Número do chassi"
                                    required={true}
                                    helperText={messageSave?.includes('chassi') ? messageSave : ""}
                                    error={messageSave?.includes('chassi')}
                                    value={vehicleChoosedData.chassis}
                                    maxLength={100}
                                    onChange={(event) => {
                                        setMessageSave("");
                                        setVehicleChoosedData({ ...vehicleChoosedData, chassis: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <SelectComponent
                                    isFilter={true}
                                    values={allSelectsList?.transmissionFeatures}
                                    text="Características da transmissão"
                                    value={vehicleChoosedData.transmissionDescription}
                                    required={true}
                                    onSelect={(event: any) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, transmissionDescription: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.doorNumber !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <SelectComponent
                                    isFilter={true}
                                    text="Quantidade de portas"
                                    values={allSelectsList?.doors}
                                    value={vehicleChoosedData.doorNumber}
                                    required={true}
                                    onSelect={(event: any) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, doorNumber: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.fase !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                                <SelectComponent
                                    isFilter={true}
                                    text="Fase do veículo"
                                    values={allSelectsList?.fases}
                                    value={vehicleChoosedData.fase}
                                    required={true}
                                    onSelect={(event: any) => {
                                        setVehicleChoosedData({ ...vehicleChoosedData, fase: event.target.value });
                                        if ((event.target.value !== "") &&
                                            (vehicleChoosedData.positionVDS !== "" || vehicleChoosedData.vds) &&
                                            (vehicleChoosedData.carId !== "") &&
                                            (vehicleChoosedData.color !== "") &&
                                            (vehicleChoosedData.modelYear !== "") &&
                                            (vehicleChoosedData.motorization !== "") &&
                                            (vehicleChoosedData.engineDescription !== "") &&
                                            (vehicleChoosedData.chassis !== "") &&
                                            (vehicleChoosedData.transmissionDescription !== "") &&
                                            (vehicleChoosedData.doorNumber !== "")) {
                                            setValid(true);
                                        } else {
                                            setValid(false);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '32px',
                        paddingBottom: '8px',
                    }}>
                        <ButtonComponent
                            border="1px solid #00b0f0"
                            width="150px"
                            marginTop="0px"
                            text="Cancelar"
                            color="#00b0f0"
                            marginLeft="32px"
                            marginRight="32px"
                            disable={true}
                            onClick={() => setOpenModal(true)} />
                        <ButtonComponent
                            width="150px"
                            marginTop="0px"
                            backgroundColor="#00b0f0"
                            text={"Salvar"}
                            marginLeft="64px"
                            disabled={!valid}
                            onClick={() => {
                                post<VehicleAdd[]>("projectCars", vehicleChoosedData).then((value: VehicleAdd) => {
                                    if (value.codeStatus === 201) {
                                        setOpenAlert(true);
                                        setAddVehicle(false);
                                        props.onSubimit(false);
                                    } else {
                                        setAddVehicle(true);
                                        if (value.errorMessage === 'Not Operation') {
                                            setRead(true)
                                        }
                                        setMessageSave(value.errorMessage);
                                    }
                                });
                            }} />
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ModalVehicleComponent;
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CardLaboratoryProg from "../../components/card/cardLabProgComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import useProgPage from "../../hooks/programing/useProgPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function LaboratoryProgPage() {

  const [card, setCard] = useState<ParamsField[]>([])

  const { loading, schedule, access } = useProgPage();

  return (
    // access ?
      <Grid container>
        {loading ? <LoadingComponente /> : null}
        <HeaderComponent text="Programação de Laboratórios" access={access}/>
        <Grid container sx={{ margin: "48px" }}>
          {schedule.map((value: LaboratoryProg) => {
            return (
              <CardLaboratoryProg
                key={value.id}
                id={value.id}
                media={value.media}
                text={value.name}
                description={value.supervisionsShared}
                type={value.type}
              />
            )
          })}
        </Grid>
      </Grid>
      // : <NotAuthorizedPage />
  )
}

export default LaboratoryProgPage;
import { useEffect } from "react";
import { Grid } from "@mui/material";
import moment from 'moment';
import ButtonComponent from "../../../components/button/buttonComponent";
import TextFieldComponent from "../../../components/field/textFieldComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import SelectComponent from "../../../components/select/selectComponent";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import SnackbarComponent from "../../../components/snackbar/snackbarComponent";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1ProjectDetails(props: any) {
    const paddingFix = { paddingRight: '16px' };
    const {
        displayAlert, read, setRead,
        isError, setIsError,
        messageAlert,
        setMessageAlert,
        selectAllList,
        projectsList,
        typedValuesDetailProjects,
        setTypedValuesDetailProjects,
        fetchSaveProjectDetails,
        alertNewStatus, setAlertNewStatus,
        alertNewMessage, setAlertNewMessage,
        idNewRegister,
        access
    } = useTestPlanTab1(props.currentModal, props.type, props.id);

    const changeDates = (_durationText: string, _duration: number, _month: number, _year: number) => {
        let calendarYear: string = '';
        let durationText: number = _durationText ? Number(_durationText) : _duration;
        let duration: number = _duration;
        let month: number = typeof _month == 'string' ? Number(_month) : _month;
        let lastMonth: number = _duration;
        let year: number = _year;
        let lastYear: number = _year;

        let dateStartApi: string;
        let endDateApi: string;

        let dateStartScreen: string;
        let endDateScreen: string;

        if (durationText < 1 || month < 1 || year < 1) {
            setTypedValuesDetailProjects({
                ...typedValuesDetailProjects,
                calendarYear: calendarYear,
                dateStart: '',
                dateStartScreen: '',
                endDate: '',
                endDateScreen: '',
                duration: duration,
                durationText: durationText,
                month: month,
                year: year,
            });
            return;
        }

        if (durationText == 0) {
            lastMonth = 0;
            calendarYear = '';
        } else if (durationText == 6) {
            lastMonth = month + durationText;
        } else if (durationText == 12) {
            lastMonth = month;
            lastYear = year + 1;
        } else if (durationText == 18) {
            lastMonth = month + 6;
            lastYear = year + 1;
        } else if (durationText == 24) {
            lastMonth = month;
            lastYear = year + 2;
        } else if (durationText == 30) {
            lastMonth = month + 6;
            lastYear = year + 2;
        } else if (durationText == 36) {
            lastMonth = month;
            lastYear = year + 3;
        } else if (durationText == 42) {
            lastMonth = month + 6;
            lastYear = year + 3;
        } else { //if(durationText == 48) {
            lastMonth = month;
            lastYear = year + 4;
        }

        if (lastMonth <= 9) {
            endDateApi = (moment(lastYear) + "-0" + moment(lastMonth) + "-01");
            endDateScreen = "01/0" + lastMonth + "/" + lastYear;
        } else if (lastMonth > 9 && lastMonth <= 12) {
            endDateApi = (moment(lastYear) + "-" + moment(lastMonth) + "-01");
            endDateScreen = "01/" + lastMonth + "/" + lastYear;
        } else {
            lastMonth = lastMonth - 12;
            lastYear = lastYear + 1
            endDateApi = (moment(lastYear) + "-" + moment(lastMonth) + "-01");
            endDateScreen = "01/0" + lastMonth + "/" + lastYear;
        }

        if (month <= 9) {
            dateStartApi = (moment(year) + "-0" + moment(month) + "-01");
            dateStartScreen = "01/0" + month + "/" + year;
        } else {
            dateStartApi = (moment(year) + "-" + moment(month) + "-01");
            dateStartScreen = "01/" + month + "/" + year;
        }

        calendarYear = (lastYear - year + 1).toString() + " anos";

        setTypedValuesDetailProjects({
            ...typedValuesDetailProjects,
            calendarYear: calendarYear,
            dateStart: dateStartApi,
            dateStartScreen: dateStartScreen,
            duration: duration,
            durationText: durationText,
            endDate: endDateApi,
            endDateScreen: endDateScreen,
            month: month,
            year: year,
        });
    };

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const alertNewClose = () => {
        setAlertNewStatus(false);
        location.pathname = '/stapp/plano-testes/editar/' + idNewRegister;
    };

    return (
        access ?
            <>
                {selectAllList && selectAllList?.duration?.length > 0 && typedValuesDetailProjects != undefined ?
                    <Grid container sx={{ position: "relative" }}>
                        <Grid sx={{ position: "absolute", top: "-300px", width: "100vw", left: "-60px" }}>
                            <SnackbarComponent text={alertNewMessage} open={alertNewStatus} closeSnackbar={alertNewClose} />
                        </Grid>
                        <ModalComponent
                            description={messageAlert}
                            isOk={true}
                            open={isError}
                            close={() => { setIsError(false) }}
                            onCancel={() => { setIsError(false) }}
                            onNext={() => setIsError(false)}
                        />
                        <ModalReadPage open={read} onCancel={() => setRead(false)} />
                        <Grid item xs={12} sm={12} md={12} lg={6} >
                            <LabelComponent text="Informações" fontSize="20px" />
                            <Grid container sx={{ padding: "16px 0 24px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={4} sx={paddingFix}>
                                    <TextFieldComponent
                                        helperText={messageAlert?.includes('existente') ? messageAlert : typedValuesDetailProjects?.fields && typedValuesDetailProjects.name === "" ? "Campo obrigatório" : ""}
                                        error={messageAlert?.includes('existente') ? true : false}
                                        required={true}
                                        maxLength={100}
                                        text="Nome do projeto"
                                        value={typedValuesDetailProjects?.name ? typedValuesDetailProjects.name : ""}
                                        onChange={(event) => {
                                            setMessageAlert("");
                                            setTypedValuesDetailProjects({
                                                ...typedValuesDetailProjects,
                                                name: event.target.value
                                            });
                                        }}
                                    ></TextFieldComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} sx={paddingFix}>
                                    <SelectComponent
                                        text="Duração"
                                        required={true}
                                        value={typedValuesDetailProjects?.duration == 0 ? '' : typedValuesDetailProjects.duration}
                                        values={selectAllList ? selectAllList.duration : []}
                                        onSelect={(event: any, child: any) => {
                                            changeDates(
                                                child.props.value > 0 ? child.props.children.replace(' Meses', '') : '',
                                                event.target.value,
                                                typedValuesDetailProjects?.month,
                                                typedValuesDetailProjects?.year
                                            );
                                        }}
                                    ></SelectComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} sx={paddingFix}>
                                    <SelectComponent
                                        text="Ano"
                                        required={true}
                                        value={typedValuesDetailProjects?.year == 0 ? '' : typedValuesDetailProjects.year}
                                        values={selectAllList ? selectAllList.years : []}
                                        onSelect={(event: any) => {
                                            changeDates(
                                                typedValuesDetailProjects?.durationText,
                                                typedValuesDetailProjects?.duration,
                                                typedValuesDetailProjects?.month,
                                                event.target.value
                                            )
                                        }}
                                    ></SelectComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "20px" }}></Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} sx={paddingFix}>
                                    <SelectComponent
                                        text="Mês"
                                        required={true}
                                        value={typedValuesDetailProjects?.month == 0 ? '' : typedValuesDetailProjects.month}
                                        values={selectAllList ? selectAllList.months : []}
                                        onSelect={(event: any) => {
                                            changeDates(
                                                typedValuesDetailProjects?.durationText,
                                                typedValuesDetailProjects?.duration,
                                                event.target.value,
                                                typedValuesDetailProjects?.year
                                            )
                                        }}
                                    ></SelectComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} sx={paddingFix}>
                                    <SelectComponent
                                        text="Nº do projeto"
                                        required={true}
                                        values={projectsList}
                                        value={typedValuesDetailProjects?.carId}
                                        onSelect={(event: any) => {
                                            const project = projectsList.find((valueList) => valueList.id === event.target.value);
                                            setTypedValuesDetailProjects({
                                                ...typedValuesDetailProjects,
                                                carId: event.target.value,
                                                familyCode: project.family,
                                            });
                                        }}
                                    ></SelectComponent>
                                </Grid>
                            </Grid>
                        </Grid >

                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <LabelComponent text="Informações automáticas" fontSize="20px" />
                            <Grid container sx={{ backgroundColor: "#dfe4e8", borderRadius: "5px 5px 5px 5px", marginTop: "16px", padding: "16px 24px 24px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={6} sx={paddingFix}>
                                    <TextFieldComponent value={typedValuesDetailProjects.familyCode} text="Código da família" readOnly={true} textTransform="uppercase"></TextFieldComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} sx={paddingFix}>
                                    <TextFieldComponent value={typedValuesDetailProjects.calendarYear} text="Ano calendário" readOnly={true}></TextFieldComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "20px" }}></Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} sx={paddingFix}>
                                    <TextFieldComponent value={typedValuesDetailProjects.dateStartScreen} text="Data de início" readOnly={true}></TextFieldComponent>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} sx={paddingFix}>
                                    <TextFieldComponent value={typedValuesDetailProjects.endDateScreen} text="Data de término" readOnly={true}></TextFieldComponent>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "32px" }}>
                            <LabelComponent text="Detalhes*" fontSize="20px" />

                            <Grid container sx={{ marginTop: "16px" }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <textarea cols={30} rows={10} style={{ width: "100%" }}
                                        required={true}
                                        value={typedValuesDetailProjects?.detailsDescription}
                                        onChange={(event) => {
                                            setTypedValuesDetailProjects({
                                                ...typedValuesDetailProjects,
                                                detailsDescription: event.target.value
                                            });
                                        }}
                                    ></textarea>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
                                <ButtonComponent text="Cancelar" disable={true} onClick={() => {
                                    props.setOpenCancelModal(true);
                                }} />
                            </Grid>
                            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
                                <ButtonComponent text="Salvar"
                                    active={typedValuesDetailProjects.name && typedValuesDetailProjects.duration && typedValuesDetailProjects.year && typedValuesDetailProjects.month && typedValuesDetailProjects.carId && typedValuesDetailProjects.detailsDescription ? true : false}
                                    onClick={() => fetchSaveProjectDetails(typedValuesDetailProjects, props.type, props.id)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <LoadingComponente></LoadingComponente>
                }
            </>
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1ProjectDetails;
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import ButtonComponent from "../../../components/button/buttonComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1Phases(props: any) {
    const {
        loading,
        isError, setIsError,
        displayAlert, messageAlert,
        typedValuesPhasesArray,
        setTypedValuesPhasesArray,
        fetchSavePhases, fetchPhases, read, setRead, access,
    } = useTestPlanTab1(props.currentModal, props.type, props.id);

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    const inputStyle = {
        textAlign: "center",
        backgroundColor: "#fff",
        border: "solid 0.5px #dfe4e8",
        borderRadius: "8px",
        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "90px",
        marginBottom: "10px"
    };

    const [validSaveButton, setValidSaveButton] = useState<boolean>(true);

    return (
        access ?
            <Grid container>

                <ModalComponent
                    description={messageAlert}
                    isOk={true}
                    open={isError}
                    close={() => { setIsError(false) }}
                    onCancel={() => { setIsError(false) }}
                    onNext={() => setIsError(false)}
                />
                <ModalReadPage open={read} onCancel={() => setRead(false)} />
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingBottom: "16px" }}>
                        <LabelComponent text="Calendarização das fases" fontSize="20px" />
                    </Grid>
                </Grid>

                {typedValuesPhasesArray && typedValuesPhasesArray?.listTPPhaseSchedule?.length > 0 ?
                    <>
                        <Grid item xs={12} sm={12} md={5.8} lg={5.75} sx={{ backgroundColor: "#dfe4e8", borderRadius: "10px 10px 10px 10px", padding: "20px" }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={2} lg={3}></Grid>
                                <Grid item xs={12} sm={12} md={8} lg={6}>
                                    <Grid container sx={{ paddingBottom: "8px" }}>
                                        <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ paddingLeft: "32px" }}>
                                            <LabelComponent text="KW" fontSize="14px" />
                                        </Grid>

                                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ paddingLeft: "32px" }}>
                                            <LabelComponent text="Year" fontSize="14px" />
                                        </Grid>
                                    </Grid>

                                    {typedValuesPhasesArray.listTPPhaseSchedule.slice(0, 5).map((item: any, key: number) =>
                                        <Grid container key={key}>
                                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ paddingTop: "16px" }}>
                                                <LabelComponent text={item.name ? item.name : item.type} fontSize="14px" />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                                <Box sx={inputStyle}>
                                                    {item.kw && <span style={{ paddingLeft: "10px" }}>KW</span>}
                                                    <NumberFormat
                                                        style={{ border: "none", fontSize: "15px", height: "40px", paddingLeft: "5px", paddingTop: "3px", outline: "none", width: "40px" }}
                                                        allowNegative={false}
                                                        displayType={"input"}
                                                        format={"##"}
                                                        placeholder="KW"
                                                        name={item.type}
                                                        value={item.kw ? item.kw : ''}
                                                        onChange={(event: any) => {
                                                            let array = typedValuesPhasesArray.listTPPhaseSchedule;

                                                            event.target.value < 1 && (event.target.value = '');
                                                            event.target.value > 53 && (event.target.value = event.target.value.substr(0, 1));

                                                            array.find((data: any) => {
                                                                if (item.type === data.type) {
                                                                    setValidSaveButton(true);
                                                                    data.kw = event.target.value;
                                                                    data.year = item.year;

                                                                    if (data.kw === '' || data.year === '' || !(data.year.replace(' ', '').length === 4)) {
                                                                        setValidSaveButton(false);
                                                                    } else {
                                                                        setValidSaveButton(true);
                                                                    }
                                                                }
                                                            });
                                                            setTypedValuesPhasesArray({ ...typedValuesPhasesArray, listTPPhaseSchedule: array });
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                                <Box sx={inputStyle}>
                                                    <NumberFormat
                                                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "40px" }}
                                                        allowNegative={false}
                                                        displayType={"input"}
                                                        format={"####"}
                                                        placeholder=""
                                                        value={item.year}
                                                        onChange={(event: any) => {
                                                            let array = typedValuesPhasesArray.listTPPhaseSchedule;
                                                            array.find((data: any) => {
                                                                if (item.type === data.type) {
                                                                    setValidSaveButton(true);
                                                                    data.kw = item.kw;
                                                                    data.year = event.target.value;

                                                                    if (data.kw === '' || data.year === '' || !(data.year.replace(' ', '').length === 4)) {
                                                                        setValidSaveButton(false);
                                                                    } else {
                                                                        setValidSaveButton(true);
                                                                    }
                                                                }
                                                            });
                                                            setTypedValuesPhasesArray({ ...typedValuesPhasesArray, listTPPhaseSchedule: array });
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={3}></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={1} lg={0.5}></Grid>

                        <Grid item xs={12} sm={12} md={5.5} lg={5.75} sx={{ backgroundColor: "#dfe4e8", borderRadius: "10px 10px 10px 10px", padding: "20px" }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={2} lg={3}></Grid>
                                <Grid item xs={12} sm={12} md={8} lg={6}>

                                    <Grid container sx={{ paddingBottom: "8px" }}>
                                        <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ paddingLeft: "32px" }}>
                                            <LabelComponent text="KW" fontSize="14px" />
                                        </Grid>

                                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ paddingLeft: "32px" }}>
                                            <LabelComponent text="Year" fontSize="14px" />
                                        </Grid>
                                    </Grid>

                                    {typedValuesPhasesArray.listTPPhaseSchedule.slice(5, 10).map((item: any, key: number) =>
                                        <Grid container key={key}>
                                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ paddingTop: "16px" }}>
                                                <LabelComponent text={item.name ? item.name : item.type} fontSize="14px" />
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                                <Box sx={inputStyle}>
                                                    {item.kw && <span style={{ paddingLeft: "10px" }}>KW</span>}
                                                    <NumberFormat
                                                        style={{ border: "none", fontSize: "15px", height: "40px", paddingLeft: "5px", paddingTop: "3px", outline: "none", width: "40px" }}
                                                        allowNegative={false}
                                                        displayType={"input"}
                                                        format={"##"}
                                                        placeholder="KW"
                                                        name={item.type}
                                                        value={item.kw ? item.kw : ''}
                                                        onChange={(event: any) => {
                                                            let array = typedValuesPhasesArray.listTPPhaseSchedule;
                                                            array.find((data: any) => {
                                                                if (item.type === data.type) {
                                                                    data.kw = event.target.value;
                                                                    data.year = item.year;

                                                                    if (data.kw === '' || data.year === '' || !(data.year.replace(' ', '').length === 4)) {
                                                                        setValidSaveButton(false);
                                                                    } else {
                                                                        setValidSaveButton(true);
                                                                    }
                                                                }
                                                            });
                                                            setTypedValuesPhasesArray({ ...typedValuesPhasesArray, listTPPhaseSchedule: array });
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5}>
                                                <Box sx={inputStyle}>
                                                    <NumberFormat
                                                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "40px" }}
                                                        allowNegative={false}
                                                        displayType={"input"}
                                                        format={"####"}
                                                        placeholder=""
                                                        value={item.year}
                                                        onChange={(event: any) => {
                                                            let array = typedValuesPhasesArray.listTPPhaseSchedule;
                                                            array.find((data: any) => {
                                                                if (item.type === data.type) {
                                                                    data.kw = item.kw;
                                                                    data.year = event.target.value;

                                                                    if (data.kw === '' || data.year === '' || !(data.year.replace(' ', '').length === 4)) {
                                                                        setValidSaveButton(false);
                                                                    } else {
                                                                        setValidSaveButton(true);
                                                                    }
                                                                }
                                                            });
                                                            setTypedValuesPhasesArray({ ...typedValuesPhasesArray, listTPPhaseSchedule: array });
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={3}></Grid>
                            </Grid>
                        </Grid>

                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                                <ButtonComponent text="Cancelar" disable={true} onClick={() => {
                                    props.setOpenCancelModal(true);
                                }} />
                            </Grid>
                            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                                <ButtonComponent text="Salvar"
                                    active={validSaveButton}
                                    onClick={() => fetchSavePhases(typedValuesPhasesArray, props.type, props.id)}
                                />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <LoadingComponente></LoadingComponente>
                }
            </Grid>
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1Phases;
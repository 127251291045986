import React, { useState, useEffect } from 'react';
import { post } from "../../api/api";
import { CONFIG } from '../../utils/config';

const useRoles = () => {
    const [kiraRoles, setKiraRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        fetch(CONFIG.API_URL + 'users/rolesKira', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erro ao buscar roles do usuário');
            }
            return response.json();
        })
        .then(data => {
            setKiraRoles(data);
        })
        .catch(error => {
            console.error(error.message);
        });
    }, []);

    return kiraRoles;
};

export default useRoles;
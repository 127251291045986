import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { Avatar, Box, Button, Stack } from "@mui/material";
import LabelComponent from "../label/labelComponent";

export default function DataTableGridComponent(props: DataTableGridField) {
    return (
        <Box
            sx={{
                height: props.isOneRegister ? 163 : 400,
                width: "100%",
                ".MuiDataGrid-columnHeadersInner": {
                    backgroundColor: "#dfe4e8"
                },
                ".MuiDataGrid-columnHeaderTitle": {
                    fontFamily: "VWHead",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#001e50",
                },
                ".MuiDataGrid-cellContent": {
                    fontFamily: "VWHead",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#000"
                },
                '& .disable': {
                    ".MuiDataGrid-cellContent": {
                        opacity: 0.3,
                    },
                },
            }}
        >
            <DataGrid
                getRowId={(row) => Math.random()}
                disableSelectionOnClick
                disableColumnMenu
                pagination
                paginationMode="server"
                rowsPerPageOptions={[1]}
                rows={props.rows}
                columns={props.columns}
                pageSize={props.pageSize}
                rowCount={props.totalElements}
                page={props.page}
                loading={props.loading}
                components={{
                    NoRowsOverlay: () => (
                        <Stack flexDirection="row" height="100%" alignItems="center" justifyContent="center">
                            <LabelComponent text={"Nenhum"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                margin={"0px 8px 0px 0px"} />
                            <LabelComponent text={"item encontrado. "}
                                margin-left={"8px !important"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                fontWeight={"normal"} />
                            <Button sx={{
                                zIndex: 5,
                                cursor: "auto",
                                color: "#00b0f0",
                                fontFamily: "VWHead",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                textAlign: "center",
                                textTransform: "none",
                            }} variant="text">
                            </Button>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack flexDirection="row" height="100%" alignItems="center" justifyContent="center">
                            <LabelComponent text={"Nenhum"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                margin={"0px 8px 0px 0px"} />
                            <LabelComponent text={"item encontrado com esses filtros. "}
                                margin-left={"8px !important"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                fontWeight={"normal"} />
                            <Button sx={{
                                zIndex: 5,
                                cursor: "auto",
                                color: "#00b0f0",
                                fontFamily: "VWHead",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                textAlign: "center",
                                textTransform: "none",
                            }} variant="text"
                                onClick={props.onClick}>
                                {"Refazer seleção"}</Button>
                        </Stack>
                    )
                }}
                getCellClassName={(params: GridCellParams<number>) => {
                    if (params.row.active === false && params.value !== undefined) {
                        return 'disable';
                    } else {
                        return '';
                    }
                }}
                componentsProps={{
                    pagination: {
                        component: "div",
                        labelRowsPerPage: "Linhas por página",
                        labelDisplayedRows: (value: any) => {
                            return `${value.from}–${value.to} de ${value.count !== -1 ? value.count : `mais do que ${value.to}`}`;
                        },
                        getItemAriaLabel: (type: string) => {
                            if (type === "next") {
                                return "Próxima página";
                            } else if (type === "previous") {
                                return "Página anterior";
                            }
                        }
                    }
                }}
            />
        </Box>
    );
}
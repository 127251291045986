type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import { format } from "../../utils/utils";
import useRoute from "../route/useRoute";

const useRequestTestTab1 = (state: Type) => {

    const [loading, setLoading] = useState(true);
    const [requestTests, setRequestTestes] = useState<RequestTest>();
    const [categories, setCategories] = useState<CategoryTypeTests[]>([]);
    const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
    const [testSubCategory, setTestSubCategory] = useState<SubCategory[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [phases, setPhases] = useState<Phase[]>([]);
    const [temperatures, setTemperatures] = useState<Temperature[]>([]);
    const [pointImpacts, setPointImpacts] = useState<PointImpact[]>([]);
    const [loadouts, setLoadouts] = useState<Loadout[]>([]);
    const [suportsTheads, setSuportsTheads] = useState<SuportsThead[]>([]);
    const [positionsSeats, setPositionsSeats] = useState<PositionsSeat[]>([]);
    const [positionsBelts, setPositionsBelts] = useState<PositionsBelt[]>([]);
    const [positionColumnDrives, setPositionColumnDrives] = useState<PositionColumnDrive[]>([]);
    const [descriptionsTrialPositions, setDescriptionsTrialPositions] = useState<DescriptionsTrialPosition[]>([]);
    const [descriptionsTrialDirections, setDescriptionsTrialDirections] = useState<DescriptionsTrialDirection[]>([]);
    const [descriptionsTrialsSides, setDescriptionsTrialsSidens] = useState<DescriptionsTrialsSide[]>([]);
    const [typesSeats, setTypesSeats] = useState<TypesSeat[]>([]);
    const [liberations, setLiberations] = useState<Liberation[]>([]);
    const [statusTests, setStatusTests] = useState<StatusTest[]>([]);
    const [statusFiles, setStatusFiles] = useState<StatusFile[]>([]);
    const [positions, setPositions] = useState<Position[]>([]);
    const [legislationAreas, setLegislationAreas] = useState<LegislationArea[]>([]);
    const [messageSave, setMessageSave] = useState<string>("");
    const [openAlert, setOpenAlert] = useState(false);
    const [fielsdErro, setFielsdErro] = useState<any[]>([]);
    const [valid, setValid] = useState(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [openError, setOpenError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("requisicao-teste"));
        fetchConfigurators();
        if (state.type !== "modal") {
            setRequestTestes({ ...requestTests, testDate: format(new Date()) });
        }

        if (state.type === "editar") {
            fetchRequestTests(state.id);
            setIsEdit(true);
            setValid(true)
        } else {
            if (state.stateLocation !== undefined && state.stateLocation !== null) {
                if (state.stateLocation.testDate) {
                    state.stateLocation.testDate = new Date(state.stateLocation.testDate);
                }
                setRequestTestes({
                    ...requestTests, testPlanId: state.stateLocation.testPlanId,
                    categoryId: state.stateLocation.categoryId,
                    testDate: state.stateLocation.testDate !== undefined ? format(state.stateLocation.testDate) : format(new Date())
                });
            }
            fetchCategory();
        }
        fetchUser();
        fetchPhase();
    }, []);

    const fetchRequestTests = (id: number) => {
        setLoading(true);
        get("requestTests/" + id).then((value) => {
            setRequestTestes(value);
            get("categoryTypeTests").then((valueCategory) => {
                setCategories(valueCategory);
                valueCategory.map((cat: any) => {
                    if (cat.id === value.categoryId) {
                        setSubCategory(cat.subCategories);
                        cat.subCategories.map((sub: any) => {
                            if (sub.id === value.subCategoryId) {
                                get("laboratories/typeTests/subcategory/" + sub.id).then((value) => {
                                    setTestSubCategory(value);
                                }).catch(() => setLoading(false))
                                    .finally(() => setLoading(false));
                            }
                        });
                    }
                });
            }).catch(() => setLoading(false));
        }).catch(() => setLoading(false));
    }

    const fetchSubmit = (body: any) => {
        if (valid) {
            post("requestTests", body).then((value) => {
                if (value.codeStatus === 200 || value.codeStatus === 201) {
                    setOpenAlert(true);
                    setRequestTestes(value);
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    }
                    setFielsdErro(value.fields)
                    setMessageSave(value.errorMessage);
                    setOpenError(true);
                }
            });
        }
    }

    const fetchSubmitTab1AndTab2 = (body1: any, body2: any) => {
        if (valid) {
            post("requestTests", body1)
                .then((valueRequest) => {
                    if (valueRequest.codeStatus === 200 || valueRequest.codeStatus === 201) {
                        let projectCar = {
                            "projectCarId": body2.projectCar.projectCarId,
                            "carId": body2.projectCar.car.id,
                            "requestTestId": valueRequest.id
                        }
                        post("requestTests/projectCar", projectCar)
                            .then((value) => {
                                if (value.codeStatus === 400) {
                                } else {
                                    if (value.errorMessage === 'Not Operation') {
                                        setRead(true)
                                    }
                                    setOpenAlert(true)
                                }
                            });
                    } else {
                        if (valueRequest.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setFielsdErro(valueRequest.fields)
                        setMessageSave(valueRequest.errorMessage);
                        setOpenError(true);
                    }
                });
        }
    }

    const fetchCategory = () => {
        setLoading(true);
        get("categoryTypeTests").then((value) => {
            setCategories(value);
        }).finally(() => setLoading(false));
    }

    const fetchTestSubCategory = (id: any) => {
        setLoading(true);
        get("laboratories/typeTests/subcategory/" + id).then((value) => {
            setTestSubCategory(value)
        }).finally(() => setLoading(false));
    }

    const fetchUser = () => {
        setLoading(true);
        get("users").then((value) => {
            setUsers(value)
        }).finally(() => setLoading(false));
    }

    const fetchConfigurators = () => {
        setLoading(true);
        get("requestTests/configurators/stage/1")
            .then((value) => {
                setTemperatures(value.temperatures);
                setPointImpacts(value.pointImpacts);
                setLoadouts(value.loadouts);
                setSuportsTheads(value.suportsTheads);
                setPositionsSeats(value.positionsSeats);
                setPositionsBelts(value.positionsBelts);
                setPositionColumnDrives(value.positionColumnDrives);
                setDescriptionsTrialPositions(value.descriptionsTrialPositions);
                setDescriptionsTrialDirections(value.descriptionsTrialDirections);
                setDescriptionsTrialsSidens(value.descriptionsTrialsSides);
                setTypesSeats(value.typesSeats);
                setLiberations(value.liberations);
                setStatusTests(value.statusTests);
                setStatusFiles(value.statusFiles);
                setPositions(value.positions);
                setLegislationAreas(value.legislationAreas);
            }).finally(() => setLoading(false));
    }

    const fetchPhase = () => {
        setLoading(true);
        get<Phase>("testPlans/phases")
            .then((phase) => {
                setPhases(phase);
            }).finally(() => setLoading(false));
    }

    const fetchChildren = (requestTestId: number) => {
        setLoading(true);
        post('requestTests/children/' + requestTestId)
            .then((children) => {
                fetchRequestTests(requestTestId);
            }).finally(() => setLoading(false));
    }

    const fetchDeactiveTest = (id: number) => {
        put('requestTests/disable/' + id).then(() => {
            fetchRequestTests(state.id);
        });
    }

    return {
        requestTests, setRequestTestes,
        categories, access,
        subCategory, setSubCategory,
        testSubCategory, setTestSubCategory,
        users, setUsers,
        messageSave, setMessageSave,
        openAlert, setOpenAlert,
        fielsdErro, read, setRead,
        valid, setValid,
        isEdit, setIsEdit,
        openError, setOpenError,
        openModal, setOpenModal,
        loading, setLoading,
        phases, setPhases,
        temperatures, setTemperatures,
        pointImpacts, setPointImpacts,
        loadouts, setLoadouts,
        suportsTheads, setSuportsTheads,
        positionsSeats, setPositionsSeats,
        positionsBelts, setPositionsBelts,
        positionColumnDrives, setPositionColumnDrives,
        descriptionsTrialPositions, setDescriptionsTrialPositions,
        descriptionsTrialDirections, setDescriptionsTrialDirections,
        descriptionsTrialsSides, setDescriptionsTrialsSidens,
        typesSeats, setTypesSeats,
        liberations, setLiberations,
        statusTests, setStatusTests,
        statusFiles, setStatusFiles,
        positions, setPositions,
        legislationAreas, setLegislationAreas,
        fetchCategory, fetchTestSubCategory,
        fetchConfigurators, fetchSubmit, fetchChildren,
        fetchRequestTests, fetchDeactiveTest, fetchSubmitTab1AndTab2
    };
}

export default useRequestTestTab1;
type Type = /*unresolved*/ any
import { Accordion, AccordionDetails, Chip, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AccordionComponent from "../../components/accordion/accordionComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import TextareaAutosizeComponent from "../../components/field/textareaAutosize";
import TextFieldComponent from "../../components/field/textFieldComponent";
import LabelComponent from "../../components/label/labelComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalComponent from "../../components/modal/modalComponent";
import ModalReadPage from "../../components/modal/modalReadPage";
import RadioButtonComponent from "../../components/radio/radioButtonComponent";
import SelectComponent from "../../components/select/selectComponent";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import useRequestTestTab1 from "../../hooks/requestTest/useRequestTestTab1";
import LaunchIcon from '@mui/icons-material/Launch';
import { format } from "../../utils/utils";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function RequestTestPageTab1() {

  const { type, id, testPlanId } = useParams();
  const location = useLocation();
  const windowParam = window.name != "" ? JSON.parse(window.name) : "";
  const stateLocation = windowParam != "" ? windowParam : location.state as Type;
  const navigate = useNavigate();
  const {
    requestTests, setRequestTestes,
    subCategory, setSubCategory,
    testSubCategory, setTestSubCategory,
    users, categories,
    phases, setPhases,
    temperatures, setTemperatures,
    pointImpacts, setPointImpacts,
    loadouts, setLoadouts,
    suportsTheads, setSuportsTheads,
    positionsSeats, setPositionsSeats,
    positionsBelts, setPositionsBelts,
    positionColumnDrives, setPositionColumnDrives,
    descriptionsTrialPositions, setDescriptionsTrialPositions,
    descriptionsTrialDirections, setDescriptionsTrialDirections,
    descriptionsTrialsSides, setDescriptionsTrialsSidens,
    typesSeats, setTypesSeats,
    liberations, setLiberations,
    statusTests, setStatusTests,
    statusFiles, setStatusFiles,
    positions, setPositions,
    legislationAreas, setLegislationAreas,
    messageSave, setMessageSave,
    openAlert, setOpenAlert,
    loading, read, setRead,
    valid, setValid,
    isEdit, setIsEdit,
    openError, setOpenError,
    openModal, setOpenModal,
    fetchTestSubCategory, fetchSubmit,
    fetchChildren, fetchDeactiveTest, access
  } = useRequestTestTab1({ type, id, stateLocation });

  const [expanded, setExpanded] = useState<string | false>(false);
  const [currentModal, setCurrentModal] = useState<string>();
  const handleChange = (panel: string, modal: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setCurrentModal(modal);
  };

  const [isOtherPosition, setIsOtherPosition] = useState<boolean>(true);
  const [isOtherDirection, setIsOtherDirection] = useState<boolean>(true);

  const handleClose = (event: React.SyntheticEvent | Event) => {
    window.location.pathname = '/stapp/requisicao-teste/editar/' + requestTests?.id;
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (requestTests?.categoryId && categories.length > 0) {
      categories.map((category) => {
        if (category.id === requestTests.categoryId) {
          setSubCategory(category.subCategories);
        }
      });
    }

  }, [requestTests?.categoryId, categories])

  return (
    access ?
      <Grid>
        <SnackbarComponent isTab={true} text={isEdit ? "Requisição de teste editada com sucesso" : "Requisição de teste salva com sucesso"}
          open={openAlert}
          closeSnackbar={handleClose} />
        <ModalComponent text=""
          open={openModal}
          description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setValid(false);
            setOpenModal(false);
            setIsEdit(false);
            window.location.reload();
          }} />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        {loading ? <LoadingComponente /> : null}
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', 'projectDetails')} sx={{ boxShadow: "none" }}>
          <AccordionComponent text="Detalhes do teste"></AccordionComponent>
          <AccordionDetails>
            <Grid item xs={12}>
              <LabelComponent text="Detalhes " fontSize="28px" />
              <LabelComponent
                text="do Teste"
                fontSize="28px"
                fontWeight="normal"
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <TextFieldComponent text="Número do teste"
                  value={requestTests?.testNumber ? requestTests.testNumber : ""}
                  required={true}
                  helperText={messageSave !== "" ? messageSave : ""}
                  error={messageSave !== "" ? true : false}
                  onChange={(value) => {
                    setMessageSave("");
                    setRequestTestes({ ...requestTests, testNumber: value.target.value });
                    if (value.target.value !== "" && requestTests?.testDate !== undefined &&
                      requestTests?.testPlanId !== undefined) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Categoria"
                  required={true}
                  value={requestTests?.categoryId ? requestTests.categoryId : ""}
                  values={categories}
                  onSelect={(event: any) => {
                    if (event.target.value !== "") {
                      categories.map((category) => {
                        if (category.id === event.target.value) {
                          setSubCategory(category.subCategories);
                        }
                      });
                      setRequestTestes({ ...requestTests, categoryId: event.target.value });
                    } else {
                      setValid(false);
                      setSubCategory([]);
                      setTestSubCategory([]);
                      setRequestTestes({ ...requestTests, categoryId: undefined, subCategoryId: undefined, typeTestId: undefined });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Sub-categoria"
                  required={true}
                  value={requestTests?.subCategoryId ? requestTests.subCategoryId : ""}
                  values={subCategory}
                  onSelect={(event: any) => {
                    if (event.target.value !== "") {
                      fetchTestSubCategory(event.target.value);
                      setRequestTestes({ ...requestTests, subCategoryId: event.target.value });
                    } else {
                      setValid(false);
                      setTestSubCategory([]);
                      setRequestTestes({ ...requestTests, subCategoryId: undefined, typeTestId: undefined });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Nome do teste"
                  required={true}
                  value={requestTests?.typeTestId ? requestTests.typeTestId : ""}
                  values={testSubCategory}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, typeTestId: event.target.value });
                    if (event.target.value !== "" &&
                      requestTests?.testDate !== undefined &&
                      requestTests?.testNumber !== undefined &&
                      requestTests?.categoryId !== undefined &&
                      requestTests?.subCategoryId !== undefined
                    ) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent text="Fase do teste"
                  required={true}
                  value={requestTests?.testPhase ? requestTests.testPhase : ""}
                  values={phases}
                  isFilter={true}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, testPhase: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectPaginationComponent
                  text={"Plano de teste"}
                  url="testPlans/select/all"
                  status="Aprovado - Draft"
                  required={true}
                  value={requestTests?.testPlanId ? requestTests.testPlanId : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, testPlanId: event });
                    if (event !== "" && requestTests?.testDate !== undefined &&
                      requestTests?.testNumber !== undefined) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                <DateFieldComponent
                  text={"Data do teste"}
                  value={requestTests?.testDate ? new Date(requestTests?.testDate + "T00:00:00") : ""}
                  required={true}
                  onChangeDate={(newValue: Date) => {
                    if (newValue !== null) {
                      if (newValue.toString() !== "Invalid Date") {
                        setRequestTestes({ ...requestTests, testDate: format(newValue) });
                      } else {
                        setRequestTestes({ ...requestTests, testDate: "" });
                      }
                    } else {
                      setRequestTestes({ ...requestTests, testDate: "" });
                    }
                    if ((newValue !== null && newValue.getFullYear().toString().length === 4) && requestTests?.testPlanId !== undefined &&
                      requestTests?.testNumber !== undefined) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Liberação"
                  value={requestTests?.liberation ? requestTests.liberation : ""}
                  values={liberations}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, liberation: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            {(requestTests?.categoryId !== 1 &&
              requestTests?.categoryId !== 2 &&
              requestTests?.categoryId !== 5 &&
              requestTests?.categoryId !== 11) &&
              requestTests?.tchildren === false ?
              <Grid container>
                <Grid item xs={12}>
                  <LabelComponent text="Sequência " fontSize="28px" />
                  <LabelComponent
                    text="do Teste"
                    fontSize="28px"
                    fontWeight="normal"
                  />
                </Grid>

                <Grid item xs={12} marginTop={"16px"} marginBottom={"16px"}>
                  <Stack direction="row" display={"block"} alignItems={"start"}>
                    {requestTests?.tchildrens?.map((children) =>
                      <Chip
                        label={children.name}
                        variant="outlined"
                        icon={<LaunchIcon />}
                        sx={{
                          marginRight: "16px",
                          marginBottom: "16px",
                          '&.MuiButtonBase-root .MuiChip-icon.MuiChip-iconMedium': { color: '#00B0F0' },
                          '&.MuiButtonBase-root .MuiChip-deleteIcon.MuiChip-deleteIconMedium': { color: '#da4553' }
                        }}
                        onClick={(event: any) => window.open('/stapp/requisicao-teste/editar/' + children.id, '_blank')}
                        onDelete={(event: any) => {
                          children.id && fetchDeactiveTest(children.id);
                        }}
                      />
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <ButtonComponent
                    width={"160px"}
                    text={"Adicionar novo"}
                    border={"1px dashed #c2cacf"}
                    backgroundColor={"#fff"}
                    borderRadius={"4px;"}
                    color={"#00b0f0"}
                    isAdd={true}
                    onClick={() => {
                      fetchChildren(Number(requestTests?.id))
                    }}
                  />
                </Grid>
              </Grid>
              : null}
            <Grid sx={{ display: "flex" }}>
              <Grid item xs={12} sm={12} md={12} lg={5.84} sx={{ marginRight: "24px", marginTop: "24px" }}>
                <LabelComponent text="Norma " fontSize="28px" />
                <LabelComponent
                  text="do Teste"
                  fontSize="28px"
                  fontWeight="normal"
                />
                <TextareaAutosizeComponent
                  width="10px"
                  value={requestTests?.norm ? requestTests.norm : ""}
                  placeholder={"Norma"}
                  readOnly={false}
                  onChangeArea={(event) => {
                    setRequestTestes({ ...requestTests, norm: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <LabelComponent text="Informações " fontSize="28px" marginTop="32px" />
                <LabelComponent text="de Contato" fontSize="28px" fontWeight="normal" />
                <SelectComponent
                  text="Usuário"
                  values={users}
                  value={requestTests?.userId ? requestTests.userId : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, userId: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "32px" }} >
              <LabelComponent text="Finalidade " fontSize="28px" />
              <LabelComponent text="do Teste" fontSize="28px" fontWeight="normal" />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextareaAutosizeComponent
                  placeholder={"Finalidade do teste"}
                  value={requestTests?.reason ? requestTests.reason : ""}
                  onChangeArea={(value) => {
                    setRequestTestes({ ...requestTests, reason: value.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2', 'testData')} disabled={type === "novo" ? true : false} sx={{ boxShadow: "none" }}>
          <AccordionComponent text="Dados do teste"></AccordionComponent>
          <AccordionDetails>
            <Grid container>
              <Grid
                hidden={requestTests?.categoryId === 1 || requestTests?.categoryId === 2 || requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Massa do teste"
                  type="number"
                  endAdornment="kg"
                  value={requestTests?.testMass ? requestTests.testMass : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, testMass: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 1 || requestTests?.categoryId === 2 || requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Velocidade programada"
                  type="number"
                  endAdornment="km/h"
                  value={requestTests?.programmedVelocity ? requestTests.programmedVelocity : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, programmedVelocity: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 1 || requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Velocidade do teste"
                  type="number"
                  endAdornment="km/h"
                  value={requestTests?.testVelocity ? requestTests.testVelocity : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, testVelocity: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Área de legislação"
                  endAdornment="mm"
                  values={legislationAreas}
                  value={requestTests?.legislationArea ? requestTests.legislationArea : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, legislationArea: event.target.value });
                  }} />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 1 ||
                  requestTests?.categoryId === 4 ||
                  requestTests?.categoryId === 6 ||
                  requestTests?.categoryId === 7 ||
                  requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Dummies"
                  value={requestTests?.dummies ? requestTests.dummies : ""}
                  readOnly={true}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Grid NCAP"
                  value={requestTests?.gridNcap ? requestTests.gridNcap : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, gridNcap: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Coordenada X"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.coordX ? requestTests.coordX : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, coordX: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Coordenada Y"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.coordY ? requestTests.coordY : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, coordY: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Coordenada Z"
                  endAdornment="mm"
                  value={requestTests?.coordZ ? requestTests.coordZ : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, coordZ: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Ângulo"
                  type="number"
                  endAdornment="°"
                  value={requestTests?.angle ? requestTests.angle : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, angle: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Altura Z Perna"
                  value={requestTests?.heightZ ? requestTests.heightZ : ""}
                  endAdornment="mm"
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, heightZ: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 2 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Energia"
                  endAdornment="kN"
                  value={requestTests?.energy ? requestTests.energy : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, energy: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Ponto de impacto"
                  values={pointImpacts}
                  value={requestTests?.pointImpact ? requestTests.pointImpact : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, pointImpact: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Carregamento"
                  values={loadouts}
                  value={requestTests?.loadout ? requestTests.loadout : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, loadout: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Atitude DE"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.attitudeDE ? requestTests.attitudeDE : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, attitudeDE: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Atitude TE"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.attitudeTE ? requestTests.attitudeTE : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, attitudeTE: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Atitude DD"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.attitudeDD ? requestTests.attitudeDD : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, attitudeDD: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid hidden={requestTests?.categoryId === 5 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Atitude TD"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.attitudeTD ? requestTests.attitudeTD : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, attitudeTD: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Temperatura"
                  type="number"
                  endAdornment="°C"
                  value={requestTests?.temperature ? requestTests.temperature : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, temperature: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Tipo da temperatura"
                  values={temperatures}
                  value={requestTests?.temperatureType ? requestTests.temperatureType : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, temperatureType: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Umidade"
                  type="number"
                  endAdornment="%"
                  value={requestTests?.humidity ? requestTests.humidity : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, humidity: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 || requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Altura do apoio de cabeça"
                  values={suportsTheads}
                  value={requestTests?.supportHead ? requestTests.supportHead : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, supportHead: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 || requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Ângulo de encosto"
                  type="number"
                  endAdornment="°"
                  value={requestTests?.angularRestHead ? requestTests.angularRestHead : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, angularRestHead: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 || requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Posicionamento do banco"
                  values={positionsSeats}
                  value={requestTests?.positionSeat ? requestTests.positionSeat : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, positionSeat: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 || requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Posicionamento do regulador de altura do cinto"
                  values={positionsBelts}
                  value={requestTests?.positionBelt ? requestTests.positionBelt : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, positionBelt: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <TextFieldComponent text="Ponto H"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.pointH ? requestTests.pointH : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, pointH: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 6 || requestTests?.categoryId === 9 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Posicionamento da coluna de direção"
                  values={positionColumnDrives}
                  value={requestTests?.positionColumnDrive ? requestTests.positionColumnDrive : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, positionColumnDrive: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Tipo de banco"
                  values={typesSeats}
                  value={requestTests?.typeSeat ? requestTests.typeSeat : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, typeSeat: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Posição do Varibo"
                  value={requestTests?.positionVaribo ? requestTests.positionVaribo : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, positionVaribo: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Ponto de impacto"
                  endAdornment="mm"
                  value={requestTests?.pointImpact ? requestTests.pointImpact : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, pointImpact: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 9 || requestTests?.categoryId === 10 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Força"
                  type="number"
                  endAdornment="kN"
                  value={requestTests?.strength ? requestTests.strength : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, strength: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 9 || requestTests?.categoryId === 10 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Variação de Altura ∆h"
                  type="number"
                  endAdornment="mm"
                  value={requestTests?.heightVariation ? requestTests.heightVariation : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, heightVariation: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 4 || requestTests?.categoryId === 6 || requestTests?.categoryId === 7 || requestTests?.categoryId === 8 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Posição"
                  values={positions}
                  value={requestTests?.positionTypeBank ? requestTests.positionTypeBank : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, positionTypeBank: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sx={{ marginBottom: "32px", paddingRight: "20px", }}>
                <RadioButtonComponent text={"Características do Ensaio - Posição"}
                  values={descriptionsTrialPositions}
                  value={requestTests?.descriptionTrialPosition ? requestTests.descriptionTrialPosition : ""}
                  onChange={(event: any) => {
                    if (event.target.value === "3") {
                      setIsOtherPosition(false);
                    } else {
                      setIsOtherPosition(true);
                    }
                    setRequestTestes({ ...requestTests, descriptionTrialPosition: event.target.value });
                  }}
                />
                <TextFieldComponent text="Outros"
                  width="200px"
                  marginLeft="40px"
                  marginTop="8px"
                  disabled={isOtherPosition}
                  value={requestTests?.descriptionTrialPositionOther ? requestTests.descriptionTrialPositionOther : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, descriptionTrialPositionOther: value.target.value });
                  }}
                />
              </Grid>
              <Grid hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <RadioButtonComponent text={"Características do Ensaio - Direção"}
                  values={descriptionsTrialDirections}
                  value={requestTests?.descriptionTrialDirection ? requestTests.descriptionTrialDirection : ""}
                  onChange={(event: any) => {
                    if (event.target.value === "3") {
                      setIsOtherDirection(false);
                    } else {
                      setIsOtherDirection(true);
                    }
                    setRequestTestes({ ...requestTests, descriptionTrialDirection: event.target.value });
                  }}
                />
                <TextFieldComponent text="Outros"
                  width="200px"
                  marginLeft="40px"
                  marginTop="8px"
                  disabled={isOtherDirection}
                  value={requestTests?.descriptionTrialDirectionOther ? requestTests.descriptionTrialDirectionOther : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, descriptionTrialDirectionOther: value.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <RadioButtonComponent text={"Características do Ensaio - Lado"}
                  values={descriptionsTrialsSides}
                  value={requestTests?.descriptionTrialSide ? requestTests.descriptionTrialSide : ""}
                  onChange={(event: any) => {
                    setRequestTestes({ ...requestTests, descriptionTrialSide: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Forças Especificadas"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.strengthSpecific ? requestTests.strengthSpecific : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, strengthSpecific: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Forças Especificadas"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.strengthSpecificTime ? requestTests.strengthSpecificTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, strengthSpecificTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Forças Obtidas"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.strengthObtained ? requestTests.strengthObtained : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, strengthObtained: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Forças Obtidas"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.strengthObtainedTime ? requestTests.strengthObtainedTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, strengthObtainedTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal LE"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.diagLE ? requestTests.diagLE : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagLE: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal LE"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.diagLETime ? requestTests.diagLETime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagLETime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal LE"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.subabdominalLE ? requestTests.subabdominalLE : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalLE: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal LE"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.subabdominalLETime ? requestTests.subabdominalLETime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalLETime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal Central"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.diagCentral ? requestTests.diagCentral : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagCentral: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal Central"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.diagCentralTime ? requestTests.diagCentralTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagCentralTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal Central"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.subabdominalCentral ? requestTests.subabdominalCentral : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalCentral: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal Central"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.subabdominalCentralTime ? requestTests.subabdominalCentralTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalCentralTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal LD"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.diagLD ? requestTests.diagLD : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagLD: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Diagonal LD"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.diagLDTime ? requestTests.diagLDTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, diagLDTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal LD"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.subabdominalLD ? requestTests.subabdominalLD : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalLD: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="Subabdominal LD"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.subabdominalLDTime ? requestTests.subabdominalLDTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, subabdominalLDTime: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="CG do Banco"
                  type="number"
                  endAdornment="N"
                  value={requestTests?.cgSeat ? requestTests.cgSeat : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, cgSeat: Number(value.target.value) });
                  }}
                />
              </Grid>
              <Grid
                hidden={requestTests?.categoryId === 3 ? false : true}
                item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="CG do Banco"
                  type="number"
                  endAdornment="s"
                  value={requestTests?.cgSeatTime ? requestTests.cgSeatTime : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, cgSeatTime: Number(value.target.value) });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3', 'technicalOpinionAndConclusion')} disabled={type === "novo" ? true : false} sx={{ boxShadow: "none" }}>
          <AccordionComponent text="Parecer técnico e conclusão "></AccordionComponent>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <LabelComponent text="Parecer " fontSize="28px" marginTop="32px" />
                <LabelComponent
                  text="técnico e conclusão"
                  fontSize="28px"
                  fontWeight="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginTop: "16px", marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Status"
                  values={statusTests}
                  value={requestTests?.statusTest ? requestTests.statusTest : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, statusTest: event.target.value !== "" ? event.target.value : null });
                  }}
                />
              </Grid>
              <Grid item sx={{ marginTop: "32px" }} xs={12} sm={12} md={12} lg={12} >
                <TextareaAutosizeComponent
                  value={requestTests?.techOpinion ? requestTests.techOpinion : ""}
                  placeholder={"Parecer técnico"}
                  onChangeArea={(event) => {
                    setRequestTestes({ ...requestTests, techOpinion: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4', 'relevantFiles')} disabled={type === "novo" ? true : false} sx={{ boxShadow: "none" }}>
          <AccordionComponent text="Arquivos relevantes"></AccordionComponent>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}  >
                <SelectComponent
                  text="Status - Folha tarefa"
                  values={statusFiles}
                  value={requestTests?.taskPaperStatus ? requestTests.taskPaperStatus : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, taskPaperStatus: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Status - Apresentação"
                  values={statusFiles}
                  value={requestTests?.presentationStatus ? requestTests.presentationStatus : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, presentationStatus: event.target.value });
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <SelectComponent
                  text="Status - Relatório"
                  values={statusFiles}
                  value={requestTests?.reportStatus ? requestTests.reportStatus : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, reportStatus: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  text="Status - Sistema PSE"
                  values={statusFiles}
                  value={requestTests?.pseSystemStatus ? requestTests.pseSystemStatus : ""}
                  onSelect={(event: any) => {
                    setRequestTestes({ ...requestTests, pseSystemStatus: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }} >
                <TextFieldComponent text="URL - Folha tarefa" value={requestTests?.taskPaperURL ? requestTests.taskPaperURL : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, taskPaperURL: value.target.value });
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="URL - Apresentação" value={requestTests?.presentationURL ? requestTests.presentationURL : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, presentationURL: value.target.value });
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="URL - Relatório"
                  value={requestTests?.reportURL ? requestTests.reportURL : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, reportURL: value.target.value });
                  }} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent text="URL - Sistema PSE"
                  value={requestTests?.pseSystemURL ? requestTests.pseSystemURL : ""}
                  onChange={(value) => {
                    setRequestTestes({ ...requestTests, pseSystemURL: value.target.value });
                  }} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={5} sm={4} md={2} lg={2}>
            <ButtonComponent
              text="Cancelar"
              disable={true}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </Grid>
          <Grid item xs={5} sm={4} md={2} lg={2}>
            <ButtonComponent active={valid} text="Salvar" onClick={() => fetchSubmit(requestTests)} />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default RequestTestPageTab1;
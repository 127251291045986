import HeaderComponent from "../../components/header/headerComponent";
import Grid from '@mui/material/Grid';
import { Box, RadioGroup } from "@mui/material";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import SearchComponent from "../../components/search/searchComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import RadioButtonColorComponent from "../../components/radio/radioButtonColorComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from 'react';
import { get, post, put } from '../../api/api';
import ModalReadPage from "../../components/modal/modalReadPage";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function RegisterModelPage() {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [valid, setValid] = useState(false);
  const [messageSave, setMessageSave] = useState<string>("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [id, setId] = useState<number | null>(0);
  const [carsAll, setCarsAll] = useState<CarsAll[]>([]);
  const [cars, setCars] = useState<CarsAll>();
  const [carsBrand, setCarsBrand] = useState<CarsBrand[]>([]);
  const [carsCategory, setCarsCategory] = useState<CarsCategory[]>([]);
  const [read, setRead] = useState<boolean>(false);
  const [model, setModel] = useState<string>("");
  const [project, setProject] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [carsAllId, setCarsAllId] = useState<string>("");
  const [carsBrandId, setCarsBrandId] = useState<string>("");
  const [carsCategoryId, setCarsCategoryId] = useState<string>("");
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();

  const columns: GridColDef[] = [
    { field: "model", headerName: "Nome do modelo", flex: 1, minWidth: 200 },
    {
      field: "brand", headerName: "Nome do fabricante", flex: 1, minWidth: 200,
      valueGetter: (params) => {
        return params.row.brand.name;
      }
    },
    {
      field: "category", headerName: "Tipo de carroceria", flex: 1, minWidth: 200,
      valueGetter: (params) => {
        return params.row.category.name;
      }
    },
    {
      field: "project", headerName: "Nº do projeto", flex: 1, minWidth: 200,
    },
    {
      field: "family", headerName: "Código da família", flex: 1, minWidth: 200,
    },
    {
      field: "action", headerName: "Ação", flex: 1, minWidth: 250,
      renderCell: (value) => {
        if (value.row.active) {
          return <Box flex={3}>
            <ButtonTextComponent text="Desativar" onClick={() => {
              setOpenDisable(true);
              setId(value.row.id);
            }} />
            <ButtonTextComponent text="Editar" onClick={() => {
              setId(value.row.id);
              setModel(value.row.model);
              setProject(value.row.project);
              setFamily(value.row.family);
              setCarsBrandId(value.row.brand.id);
              setCarsCategoryId(value.row.category.id);
              setValid(true);
              setIsEdit(true);
            }} />

          </Box>
        } else {
          return <Box flex={1}>
            <ButtonTextComponent text="Ativar" onClick={() => {
              setCarsAll([]);
              setLoading(true);
              put('cars/' + value.row.id + '/active/' + true).then((value) => {
                getList(filter, filter1, filter2, page, pageSize);
              });
            }} />
          </Box>
        }
      }
    },
  ]

  useEffect(() => {
    setAccess(fetchPage("cadastro-modelos"));
    getList(filter, filter1, filter2, page, pageSize);
    getCarsBrand();
    getCarsCategory();
  }, []);

  function getList(filter: string, filter1: string, filter2: string, page: number, pageSize: number) {
    if (!isFilter) {
      post('cars/all', { "param": filter, "page": page, "size": pageSize }).then((value) => {
        setCarsAll(value.content);
        setTotalElements(value.totalElements);
        setTotalPages(value.totalPages);
        setLoading(false);
      });
    } else {
      post('cars/all',
        {
          "param": filter,
          "brand": filter1,
          "page": page,
          "category": filter2,
          "size": pageSize
        }).then((value) => {
          setCarsAll(value.content);
          setTotalElements(value.totalElements);
          setTotalPages(value.totalPages);
          setLoading(false);
        });
    }
  };

  function getCarsBrand() {
    get('carBrands').then((value) => {
      setCarsBrand(value);
    })
  }

  function getCarsCategory() {
    get('carCategories').then((value) => {
      setCarsCategory(value);
    })
  }

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      getList("", "", "", page, pageSize);
    } else {
      setFilter(event.target.value);
      getList(event.target.value, filter1, filter2, page, pageSize);
    }
  }

  const handleSubmit = (e: any) => {
    if (!isEdit && id == 0) {
      post<CarsAll[]>("cars", {
        "carBrandId": carsBrandId,
        "carCategoryId": carsCategoryId,
        "family": family,
        "model": model,
        "project": project,
      }).then((value: CarsAll) => {
        if (value.codeStatus === 201) {
          setOpenAlert(true)
        } else {
          if (value.errorMessage === 'Not Operation') {
            setRead(true)
          }
          setMessageSave(value.errorMessage);
        }
      });
    } else {
      put<CarsAll[]>("cars", {
        "id": id,
        "carBrandId": carsBrandId,
        "carCategoryId": carsCategoryId,
        "family": family,
        "model": model,
        "project": project,
      }).then((value: CarsAll) => {
        if (value.codeStatus === 200) {
          setOpenAlert(true)
        } else {
          if (value.errorMessage === 'Not Operation') {
            setRead(true)
          }
          setMessageSave(value.errorMessage);
        }
      });
    }
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  return (
    access ?
    <div>
      <HeaderComponent text="Cadastro de Sistema / Cadastro de Modelos" access={access}/>
      <SnackbarComponent text={isEdit ? "Modelo editado com sucesso" : "Modelo salvo com sucesso"} open={openAlert} closeSnackbar={handleClose} />
      <ModalComponent description="Ao desativar este Modelo, não será mais possível atribuir novas interações à ele."
        open={openDisable}
        close={handleCloseModalDisable}
        onCancel={() => { setOpenDisable(false) }}
        onNext={() => {
          setCarsAll([]);
          setLoading(true);
          put('cars/' + id + '/active/' + false).then((value) => {
            getList(filter, filter1, filter2, page, pageSize);
            setId(null);
            setOpenDisable(false);
          });
        }} />

      <ModalComponent text="Atenção!"
        description={isEdit ? "Deseja realmente cancelar a edição do Modelo?" : "Deseja realmente cancelar a criação de um novo Modelo?"}
        open={openModal}
        close={handleCloseModal}
        onCancel={() => { setOpenModal(false); }}
        onNext={() => {
          setModel("");
          setCarsBrandId("");
          setCarsCategoryId("");
          setProject("");
          setFamily("");
          setValid(false);
          setIsEdit(false);
          setOpenModal(false);
          setMessageSave("");
        }} />

      <ModalFilterComponent
        open={openFilter}
        labelFilter1={"Fabricante"}
        value1={filter1}
        filter1={carsBrand}
        onSelect1={(event: any) => {
          setFilter1(event.target.value);
          setIsFilter(true);
        }}
        labelFilter2={"Tipo de carroceria"}
        value2={filter2}
        filter2={carsCategory}
        onSelect2={(event: any) => {
          setFilter2(event.target.value);
          setIsFilter(true);
        }}
        close={handleCloseModalFilter}
        onCancel={() => { setOpenFilter(false) }}
        onNext={() => {
          if (filter1 === "" && filter2 === "") {
            setPage(0);
            setPageSize(5);
          }
          getList(filter, filter1, filter2, page, pageSize);
          setOpenFilter(false);
        }} />

      <ModalReadPage open={read} onCancel={() => setRead(false)} />

      <Grid container md={12} sx={{ paddingX: "70px", paddingY: "20px" }}>
        <Grid container xs={12} sm={12} md={12} lg={12} sx={{ paddingY: "20px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" />
            <LabelComponent text={isEdit ? "Modelo (Veículo)" : "Novo Modelo (Veículo)"} fontSize="28px" fontWeight="normal" />
          </Grid>
          <Grid item xs={12} sm={8} md={12} lg={12} sx={{ paddingTop: "30px" }}>
            <LabelComponent text="Dados do Modelo" />
          </Grid>
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} sx={{ paddingBottom: "20px" }}>
          <Grid item xs={12} sm={5} md={3} lg={1.8} sx={{ marginRight: "30px", marginY: "16px", paddingTop: "10px" }}>
            <TextFieldComponent
              helperText={messageSave?.includes('Nome') ? messageSave :
                cars?.fields && model === "" ? "Campo obrigatório" : ""}
              error={messageSave?.includes('Nome') || cars?.fields && model === "" ? true : false}
              text="Nome do modelo"
              required={true}
              maxLength={100}
              value={model}
              onChange={(event) => {
                setModel(event.target.value);
                setMessageSave("");
                if (event.target.value != ""
                  && carsBrandId != ""
                  && carsCategoryId != ""
                  && project != ""
                  && family.length >= 1) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={3} lg={1.9} sx={{ marginRight: "30px", marginY: "16px", paddingTop: "10px" }}>
            <SelectComponent
              helperText={messageSave?.includes('Fabricante') ? messageSave :
                cars?.fields && carsBrandId === "" ? "Campo obrigatório" : ""}
              required={true}
              value={carsBrandId}
              values={carsBrand}
              text="Nome do fabricante"
              onSelect={(event: any) => {
                setCarsBrandId(event.target.value)
                if (event.target.value != ""
                  && model != ""
                  && carsCategoryId != ""
                  && project != ""
                  && family.length >= 1
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={1.9} sx={{ marginRight: "30px", marginY: "16px", paddingTop: "10px", }}>
            <SelectComponent
              helperText={messageSave?.includes('Carroceria') ? messageSave :
                cars?.fields && carsCategoryId === "" ? "Campo obrigatório" : ""}
              required={true}
              value={carsCategoryId}
              values={carsCategory}
              text="Tipo de carroceria"
              onSelect={(event: any) => {
                setCarsCategoryId(event.target.value)
                if (event.target.value != ""
                  && model != ""
                  && carsBrandId != ""
                  && project != ""
                  && family.length >= 1
                ) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5} md={3} lg={1.9} sx={{ marginRight: "30px", marginY: "16px", paddingTop: "10px", }}>
            <TextFieldComponent
              helperText={messageSave?.includes('Projeto') ? messageSave :
                cars?.fields && project === "" ? "Campo obrigatório" : ""}
              error={messageSave?.includes('Projeto') || cars?.fields && project === "" ? true : false}
              text="Nº do projeto"
              required={true}
              maxLength={15}
              value={project}
              onChange={(event) => {
                setProject(event.target.value);
                if (event.target.value.length === 4) {
                  setFamily(event.target.value + "X");
                } else if (event.target.value.length < 4) {
                  setFamily("N/A");
                }
                setMessageSave("");
                if (event.target.value != ""
                  && model != ""
                  && carsBrandId != ""
                  && carsCategoryId != ""
                  && family.length >= 0) {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }}
            />
          </Grid>
          <Grid item
            xs={12} sm={5} md={3} lg={1.9}
            sx={{
              marginRight: "30px",
              marginY: "16px",
              backgroundColor: "#DFE4E8",
              paddingTop: "10px",
              paddingBottom: "20px",
              paddingX: "10px"
            }}>
            <TextFieldComponent
              helperText={messageSave?.includes('Código') ? messageSave : ""}
              error={messageSave?.includes('Código')}
              text="Código da família"
              required={true}
              disabled={true}
              maxLength={4}
              value={family}
            />
          </Grid>

          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
            <LabelComponent text="Lista " fontSize="28px" />
            <LabelComponent text="de Modelos" fontWeight="normal" fontSize="28px" />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginY: "16px" }}>
            <SearchComponent
              text=""
              value={filter}
              placeholder="Buscar por …"
              onChange={filterList}
              onClick={() => {
                setOpenFilter(true)
              }}
              onCancel={() => {
                setFilter("");
                getList('', filter1, filter2, page, pageSize);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={carsAll}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, page, pageSize);
              }}
              onClick={filterList}
            />
          </Grid>
        </Grid>
      </Grid>

    </div>
    : <NotAuthorizedPage/>
  )
}
export default RegisterModelPage;
import React, { useState } from 'react';
import { Grid, Button, Collapse } from "@mui/material";
import LabelComponent from "../../components/label/labelComponent";
import ArrowDown from "../../assets/images/arrow-down-blue.png"
import ArrowUp from "../../assets/images/arrow-up-blue.png"
import ButtonComponent from '../button/buttonComponent';
import ButtonTextComponent from '../button/buttonTextComponent';
import { useNavigate } from 'react-router-dom';
import { put } from '../../api/api';
import ModalComponent from '../modal/modalComponent';

function TestPlanPSEComponent(props: TestPlanPSE) {

    const [showMore, setShowMore] = useState<boolean>(false);
    const [id, setId] = useState<number>();
    const [openModal, setOpenModal] = useState(false);
    const { children, text1, text2 } = props;

    const buttonShowMore = () => {
        if (props.hasChildren) {
            return (
                <Button onClick={() => setShowMore(!showMore)}>
                    <img style={{ padding: "0 8px" }} src={showMore ? ArrowUp : ArrowDown} alt="Arrow Down" />
                </Button>
            )
        }
    }

    const navigate = useNavigate();

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    return (
        <Grid container sx={{ flexDirection: 'column' }}>
            <ModalComponent
                description={"Deseja realmente excluir esta Requisição de teste?"}
                open={openModal}
                close={handleCloseModal}
                onCancel={() => { setOpenModal(false); }}
                onNext={() => {
                    props.fetchDeactive(id, props.bodyDeactive)
                    setOpenModal(false);
                }} />
            <Grid item sx={{ flexDirection: 'row' }}>
                <Button sx={{ textTransform: 'none' }}
                    onClick={(event) => window.open("/stapp/requisicao-teste/editar/" + props.id)}
                >
                    <Grid item >
                        <LabelComponent
                            text={text1}
                            fontSize={"24px"}
                            fontFamily={"VWHead"}
                            fontWeight={"light"}
                            color={"#001d53"}
                        />
                        <LabelComponent
                            text={text2}
                            fontSize={"24px"}
                            fontFamily={"VWHead"}
                            fontWeight={"bold"}
                            color={"#001d53"}
                        />
                    </Grid >
                </Button>
                {buttonShowMore()}
                <ButtonTextComponent text="Excluir"
                    float='right'
                    onClick={() => {
                        setId(props.id);
                        setOpenModal(true);
                    }}
                />
            </Grid>
            <Grid item sx={{ margin: '8px 0 8px 32px' }}>
                {children.length > 0 ?
                    <Collapse in={showMore}>
                        {children.map((value: any) => {
                            return (
                                <Grid item sx={{ marginY: '8px' }}>
                                    <Button sx={{ textTransform: 'none' }}
                                        onClick={(event) => window.open("/stapp/requisicao-teste/editar/" + value.id)}>
                                        <LabelComponent
                                            text={"Sequência de teste"}
                                            fontSize={"24px"}
                                            marginRight={"8px"}
                                            fontFamily={"VWHead"}
                                            fontWeight={"light"}
                                            display={"block"}
                                            color={"#001d53"}
                                        /> 
                                        &nbsp;
                                        <LabelComponent
                                            text={`${value.testNumber} - ${value.subCategory} - ${value.kwWeek}`}
                                            fontSize={"24px"}
                                            fontFamily={"VWHead"}
                                            fontWeight={"bold"}
                                            color={"#001d53"}
                                        />
                                    </Button>
                                    <ButtonTextComponent text="Excluir"
                                        float='right'
                                        onClick={() => {
                                            setId(value.id);
                                            setOpenModal(true);
                                        }}
                                    />
                                </Grid >
                            )
                        })}
                    </Collapse>
                    : null
                }
            </Grid>
        </Grid >

    )
}

export default TestPlanPSEComponent;
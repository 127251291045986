import { DataObjectOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import { calculateAdministrativeProvisionTotals, hourToMinutes } from "../../utils/utils";
import useRoute from "../route/useRoute";

const useTestPlanTab1 = (currentModal: string, type?: string, id?: number) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [displayAlert, setDisplayAlert] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [alertNewStatus, setAlertNewStatus] = useState<boolean>(false);
    const [alertNewMessage, setAlertNewMessage] = useState<string>("");
    const [selectAllList, setSelectAllList] = useState<any>([]);
    const [projectsList, setProjectsList] = useState<any>([]);
    const [typedValuesDetailProjects, setTypedValuesDetailProjects] = useState<any>();
    const [rateTotalRow, setRateTotalRow] = useState<any>({});
    const [testPlanInputRate, setTestPlanInputRate] = useState<TestPlanInputRate>();
    const [testPlansSupervisions, setTestPlansSupervisions] = useState<TestPlansSupervisions>();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [isSuccessColumn, setIsSuccessColumn] = useState<boolean>(true);
    const [typedValuesPhasesArray, setTypedValuesPhasesArray] = useState<any>([]);
    const [provisionsAdm, setProvisionsAdm] = useState<AdministrativeProvisions[]>([]);
    const [administrativeProvisionTotals, setAdministrativeProvisionTotals] = useState<any>();
    const [provisionById, setProvisionById] = useState<TravelProvisionPost>();
    const [provisionByIdTotals, setProvisionByIdTotals] = useState<TravelProvisionTotals>({ n7: 0, n15: 0, i15: 0, i30: 0 });
    const [externalTestsById, setExternalTestsById] = useState<any>();
    const [externalTestsTotals, setExternalTestsTotals] = useState<any>({});
    const [testPlanTotals, setTestPlanTotals] = useState<any>();
    const [rowDist, setRowDist] = useState<any>({ year1: "00,0%", year2: "00,0%", year3: "00,0%", year4: "00,0%", year5: "00,0%" });
    const [testPlanInputRateOld, setTestPlanInputRateOld] = useState<InputRate[]>([]);
    const [idNewRegister, setIdNewRegister] = useState<number>();
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("plano-testes"));
        if (currentModal === 'projectDetails') {
            fetchProjectsList();
            fetchSelectAllList();
            (type && id) ? fetchTestPlanById(id) : startValues();
        } else if (currentModal === 'rateByYear') {
            id && fetchInputRate(id);
        } else if (currentModal === 'phases') {
            fetchPhases();
        } else if (currentModal === 'administrativeProvision') {
            id && fetchAdministrativeProvision(id);
        } else if (currentModal === 'travelProvision') {
            id && fetchTravelProvisionById(id);
        } else if (currentModal === 'externalTests') {
            id && fetchExternalTestsById(id);
        }
        id && fetchTestPlanTotals(id);
    }, [currentModal, type, id]);

    const fetchSelectAllList = () => {
        setSelectAllList([]);
        get('testPlans/data').then((values) => {
            setSelectAllList(values);
        });
    };

    const fetchProjectsList = () => {
        setProjectsList([]);
        post('cars/active/true', {}).then((values) => {
            let list: any = [];
            values.content.forEach((element: any) => {
                list.push({ id: element.id, name: element.project, family: element.family });
            });
            setProjectsList(list);
        });
    };

    const startValues = () => {
        setTypedValuesDetailProjects({
            ...typedValuesDetailProjects,
            dateStartScreen: "",
            endDateScreen: "",
            detailsDescription: "",
            updatedAt: "",
            familyCode: "",
            calendarYear: "",
            duration: 0,
            durationText: "",
            month: 0,
            status: 'Draft',
            responsibleId: process.env.REACT_APP_ENVIRONMENT === "DS" ? 1 : localStorage.getItem("userId"),
            year: 0
        });
    };

    const fetchTestPlanById = (id: number) => {
        get("/testPlans/" + id).then((values: any) => {
            if (values.dateStart) {
                let ds = values.dateStart.split('-');
                values.dateStartScreen = ds[2] + '/' + ds[1] + '/' + ds[0];
            } else {
                values.dateStartScreen = '';
            }

            if (values.endDate) {
                let ed = values.endDate ? values.endDate.split('-') : '';
                values.endDateScreen = ed[2] + '/' + ed[1] + '/' + ed[0];
            } else {
                values.endDate = '';
            }

            values.carId = values.car.id;
            setTypedValuesDetailProjects(values);
            setTestPlanTotals(values.totals);
        }).finally(() => setLoading(false));
    };

    const fetchSaveProjectDetails = (typedValuesDetailProjects: any, action: string, id?: number) => {
        let obj = typedValuesDetailProjects;
        if (obj.name && obj.duration && obj.year && obj.month && obj.carId && obj.detailsDescription) {
            if (obj.endDate && obj.endDate.split('-')[1].length == 1) {
                let data = obj.endDate.split('-');
                obj.endDate = data[0] + '-0' + data[1] + '-' + data[2];
            }
            if (action === 'clonar' || action === 'editar' && id) {
                obj.responsibleId = obj.responsible.id;
            }
            if (action === 'editar' && id) {
                put('testPlans', obj).then((value) => {
                    if (value.codeStatus === 200) {
                        setDisplayAlert(true);
                        setMessageAlert("Plano de testes editado com sucesso");
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setIsError(true)
                        setMessageAlert(value.errorMessage);
                    }
                });
            } else {
                post('testPlans', obj).then((value) => {
                    if (value.codeStatus === 201) {
                        setIdNewRegister(value.id);
                        setAlertNewStatus(true);
                        setAlertNewMessage("Plano de testes salvo com sucesso");
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setMessageAlert(value.errorMessage);
                    }
                });
            }
        }
    };
    // #endregion Accordion 1 - Project Details

    // #region Accordion 2 - Rate by Year
    const fetchCalendarization = () => {
        let startMonth = Number(testPlanTotals.month);
        let durationMonths = testPlanTotals.duration;

        let monthsYear1Aux = 0;
        let monthsYear1 = 0;
        let monthsYear2 = 0;
        let monthsYear3 = 0;
        let monthsYear4 = 0;
        let monthsYear5 = 0;

        if (durationMonths == 6 && startMonth <= 7) {
            monthsYear1 = 6;
            monthsYear1Aux = 0;
        } else {
            monthsYear1 = 13 - startMonth;
            monthsYear1Aux = 12 - monthsYear1;
        }

        for (let index = 0; index < durationMonths; index++) {
            if (monthsYear1Aux < 12) {
                monthsYear1Aux++;
            } else if (monthsYear1Aux == 12 && monthsYear2 < 12) {
                monthsYear2++;
            } else if (monthsYear1Aux == 12 && monthsYear2 == 12 && monthsYear3 < 12) {
                monthsYear3++;
            } else if (monthsYear1Aux == 12 && monthsYear2 == 12 && monthsYear3 == 12 && monthsYear4 < 12) {
                monthsYear4++;
            } else if (monthsYear1Aux == 12 && monthsYear2 == 12 && monthsYear3 == 12 && monthsYear4 == 12 && monthsYear5 < 12) {
                monthsYear5++;
            }
        }

        let _testPlansSupervisions: TestPlansSupervisions;
        if (testPlansSupervisions) {
            _testPlansSupervisions = testPlansSupervisions;
            _testPlansSupervisions.supervisions.map((_testPlansSupervisionsItem: Supervision) => {
                _testPlansSupervisionsItem.distRate[0].rate = (monthsYear1 * 100) / durationMonths;
                _testPlansSupervisionsItem.distRate[1].rate = (monthsYear2 * 100) / durationMonths;
                _testPlansSupervisionsItem.distRate[2].rate = (monthsYear3 * 100) / durationMonths;
                _testPlansSupervisionsItem.distRate[3].rate = (monthsYear4 * 100) / durationMonths;
                _testPlansSupervisionsItem.distRate[4].rate = (monthsYear5 * 100) / durationMonths;
            });
            setTestPlansSupervisions({ ..._testPlansSupervisions });
            testPlanInputRate && _testPlansSupervisions && _testPlansSupervisions.supervisions && _testPlansSupervisions.supervisions.map((sup) => fetchCalcRow(sup, _testPlansSupervisions.supervisions, _testPlansSupervisions.supervisions.length, testPlanInputRate.inputRates, true));
        }

    };

    const fetchTestPlansSupervisions = (id: number, testPlanInputRate: TestPlanInputRate) => {
        get('testPlans/supervisions/distRate/' + id).then((values: TestPlansSupervisions) => {
            values && setTestPlansSupervisions(values);
            testPlanInputRate && values.supervisions && values.supervisions.map((sup) => fetchCalcRow(sup, values.supervisions, values.supervisions.length, testPlanInputRate.inputRates));
        }).finally(() => setLoading(false));
    };

    const fetchInputRate = (id: number) => {
        get('testPlans/inputRate/' + id).then((values: TestPlanInputRate) => {
            setTestPlanInputRate(values);
            fetchTestPlansSupervisions(id, values);
        }).finally(() => setLoading(false));
        get('testPlans/inputRate/' + id).then((values: TestPlanInputRate) => setTestPlanInputRateOld(values.inputRates));
    };

    const fetchCalcRow = (sup: Supervision, total: any, totalElements: number, inputRates: InputRate[], calendarization?: boolean) => {
        let sum = 0;
        let _isSuccess: boolean = false;

        sup.distRate.map((dist: DistRate) => sum += Number((dist.rate.toString().replaceAll('.', '').replaceAll(',', '.'))));

        if (sum != 100) {
            setIsSuccess(false);
            _isSuccess = false;
        } else {
            setIsSuccess(true);
            _isSuccess = true;
        }

        let distRatePercentual = {
            year1: 0,
            year2: 0,
            year3: 0,
            year4: 0,
            year5: 0
        };

        total.map((totalItem: any) => {
            distRatePercentual.year1 += typeof totalItem.distRate[0].rate == "number" ? totalItem.distRate[0].rate : Number((totalItem.distRate[0].rate.toString().replaceAll('.', '').replaceAll(',', '.')));
            distRatePercentual.year2 += typeof totalItem.distRate[1].rate == "number" ? totalItem.distRate[1].rate : Number((totalItem.distRate[1].rate.toString().replaceAll('.', '').replaceAll(',', '.')));
            distRatePercentual.year3 += typeof totalItem.distRate[2].rate == "number" ? totalItem.distRate[2].rate : Number((totalItem.distRate[2].rate.toString().replaceAll('.', '').replaceAll(',', '.')));
            distRatePercentual.year4 += typeof totalItem.distRate[3].rate == "number" ? totalItem.distRate[3].rate : Number((totalItem.distRate[3].rate.toString().replaceAll('.', '').replaceAll(',', '.')));
            distRatePercentual.year5 += typeof totalItem.distRate[4].rate == "number" ? totalItem.distRate[4].rate : Number((totalItem.distRate[4].rate.toString().replaceAll('.', '').replaceAll(',', '.')));
        });

        distRatePercentual.year1 = Number((distRatePercentual.year1 / totalElements));
        distRatePercentual.year2 = Number((distRatePercentual.year2 / totalElements));
        distRatePercentual.year3 = Number((distRatePercentual.year3 / totalElements));
        distRatePercentual.year4 = Number((distRatePercentual.year4 / totalElements));
        distRatePercentual.year5 = Number((distRatePercentual.year5 / totalElements));
        let totalRow = {
            lb:
                (inputRates[0].labor * Number(distRatePercentual.year1.toFixed(2)) / 100) +
                (inputRates[1].labor * Number(distRatePercentual.year2.toFixed(2)) / 100) +
                (inputRates[2].labor * Number(distRatePercentual.year3.toFixed(2)) / 100) +
                (inputRates[3].labor * Number(distRatePercentual.year4.toFixed(2)) / 100) +
                (inputRates[4].labor * Number(distRatePercentual.year5.toFixed(2)) / 100),
            ps:
                (inputRates[0].purchase * Number(distRatePercentual.year1.toFixed(2)) / 100) +
                (inputRates[1].purchase * Number(distRatePercentual.year2.toFixed(2)) / 100) +
                (inputRates[2].purchase * Number(distRatePercentual.year3.toFixed(2)) / 100) +
                (inputRates[3].purchase * Number(distRatePercentual.year4.toFixed(2)) / 100) +
                (inputRates[4].purchase * Number(distRatePercentual.year5.toFixed(2)) / 100),
            status: (distRatePercentual.year1 + distRatePercentual.year2 + distRatePercentual.year3 + distRatePercentual.year4 + distRatePercentual.year5).toFixed(2)
        }
        setRateTotalRow(totalRow);

        if (Number(totalRow.status) != 100) {
            setIsSuccessColumn(false);
        } else {
            if (_isSuccess == true) {
                setIsSuccessColumn(true);
            }
        }

        if (calendarization) {
            setIsSuccess(true);
            setIsSuccessColumn(true);
        }

        setRowDist({
            year1: distRatePercentual.year1.toFixed(2) + '%',
            year2: distRatePercentual.year2.toFixed(2) + '%',
            year3: distRatePercentual.year3.toFixed(2) + '%',
            year4: distRatePercentual.year4.toFixed(2) + '%',
            year5: distRatePercentual.year5.toFixed(2) + '%'
        });
    }

    const inputRateCalc = (type: string, inputRates: InputRate[]) => {
        if (inputRates) {
            let newInputRates: InputRate[] = inputRates;
            let value1;
            let value2;

            if (type === 'lbps') {
                inputRates.map((inputRateRow: InputRate, index: number) => {
                    testPlanInputRateOld && testPlanInputRateOld.map((testPlanInputRateOldRow: InputRate) => {
                        if (inputRateRow.year === testPlanInputRateOldRow.year) {
                            if (inputRateRow.inflation != testPlanInputRateOldRow.inflation || inputRateRow.labor != testPlanInputRateOldRow.labor || inputRateRow.purchase != testPlanInputRateOldRow.purchase) {
                                if (newInputRates[index + 1]) {
                                    inputRates[index].labor = inputRates[index].labor.toString().search(',') >= 0 ? Number(inputRates[index].labor.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRates[index].labor;
                                    inputRates[index].purchase = inputRates[index].purchase.toString().search(',') >= 0 ? Number(inputRates[index].purchase.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRates[index].purchase;
                                    newInputRates[index + 1].labor = (inputRates[index].labor * 1) * (1 + ((inputRates[index].inflation.toString().search(',') >= 0 ? Number(inputRates[index].inflation.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRates[index].inflation) / 100));
                                    newInputRates[index + 1].purchase = (inputRates[index].purchase * 1) * (1 + ((inputRates[index].inflation.toString().search(',') >= 0 ? Number(inputRates[index].inflation.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRates[index].inflation) / 100));
                                    !newInputRates[index + 1].labor && (newInputRates[index + 1].labor = 0)
                                    !newInputRates[index + 1].purchase && (newInputRates[index + 1].purchase = 0)
                                }
                            }
                        }
                    })
                });
            } else if (type === 'inflation') {
                inputRates.map((inputRateItem: InputRate, index: number) => {
                    if (inputRateItem && index < inputRates.length - 1) {
                        value1 = (
                            Number(inputRateItem.labor.toString().search(',') >= 0 ? Number(inputRateItem.labor.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRateItem.labor) +
                            Number(inputRateItem.purchase.toString().search(',') >= 0 ? Number(inputRateItem.purchase.toString().replaceAll('.', '').replaceAll(',', '.')) : inputRateItem.purchase)
                        ) / 2;

                        value2 = (
                            Number(newInputRates[index + 1].labor.toString().search(',') >= 0 ? Number(newInputRates[index + 1].labor.toString().replaceAll('.', '').replaceAll(',', '.')) : newInputRates[index + 1].labor) +
                            Number(newInputRates[index + 1].purchase.toString().search(',') >= 0 ? Number(newInputRates[index + 1].purchase.toString().replaceAll('.', '').replaceAll(',', '.')) : newInputRates[index + 1].purchase)
                        ) / 2;

                        !value1 && (value1 = 1);
                        !value2 && (value2 = 1);

                        inputRateItem.inflation = ((value2 / value1) - 1) * 100;
                        !inputRateItem.inflation && (inputRateItem.inflation = 0)
                    }
                });
            }
            let oldObj: any = [];
            newInputRates.map((row: InputRate) => oldObj.push({ inflation: row.inflation, labor: row.labor, minutes: row.minutes, purchase: row.purchase, year: row.year }));
            setTestPlanInputRateOld(oldObj);
            testPlanInputRate && setTestPlanInputRate({ ...testPlanInputRate, inputRates: newInputRates });
        }
    };

    const inputRateCalcOnBlur = (inputRates: InputRate[]) => {
        testPlanInputRate && setTestPlanInputRate({ ...testPlanInputRate, inputRates: [] });
        testPlanInputRate && setTestPlanInputRate({ ...testPlanInputRate, inputRates: inputRates });
    };

    const fetchSubmitTestPlansSupervisions = (_body?: TestPlansSupervisions) => {
        setLoading(true);
        let body: any = _body;
        body.testPlanId = Number(id);
        body.totalRateLb = Number(rateTotalRow.lb);
        body.totalRatePs = Number(rateTotalRow.ps);
        body.totalAdministrative = Number(_body?.totals.totalAdministrative);
        body.totalTravels = Number(_body?.totals.totalTravels);
        body.totalExternalTests = Number(_body?.totals.totalExternalTests);
        body.totalAll = Number(_body?.totals.totalAll);

        post('testPlans/supervisions/distRate', body).then((value: any) => {
            if (value.codeStatus === 201) {
                setDisplayAlert(true);
                setMessageAlert("Plano de testes editado com sucesso");
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setMessageAlert(value.errorMessage);
            }
        }).finally(() => setLoading(false));
    };

    const fetchSubmitInputRate = (_body?: TestPlanInputRate) => {
        setLoading(true);
        let body: any = _body;
        let _inputRates = body?.inputRates ? body.inputRates : [];
        body.inputRates = _inputRates;
        body.testPlanId = Number(id);
        body.totalRateLb = Number(rateTotalRow.lb);
        body.totalRatePs = Number(rateTotalRow.ps);
        body.totalAdministrative = Number(_body?.totals.totalAdministrative);
        body.totalTravels = Number(_body?.totals.totalTravels);
        body.totalExternalTests = Number(_body?.totals.totalExternalTests);
        body.totalAll = Number(_body?.totals.totalAll);

        post('testPlans/inputRate', body).then((value: any) => {
            if (value.codeStatus === 201) {
                setDisplayAlert(true);
                setMessageAlert("Plano de testes editado com sucesso");
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        }).finally(() => setLoading(false));
    };

    const fetchPhases = () => {
        get('testPlans/tab1/phases').then((values) => {
            let array: any = [];
            values.map((item: any) => array.push({ type: item.type, name: item.name, kw: '', year: '' }));
            setTypedValuesPhasesArray({ listTPPhaseSchedule: array, testPlanId: 0 });
            fetchPhasesById(id ? id : 0, array);
            setTestPlanTotals(values.totals);
        }).finally(() => setLoading(false));
    }

    const fetchPhasesById = (id: number, array: any) => {
        if (id && array.length > 0) {
            get("/testPlans/phase/" + id).then((values: any) => {
                let newArray: any = [];

                array.forEach((list: any) => {
                    let foundItem: boolean = false;
                    if (values.listTPPhaseSchedule.length > 0) {
                        values.listTPPhaseSchedule.find((item: any) => {
                            if (list.type == item.type && item.kw) {
                                foundItem = true;
                                delete item.id;
                                newArray.push(item);
                            }
                        })
                    }

                    if (foundItem === false) {
                        newArray.push(list);
                    }
                });

                setTypedValuesPhasesArray({
                    listTPPhaseSchedule: newArray,
                    testPlanId: id
                });
            })
        };
    };

    const fetchSavePhases = (typedValuesPhasesArray: any, action: string, id?: number) => {
        setLoading(true);
        typedValuesPhasesArray.testPlanId = id;
        post('testPlans/phaseSchedule', typedValuesPhasesArray).then((value) => {
            if (value.codeStatus === 201) {
                setDisplayAlert(true);
                setMessageAlert("Plano de testes editado com sucesso");
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        }).finally(() => setLoading(false));
    };
   
    const fetchAdministrativeProvision = (_id: number) => {
        setLoading(true);
        get('testPlans/supervisions/provisionAdmin/' + _id).then((values) => {
            setProvisionsAdm(values.provisionsAdm);
            setTestPlanTotals(values.totals);
            setAdministrativeProvisionTotals(calculateAdministrativeProvisionTotals(values.provisionsAdm, values.totals));
        }).finally(() => { setLoading(false); });
    };

    const fetchSaveAdministrativeProvision = (_provisionsAdm: AdministrativeProvisions[], _testPlanTotals?: Totals, administrativeProvisionTotals?: any) => {
        if (_provisionsAdm && _testPlanTotals) {

            let body: any = {};
            body.totalAll = _testPlanTotals.totalAll;
            body.totalExternalTests = _testPlanTotals.totalExternalTests;
            body.totalTravels = _testPlanTotals.totalTravels;
            body.testPlanId = id;
            body.supervisions = _provisionsAdm;



            let total: number = 0

            administrativeProvisionTotals.map((item: any) => {
                total += Number(item.values[7].value.substring(3).replaceAll(".", "").replaceAll(",", "."))
            });
            body.totalAdministrative = total.toString();

            body.totalAll = Number(body.totalAdministrative) + Number(body.totalExternalTests) + Number(body.totalTravels);

            post('testPlans/supervisions/provisionAdm', body).then((value) => {
                if (value.codeStatus === 201) {
                    setDisplayAlert(true);
                    setMessageAlert("Plano de testes editado com sucesso");
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    }
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
            });
        }
    };
    
    const fetchTravelProvisionById = (_id: number) => {
        get('testPlans/supervisions/provisionTravel/' + _id).then((values: TravelProvisionGet) => {
            let arrayScreen: TravelProvisionPost = {
                supervisions: values.supervisionsTravels,
                testPlanId: Number(_id),
                totalAdministrative: Number(values.totals.totalAdministrative),
                totalTravels: Number(values.totals.totalTravels),
                totalExternalTests: Number(values.totals.totalExternalTests),
                totalAll: Number(values.totals.totalAll)
            };
            let totals: TravelProvisionTotals = { n7: 0, n15: 0, i15: 0, i30: 0 };
            values.supervisionsTravels.map((item: SupervisionsTravel) => {
                item.travels.map((travel: Travel) => {
                    switch (travel.type) {
                        case "N7":
                            totals.n7 = totals.n7 + travel.quantity * travel.cost;
                            break;
                        case "N15":
                            totals.n15 = totals.n15 + travel.quantity * travel.cost;
                            break;
                        case "I15":
                            totals.i15 = totals.i15 + travel.quantity * travel.cost;
                            break;
                        case "I30":
                            totals.i30 = totals.i30 + travel.quantity * travel.cost;
                            break;
                        default:
                            break;
                    }
                });
            });
            setProvisionById(arrayScreen);
            setProvisionByIdTotals(totals);
        }).finally(() => setLoading(false));
    };

    const fetchSaveTravelProvision = (body: TravelProvisionPost) => {
        setLoading(true);
        body.supervisions.map((row: any) => {
            row.travels[0].cost = row && row.travels[0].cost ? row.travels[0].cost.toString().replaceAll('.', '').replaceAll(',', '.') : 0;
            row.travels[1].cost = row && row.travels[1].cost ? row.travels[1].cost.toString().replaceAll('.', '').replaceAll(',', '.') : 0;
            row.travels[2].cost = row && row.travels[2].cost ? row.travels[2].cost.toString().replaceAll('.', '').replaceAll(',', '.') : 0;
            row.travels[3].cost = row && row.travels[3].cost ? row.travels[3].cost.toString().replaceAll('.', '').replaceAll(',', '.') : 0;
        });
        body.totalTravels = provisionByIdTotals.n7 + provisionByIdTotals.n15 + provisionByIdTotals.i15 + provisionByIdTotals.i30;
        body.totalAll = body.totalAdministrative + body.totalExternalTests + body.totalTravels;
        post('testPlans/supervisions/provisionTravel/', body).then((value) => {
            if (value.codeStatus === 201) {
                setDisplayAlert(true);
                setMessageAlert("Plano de testes editado com sucesso");
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        }).finally(() => setLoading(false));
    };

    const fetchExternalTestsById = (_id: number) => {
        get('testPlans/externalTests/' + _id).then((values: ExternalTestItem[]) => {
            let externalTestsTotalTry: number = 0;
            let externalTestsTotalFreight: number = 0;
            let externalTestsTotalOtherCosts: number = 0;
            let externalTestsTotalSum: number = 0;
            values.map((item: any) => externalTestsTotalTry = externalTestsTotalTry + item.quantityCost * item.tryCost);
            values.map((item: any) => externalTestsTotalFreight = externalTestsTotalFreight + item.freightCost * item.quantityFreight);
            values.map((item: any) => externalTestsTotalOtherCosts = externalTestsTotalOtherCosts + item.otherCost * 1);
            values.map((item: any) => externalTestsTotalSum = externalTestsTotalSum + item.total * 1);
            setExternalTestsById(values);
            setExternalTestsTotals({
                externalTestsTotalTry: externalTestsTotalTry,
                externalTestsTotalFreight: externalTestsTotalFreight,
                externalTestsTotalOtherCosts: externalTestsTotalOtherCosts,
                externalTestsTotalSum: externalTestsTotalSum
            });
        }).finally(() => setLoading(false));
    };

    const fetchSaveExternalTests = (_listExternalTest: ExternalTestItem[], _testPlanTotals?: Totals, total?: any) => {
        if (_listExternalTest && _testPlanTotals) {
            setLoading(true);
            let listExternalTest: ExternalTestItem[] = [];
            _listExternalTest.map((item: ExternalTestItem) =>
                listExternalTest.push({
                    id: item.id,
                    name: item.name,
                    quantityCost: Number(item.quantityCost),
                    freightCost: item.freightCost ? item.freightCost.toString().search(',') >= 0 ? Number(item.freightCost.toString().replaceAll('.', '').replaceAll(',', '.')) : Number(item.freightCost) : 0,
                    quantityFreight: Number(item.quantityFreight),
                    tryCost: item.tryCost ? item.tryCost.toString().search(',') >= 0 ? Number(item.tryCost.toString().replaceAll('.', '').replaceAll(',', '.')) : Number(item.tryCost) : 0,
                    otherCost: item.otherCost ? item.otherCost.toString().search(',') >= 0 ? Number(item.otherCost.toString().replaceAll('.', '').replaceAll(',', '.')) : Number(item.otherCost) : 0,
                    description: item.description,
                    total: item.total ? item.total.toString().search(',') >= 0 ? Number(item.total.toString().replaceAll('.', '').replaceAll(',', '.')) : Number(item.total) : 0
                })
            );

            let body: any = {};
            body.listExternalTest = listExternalTest;
            body.testPlanId = id;
            body.totalExternalTests = total;
            body.totalTravels = _testPlanTotals.totalTravels;
            body.totalAdministrative = _testPlanTotals.totalAdministrative;
            body.totalAll = Number(body.totalAdministrative) + Number(body.totalExternalTests) + Number(body.totalTravels);

            post('testPlans/externalTests/', body).then((value) => {
                if (value.codeStatus === 201) {
                    setDisplayAlert(true);
                    setMessageAlert("Plano de testes editado com sucesso");
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    }
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
            }).finally(() => setLoading(false));
        }
    };

    const fetchTestPlanTotals = (_id: number) => {
        get('testPlans/totals/' + _id).then((values: Totals) => {
            setTestPlanTotals(values);
        }).finally(() => setLoading(false));
    };

    return {
        loading,
        isError, setIsError, read, setRead, access,
        displayAlert, messageAlert, setMessageAlert,
        selectAllList, projectsList, typedValuesDetailProjects, setTypedValuesDetailProjects, fetchProjectsList, fetchSelectAllList, fetchTestPlanById, fetchSaveProjectDetails,
        testPlansSupervisions, setTestPlansSupervisions, testPlanInputRate, setTestPlanInputRate, isSuccess, isSuccessColumn, setIsSuccess, fetchSubmitTestPlansSupervisions, fetchSubmitInputRate, fetchCalcRow, inputRateCalc, rowDist, inputRateCalcOnBlur, fetchTestPlansSupervisions, fetchCalendarization, rateTotalRow,
        typedValuesPhasesArray, setTypedValuesPhasesArray, fetchSavePhases, fetchPhases,
        administrativeProvisionTotals, fetchSaveAdministrativeProvision, provisionsAdm, setProvisionsAdm, setAdministrativeProvisionTotals,
        provisionById, setProvisionById, provisionByIdTotals, setProvisionByIdTotals, fetchSaveTravelProvision,
        externalTestsById, setExternalTestsById, externalTestsTotals, setExternalTestsTotals, fetchSaveExternalTests,
        testPlanTotals, alertNewStatus, setAlertNewStatus, alertNewMessage, setAlertNewMessage, idNewRegister
    };
}
export default useTestPlanTab1;
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../../api/api";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import TextareaAutosizeComponent from "../../components/field/textareaAutosize";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import useRoute from "../../hooks/route/useRoute";
import { hourAndMinutesSubtraction } from "../../utils/utils";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function LogDetails() {
  const { type, rev } = useParams();
  const [auditTimeline, setAuditTimeline] = useState<AuditDetails[]>([]);
  const [textData, setTextData] = useState<string>("");
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();
  const columns: GridColDef[] = [
    { field: "idAudited", headerName: "ID", flex: 0.1, minWidth: 100 },
    {
      field: "date", headerName: "Data/Hora", flex: 0.2, minWidth: 100,
      sortComparator: (value1: any, value2: any) => {
        return hourAndMinutesSubtraction(value1, value2);
      }
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 0.2,
      minWidth: 100,
    },
    { field: "user", headerName: "Responsável", flex: 0.2, minWidth: 100 },
    {
      field: "action",
      headerName: "Tipo",
      flex: 0.2,
      minWidth: 100
    },
    {
      headerName: "Ação",
      flex: 0.1,
      minWidth: 100,
      renderCell: (value: any) => {
        {
          return (
            <Box flex={3}>
              <ButtonTextComponent
                text="Ver Detalhes"
                onClick={() => {
                  let aux: any[] = [];
                  setTextData("");
                  if (value.row.updateFields.length !== 0) {
                    value.row.updateFields.map((field: UpdateField) => {
                      aux.push("Valor Anterior: " + field.oldValue + " -> Valor Atual: " + field.newValue + "\n");
                    });
                    setTextData(aux.toString().replace(',', ''));
                  } else {
                    setTextData(value.row.data);
                  }
                }}
              />
            </Box>
          );
        }
      },
    }
  ];

  useEffect(() => {
    setAccess(fetchPage("log-sistema"));
    getList();
  }, []);

  function getList() {
    get<AuditDetails[]>("audits/timeline/" + type + "/" + rev).then((value) => {
      setAuditTimeline(value);
    });
  }

  return (
    access ?
      <Grid container md={12}>
        <HeaderComponent text="Log de Sistema / Detalhes" access={access}/>
        <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text="Detalhe " fontSize="28px" marginTop="32px" />
              <LabelComponent text="Log" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid item sx={{ marginTop: "32px" }} xs={12} sm={12} md={12} lg={12}>
            <DataTableGridComponent
              rows={auditTimeline}
              columns={columns}
              loading={false}
            />
          </Grid>
          <Grid container>
            <Grid item sx={{ marginTop: "32px" }} xs={12} sm={12} md={12} lg={12}>
              <TextareaAutosizeComponent value={textData} readOnly={true} placeholder={"Detalhamento dos dados"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default LogDetails;
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import Radio from '@mui/material/Radio';
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import useRequestTestTab2 from "../../hooks/requestTest/useRequestTestTab2";
import { useParams } from "react-router-dom";
import ButtonComponent from "../../components/button/buttonComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalComponent from "../../components/modal/modalComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import ModalReadPage from "../../components/modal/modalReadPage";

function RequestTestPageTab2VDS() {

  const { type, id } = useParams();
  const {
    loading,
    valid, setValid,
    modelVDS, read, setRead,
    openAlert, setOpenAlert,
    messageModal,
    openModalError, setOpenModalError,
    fetchVDS, fetchPosition, fetchSubmit,
    isEdit, fetchRequestTestsProjectCar,
    fetchDevices
  } = useRequestTestTab2({ type, id });
  const [selectedValue, setSelectedValue] = useState('a');
  const [selectCarModel, setSelectCarModel] = useState<any>();
  const [VDS, setVDS] = useState<string>("");
  const [numberIdent, setNumberIdent] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVDS("");
    setNumberIdent("");
    setSelectedValue(event.target.value);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
  };

  const handleSubmit = (e: any) => {
    if (valid) {
      fetchSubmit({
        "projectCarId": modelVDS?.projectCar?.projectCarId,
        "carId": modelVDS?.projectCar?.car?.id,
        "requestTestId": id
      });
    }
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalError = (e: any) => {
    setOpenModalError(false);
  };

  return (
    <>
      <SnackbarComponent isTab={true} text={isEdit ? "Requisição de teste editada com sucesso" : "Requisição de teste salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
      {loading ? <LoadingComponente /> : null}
      <ModalComponent
        description={messageModal}
        isOk={true}
        open={openModalError}
        close={handleCloseModalError}
        onCancel={() => { handleCloseModalError(false) }}
        onNext={() => handleCloseModalError(false)}
      />
      <ModalComponent text=""
        open={openModal}
        description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
        close={handleCloseModal}
        onCancel={() => { setOpenModal(false) }}
        onNext={() => {
          setVDS("");
          setNumberIdent("");
          fetchRequestTestsProjectCar(id);
          fetchDevices();
          setValid(false);
          setOpenModal(false);
        }} />
      <ModalReadPage open={read} onCancel={() => setRead(false)} />
      <Grid container sx={{ display: 'flex', paddingBottom: '16px', }}>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86}
          sx={{ marginX: '8px', marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={selectedValue === 'a'}
            onChange={handleChange}
            value="a"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'A' }}
          />
          <SearchComponent
            disabled={selectedValue === 'b'}
            display="none"
            placeholder="Número do VDS"
            value={VDS}
            onCancel={() => {
              setVDS("");
              fetchVDS("");
              setValid(false);
            }}
            onChange={(event: any) => {
              setVDS(event.target.value);
              if (event.target.value !== "") {
                fetchVDS(event.target.value);
              } else {
                setValid(false)
                fetchVDS("");
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86}
          sx={{ marginX: '8px', marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={selectedValue === 'b'}
            onChange={handleChange}
            value="b"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
          <SearchComponent
            disabled={selectedValue === 'a'}
            value={numberIdent}
            display="none"
            placeholder="Identificação Provisória"
            onCancel={() => {
              setNumberIdent("");
              fetchPosition("");
              setValid(false);
            }}
            onChange={(event: any) => {
              setNumberIdent(event.target.value);
              if (event.target.value !== "") {
                fetchPosition(event.target.value);
              } else {
                setValid(false)
                fetchPosition("");
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ backgroundColor: '#eeeeee', paddingBottom: '24px' }}>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Número do VDS"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.vds ? modelVDS.projectCar.vds : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Identificação Provisória"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.positionVDS ? modelVDS.projectCar.positionVDS : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Número do projeto"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.car?.project ? modelVDS.projectCar.car.project : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Nome do modelo"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.car?.model ? modelVDS.projectCar.car.model : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Versão do veículo"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.engineDescription ? modelVDS.projectCar.engineDescription : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Código da família"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.car?.family ? modelVDS.projectCar.car.family : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Número do chassi"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.chassis ? modelVDS.projectCar.chassis : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Motorização"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.motorization ? modelVDS.projectCar.motorization : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Código do motor"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.engineCode ? modelVDS.projectCar.engineCode : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Identificação da transmissão"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.transmissionIdentification ? modelVDS.projectCar.transmissionIdentification : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Fase do veículo"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.fase ? modelVDS.projectCar.fase : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Ano do modelo"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.modelYear ? modelVDS.projectCar.modelYear : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Status recebimento do veículo"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.receivingStatus ? modelVDS.projectCar.receivingStatus : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Previsão de recebimento"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.provisionReceiving ?
              modelVDS.projectCar.provisionReceiving.slice(8, 10) + "/" + modelVDS.projectCar.provisionReceiving.slice(5, 7) + "/" + modelVDS.projectCar.provisionReceiving.slice(0, 4)
              : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Data de recebimento"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.receivingDate ?
              modelVDS.projectCar.receivingDate.slice(8, 10) + "/" + modelVDS.projectCar.receivingDate.slice(5, 7) + "/" + modelVDS.projectCar.receivingDate.slice(0, 4)
              : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="Massa de recebimento"
            disabled
            endAdornment="Kg"
            maxLength={100}
            value={modelVDS?.projectCar?.massPedPet ? modelVDS.projectCar.massPedPet : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="PED"
            disabled
            endAdornment="Kg"
            maxLength={100}
            value={modelVDS?.projectCar?.ped ? modelVDS.projectCar.ped : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', marginY: '16px' }}>
          <TextFieldComponent
            text="PET"
            endAdornment="Kg"
            disabled
            maxLength={100}
            value={modelVDS?.projectCar?.pet ? modelVDS.projectCar.pet : ""}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={5} sm={4} md={2} lg={1.4} sx={{ marginTop: "40px", display: 'flex', justifyContent: 'center' }}>
          <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
        </Grid>
        <Grid item xs={5} sm={4} md={2} lg={1.4} sx={{ marginTop: "40px", display: 'flex', justifyContent: 'center' }} >
          <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default RequestTestPageTab2VDS;
export const CONFIG = {
    API_URL: process.env.REACT_APP_API,
    AUTHORIZE_ENDPOINT: process.env.REACT_APP_AUTHORIZE_ENDPOINT,
    LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
    TOKEN_ENDPOINT: process.env.REACT_APP_TOKEN_ENDPOINT,
    CLIENT_URL: process.env.REACT_APP_CLIENT_URL,
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI as string,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID as string,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET as string,
    LOGIN: process.env.REACT_APP_LOGIN,
    COOKIE_PATH: "/",
    GRANT_TYPE: "authorization_code",
    PROMPT: "login",
    RESPONSE_TYPE: "code",
    SCOPE: "openid",
};
import { Avatar, Button, Grid, Icon, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Add from '../../assets/images/add.png';
import iconUpdateBlue from "../../assets/images/covered-sum.png";

function ButtonAddComponent(props: ParamsField) {
    return (
        <Grid container sx={{
            display: props.display ? props.display : "flex",
            justifyContent: props.justifyContent ? props.justifyContent : "flex-start"
        }}>
            <Grid item>
                <Button
                    onClick={props.onClick}
                    startIcon={
                        <Avatar
                            src={props.imgIcon === "blue" ? iconUpdateBlue : Add}
                            sx={{
                                width: "46px",
                                height: "46px"
                            }}>
                        </Avatar>
                    }
                >
                </Button>
            </Grid>
            <Grid item sx={{
                color: props.color ? props.color : "#001e50",
                fontFamily: props.fontFamily ? props.fontFamily : "VWHead",
                fontSize: props.fontSize ? props.fontSize : "20px",
                fontWeight: props.fontWeight ? props.fontWeight : "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                alignSelf: "center",

            }}>
                {props.text ? props.text : "Adicionar novo teste"}
            </Grid>
        </Grid>
    );
}
export default ButtonAddComponent;
import { Radio } from "@mui/material";
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { useState } from "react";
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} sx={{
    '.MuiTypography-root': {
      display: 'none',
    }
  }} />))
  (({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  }));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

function RadioButtonColorComponent(props: ParamsField) {
  return (
    <MyFormControlLabel
      sx={{
        paddingX: "10px",
        paddingY: "10px",
        marginX: "0px"
      }}
      value={props.value}
      label={props.labelItem}
      control={<Radio id={props.idItem}
        sx={{
          width: props.sizeRadio,
          height: props.sizeRadio,
          color: 'transparent',
          border: '2px solid #f6eced',
          bgcolor: props.colorRadio,
          marginX: '10px',
          '&.Mui-checked': {
            color: 'transparent',
            border: '3px solid #f6eced',
            background: props.colorRadio,
            boxShadow: '0 0 10px' + props.colorRadio,
            marginX: '10px',
          },
          '&.MuiSvgIcon-root': {
            fontSize: props.sizeRadio,
          },
          '&:hover:none': {
            opacity: '0.2',
            border: '2px solid darkslategray',
            bgcolor: 'gray',
          },
        }}
      />}
    />
  )
}

export default RadioButtonColorComponent;
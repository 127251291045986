import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Divider, Grid, Menu, MenuItem, Popper } from '@mui/material';
import LabelComponent from '../label/labelComponent';
import icon from "../../assets/images/chevron.png";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { minutesToHours } from '../../utils/utils';

export default function CardComponent(props: CardParams) {

    const navigate = useNavigate();
    const [idValue, setIdValue] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id: any) => {
        setIdValue(id);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function onEdit() {
        handleClose();
        props.onClickEdit(idValue);
    }

    function onDelete() {
        handleClose();
        props.onClickDelete(idValue);
    }

    return props.values.map((value: any) => {
        let cost;
        if (props.type === "resource") {
            cost = value?.valueByQuantity.toString().split('.');
            if (cost.length === 1) {
                cost[1] = ',00';
            } else {
                cost[1] = ',' + cost[1];
            }
        }
        return <Card sx={{
            width: "310px",
            margin: "8px 16px 0 0",
            borderRadius: "8px",
            boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
            backgroundColor: "#fff",
        }}>
            <CardHeader
                sx={{
                    ".MuiTypography-root": {
                        color: "#001e50",
                        fontSize: "18px",
                        maxWidth: "25ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }
                }}
                action={
                    <Grid>
                        <IconButton
                            onClick={(event) => {
                                if (props.type === "resource" || props.type === "activity") {
                                    handleClick(event, value.laborTypeTestId);
                                } else if (props.type === "laboratory") {
                                    handleClick(event, value.id);
                                }
                            }
                            }
                            sx={{
                                width: "5px",
                                height: "20px",
                                margin: "22px 0 12px 11px",
                                objectFit: "contain",
                                color: "#00b0f0",
                                "&.MuiIconButton-root": {
                                    margin: "0px"
                                }
                            }}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            sx={{
                                ".MuiPaper-root": {
                                    width: "100px",
                                    borderRadius: "10px",
                                    boxShadow: "0 4px 16px 0 rgba(208, 217, 232, 0.03), 0 10px 20px 0 rgba(176, 197, 209, 0.5), 0 4px 10px 0 rgba(210, 215, 218, 0.1)"
                                },
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}>
                            <MenuItem
                                sx={{
                                    fontFamily: "VWText",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.25",
                                    letterSpacing: "normal",
                                    color: "#00b0f0",
                                    padding: "0px",
                                    justifyContent: 'center',
                                    marginTop: "4px"
                                }}
                                onClick={onEdit}>
                                {"Editar"}
                            </MenuItem>
                            <Divider variant="middle" sx={{
                                marginTop: "8px",
                            }} />
                            <MenuItem
                                sx={{
                                    fontFamily: "VWText",
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.25",
                                    letterSpacing: "normal",
                                    color: "#00b0f0",
                                    padding: "0px",
                                    justifyContent: 'center',
                                    marginBottom: "4px"
                                }}
                                onClick={onDelete}>
                                {"Excluir"}
                            </MenuItem>
                        </Menu>
                    </Grid>
                }
                title={props.type === "activity" || props.type === "resource" ? value.description : value?.name}
            />
            <CardContent
                sx={{
                    "&.MuiCardContent-root": {
                        padding: "0px 16px 0px 16px"
                    }
                }}>
                {props.type === "activity" ?
                    <Grid container>
                        <Grid item xs={6}>
                            <LabelComponent text={"Atividades"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                            <LabelComponent text={"Total de horas"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                        </Grid>
                        <Grid item xs={4}>
                            <LabelComponent text={value?.totalActivities} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: "end" }}>
                            <LabelComponent text={minutesToHours(value?.totalMinutes)} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                            <LabelComponent text={' h'} color={"#96a3a8"} fontSize={"px"} fontWeight={"normal"} />
                        </Grid>
                    </Grid> :
                    props.type === "resource" ?
                        <Grid container>
                            <Grid item xs={6}>
                                <LabelComponent text={"Recursos"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                                <LabelComponent text={"Total de custo"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={value?.totalResources} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                            </Grid>
                            <Grid item xs={8} sx={{ textAlign: "end" }}>
                                <LabelComponent text={"R$ "} color={"#96a3a8"} fontSize={"px"} fontWeight={"normal"} />
                                <LabelComponent text={cost[0]} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                                <LabelComponent text={cost[1]} color={"#96a3a8"} fontSize={"px"} fontWeight={"normal"} />
                            </Grid>
                        </Grid>
                        : <Grid container>
                            <Grid item xs={4}>
                                <LabelComponent text={"Testes"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={"Aprovados"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={"Em revisão"} color={"#96a3a8"} fontSize={"14px"} fontWeight={"normal"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={value?.tests} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={value?.approved} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabelComponent text={value?.revision} color={"#000"} fontSize={"44px"} fontWeight={"300"} />
                            </Grid>
                        </Grid>
                }
                <Divider variant="middle" sx={{
                    marginTop: "8px",
                }} />
                <Button
                    onClick={() => navigate(props.navigate! + (props.type === "activity" ? value.laborTypeTestId : props.type === "resource" ? value.laborTypeTestId : value.id))}
                    sx={{
                        width: "100%",
                        height: "20px",
                        margin: "16px 0px 16px 0px",
                        padding: "0px",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.25",
                        letterSpacing: "normal",
                        textAlign: "right",
                        color: "#00b0f0",
                        textTransform: 'none'
                    }}
                >{"Ver detalhes"}
                    <img style={{
                        width: "11px",
                        height: "12px",
                        margin: "6px 0 4px 7px",
                        objectFit: "contain",
                    }} src={icon} />
                </Button>
            </CardContent>
        </Card >
    });
}
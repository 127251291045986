import axios from "axios";
import { sendAuthorizationRequest } from "../services/login";

const api = axios.create(
    process.env.REACT_APP_ENVIRONMENT !== "TESTE" ?
        {
            baseURL: process.env.REACT_APP_API,
            headers: {
                Authorization: `${localStorage.getItem("access_token")}`
            }
        } :
        {
            baseURL: process.env.REACT_APP_API
        });

export function get<T = unknown>(url: string) {
    return api.get(url).then((data) => {
        if (data.status === 200 || data.status === 201) {
            data.data.codeStatus = data.status;
            return data.data
        }
    }).catch((err) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "TESTE") {
            if (err.response.data === undefined) {
                sendAuthorizationRequest();
            }
        }
        return err.response.data;
    }).finally(() => false);
}

export function post<T = unknown>(url: string, options?: any) {
    return api.post(url, options).then((data) => {
        if (data.status === 200 || data.status === 201) {
            data.data.codeStatus = data.status;
            return data.data
        }
    }).catch((err) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "TESTE") {
            if (err.response.data === undefined) {
                sendAuthorizationRequest();
            }
        }
        return err.response.data;
    }).finally(() => false);
}

export function put<T = unknown>(url: string, options?: any) {
    return api.put(url, options).then((data) => {
        if (data.status === 200) {
            data.data.codeStatus = data.status;
            return data.data
        }
    }).catch((err) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "TESTE") {
            if (err.response.data === undefined) {
                sendAuthorizationRequest();
            }
        }
        return err.response.data;
    }).finally(() => false);
}

export function deleteR<T = unknown>(url: string, options?: any) {
    return api.delete(url, options).then((data) => {
        if (data.status === 200) {
            data.data.codeStatus = data.status;
            return data.data
        }
    }).catch((err) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "TESTE") {
            if (err.response.data === undefined) {
                sendAuthorizationRequest();
            }
        }
        return err.response.data;
    }).finally(() => false);
}
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import ButtonToggle, { TogglePanel } from "../../components/button/buttonToggleComponent";
import RequestTestPageTab2VDS from "./requestTestTab2VDS";
import RequestTestPageTab2Model from "./requestTestTab2Model";
import LabelComponent from "../../components/label/labelComponent";
import { useParams } from "react-router-dom";
import useRequestTestTab2 from "../../hooks/requestTest/useRequestTestTab2";

function RequestTestPageTab2() {
  const { type, id } = useParams();
  const { value, setValue } = useRequestTestTab2({ type, id });

  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'center', }}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ paddingY: '8px', }}>
          <LabelComponent text="Informações " fontSize="24px" />
          <LabelComponent
            text="do Veículo/Modelo"
            fontSize="24px"
            fontWeight="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{
          paddingX: '8px',
          paddingY: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: { md: 'end', lg: 'end', xl: 'end' },

        }}>
          <ButtonToggle
            value={value}
            textBtn1={'VDS do veículo'}
            textBtn2={'Modelo'}
            bgColor={'#dfe4e8'}
            textColor={'#6a767d'}
            bgColor2={'#021e51'}
            textColor1={'white'}
            width={'146px'}
            onSelect={(event: any) => setValue(event)}
            disabledBtn1={value === 0 ? false : true}
            disabledBtn2={value === 1 ? false : true}
          />
        </Grid>
      </Grid>
      <TogglePanel value={value} index={0} >
        <RequestTestPageTab2VDS ></RequestTestPageTab2VDS>
      </TogglePanel>
      <TogglePanel value={value} index={1}>
        <RequestTestPageTab2Model></RequestTestPageTab2Model>
      </TogglePanel>
    </>
  );
}

export default RequestTestPageTab2;
import { Grid } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder, format } from "../../utils/utils";
import CardRequestTestComponent from "../card/cardRequestTest";
import LabelComponent from "../label/labelComponent";

export default function DraggableRequestTestComponent(props: DraggableParamsField) {
    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return;
        if (props.listResourcesConsumable.length > 0) {
            const newItems = reorder(props.listResourcesConsumable, source.index, destination.index);
            newItems.map((value, index) => {
                value.order = index;
            });
            props.onChangeListResourcesConsumable(newItems);
        }

        if (props.listResourcesInventory.length > 0) {
            const newItems = reorder(props.listResourcesInventory, source.index, destination.index);
            newItems.map((value, index) => {
                value.order = index;
            });
            props.onChangeListResourcesInventory(newItems);
        }
    };

    return (
        <Grid container sx={{
            minHeight: "394px",
            maxHeight: "394px",
            overflowX: "auto",
            marginTop: "24px",
            borderRadius: "4px",
            backgroundColor: "#dfe4e8",
            display: "block"
        }}>
            {props.listActivities.length > 0 ?
                < DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <Grid item md={12} className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                {props.listActivities.map((value: any, index: number) => {
                                    return <Draggable key={value.id} draggableId={value.id + "," + value.name} index={index}>
                                        {(provided) => (
                                            <Grid ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <CardRequestTestComponent
                                                    text={value.name}
                                                    quantity={value.quantity}
                                                    showCalendar={value.showCalendar}
                                                    requiredDate={false}
                                                    date={value.date}
                                                    onChangeDate={(data: any) => props.onChangeDate && props.onChangeDate(data, value)}
                                                    onClickDelete={() => props.onClickDelete(value, "activity")} />
                                            </Grid>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
                : props.isActivities === true && props.listActivities.length === 0 ?
                    <Grid container sx={{
                        minHeight: "394px",
                        maxHeight: "394px",
                        alignContent: "center"
                    }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "center" }}>
                            <LabelComponent text="Não há atividades para este teste"
                                opacity="0.5"
                                color="#6a767d"
                                fontFamily="VWText"
                                fontSize="16px"
                                fontWeight="bold" />
                        </Grid>
                    </Grid> : null
            }
            {props.isResources === true && props.listResourcesConsumable.length === 0 && props.listResourcesInventory.length === 0 ?
                <Grid container sx={{
                    minHeight: "394px",
                    maxHeight: "394px",
                    alignContent: "center"
                }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "center" }}>
                        <LabelComponent text="Não há recursos para este teste"
                            opacity="0.5"
                            color="#6a767d"
                            fontFamily="VWText"
                            fontSize="16px"
                            fontWeight="bold" />
                    </Grid>
                </Grid>
                :
                props.listResourcesConsumable.length > 0 ?
                    <Grid sx={{
                        height: "32px",
                        marginBottom: "0px",
                        marginLeft: "16px",
                        marginTop: "16px",
                        marginRight: "16px",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2",
                        letterSpacing: "normal",
                        color: "#6a767d",
                    }}>
                        Consumível
                    </Grid> : null
            }
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <Grid item md={12} className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                            {props.listResourcesConsumable.map((value: any, index: number) => {
                                return <Draggable key={value.id} draggableId={value.id + "," + value.name} index={index}>
                                    {(provided) => (
                                        <Grid ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <CardRequestTestComponent
                                                text={value.name}
                                                quantity={value.quantity}
                                                showCalendar={false}
                                                requiredDate={false}
                                                onChangeDate={() => { }}
                                                onClickDelete={() => props.onClickDelete(value, "consumable")} />
                                        </Grid>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
            {
                props.listResourcesInventory.length > 0 ?
                    <Grid sx={{
                        height: "32px",
                        marginBottom: "0px",
                        marginLeft: "16px",
                        marginTop: "16px",
                        marginRight: "16px",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2",
                        letterSpacing: "normal",
                        color: "#6a767d",
                    }}>
                        Inventariável
                    </Grid> : null
            }
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <Grid item md={12} className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                            {props.listResourcesInventory.map((value: any, index: number) => {
                                return <Draggable key={value.id} draggableId={value.id + "," + value.name} index={index}>
                                    {(provided) => (
                                        <Grid ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <CardRequestTestComponent
                                                text={value.name}
                                                quantity={value.quantity}
                                                showCalendar={false}
                                                requiredDate={false}
                                                onChangeDate={() => { }}
                                                onClickDelete={() => props.onClickDelete(value, "inventory")} />
                                        </Grid>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Grid >
    );
}
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function a11yProps(index: number) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}`, };
}

function TabsComponent(props: any) {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (props.changeTab) {
            props.changeTab(newValue);
            props.setOpenCancelModal(true);
        } else {
            props.setvalue(newValue);
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={props.value}
                    onChange={handleChange}
                    variant="fullWidth"
                    TabIndicatorProps={{ sx: { background: "#001e50" } }}
                    sx={{
                        "& .Mui-selected": {
                            fontFamily: "VWHead",
                            color: "#001e50 !important",
                            fontWeight: "bold"
                        }
                    }}
                >
                    {props.tabs.map((value: any, index: number) => {
                        return <Tab
                            key={value}
                            disabled={props.disabled}
                            label={value}
                            {...a11yProps(index)}
                            sx={{
                                color: "#001e50",
                                fontSize: "16px",
                                fontFamily: "VWHead",
                                textTransform: "initial",
                                fontWeight: 300,
                                alignItems: "start",
                                paddingLeft: "0px"
                            }}
                        />
                    })}
                </Tabs>
            </Box>
        </Box >
    );
}

export default TabsComponent;
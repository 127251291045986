import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import ButtonsCalendar from "../../components/button/buttonToggleCalendarComponent";
import InputCars from "../../components/uploadCars/uploadCarsComponent";
import Box, { BoxProps } from '@mui/material/Box';
import CardCar from "../../components/card/cardCarComponent";
import CardTestPlanColor from "../../components/card/cardTestPlanColorComponent";
import LabelComponent from "../../components/label/labelComponent";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

function TestPlanTab2Atemporal(props: TestPlanTab2AtemporalParamsField) {

  const navigate = useNavigate();

  const gridInputCalendar = {
    padding: '16px 16px 16px 0',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  }
  const boxTest = {
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "246px",
    height: "310px",
    overflowY: "auto",
    alignItems: "center",
  }

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>

      <Grid container sx={{ overflowX: "auto", display: 'flex', justifyContent: 'center', backgroundColor: '#F1F4F6' }}>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container sx={{ flexWrap: 'nowrap' }}>
            <Grid item xs={12} sm={6} md={2} lg={3} xl={2} sx={{ backgroundColor: '#F1F4F6' }}>
              <Box sx={{ width: '246px', height: '59px' }}></Box>
            </Grid>
            {
              Array.from(Array(props.qtTests)).map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={2} lg={3} xl={2} sx={{ backgroundColor: '#F1F4F6' }}>
                    <Box sx={{ width: '246px', height: '59px', display: 'flex', justifyContent: 'center' }}>
                      <ButtonsCalendar text={'Teste ' + (index + 1)} backgroundColor={'transparent'} color={'#6a767d'} fontSize={'14px'} />
                    </Box>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>

        <Grid container sx={{
          flexWrap: 'nowrap',
          borderBottom: "1px solid #e5e9ed",
          backgroundColor: '#dfe4e8',
        }}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ width: '214px', height: "28px", display: 'flex', alignItems: 'center', justifyContent: 'start', paddingX: '16px' }}>
            <LabelComponent
              text="Veículos"
              fontSize="20px"
              fontWeight="bold"
              color="#001e50"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ width: '214px', height: "28px", display: 'flex', alignItems: 'center', justifyContent: 'center', paddingX: '16px' }}>
            <LabelComponent
              text="Sinergia"
              fontSize="20px"
              fontWeight="bold"
              color="#001e50"
            />
          </Grid>
        </Grid>

        {
          props?.atemporalCars !== undefined ?
            props?.atemporalCars.cardsVds.map((itemCar: CardsVdsAtemporal, indexCar: number) => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed", backgroundColor: '#fff' }}>
                    <Grid item xs={12} sm={6} md={12} lg={3} xl={2} sx={gridInputCalendar}>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ padding: "0 5px", zIndex: '1200', textAlign: "center" }}>
                          {itemCar.receivingDate === null ? <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  margin: "9.5px 136.5px 172.5px 57px",
                                  padding: "20px 30px 30px 3px",
                                  borderRadius: "10px",
                                  boxShadow: "0 4px 16px 0 rgba(208, 217, 232, 0.03), 0 10px 20px 0 rgba(176, 197, 209, 0.5), 0 4px 10px 0 rgba(210, 215, 218, 0.1)",
                                  backgroundColor: "#fff"
                                }
                              }
                            }}
                            title={
                              <Grid container >
                                <Grid item sx={{
                                  margin: "0 193px 5px 32px",
                                  fontFamily: "VWText",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "1.25",
                                  letterSpacing: "normal",
                                  color: "#001e50"
                                }}>Aviso</Grid>
                                <Grid item sx={{
                                  margin: "5px 0 0 32px",
                                  fontFamily: "VWText",
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "1.43",
                                  letterSpacing: "normal",
                                  color: "#6a767d"
                                }}>Os testes agendados para este veículo, mesmo aparecendo no plano de teste, não podem acontecer enquanto não for confirmado o recebimento do veículo!                                                </Grid>
                              </Grid>
                            }
                          >
                            <IconButton sx={{
                              backgroundColor: "#ffd100",
                              width: "24px",
                              height: "24px"
                            }}>
                              <PriorityHighIcon sx={{
                                color: "black",
                                width: "12px"
                              }} />
                            </IconButton>
                          </Tooltip> : null}
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Box sx={{ width: '214px', height: '272px' }}>
                            <CardCar
                              id={itemCar.projectCarId}
                              text={'VDS ' + itemCar.vds}
                              isButtonTitle={true}
                              textPhase={itemCar.fase}
                              textMotorization={itemCar.motorization}
                              textPosition={itemCar.positionVDS}
                              damagesPosition={itemCar.damagesAndPosition}
                              isCopy={false}
                              onNavigate={() => window.open("/stapp/cadastro-veiculos/editar/" + itemCar.projectCarId)}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {itemCar.cardsTests ? itemCar.cardsTests.map((itemTest: AtemporalCard) => {
                      return itemTest.isEmpty === false ?
                        <Grid item xs={12} sm={6} md={2} lg={3} xl={2} sx={boxTest} >
                          <CardTestPlanColor
                            id={itemTest.requestTestId}
                            isCopy={false}
                            isColor={true}
                            text={itemTest.typeTestName}
                            color={itemTest.color}
                            title1={'Número do teste: '}
                            text1={itemTest?.requestTestNumber}
                            title2={'VDS '}
                            text2={itemCar.vds}
                            title3={'Plano de teste'}
                            text3={props?.atemporalCars?.testPlanName}
                            title4={itemTest.weekName}
                            text4={itemTest.year.toString()}
                            isDetails={itemTest.requestTestId !== null ? true : false}
                            onNavigate={() => window.open("/stapp/requisicao-teste/editar/" + itemTest.requestTestId)}
                          />
                        </Grid> : <Grid item xs={12} sm={6} md={2} lg={3} xl={2} sx={boxTest}></Grid>
                    }) : ""}
                  </Grid>
                </Grid>
              )
            })
            : []
        }
      </Grid>
    </Grid >
  );
}

export default TestPlanTab2Atemporal;
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { Accordion, AccordionDetails, Button, IconButton, Tooltip } from "@mui/material";
import AccordionComponent from "../../components/accordion/accordionComponent";
import SelectComponent from "../../components/select/selectComponent";
import ButtonsCalendar from "../../components/button/buttonToggleCalendarComponent";
import Box from '@mui/material/Box';
import CardCar from "../../components/card/cardCarComponent";
import CardTestPlanColor from "../../components/card/cardTestPlanColorComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonAddComponent from "../../components/button/buttonAddComponent";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ModalComponent from "../../components/modal/modalComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RequestTestModalComponent from "../requestTest/requestTestModal";
import { format } from "../../utils/utils";

function TestPlanTab2Temporal(props: TestPlanTab2TemporalParamsField) {

  const [openModal, setOpenModal] = useState(false);
  const [openModalRequestTest, setOpenModalRequestTest] = useState(false);
  const [dayOfMonth, setDayOfMonth] = useState<number>();
  const [copy, setCopy] = useState<any>();
  const [categoryId, setCategoryId] = useState<number>();
  const [projectCarId, setProjectCarId] = useState<number>();
  const [requestTests, setRequestTestes] = useState<RequestTest>();
  const [car, setCar] = useState<any>();
  const [dragOver, setDragOver] = useState(false);
  const [origin, setOrigin] = useState<any>({
    projectCarId: null,
    day: null,
    requestTestId: null
  });
  const [destination, setDestination] = useState<any>({
    day: null
  });

  // function scroll(scrollOffset: number) {
  //   const element = document.getElementById("myDiv");
  //   element!.scrollLeft += scrollOffset;
  // }

  const gridCalendar = {
    display: 'flex',
    backgroundColor: '#EFF1F3',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '15px',
  }

  const boxCalendar = {
    width: '224px',
    display: 'flex',
    justifyContent: 'center',
  }

  const gridInputCalendar = {
    borderRight: "1px solid #e5e9ed",
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white'
  }

  const boxTest = {
    backgroundColor: dragOver ? '#EFF1F3' : 'white',
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "254px",
    height: "310px",
    alignItems: "center",
    overflowY: 'auto'
  }

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    let month = '0';
    if (props.cardItemVds[0].testsOfWeek[0].dayOfMonth > destination.day) {
      month = props?.monthId + 1;
    } else {
      month = props?.monthId;
    }
    if (origin.projectCarId === destination.projectCarId && origin.day !== destination.day) {
      props.handleOnDragEnd(origin.requestTestId, new Date(Number(props?.yearId), Number(month) - 1, destination.day));
    } else {
      props.handleUpdateDateAndVehicleTest(Number(destination.projectCarId), origin.requestTestId, new Date(Number(props?.yearId), Number(month) - 1, destination.day));
    }
  }

  function buttonAddComponent(category: GeneralType, item: CardsVds, itemTest: TestsOfWeek, dayInit: TestsOfWeek) {
    let month = '0';
    if (dayInit.dayOfMonth > itemTest.dayOfMonth) {
      month = props?.monthId + 1;
    } else {
      month = props?.monthId;
    }
    return <Box
      sx={{
        border: "2px dashed #dfe4e8",
        width: '224px',
        height: itemTest.cards[0].isEmptyCard ? '272px' : '112px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <ButtonAddComponent
        imgIcon={'blue'}
        display={'flex'}
        justifyContent={'center'}
        onClick={() => {
          var copy = JSON.parse(localStorage.getItem('copyTestPlan') || '{}');
          setDayOfMonth(itemTest.dayOfMonth);
          setCategoryId(category.id);
          setCopy(copy);
          setProjectCarId(item.projectCarId);
          if (copy.id === undefined) {
            setCar({
              "vds": item.vds,
              "position": item.positionVDS
            });
            setRequestTestes({
              ...requestTests,
              categoryId: category.id,
              testDate: format(new Date(Number(props?.yearId), Number(month) - 1, itemTest.dayOfMonth)),
              testPlanId: props.testPlanId,
            });
            setOpenModalRequestTest(true);
          } else {
            setOpenModal(true);
          }
        }} />
    </Box>
  }

  function cardTestPlanColor(item: CardsVds, itemTest: TestsOfWeek, cardItem: TemporalCard) {
    return <div
      id={itemTest.dayOfMonth.toString()}
      draggable="true"
      onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
        setOrigin({
          ...origin,
          projectCarId: item.projectCarId,
          day: itemTest.dayOfMonth,
          requestTestId: cardItem.requestTestId
        });
      }}>
      <CardTestPlanColor
        key={itemTest.dayOfMonth}
        id={cardItem.requestTestId}
        projectCarId={item.projectCarId}
        text={cardItem?.typeTestName}
        isColor={true}
        color={cardItem?.color}
        title1={'Número do teste: '}
        text1={cardItem?.requestTestNumber}
        title2={'Norma'}
        text2={cardItem?.norma}
        title3={'KW' + props.weekId}
        text3={props?.yearId !== undefined ? props.yearId : ""}
        title4={'Finalidade'}
        text4={cardItem?.reason}
        title5={'Responsável'}
        text5={cardItem?.responsible}
        title6={'Dummy'}
        text6={cardItem?.dummy}
        isDetails={cardItem.requestTestId !== null ? true : false}
        isCopy={true}
        isTestPlan={true}
        onNavigate={() => window.open("/stapp/requisicao-teste/editar/" + cardItem.requestTestId)}
      />
    </div>
  }

  return (
    <Grid>
      <ModalComponent
        description={"Há teste copiado, deseja colar nessa data e/ou veículo?"}
        open={openModal}
        textButton1={"Sim"}
        textButton2={"Não"}
        close={() => { setOpenModal(false) }}
        onCancel={() => {
          setOpenModal(false);
          if (copy.projectCarId === projectCarId) {
            props.handleOnDragEnd(Number(copy.id), new Date(Number(props?.yearId), Number(props?.monthId) - 1, dayOfMonth));
          } else {
            props.handleUpdateDateAndVehicleTest(Number(projectCarId), Number(copy.id), new Date(Number(props?.yearId), Number(props?.monthId) - 1, dayOfMonth));
          }
          localStorage.removeItem("copyTestPlan");
        }}
        onNext={() => {
          setOpenModal(false);
          setOpenModalRequestTest(true);
        }}
      />
      <RequestTestModalComponent
        open={openModalRequestTest}
        requestTests={requestTests}
        car={car}
        close={() => { setOpenModalRequestTest(false) }}
        onCancel={() => { setOpenModalRequestTest(false) }}
        onNext={() => {
          setOpenModalRequestTest(false);
          props.handleAddModal();
        }} />
      {
        props.categories.map((category, indexCategory) => {
          return (
            <Accordion expanded={props.expanded === category.id} onChange={props.handleChangeAccordion(category.id)} key={indexCategory}
              sx={{ boxShadow: "none" }}>
              <AccordionComponent text={category.name}></AccordionComponent>
              <AccordionDetails>
                {props.weeks.length > 0 ? <Grid>
                  <Grid container>
                    <Grid item xs={12}
                      sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                      <Grid item xs={12} sm={6} md={12} lg={1.5} xl={1.2} sx={{ marginX: '10px' }}>
                        <SelectComponent
                          text="Mês"
                          required={true}
                          fontSize={'24px'}
                          value={props?.monthId ? props.monthId : ""}
                          values={props.months}
                          onSelect={props.onSelectMonth}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={1.5} xl={1} sx={{ marginX: '10px' }}>
                        <SelectComponent
                          text="KW"
                          required={true}
                          fontSize={'24px'}
                          value={props?.weekId ? props.weekId : ""}
                          values={props.weeks}
                          onSelect={props.onSelectWeek}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={1.5} xl={1.2} sx={{ marginX: '10px' }}>
                        <SelectComponent
                          text="Ano"
                          required={true}
                          fontSize={'24px'}
                          value={props?.yearId ? props.yearId : ""}
                          values={props.years}
                          onSelect={props.onSelectYear}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid id="myDiv" container sx={{ overflowX: "auto", display: 'flex', justifyContent: 'center', backgroundColor: "#dfe4e8" }}>
                    <Grid container sx={{ height: '28px' }}>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4} sx={{ textAlign: 'center' }}>
                        <LabelComponent text={'KW' + props.weekId} fontSize="20px" fontWeight="bold" color="#001e50" />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "-5px", textAlign: "right" }}>
                        <Button onClick={() => props.changeWeek(false, category.id)} sx={{ color: "#001e50", fontSize: "14px", textTransform: "capitalize" }}>
                          <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
                          Semana Anterior
                        </Button>
                        <Button onClick={() => props.changeWeek(true, category.id)} sx={{ color: "#001e50", fontSize: "14px", textTransform: "capitalize" }}>
                          Próxima Semana
                          <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px", transform: "rotate(180deg)" }} />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ flexWrap: 'nowrap', }}>
                      <Grid item xs={12} sx={gridCalendar}>
                        <Box sx={boxCalendar}></Box>
                      </Grid>
                      {props.cardItemVds.length > 0 &&
                        props.cardItemVds[0].testsOfWeek.map((item: TestsOfWeek, index) => {
                          return (
                            <Grid item key={index} xs={12} sx={gridCalendar}>
                              <Box sx={boxCalendar}>
                                <ButtonsCalendar text={item.dayWeekName} value={item.dayOfMonth} />
                              </Box>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                    <Grid item xs={12}
                      sx={{
                        backgroundColor: '#dfe4e8', display: 'flex',
                      }}>
                      <Grid item xs={12} sm={6} md={12} lg={4} xl={4} sx={{
                        height: "28px",
                        display: 'flex',
                        paddingX: '16px',
                        justifyContent: 'flex-start'
                      }}>
                        <LabelComponent
                          text="Veículos"
                          fontSize="20px"
                          fontWeight="bold"
                          color="#001e50"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={4} xl={4} sx={{
                        height: "28px",
                        display: 'flex',
                        paddingX: '16px',
                        justifyContent: 'center'
                      }}>
                        <LabelComponent
                          text="Sinergia"
                          fontSize="20px"
                          fontWeight="bold"
                          color="#001e50"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {props.cardItemVds.map((item: CardsVds, indexCard: number) => {
                        return <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed" }}>
                          <Grid item xs={3} sm={6} md={12} lg={3} xl={2} sx={gridInputCalendar}>
                            <Box sx={{ width: '224px', height: '272px' }}>
                              {
                                <Grid container>
                                  <Grid item xs={2} sm={2} md={2} lg={2} sx={{ padding: "0 5px", zIndex: '1200', textAlign: "center" }}>
                                    {item.receivingDate === null ? <Tooltip
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            margin: "9.5px 136.5px 172.5px 57px",
                                            padding: "20px 30px 30px 3px",
                                            borderRadius: "10px",
                                            boxShadow: "0 4px 16px 0 rgba(208, 217, 232, 0.03), 0 10px 20px 0 rgba(176, 197, 209, 0.5), 0 4px 10px 0 rgba(210, 215, 218, 0.1)",
                                            backgroundColor: "#fff"
                                          }
                                        }
                                      }}
                                      title={
                                        <Grid container >
                                          <Grid item sx={{
                                            margin: "0 193px 5px 32px",
                                            fontFamily: "VWText",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.25",
                                            letterSpacing: "normal",
                                            color: "#001e50"
                                          }}>Aviso</Grid>
                                          <Grid item sx={{
                                            margin: "5px 0 0 32px",
                                            fontFamily: "VWText",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.43",
                                            letterSpacing: "normal",
                                            color: "#6a767d"
                                          }}>Os testes agendados para este veículo, mesmo aparecendo no plano de teste, não podem acontecer enquanto não for confirmado o recebimento do veículo!                                                </Grid>
                                        </Grid>
                                      }
                                    >
                                      <IconButton sx={{
                                        backgroundColor: "#ffd100",
                                        width: "24px",
                                        height: "24px"
                                      }}>
                                        <PriorityHighIcon sx={{
                                          color: "black",
                                          width: "12px"
                                        }} />
                                      </IconButton>
                                    </Tooltip> : null}
                                  </Grid>
                                  <Grid item xs={10} sm={10} md={10} lg={10}>
                                    <CardCar
                                      key={indexCard}
                                      id={item.projectCarId}
                                      isCopy={false}
                                      text={item.vds !== null ? 'VDS ' + item.vds : ' ' + item.positionVDS}
                                      isButtonTitle={true}
                                      textPhase={item.fase}
                                      textMotorization={item.motorization}
                                      textPosition={item.positionVDS}
                                      damagesPosition={item.damagesAndPosition}
                                      onNavigate={() => window.open("/stapp/cadastro-veiculos/editar/" + item.projectCarId)}
                                      onClickDamagesPosition={(event: any, value: any) => { props.fetchDamage(value) }}
                                    // (event: any, value: any) => {
                                    // item.testsOfWeek.map((item, indexItem) => {
                                    //   item.cards.map((card) => {
                                    //     if (card.requestTestId === value.requestTestId) {
                                    //       if (indexItem === 1) {
                                    //         scroll(500);
                                    //       }
                                    //       if (indexItem >= 2) {
                                    //         scroll(750);
                                    //       }
                                    //     }
                                    //   });
                                    // });
                                    // }}
                                    />
                                  </Grid>
                                </Grid>
                              }
                            </Box>
                          </Grid>
                          {item.testsOfWeek.map((itemTest: TestsOfWeek) => {
                            return <Grid item xs={12} sm={6} md={12} lg={3} xl={2} sx={boxTest} >
                              {itemTest.cards.map((cardItem: TemporalCard, indexTemporalCard: number) => {
                                return <div
                                  onDropCapture={(event) => {
                                    setDragOver(false);
                                    setDestination({
                                      ...destination,
                                      day: itemTest.dayOfMonth,
                                      projectCarId: item.projectCarId
                                    });
                                  }}
                                  onDragOver={enableDropping}
                                  onDrop={handleDrop}
                                  onDragLeave={(event) => {
                                    setDragOver(true);
                                  }}
                                >
                                  <Grid sx={{ width: '90%', height: 'auto' }}>
                                    {cardItem.isEmptyCard === false ?
                                      itemTest.cards.length !== (indexTemporalCard + 1) ?
                                        cardTestPlanColor(item, itemTest, cardItem)
                                        : <div>
                                          {cardTestPlanColor(item, itemTest, cardItem)}
                                          {buttonAddComponent(category, item, itemTest, item.testsOfWeek[0])}
                                        </div>
                                      : buttonAddComponent(category, item, itemTest, item.testsOfWeek[0])}
                                  </Grid>
                                </div>
                              })}
                            </Grid>
                          })}
                        </Grid>
                      })}
                    </Grid>
                  </Grid>
                </Grid> : ''}
              </AccordionDetails>
            </Accordion>
          );
        })
      }
    </Grid >
  );
}

export default TestPlanTab2Temporal;
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

function ButtonLinkComponent(props: ParamsField) {

    return (<Link to={props.link!} style={{ textDecoration: "none" }}>
        <Button variant="contained"
            style={{
                width: "163px",
                height: "44px",
                borderRadius: "24px",
                backgroundColor: "#00b0f0",
                textTransform: "capitalize"
            }}>
            {props.text}</Button>
    </Link>);
}

export default ButtonLinkComponent;
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import useRoute from "../route/useRoute";

const useRequestTestList = () => {

    const [loading, setLoading] = useState(true);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [requestTestList, setRequestTestList] = useState<any>([]);
    const [codeStatus, setCodeStatus] = useState<number | null>(null);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("requisicao-teste"));
        fetchRequestTestList("", "", "", 0, 5);
    }, []);

    const fetchRequestTestList = (filter: string, filter1: string, filter2: string, page: number, pageSize: number) => {
        setLoading(true);
        setRequestTestList([]);
        post('requestTests/all', {
            "page": page,
            "size": pageSize,
            "param": filter,
            "testNumber": filter1,
            "statusTest": filter2,
        }).then((value) => {
            setTotalElements(value.totalElements);
            setLoading(false);
            setRequestTestList(value.content);
        });
    };

    const fetchDeactive = (id: number | null, active: boolean, filter: string, filter1: string, filter2: string, page: number, pageSize: number) => {
        put('requestTests/disable/' + id).then((value) => {
            fetchRequestTestList(filter, filter1, filter2, page, pageSize);
            setCodeStatus(value.codeStatus);
        });
    }

    return {
        fetchRequestTestList,
        loading,
        totalElements,
        requestTestList,
        fetchDeactive,
        codeStatus,
        access
    };
}

export default useRequestTestList;
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";

const useTestPlanTab5 = (state: any) => {

    const [status, setStatus] = useState<StatusTest[]>([])
    const [user, setUser] = useState<User[]>([]);
    const [resume, setResume] = useState<PlanResume>();
    const [loading, setLoading] = useState(true);
    const [componentTest, setComponentTest] = useState<ComponentTest[]>([]);
    const [dataStep, setDataStep] = useState<any>();
    const [firstYear, setFirstYear] = useState<number>();
    const [phaseName, setPhaseName] = useState<string>();
    const [phaseStatus, setPhaseStatus] = useState<string>("");
    const [lastYear, setLastYear] = useState<number>();
    const [totalTable, setTotalTable] = useState<any>();
    const [messageSave, setMessageSave] = useState<string>("");
    const [openAlert, setOpenAlert] = useState(false);
    const [listUser, setListUser] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>("");
    const [codeStatus, setCodeStatus] = useState<number | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");

    let phaseList: any = [];

    useEffect(() => {
        fetchPlanStatus();
        fetchUser();
        fetchResume(1, state.id, "", "");
        fetchPhasesList(state.id);

    }, [])

    const fetchPlanStatus = () => {
        post<StatusTest>("testPlans/tab5/testPlanStatus").then((status) => {
            if (status.codeStatus === 200) {
                setStatus(status)
            } else {
                setStatus([])
            }
        }).catch((err) => {
        })
    }

    const fetchUser = () => {
        get<User>('users').then((users) => {
            setUser(users)
        })
    }

    const fetchPhasesList = (id: number) => {
        get<PhaseList>("testPlans/phase/tab3/" + id)
            .then((value: PhaseList) => {
                setPhaseName(value.name);
                setPhaseStatus(value.status);
                setLastYear(value.lastYear);
                setFirstYear(value.firstYear);
                value.listTPPhaseScheduleDto.map((item: ListPhaseSchedule) => {
                    phaseList.push({
                        type: item.type,
                        kw: item.kwName,
                        id: item.kw,
                        year: item.year,
                    })
                })
                setDataStep(phaseList);
            }).finally(() => setLoading(false));
    }

    const fetchChangeStatus = (body: any) => {
        put('testPlans/tab5/changeStatus', body).then((value) => {
            if (value.codeStatus === 200) {
                setOpenAlert(true)
            } else {
                setMessageSave(value.errorMessage);
            }
        })
    }

    const fetchCleanMessageError = () => {
        setMessageSave("");
    }

    const fetchSelectUser = () => {
        setLoading(true);
        listUser.push({ id: selectedUser })
        let array: any[] = [];
        listUser.map((value: any) => {
            array.push(value.id)
        });
        put('testPlans/tab5/engineers', {
            "engineersUsersIds": array,
            "testPlanId": state.id
        }
        ).then((value) => {
            if (value.codeStatus !== 200) {
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
            setSelectedUser("");
            fetchResume(1, state.id, "", "");
        }).finally(() => setLoading(false));

    }

    const fetchResume = (type: number, testPlanId: any, week: any, year: any) => {
        setLoading(true);
        if (type === 1) {
            post('/testPlans/tab5/resume',
                {
                    "testPlanId": testPlanId
                })
                .then((value) => {
                    setListUser(value.engineers);
                    let array = [];
                    array.push(value.totals);
                    setPhaseStatus(value.status);
                    setResume(value);
                    setTotalTable(array);
                }).finally(() => setLoading(false));
        } else {
            post('/testPlans/tab5/resume',
                {
                    "testPlanId": testPlanId,
                    "week": week,
                    "year": year
                })
                .then((value) => {
                    setListUser(value.engineers);
                    let array = [];
                    array.push(value.totals);
                    setPhaseStatus(value.status);
                    setTotalTable(array);
                }).finally(() => setLoading(false));
        }
    };

    return {
        loading,
        status,
        user,
        fetchPlanStatus,
        resume, dataStep, firstYear, componentTest,
        fetchResume,
        phaseName, messageAlert, isError, setIsError,
        phaseStatus, setPhaseStatus,
        lastYear, totalTable, codeStatus, fetchChangeStatus, fetchCleanMessageError,
        openAlert, setOpenAlert, messageSave, listUser, setListUser, selectedUser, setSelectedUser, fetchSelectUser
    }
}

export default useTestPlanTab5;
import { FormLabel } from "@mui/material";

function LabelComponent(props: ParamsField) {
    return (
        <FormLabel sx={{
            display: props.display ? props.display : "initial",
            fontFamily: props.fontFamily ? props.fontFamily : "VWHead",
            color: props.color ? props.color : "#000",
            fontSize: props.fontSize ? props.fontSize : "20px",
            fontWeight: props.fontWeight ? props.fontWeight : "bold",
            fontStretch: props.fontStretch ? props.fontStretch : "normal",
            fontStyle: props.fontStyle ? props.fontStyle : "normal",
            lineHeight: props.lineHeight ? props.lineHeight : "normal",
            letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
            marginLeft: props.marginLeft ? props.marginLeft : "0px",
            marginTop: props.marginTop ? props.marginTop : "0px",
            marginRight: props.marginRight ? props.marginRight : "0px",
            marginBottom: props.marginBottom ? props.marginBottom : "0px",
            margin: props.margin ? props.margin : "0px",
            opacity: props.opacity ? props.opacity : 1,
        }}>{props.text}</FormLabel>);
}

export default LabelComponent;
import { Box, Grid } from "@mui/material";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { GridColDef } from "@mui/x-data-grid";
import useSparePartsList from "../../hooks/sparePartsList/sparePartsList";
import { useState } from "react";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import SearchComponent from "../../components/search/searchComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { useNavigate } from "react-router-dom";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";
import ModalReadPage from "../../components/modal/modalReadPage";
import ModalComponent from "../../components/modal/modalComponent";

function SparePartsListPage() {

    const { loading, access, parts, categories,
        subCategories, tests, totalElements,
        read, setRead,
        isError, setIsError, messageSave,
        fetchList, fetchUpdateTable } = useSparePartsList();

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [filter, setFilter] = useState<string>("");
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [filter3, setFilter3] = useState<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: "testPlanName", headerName: "Nome do projeto", flex: 0.4, minWidth: 200,
        },
        {
            field: "testNumber", headerName: "Nº do teste", flex: 0.4, minWidth: 200
        },
        {
            field: "categoryName", headerName: "Categoria", flex: 0.4, minWidth: 250
        },
        {
            field: "subCategoryName", headerName: "Subcategoria", flex: 0.4, minWidth: 150
        },
        {
            field: "typeTestName", headerName: "Nome do teste", flex: 0.4, minWidth: 350
        },
        {
            field: "description", headerName: "Descrição", editable: true, flex: 0.4, minWidth: 250
        },
        {
            field: "position", headerName: "Pos", editable: true, flex: 0.4, minWidth: 100
        },
        {
            field: "partNumber", headerName: "Nº da Peça", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "provider", headerName: "Fornecedor", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "amount", headerName: "Quantidade", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "sampleLevel", headerName: "Nível amostra", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "partStatus", headerName: "Status Peça", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "performance", headerName: "Desempenho", editable: true, flex: 0.4, minWidth: 150
        },
        {
            field: "action", headerName: "Ação", flex: 0.4, minWidth: 150,
            renderCell: (value: any) =>
                <Box flex={1}>
                    <ButtonTextComponent text="Ver Detalhes" onClick={() => { navigate("/requisicao-teste/editar/" + value.row.requestTestId) }} />
                </Box>
        },
    ];

    const filterList = (event: any) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            setFilter3("");
            fetchList("", "", "", "", page, pageSize);

        } else {
            setFilter(event.target.value);
            fetchList(event.target.value, filter1, filter2, filter3, page, pageSize);
        }
    }

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    return (
        access ?
            <Grid container>
                <HeaderComponent text="Consultas / Lista Peças de Reposição" access={access}/>
                <ModalReadPage open={read} onCancel={() => setRead(false)} />
                <ModalComponent
                    description={messageSave}
                    isOk={true}
                    open={isError}
                    close={() => {
                        setIsError(false);
                        fetchList("", "", "", "", page, pageSize);
                    }}
                    onCancel={() => {
                        setIsError(false);
                        fetchList("", "", "", "", page, pageSize);
                    }}
                    onNext={() => {
                        setIsError(false);
                        fetchList("", "", "", "", page, pageSize);
                    }}
                />
                <ModalFilterComponent
                    open={openFilter}
                    labelFilter1={"Categoria"}
                    isPagination1={false}
                    value1={filter1}
                    filter1={categories}
                    onSelect1={(event: any) => {
                        setFilter1(event.target.value);
                    }}
                    labelFilter2={"Sub-categoria"}
                    isPagination2={false}
                    value2={filter2}
                    filter2={subCategories}
                    onSelect2={(event: any) => {
                        setFilter2(event.target.value);
                    }}
                    labelFilter3={"Nome do teste"}
                    isPagination3={false}
                    value3={filter3}
                    filter3={tests}
                    onSelect3={(event: any) => {
                        setFilter3(event.target.value);
                    }}
                    close={handleCloseModalFilter}
                    onCancel={() => { setOpenFilter(false) }}
                    onNext={() => {
                        if (filter1 === "" && filter2 === "" && filter3 === "") {
                            setPage(0);
                            setPageSize(5);
                        }
                        fetchList(filter, filter1, filter2, filter3, page, pageSize);
                        setOpenFilter(false);
                    }} />
                <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LabelComponent text={"Consulta "} fontSize="28px" />
                            <LabelComponent text="de Peças de Reposição" fontSize="28px" fontWeight="normal" />
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                            <SearchComponent text="" value={filter} placeholder="Buscar por …"
                                onChange={filterList}
                                onClick={() => {
                                    setOpenFilter(true)
                                }}
                                onCancel={() => {
                                    setFilter("");
                                    fetchList("", filter1, filter2, filter3, page, pageSize);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DataTableComponent
                            rows={parts}
                            columns={columns}
                            loading={loading}
                            page={page}
                            pageSize={pageSize}
                            totalElements={totalElements}
                            onPageSizeChange={(page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                                fetchList(filter, filter1, filter2, filter3, page, pageSize);
                            }}
                            onPageChange={(page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                                fetchList(filter, filter1, filter2, filter3, page, pageSize);
                            }}
                            processRowUpdate={(newRow, oldRow) => {
                                fetchUpdateTable(newRow);
                            }}
                            onProcessRowUpdateError={(error: Error) => error}
                            onClick={filterList} />
                    </Grid>
                </Grid>
            </Grid>
            : <NotAuthorizedPage />
    );
}

export default SparePartsListPage;
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { deleteR, get } from "../../api/api";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { useNavigate, useParams } from "react-router-dom";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import { moneyToIntegerSubtraction } from "../../utils/utils";

function ResourceTestDetails() {
    const [resources, setResources] = useState<LaborTypeTestResources[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { laborTypeTestId } = useParams();
    const [laborName, setLaborName] = useState<string>("");


    const columns: GridColDef[] = [
        { field: "quantity", headerName: "Quantidade", flex: 2, minWidth: 100, },
        { field: "resourceName", headerName: "Recursos", flex: 2, minWidth: 100, },
        { field: "resourceType", headerName: "Tipo do recurso", flex: 2, minWidth: 100 },
        {
            field: "totalCost", headerName: "Valor total por uso", flex: 2, minWidth: 100,
            valueGetter: (params: any) => {
                return 'R$ ' + params.row.totalCost.replace('.', ',');
            },
            sortComparator: (value1: any, value2: any) => {
                return moneyToIntegerSubtraction(value1, value2);
            }
        },
        {
            field: "altra", headerName: "Ação", flex: 3, minWidth: 100,
            renderCell: (value) => {
                return <Box flex={1}>
                    <ButtonTextComponent text="Excluir" onClick={() => deleteResource(value.row.laborTypeTestResourceId)} />
                </Box>
            }
        },
    ];

    useEffect(() => {
        getList();
    }, []);

    function getList() {
        get<LaborTypeTestResources[]>("laboratories/typeTests/resources/" + laborTypeTestId).then((value) => {
            setResources(value.laborTypeTestResources);
            setLaborName(value.laborName);
            setLoading(false);
        });
    }

    function deleteResource(id: number) {
        deleteR("laboratories/typeTests/resources/" + id).then((value: any) => {
            setLoading(true);
            getList();
        });
    }

    return (
        <Grid container>
            <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Recursos / Detalhes" access={true}/>
            <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <LabelComponent text={"Detalhes "} fontSize="28px" />
                        <LabelComponent text="da Associação dos Testes" fontSize="28px" fontWeight="normal" />
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: "32px", marginBottom: "24px" }}>
                    <Grid item xs={12} >
                        <LabelComponent text="Testes no laboratório " />
                        <LabelComponent text={laborName} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataTableGridComponent
                        rows={resources}
                        columns={columns}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Grid>);
}
export default ResourceTestDetails;
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import ButtonComponent from "../../../components/button/buttonComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import { calculateAdministrativeProvisionTotals, decimalToTime, fillTimeField, hourToMinutes, minutesToHours, rightPad, timeToDecimal } from "../../../utils/utils";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1AdministrativeProvision(props: any) {
    const { displayAlert, loading, messageAlert,
        isError, setIsError,
        administrativeProvisionTotals,
        fetchSaveAdministrativeProvision,
        provisionsAdm,
        setProvisionsAdm,
        testPlanTotals,
        setAdministrativeProvisionTotals,
        read, setRead, access
    } = useTestPlanTab1(props.currentModal, props.type, props.id);

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    return (
        access ?
        <Grid container>
            {loading && <LoadingComponente />}
            <ModalComponent
                description={messageAlert}
                isOk={true}
                open={isError}
                close={() => { setIsError(false) }}
                onCancel={() => { setIsError(false) }}
                onNext={() => setIsError(false)}
            />
            <ModalReadPage open={read} onCancel={() => setRead(false)} />
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <LabelComponent fontSize={"20px"} text="Duração de reuniões mensais" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {provisionsAdm.length > 0 && provisionsAdm.map((item, itemIndex) =>
                    <Grid container
                        sx={{
                            marginTop: "16px",
                            backgroundColor: '#dfe4e8',
                            borderRadius: "5px",
                            color: "#001e50",
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            letterSpacing: "normal",
                            textAlign: "center"
                        }}
                    >
                        <Grid container sx={{ padding: "30px" }}>
                            <LabelComponent color={"#001e50"} fontSize={"20px"} text="Supervisão&nbsp;" fontWeight="light" />
                            <LabelComponent color={"#001e50"} fontSize={"20px"} text={item.supervisionInitial} />
                        </Grid>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} sm={12} md={2} lg={2} sx={{ padding: "45px" }}>
                                <LabelComponent color={"#001e50"} fontSize={"14px"} text="QTD Mensal" />
                                <LabelComponent color={"#001e50"} fontSize={"14px"} text="Duração" display="block" marginTop="47px !important" />
                            </Grid>
                            {
                                item.provisionsAdms.map((subItem, subItemIndex) =>
                                    <Grid item xs={12} sm={12} md={1.4} lg={1.4} sx={{ marginBottom: "32px" }}>
                                        <Box>{subItem.type}</Box>
                                        <br />
                                        <Grid style={{ display: "flex", justifyContent: "center", }}>
                                            <Box style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "100px" }}>
                                                <NumberFormat
                                                    style={{ border: "none", fontSize: "16px", height: "40px", paddingTop: "3px", outline: "none", width: "40px", textAlign: "center" }}
                                                    displayType={"input"}
                                                    type="text"
                                                    decimalSeparator={","}
                                                    allowNegative={false}
                                                    value={subItem.quantity}
                                                    onChange={(event: any) => {
                                                        let newArr = [...provisionsAdm];
                                                        newArr[itemIndex].provisionsAdms[subItemIndex] = { ...newArr[itemIndex].provisionsAdms[subItemIndex], quantity: Number(event.target.value) };
                                                        setProvisionsAdm(newArr);
                                                        setAdministrativeProvisionTotals(calculateAdministrativeProvisionTotals(newArr, testPlanTotals));
                                                    }}
                                                ></NumberFormat>
                                            </Box>
                                        </Grid>
                                        <br />
                                        <Grid style={{ display: "flex", justifyContent: "center", }}>
                                            <Box style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "100px" }}>
                                                <NumberFormat
                                                    style={{ border: "none", fontSize: "16px", height: "40px", paddingTop: "3px", outline: "none", width: "40px", textAlign: "center" }}
                                                    displayType={"input"}
                                                    type="text"
                                                    decimalSeparator={","}
                                                    allowNegative={false}
                                                    format={"##:##"}
                                                    value={subItem.durationScreen ? subItem.durationScreen : minutesToHours(Number(subItem.duration))}
                                                    onBlur={(value: any) => value.target.value = fillTimeField(value.target.value)}
                                                    onChange={(event: any) => {
                                                        let newArr = [...provisionsAdm];
                                                        let value = fillTimeField(event.target.value);
                                                        newArr[itemIndex].provisionsAdms[subItemIndex] = { ...newArr[itemIndex].provisionsAdms[subItemIndex], duration: hourToMinutes(value), durationScreen: value };
                                                        setProvisionsAdm([]);
                                                        setProvisionsAdm(newArr);
                                                        setAdministrativeProvisionTotals(calculateAdministrativeProvisionTotals(newArr, testPlanTotals));
                                                    }}
                                                ></NumberFormat>
                                                <LabelComponent color={"#001e50"} fontSize={"16px"} text="h" fontWeight="400" />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>)}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "32px" }}>
                <LabelComponent fontSize={"20px"} text="Custo total" />
            </Grid>
            {administrativeProvisionTotals && administrativeProvisionTotals.map((row: any) =>
                <Grid item xs={12} sm={6} md={2.4} lg={2.4} style={{ paddingRight: "20px" }}>
                    <Grid container sx={{
                        backgroundColor: '#dfe4e8',
                        borderRadius: "5px",
                        color: "#001e50",
                        fontFamily: "VWHead",
                        fontSize: "14px",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        letterSpacing: "normal",
                        marginTop: "16px",
                        padding: "20px",
                        textAlign: "left",
                    }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LabelComponent fontSize={"20px"} text={row.year} />
                        </Grid>

                        {row.values.map((rowValues: any) => <>
                            <Grid item xs={6} sm={6} md={6} lg={6}> {rowValues.name} </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}> {rowValues.value} </Grid>
                        </>
                        )}
                    </Grid>
                </Grid>
            )}

            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                    <ButtonComponent
                        text="Cancelar"
                        disable={true}
                        onClick={() => {
                            props.setOpenCancelModal(true);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }} >
                    <ButtonComponent
                        text="Salvar"
                        active={true}
                        onClick={() => {
                            fetchSaveAdministrativeProvision(provisionsAdm, testPlanTotals, administrativeProvisionTotals);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        : <NotAuthorizedPage/>
    )
}

export default TestPlanPageTab1AdministrativeProvision;
import { Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { get, post, put } from "../../api/api";
import TextareaAutosizeComponent from "../../components/field/textareaAutosize";
import ButtonComponent from "../../components/button/buttonComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import LabelComponent from "../../components/label/labelComponent";
import SelectComponent from "../../components/select/selectComponent";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import useRequestTestTab2 from "../../hooks/requestTest/useRequestTestTab2";
import { useParams } from "react-router-dom";
import ModalComponent from "../../components/modal/modalComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalReadPage from "../../components/modal/modalReadPage";

function RequestTestPageTab2Model() {

  const { type, id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | string>("");

  const {
    modelVDS, setModelVDS,
    devices, setDevices,
    loading, read, setRead,
    fetchSubmit,
    idModelCar, setIdModelCar,
    fetchCarId,
    messageSave, openAlert, setOpenAlert,
    messageModal, setMessageModal,
    openModalError, setOpenModalError,
    valid, setValid, fetchRequestTestsProjectCar,
    fetchDevices
  } = useRequestTestTab2({ type, id });

  const handleSubmit = (e: any) => {
    if (valid) {
      fetchSubmit({
        "deviceDescription": modelVDS?.deviceDescription,
        "pulse": modelVDS?.pulse,
        "resourceDeviceId": modelVDS?.resourceDeviceId,
        "bodyWork": modelVDS?.bodyWork,
        "carId": modelVDS?.projectCar?.car?.id,
        "requestTestId": id,
        "supervisionId": modelVDS?.supervisionId
      });
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
  };

  const handleCloseModalError = (e: any) => {
    setOpenModalError(false);
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  return (
    <>
      <SnackbarComponent isTab={true} text={isEdit ? "Requisição de teste editada com sucesso" : "Requisição de teste salva com sucesso"}
        open={openAlert}
        closeSnackbar={handleClose} />
      {loading ? <LoadingComponente /> : null}
      <ModalComponent
        description={messageModal}
        isOk={true}
        open={openModalError}
        close={handleCloseModalError}
        onCancel={() => { handleCloseModalError(false) }}
        onNext={() => handleCloseModalError(false)}
      />
      <ModalComponent text=""
        open={openModal}
        description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
        close={handleCloseModal}
        onCancel={() => { setOpenModal(false) }}
        onNext={() => {
          setValid(false);
          fetchRequestTestsProjectCar(id);
          fetchDevices();
          setOpenModal(false);
        }} />
      <ModalReadPage open={read} onCancel={() => setRead(false)} />
      <Grid container sx={{ display: 'flex', paddingBottom: '16px' }}>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingY: '16px', paddingX: '8px', }}>
          <SelectPaginationComponent
            text={"Nome do modelo"}
            url="cars/active/true"
            required={true}
            isModel={true}
            value={modelVDS?.projectCar?.car?.id ? modelVDS.projectCar.car.id : ""}
            onSelect={(event: any) => {
              fetchCarId(event);
              setModelVDS({ ...modelVDS, projectCar: { car: { id: event != "" ? event : undefined } } });
              if (event != "" && modelVDS?.supervisionId != undefined) {
                setValid(true);
              } else {
                setValid(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingY: '16px', paddingX: '8px', }}>
          <SelectPaginationComponent
            text={"Supervisão"}
            url="supervisions/active/true"
            required={true}
            value={modelVDS?.supervisionId ? modelVDS.supervisionId : ""}
            onSelect={(event: any) => {
              setModelVDS({ ...modelVDS, supervisionId: event != "" ? event : undefined });
              if (event != "" && modelVDS?.projectCar?.car?.id != undefined) {
                setValid(true);
              } else {
                setValid(false);
              }
            }}

          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingY: '16px', paddingX: '8px', backgroundColor: '#EEEEEE' }}>
          <TextFieldComponent
            text="Nº do projeto"
            required={false}
            disabled
            maxLength={100}
            value={idModelCar?.project ? idModelCar.project : ""}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingY: '16px', paddingX: '8px', backgroundColor: '#EEEEEE' }}>
          <TextFieldComponent
            text="Código da família"
            required={false}
            disabled
            maxLength={100}
            value={idModelCar?.family ? idModelCar.family : ""}
            readOnly={true}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} sx={{ paddingTop: '24px', paddingBottom: '16px', paddingX: '8px', }}>
        <LabelComponent text="Informações " fontSize="24px" />
        <LabelComponent
          text="do dispositivo"
          fontSize="24px"
          fontWeight="normal"
        />
      </Grid>
      <Grid container sx={{ display: 'flex', }}>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingBottom: '16px', paddingX: '8px', }}>
          <SelectComponent
            text="Dispositivo"
            required={false}
            value={modelVDS?.resourceDeviceId ? modelVDS.resourceDeviceId : ""}
            values={devices}
            onSelect={(event: any) => {
              setModelVDS({ ...modelVDS, resourceDeviceId: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingBottom: '16px', paddingX: '8px', }}>
          <TextFieldComponent
            text="Pulso"
            required={false}
            maxLength={100}
            value={modelVDS?.pulse ? modelVDS.pulse : ""}
            onChange={(event: any) => {
              setModelVDS({ ...modelVDS, pulse: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5.74} lg={2.82} xl={2.86} sx={{ marginX: '8px', paddingBottom: '16px' }}>
          <TextFieldComponent
            text="Carroceria"
            required={false}
            maxLength={100}
            value={modelVDS?.bodyWork ? modelVDS.bodyWork : ""}
            onChange={(event: any) => {
              setModelVDS({ ...modelVDS, bodyWork: event.target.value });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} sx={{ paddingTop: '24px', paddingBottom: '16px' }}>
        <LabelComponent text="Descrição " fontSize="24px" />
        <LabelComponent
          text="do dispositivo"
          fontSize="24px"
          fontWeight="normal"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '24px', paddingX: '8px' }}>
        <TextareaAutosizeComponent
          placeholder="Descrição do dispositivo"
          value={modelVDS?.deviceDescription ? modelVDS.deviceDescription : ""}
          onChangeArea={(event: any) => {
            setModelVDS({ ...modelVDS, deviceDescription: event.target.value });
          }}
          readOnly={false}
        />
      </Grid>

      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", display: 'flex', justifyContent: 'center' }}>
          <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
        </Grid>
        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", display: 'flex', justifyContent: 'center' }} >
          <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}

export default RequestTestPageTab2Model;
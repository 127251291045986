import { Box, Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { GridColDef, GridColumns } from "@mui/x-data-grid";
import SearchComponent from "../../components/search/searchComponent";
import { useState, useMemo } from "react";
import ModalComponent from "../../components/modal/modalComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { useNavigate } from "react-router-dom";
import ButtonLinkComponent from "../../components/button/buttonLinkComponent";
import useRequestTestList from "../../hooks/requestTest/useRequestTestList";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function RequestTestList() {
  const [id, setId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(5);
  const [openDisable, setOpenDisable] = useState(false);
  const [errorDisable, setErrorDisable] = useState<string>("");
  const [openDisableError, setOpenDisableError] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const {
    fetchRequestTestList,
    loading,
    totalElements,
    requestTestList,
    fetchDeactive,
    codeStatus,
    access
  } = useRequestTestList();

  const navigate = useNavigate();

  const columns = useMemo<GridColumns<any>>(
    () => [
      { field: "testNumber", headerName: "Número do teste", flex: 0.2, minWidth: 100, },
      { field: "testName", headerName: "Nome do teste", flex: 0.2, minWidth: 100, },
      {
        field: "testPhase", headerName: "Fase do teste", flex: 0.2, minWidth: 100,
        valueGetter: (params) => { return params.row.testPhase; }
      },
      { field: "testDate", headerName: "Data do teste", flex: 0.2, minWidth: 100, },
      {
        field: "statusTest", headerName: "Status", flex: 0.2, minWidth: 100,
        valueGetter: (params) => { return params.row.statusTest; }
      },
      { field: "user", headerName: "Responsável", flex: 0.2, minWidth: 100, },
      {
        field: 'actions', headerName: "Ação", flex: 0.1, minWidth: 180,
        renderCell: (params) => <Box flex={1}>
          <ButtonTextComponent text="Excluir" onClick={() => {
            setOpenDisable(true);
            setId(params.row.id);
          }} />
          <ButtonTextComponent text="Editar" onClick={() => navigate("/requisicao-teste/editar/" + params.row.id)} />
        </Box>
      },
    ],
    [],
  );

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      fetchRequestTestList("", "", "", page, pageSize);

    } else {
      setFilter(event.target.value);
      fetchRequestTestList(event.target.value, filter1, filter2, page, pageSize);
    }
  }

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  return (
    access ?
      <Grid container>
        <HeaderComponent text="Requisição de Teste" access={access}/>
        <ModalComponent description="Deseja realmente excluir esta Requisição de teste?"
          open={openDisable}
          close={handleCloseModalDisable}
          onCancel={() => { setOpenDisable(false) }}
          onNext={() => {
            fetchDeactive(id, false, filter, filter1, filter2, page, pageSize);
            if (codeStatus === 200) {
              setId(null);
              setOpenDisable(false);
            } else {
              setOpenDisable(false);
              setErrorDisable("Não é possível desativar esta supervisão, pois existem laboratórios associados a ela.");
              setOpenDisableError(true);
            }
          }} />
        <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container sx={{ paddingBottom: "20px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Requisição de Teste" fontWeight="normal" fontSize="28px" />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
              <SearchComponent text="" value={filter} placeholder="Buscar por …"
                hideButton={true}
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  fetchRequestTestList("", filter1, filter2, page, pageSize);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={requestTestList}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                fetchRequestTestList(filter, filter1, filter2, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                fetchRequestTestList(filter, filter1, filter2, page, pageSize);
              }}
              onClick={filterList}
            />
          </Grid>
        </Grid>
      </Grid >
      : <NotAuthorizedPage />
  );
}

export default RequestTestList;

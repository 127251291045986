import { InputAdornment, TextField } from "@mui/material";

function TextFieldComponent(props: ParamsField) {

    return <TextField label={props.text} variant="standard"
        sx={{
            "& .MuiFormHelperText-root.Mui-error": {
                padding: props.padding ? props.padding : "4px 8px 4px 8px",
                borderRadius: props.borderRadius ? props.borderRadius : "4px",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "#e4002c",
                color: props.color ? props.color : "#fff",
                fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                fontSize: props.fontSize ? props.fontSize : "12px",
                fontWeight: props.fontWeight ? props.fontWeight : "normal",
                fontStretch: props.fontStretch ? props.fontStretch : "normal",
                fontStyle: props.fontStyle ? props.fontStyle : "normal",
                lineHeight: props.lineHeight ? props.lineHeight : "1.33",
                letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
            },
            input:
            {
                color: props.color ? props.color : "#001e50",
                fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                fontSize: props.fontSize ? props.fontSize : "16px",
                fontWeight: props.fontWeight ? props.fontWeight : "normal",
                fontStretch: props.fontStretch ? props.fontStretch : "normal",
                fontStyle: props.fontStyle ? props.fontStyle : "normal",
                lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                textTransform: props.textTransform ? props.textTransform : "normal",
            },
            width: props.width ? props.width : "100%",
            marginLeft: props.marginLeft ? props.marginLeft : "0px",
            marginTop: props.marginTop ? props.marginTop : "0px",
            marginRight: props.marginRight ? props.marginRight : "56px",
            marginBottom: props.marginBottom ? props.marginBottom : "0px",
            fontFamily: props.fontFamily ? props.fontFamily : "VWText",
            fontSize: props.fontSize ? props.fontSize : "16px",
            fontWeight: props.fontWeight ? props.fontWeight : "normal",
            fontStretch: props.fontStretch ? props.fontStretch : "normal",
            fontStyle: props.fontStyle ? props.fontStyle : "normal",
            lineHeight: props.lineHeight ? props.lineHeight : "1.5",
            letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
        }}
        type={props.type ? props.type : "text"}
        error={props.error ? props.error : false}
        helperText={props.helperText}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur ? props.onBlur : undefined}
        inputProps={{
            maxLength: props.maxLength ? props.maxLength : 100,
            readOnly: props.readOnly ? true : false
        }}
        InputProps={{
            endAdornment: <InputAdornment position="end">{props.endAdornment}</InputAdornment>
        }}
        required={props.required ? props.required : false}
        disabled={props.disabled ? props.disabled : false}
    />;
}

export default TextFieldComponent;
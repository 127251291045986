import React, { useState } from 'react';
import { Grid, Button, Box, Collapse, IconButton, Tooltip } from "@mui/material";
import SelectComponent from '../../components/select/selectComponent';
import CardTestPlanColor from "../../components/card/cardTestPlanColorComponent";
import EyeIcon from "../../assets/images/eye.svg";
import EyeCloseIcon from "../../assets/images/eye-close.svg";
import IconLess from "../../assets/images/icon_less_circle.png";
import IconMore from "../../assets/images/icon_more_circle.png";
import FilterIcon from "../../assets/images/icon-filter.png"
import CardCar from "../../components/card/cardCarComponent";
import InputCars from "../../components/uploadCars/uploadCarsComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import ButtonsCalendar from '../../components/button/buttonToggleCalendarComponent';
import { useParams } from "react-router-dom";
import LoadingComponente from "../../components/loading/loadingComponente";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { format, getWeeksYears } from '../../utils/utils';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ModalComponent from '../../components/modal/modalComponent';
import NotAuthorizedPage from '../notAuthorized/notAuthorized';
import useProgListSinergy from '../../hooks/programing/useProgListSinergy';
import RequestTestModalComponent from '../requestTest/requestTestModal';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ScheduleProgSinergyTab() {
  const { type, id } = useParams();
  const {
    fetchData,
    loading,
    fetchSinergy, fetchPopulaTests,
    sinergyCars, sinergy,
    monthId, setMonthId,
    months,
    weekId, setWeekId,
    weeks, setWeeks,
    yearId, setYearId,
    years, days, access,
    activities, testPlans,
    fetchUpdateDateTest, fetchPopulaSinergy,
    fetchPriority, fetchUpdateDateAndVehicleTest,
    message,
    error, setError
  } = useProgListSinergy({ type, id });
  const [openModal, setOpenModal] = useState(false);
  const [openEye, setOpenEye] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [copy, setCopy] = useState<any>();
  const [projectCarId, setProjectCarId] = useState<number>();
  const [testPlanId, setTestPlanId] = useState<number>();
  const [dayOfMonth, setDayOfMonth] = useState<number>();
  const [month, setMonth] = useState<string>("");
  const [dragOver, setDragOver] = useState(false);
  const [openModalRequestTest, setOpenModalRequestTest] = useState(false);
  const [requestTests, setRequestTestes] = useState<RequestTest>();
  const [car, setCar] = useState<any>();
  const [origin, setOrigin] = useState<any>({
    projectCarId: null,
    day: null,
    requestTestId: null,
    testPlanId: null,
    isRequestTestActivity: false
  });
  const [destination, setDestination] = useState<any>({
    day: null,
    projectCarId: null,
    testPlanId: null
  });

  // function scroll(scrollOffset: number) {
  //   const element = document.getElementById("myDiv");
  //   element!.scrollLeft += scrollOffset;
  // }

  const boxCalendar = {
    width: "257px",
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "#EEF1F3"
  }

  const boxTestQuantities = {
    display: "flex",
    padding: "16px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "257px",
    fontFamily: "VWHead",
    fontSize: "16px",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: "#6a767d"
  }

  const boxPriority = {
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "257px",
    height: "310px",
    overflowY: "auto",
    alignItems: "center",
  }

  const boxTest = {
    backgroundColor: dragOver ? '#EFF1F3' : 'white',
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "257px",
    height: "310px",
    overflowY: "auto",
    alignItems: "center",
  }

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const toggleVisibilityCard = () => {
    setOpenEye(!openEye)
  }

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  function handleOnDragEndPriority(result: any) {
    var draggableId = result.draggableId.split(',');
    fetchPriority({
      "priority": String(result.destination.droppableId).substring(1, 2),
      "requestTestId": Number(draggableId[0])
    },
      {
        "laboratoryId": id,
        "kw": weekId,
        "year": yearId
      });
  }

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setDragOver(false);
    let month = '0';
    if (sinergyCars.sinergiaTests[0].cardsVds[0].testsOfWeek[0].dayOfMonth > destination.day) {
      month = monthId + 1;
    } else {
      month = monthId;
    }
    if (origin.projectCarId === destination.projectCarId && origin.day !== destination.day) {
      if (origin.isRequestTestActivity === false) {
        fetchUpdateDateTest(
          1,
          {
            "newDate": format(new Date(Number(yearId), Number(month) - 1, destination.day)),
            "requestTestId": origin.requestTestId
          },
          {
            "laboratoryId": id,
            "kw": weekId,
            "year": yearId,
          });
      } else {
        fetchUpdateDateTest(
          2,
          {
            "newDate": format(new Date(Number(yearId), Number(month) - 1, destination.day)),
            "requestTestActivityId": origin.requestTestId
          },
          {
            "laboratoryId": id,
            "kw": weekId,
            "year": yearId,
          });
      }
    } else {
      if (origin.isRequestTestActivity === false) {
        fetchUpdateDateAndVehicleTest(
          {
            "requestTestId": origin.requestTestId,
            "projectCarId": destination.projectCarId,
            "newDate": format(new Date(Number(yearId), Number(month) - 1, destination.day)),
            "testPlanId": destination.testPlanId
          },
          {
            "laboratoryId": id,
            "kw": weekId,
            "year": yearId,
          });
      }
    }
  }

  function cardTestPlanColor(testPlanId: number, cardColor: any, cardCar: CardsVds, carWeek: TestsOfWeek) {
    return <div
      id={carWeek.dayOfMonth.toString()}
      draggable="true"
      onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
        setOrigin({
          ...origin,
          projectCarId: cardCar.projectCarId,
          day: carWeek.dayOfMonth,
          requestTestId: cardColor.requestTestId,
          testPlanId: testPlanId,
          isRequestTestActivity: cardColor.requestTestId !== null ? false : true
        });
      }}>
      <CardTestPlanColor
        id={cardColor.requestTestId !== null ? cardColor.requestTestId : cardColor.requestTestActivityId}
        testPlanId={cardColor.testPlanId}
        isCopy={cardColor.requestTestId !== null ? true : false}
        isScheduleSinergy={true}
        text={cardColor.activityName !== null ? cardColor.activityName : cardColor.typeTestName}
        isColor={true}
        color={cardColor.color}
        projectCarId={cardCar.projectCarId}
        title1={cardColor.activityName !== null ? ' ' : 'PSE'}
        title2={cardColor.activityName !== null ? '' : 'Responsável'}
        title3={cardColor.activityName !== null ? '' : 'Dummies'}
        title4={cardColor.activityName !== null ? '' : 'Motivo'}
        text1={cardColor.activityName !== null ? '' : cardColor.requestTestNumber}
        text2={cardColor.activityName !== null ? '' : cardColor.responsible}
        text3={cardColor.activityName !== null ? '' : cardColor.resources}
        text4={cardColor.activityName !== null ? '' : cardColor.reason}
        isDetails={cardColor.requestTestId !== null ? true : false}
        onNavigate={() => window.open("/stapp/requisicao-teste/editar/" + cardColor.requestTestId)}
      />
    </div>
  }

  function buttonAddComponent(cardColor: any, item: SinergyTest, cardCar: CardsVds, carWeek: TestsOfWeek, dayInit: TestsOfWeek) {
    let month = '0';
    if (dayInit.dayOfMonth > carWeek.dayOfMonth) {
      month = String(Number(monthId) + 1);
    } else {
      month = monthId;
    }
    return <InputCars
      border={"2px dashed #dfe4e8"}
      height={cardColor.isEmptyCard ? "270px" : "70px"}
      imgIcon={"blue"}
      onClick={(event: any) => {
        var copy = JSON.parse(localStorage.getItem('copyScheduleSinergy') || '{}');
        if (copy.id === undefined) {
          setOpenModalRequestTest(true);
          setRequestTestes({
            ...requestTests,
            testPhase: cardCar.fase,
            testDate: format(new Date(Number(yearId), Number(month) - 1, carWeek.dayOfMonth)),
            testPlanId: item.testPlanId,
          });
          setCar({
            "vds": cardCar.vds,
            "position": cardCar.positionVDS
          });
          // window.open('/stapp/requisicao-teste/novo',
          //   JSON.stringify({
          //     testDate: new Date(Number(yearId), Number(month) - 1, carWeek.dayOfMonth)
          //   }));
        } else {
          setCopy(copy)
          setOpenModal(true);
          setProjectCarId(cardCar.projectCarId);
          setDayOfMonth(carWeek.dayOfMonth);
          setMonth(month);
          setTestPlanId(item.testPlanId)
        }
      }}
    />
  }

  function changeWeek(nextWeek) {
    if (nextWeek) {
      if (Number(weekId) == getWeeksYears(Number(yearId))) {
        fetchData(Number(yearId) + 1, 1);
        months.map((month: Months) => {
          month.weeks.map((week: Weeks) => {
            if (week.id === 1) {
              setWeeks(month.weeks);
              setMonthId(String(month.id));
            }
          });
        });
        setWeekId("1");
        setYearId(String(Number(yearId) + 1));
        fetchPopulaTests({
          "laboratoryId": id,
          "kw": 1,
          "year": Number(yearId) + 1,
        });
      } else {
        months.map((month: Months) => {
          month.weeks.map((week: Weeks) => {
            if (week.id === (Number(weekId) + 1)) {
              setWeeks(month.weeks);
              setMonthId(String(month.id));
            }
          });
        });
        setWeekId(String(Number(weekId) + 1));
        setYearId(yearId);
        fetchPopulaTests({
          "laboratoryId": id,
          "kw": Number(weekId) + 1,
          "year": Number(yearId),
        });
      }
    } else {
      if (weekId == '1') {
        fetchData(Number(yearId) - 1, getWeeksYears(Number(yearId)));
        months.map((month: Months) => {
          month.weeks.map((week: Weeks) => {
            if (week.id === getWeeksYears(Number(yearId))) {
              setWeeks(month.weeks);
              setMonthId(String(month.id));
            }
          });
        });
        setWeekId(String(getWeeksYears(Number(yearId))));
        setYearId(String(Number(yearId) - 1));
        fetchPopulaTests({
          "laboratoryId": id,
          "kw": getWeeksYears(Number(yearId)),
          "year": Number(yearId) - 1,
        });
      } else {
        months.map((month: Months) => {
          month.weeks.map((week: Weeks) => {
            if (week.id === (Number(weekId) - 1)) {
              setWeeks(month.weeks);
              setMonthId(String(month.id));
            }
          });
        });
        setWeekId(String(Number(weekId) - 1));
        setYearId(yearId);
        fetchPopulaTests({
          "laboratoryId": id,
          "kw": Number(weekId) - 1,
          "year": Number(yearId),
        });
      }
    }
  }

  return (
    access ?
      <Grid>
        {loading ? <LoadingComponente /> : null}
        <ModalComponent
          description={"Há teste copiado, deseja colar nessa data e/ou veículo?"}
          open={openModal}
          textButton1={"Sim"}
          textButton2={"Não"}
          close={() => { setOpenModal(false) }}
          onCancel={() => {
            setOpenModal(false);
            if (copy.projectCarId === projectCarId && copy.testPlanId === testPlanId) {
              fetchUpdateDateTest(
                1,
                {
                  "newDate": format(new Date(Number(yearId), Number(month) - 1, dayOfMonth)),
                  "requestTestId": copy.id,
                },
                {
                  "laboratoryId": id,
                  "kw": weekId,
                  "year": yearId,
                });
            } else {
              fetchUpdateDateAndVehicleTest(
                {
                  "requestTestId": copy.id,
                  "projectCarId": projectCarId,
                  "newDate": format(new Date(Number(yearId), Number(month) - 1, dayOfMonth)),
                  "testPlanId": testPlanId
                },
                {
                  "laboratoryId": id,
                  "kw": weekId,
                  "year": yearId,
                });
            }
            localStorage.removeItem("copyScheduleSinergy");
          }}
          onNext={() => {
            setOpenModal(false);
            window.open('/stapp/requisicao-teste/novo',
              JSON.stringify({
                testDate: new Date(Number(yearId), Number(monthId) - 1, dayOfMonth)
              }));
          }}
        />
        <ModalComponent
          description={message}
          isOk={true}
          open={error}
          close={() => setError(false)}
          onCancel={() => setError(false)}
          onNext={() => setError(false)} />
        <RequestTestModalComponent
          open={openModalRequestTest}
          requestTests={requestTests}
          car={car}
          close={() => { setOpenModalRequestTest(false) }}
          onCancel={() => { setOpenModalRequestTest(false) }}
          onNext={() => {
            fetchSinergy({
              "laboratoryId": id,
              "kw": weekId,
              "year": yearId
            });
            setOpenModalRequestTest(false);
          }} />
        <Grid container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Mês"
              required={true}
              value={monthId ? monthId : ""}
              values={months}
              onSelect={(event: any) => {
                setWeekId("");
                setMonthId(event.target.value);
                months.map((value: Months) => {
                  if (value.id === event.target.value) {
                    setWeeks(value.weeks);
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Semana"
              required={true}
              value={weekId ? weekId : ""}
              values={weeks}
              onSelect={(event: any) => {
                setWeekId(event.target.value);
                if (event.target.value !== "" && yearId !== "") {
                  fetchPopulaTests({
                    "laboratoryId": id,
                    "kw": event.target.value,
                    "year": yearId,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Ano"
              required={true}
              value={yearId ? yearId : ""}
              values={years}
              onSelect={(event: any) => {
                setYearId(event.target.value);
                fetchData(Number(event.target.value), Number(monthId));
                if (event.target.value !== "" && weekId !== "") {
                  fetchPopulaTests({
                    "laboratoryId": id,
                    "kw": weekId,
                    "year": event.target.value,
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{
          backgroundColor: "#dfe4e8",
          height: "32px"
        }}>
          <Grid item xs={6} sx={{
            fontFamily: "VWHead",
            fontSize: "24px",
            fontWeight: "bold",
            height: "32px",
            textAlign: "right"
          }} >
            KW{weekId}
          </Grid>
          <Grid item xs={6} sx={{ height: "32px", textAlign: "right" }}>
            <Button onClick={() => changeWeek(false)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
              Semana Anterior
            </Button>
            <Button onClick={() => changeWeek(true)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              Próxima Semana
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px", transform: "rotate(180deg)" }} />
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ flexDirection: "column", overflowX: "auto", overflowY: "clip" }}>
          <Grid container sx={{ flexDirection: "row", flexGrow: "1", flexWrap: 'nowrap', backgroundColor: "rgba(223, 228, 232, 0.5)" }}>
            <Grid item sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minWidth: "280px",
              maxWidth: "280px",
              height: "59px",
            }} />
            {days.length > 0 && days[0].map((item: any, index: number) => {
              return (
                <Grid item>
                  <Box sx={boxCalendar} key={index}>
                    <ButtonsCalendar text={item.dayWeekName} value={item.dayOfMonth} />
                  </Box>
                </Grid>
              )
            })
            }
          </Grid>
          <Grid container sx={{ flexDirection: "row", flexWrap: 'nowrap' }}>
            <Grid item sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              minWidth: "280px",
              maxWidth: "280px",
              height: "59px",
            }}>
              <Grid item sx={{
                fontSize: "16px",
                fontFamily: "VWHead",
                fontWeight: "bold",
                margin: "8px"
              }} >
                <p>Prioridades</p>
              </Grid>
              <Grid item sx={{ width: "70px", display: "flex", justifyContent: "center" }}>
                <Button sx={{ margin: "0px 8px", padding: "0px", minWidth: "20px" }} onClick={() => toggleShowMore()}>
                  <img style={{ padding: 0 }} src={showMore ? IconMore : IconLess} alt="Button Show More" />
                </Button>
                <Button sx={{ margin: "0px 8px", padding: "0px", minWidth: "35px" }} onClick={() => toggleVisibilityCard()}>
                  <img style={{ margin: "0" }} src={openEye ? EyeIcon : EyeCloseIcon} alt="Button Eye" />
                </Button>
              </Grid>

            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day1 !== undefined ? sinergyCars.day1 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day2 !== undefined ? sinergyCars.day2 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day3 !== undefined ? sinergyCars.day3 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day4 !== undefined ? sinergyCars.day4 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day5 !== undefined ? sinergyCars.day5 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day6 !== undefined ? sinergyCars.day6 + " Teste" : "0 Teste"}
            </Grid>
            <Grid item sx={boxTestQuantities}>
              {sinergyCars?.day7 !== undefined ? sinergyCars.day7 + " Teste" : "0 Teste"}
            </Grid>
          </Grid>
          <Collapse in={openEye}>
            <DragDropContext onDragEnd={handleOnDragEndPriority}>
              {sinergy !== undefined ? sinergy.map((value: any) => {
                return <Droppable droppableId={value.label}>
                  {(provided) => (
                    <Grid container className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                      <Collapse in={((value.label === "P1" || value.label === "P2") && showMore === true) || !showMore}>
                        <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed", borderTop: "1px solid #e5e9ed" }}>
                          <Grid item sx={{
                            display: "flex",
                            fontFamily: "VWHead",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "280px",
                            minHeight: "280px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#001e50",
                          }}>
                            {value.label}
                          </Grid>
                          {value.value.map((cardCar: SinergyPriority) => {
                            return (<Grid item sx={boxPriority}>
                              {cardCar.cards.map((card: any, index: number) => {
                                return (
                                  <Draggable
                                    key={cardCar.dayOfMonth}
                                    draggableId={card.requestTestId + "," + 1}
                                    index={1000 + index}>
                                    {(provided) => (
                                      <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <CardTestPlanColor
                                          id={card.requestTestId}
                                          isCopy={false}
                                          isScheduleSinergy={true}
                                          text={card.testName}
                                          isColor={true}
                                          color={card.color}
                                          title1={card.activityName === null ? '' : 'VDS'}
                                          title2={card.activityName === null ? '' : 'Plano de teste'}
                                          title3={card.activityName === null ? '' : 'Responsável'}
                                          title4={card.activityName === null ? '' : 'Motivo'}
                                          text1={card.activityName === null ? '' : card.vds}
                                          text2={card.activityName === null ? '' : card.testPlanName}
                                          text3={card.activityName === null ? '' : card.responsible}
                                          text4={card.activityName === null ? '' : card.reason}
                                          isDetails={card.requestTestId !== null ? true : false}
                                          onNavigate={() => window.open('/stapp/requisicao-teste/editar/' + card.requestTestId)}
                                        />
                                      </Box>
                                    )}
                                  </Draggable>
                                )
                              })}
                            </Grid>
                            )
                          })}
                        </Grid>
                      </Collapse>
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              }) : []}
            </DragDropContext>
          </Collapse>
        </Grid>
        <Grid container sx={{
          height: "32px",
          backgroundColor: "#dfe4e8"
        }}>
          <Grid item xs={6} sx={{
            fontFamily: "VWHead",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#001e50",
            height: "32px",
            textAlign: "right"
          }}>
            Sinergia
          </Grid>
          <Grid item xs={5} sx={{ height: "32px", textAlign: "right" }}>
            <Button onClick={() => changeWeek(false)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
              Semana Anterior
            </Button>
            <Button onClick={() => changeWeek(true)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              Próxima Semana
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px", transform: "rotate(180deg)" }} />
            </Button>
          </Grid>
          <Grid item xs={1} sx={{ height: "32px", textAlign: "right" }}>
            <Button sx={{
              color: '#001e50',
              fontWeight: 'bold',
              fontSize: "16px",
              padding: 0,
              marginTop: "-10px",
              marginRight: "8px",
              fontFamily: 'VWText',
              textTransform: 'none'
            }}
              onClick={() => {
                setOpenFilter(true)
              }}>
              <img style={{ width: '50px', margin: "0px" }} src={FilterIcon} alt="Button Back" />
              Filtrar
            </Button>
          </Grid>
        </Grid>
        <Grid id="myDiv" container sx={{ marginBottom: "32px", overflowX: "auto", overflowY: "clip" }}>
          {
            sinergyCars !== undefined && sinergyCars.sinergiaTests
              ?
              sinergyCars.sinergiaTests.map((item: SinergyTest) => {
                return (
                  <Grid container >
                    {
                      item.cardsVds.map((cardCar: CardsVds) => {
                        return (
                          <Grid>
                            <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed" }}>
                              <Grid item sx={{
                                flexDirection: "column",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: "280px",
                              }}>
                                <Grid container>
                                  <Grid item xs={2} sm={2} md={2} lg={2} sx={{ padding: "0 5px", zIndex: '1200', textAlign: "center" }}>
                                    {cardCar.receivingDate === null ? <Tooltip
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            margin: "9.5px 136.5px 172.5px 57px",
                                            padding: "20px 30px 30px 3px",
                                            borderRadius: "10px",
                                            boxShadow: "0 4px 16px 0 rgba(208, 217, 232, 0.03), 0 10px 20px 0 rgba(176, 197, 209, 0.5), 0 4px 10px 0 rgba(210, 215, 218, 0.1)",
                                            backgroundColor: "#fff"
                                          }
                                        }
                                      }}
                                      title={
                                        <Grid container >
                                          <Grid item sx={{
                                            margin: "0 193px 5px 32px",
                                            fontFamily: "VWText",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.25",
                                            letterSpacing: "normal",
                                            color: "#001e50"
                                          }}>Aviso</Grid>
                                          <Grid item sx={{
                                            margin: "5px 0 0 32px",
                                            fontFamily: "VWText",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "1.43",
                                            letterSpacing: "normal",
                                            color: "#6a767d"
                                          }}>Os testes agendados para este veículo, mesmo aparecendo na programação, não podem acontecer enquanto não for confirmado o recebimento do veículo!</Grid>
                                        </Grid>
                                      }
                                    >
                                      <IconButton sx={{
                                        backgroundColor: "#ffd100",
                                        width: "24px",
                                        height: "24px"
                                      }}>
                                        <PriorityHighIcon sx={{
                                          color: "black",
                                          width: "12px"
                                        }} />
                                      </IconButton>
                                    </Tooltip> : null}
                                  </Grid>
                                  <Grid item xs={10} sm={10} md={10} lg={10}>
                                    <Grid container>
                                      <Grid item sx={{
                                        fontFamily: "VWHead",
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#001e50"
                                      }}>
                                        Plano de teste {item.testPlanName}
                                      </Grid>
                                    </Grid>
                                    <CardCar
                                      id={cardCar.projectCarId}
                                      isCopy={true}
                                      text={cardCar.vds !== null ? 'VDS ' + cardCar.vds : ' ' + cardCar.positionVDS}
                                      isButtonTitle={true}
                                      textPhase={cardCar.fase}
                                      textMotorization={cardCar.motorization}
                                      textPosition={cardCar.positionVDS}
                                      damagesPosition={cardCar.damagesAndPosition}
                                      onNavigate={() => window.open('/stapp/cadastro-veiculos/editar/' + cardCar.projectCarId)}
                                      onClickDamagesPosition={(event: any, value: any) => {
                                        let result;
                                        months.map((month: Months) => {
                                          month.weeks.map((week: Weeks) => {
                                            if (week.id === value.kw) {
                                              result = month;
                                            }
                                          })
                                        });
                                        setMonthId(result.id);
                                        setWeeks(result.weeks);
                                        setWeekId(value.kw);
                                        setYearId(value.year);
                                        fetchPopulaTests({
                                          "laboratoryId": id,
                                          "kw": value.kw,
                                          "year": value.year,
                                        });
                                        // cardCar.testsOfWeek.map((item, indexItem) => {
                                        //   item.cards.map((card) => {
                                        //     if (card.requestTestId === value.requestTestId) {
                                        //       if (indexItem === 1) {
                                        //         scroll(500);
                                        //       }
                                        //       if (indexItem >= 2) {
                                        //         scroll(750);
                                        //       }
                                        //     } else {
                                        //       let result;
                                        //       months.map((month: Months) => {
                                        //         month.weeks.map((week: Weeks) => {
                                        //           if (week.id === value.kw) {
                                        //             result = month;
                                        //           }
                                        //         })
                                        //       });
                                        //       setMonthId(result.id);
                                        //       setWeeks(result.weeks);
                                        //       setWeekId(value.kw);
                                        //       setYearId(value.year);
                                        //       fetchPopulaTests({
                                        //         "laboratoryId": id,
                                        //         "kw": value.kw,
                                        //         "year": value.year,
                                        //       });
                                        //     }
                                        //   });
                                        // });
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {cardCar.testsOfWeek.map((carWeek: TestsOfWeek) => {
                                return <Grid item sx={boxTest}>
                                  {carWeek.cards.map((cardColor: any, indexTemporalCard: number) => {
                                    return <div
                                      onDropCapture={(event) => {
                                        setDragOver(false);
                                        setDestination({
                                          ...destination,
                                          day: carWeek.dayOfMonth,
                                          projectCarId: cardCar.projectCarId,
                                          testPlanId: item.testPlanId
                                        });
                                      }}
                                      onDragOver={enableDropping}
                                      onDrop={handleDrop}
                                      onDragLeave={(event) => {
                                        setDragOver(true);
                                      }}>
                                      <Grid sx={{ height: "0px", textAlign: "right" }}>
                                        {cardColor.alert ? <Tooltip
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                backgroundColor: "#fff",
                                                borderRadius: "10px",
                                                boxShadow: "0 4px 16px 0 rgba(208, 217, 232, 0.03), 0 10px 20px 0 rgba(176, 197, 209, 0.5), 0 4px 10px 0 rgba(210, 215, 218, 0.1)",
                                                margin: "9.5px 136.5px 172.5px 57px",
                                                padding: "20px 30px 30px 3px"
                                              }
                                            }
                                          }}
                                          title={
                                            <Grid container>
                                              <Grid item sx={{
                                                color: "#001e50",
                                                fontFamily: "VWText",
                                                fontSize: "16px",
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                fontWeight: "bold",
                                                letterSpacing: "normal",
                                                lineHeight: "1.25",
                                                margin: "0 193px 5px 32px"
                                              }}>
                                                Aviso
                                              </Grid>
                                              <Grid item sx={{
                                                color: "#6a767d",
                                                fontFamily: "VWText",
                                                fontSize: "14px",
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                fontWeight: "normal",
                                                letterSpacing: "normal",
                                                lineHeight: "1.43",
                                                margin: "5px 0 0 32px"
                                              }}>
                                                Este teste possuí pendências, verificar na área de detalhes.
                                              </Grid>
                                            </Grid>
                                          }
                                        >
                                          <IconButton sx={{ backgroundColor: "#ffd100", width: "24px", height: "24px" }}>
                                            <PriorityHighIcon sx={{ color: "black", width: "12px" }} />
                                          </IconButton>
                                        </Tooltip> : null}
                                      </Grid>
                                      <Grid sx={{ width: '90%', height: 'auto' }}>
                                        {cardColor.isEmptyCard === false ?
                                          carWeek.cards.length !== (indexTemporalCard + 1) ?
                                            cardTestPlanColor(item.testPlanId, cardColor, cardCar, carWeek)
                                            : <div>
                                              {cardTestPlanColor(item.testPlanId, cardColor, cardCar, carWeek)}
                                              {buttonAddComponent(cardColor, item, cardCar, carWeek, cardCar.testsOfWeek[0])}
                                            </div>
                                          : buttonAddComponent(cardColor, item, cardCar, carWeek, cardCar.testsOfWeek[0])}
                                      </Grid>
                                    </div>
                                  })}
                                </Grid>
                              })}

                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                )
              })
              :
              ""
          }

        </Grid>
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Nome da atividade"}
          isPagination1={false}
          value1={filter1}
          filter1={activities}
          isFilter1={false}
          onSelect1={(event: any) => {
            setFilter1(event.target.value);
          }}
          labelFilter2={"Nome do projeto"}
          isPagination2={false}
          value2={filter2}
          filter2={testPlans}
          isFilter2={false}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            fetchSinergy({
              "activityId": filter1,
              "laboratoryId": id,
              "testPlanId": filter2,
              "kw": weekId,
              "year": yearId
            });
            setOpenFilter(false);
          }}
        />
      </Grid>
      : <NotAuthorizedPage />
  )

}

export default ScheduleProgSinergyTab;
import Grid from "@mui/material/Grid";
import LabelComponent from "../../components/label/labelComponent";
import StepPhaseComponent from "../../components/step/stepPhaseComponent";
import ButtonToggle, { TogglePanel } from "../../components/button/buttonToggleComponent";
import ButtonAddComponent from "../../components/button/buttonAddComponent";
import TestPlanTab2Temporal from "./testPlanPageTab2Temporal";
import TestPlanTab2Atemporal from "./testPlanPageTab2Atemporal";
import useTestPlanTab2 from "../../hooks/testPlan/useTestPlanTab2";
import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponente from "../../components/loading/loadingComponente";
import { format } from "../../utils/utils";
import ModalComponent from "../../components/modal/modalComponent";
import { getWeeksYears } from '../../utils/utils';
import ModalVehicleComponent from "../../components/modal/modalVehicleComponent";
import useVehicle from "../../hooks/vehicle/useVehicle";
import RequestTestModalComponent from "../requestTest/requestTestModal";

function TestPlanPageTab2() {
  const { type, id } = useParams();
  const {
    qtTests,
    loading,
    dataStep,
    categories,
    cardItemVds,
    phaseName,
    phaseStatus,
    lastYear,
    firstYear,
    messageAlert, isError, setIsError,
    monthId, setMonthId,
    months,
    weekId, setWeekId,
    weeks, setWeeks,
    yearId, setYearId,
    years,
    atemporalCars, setAtemporalCars,
    fetchPreviousNextButton,
    setLoading,
    fetchPopulaCars,
    fetchData,
    fetchAtemporal,
    fetchUpdateDateTest,
    fetchDataYear,
    fetchUpdateDateAndVehicleTest
  } = useTestPlanTab2({ type, id });
  const [value, setValue] = useState(0);
  const [activePhase, setActivePhase] = useState(0);
  const [categoryId, setCategoryId] = useState<number>();
  const [expanded, setExpanded] = useState<number | false>(false);
  const [addVehicle, setAddVehicle] = useState<boolean>(false);
  const [phaseYear, setPhaseYear] = useState<number>();
  const [phaseKw, setPhaseKw] = useState<number>();
  const [phase, setPhase] = useState<string>();
  const [openModalRequestTest, setOpenModalRequestTest] = useState(false);
  const [requestTests, setRequestTestes] = useState<RequestTest>();

  const handleChangeAccordion = (categoryId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? categoryId : false);
    setCategoryId(categoryId);
    fetchData({
      "categoryId": categoryId,
      "testPlanId": id,
      "weekNumber": phaseKw !== undefined ? phaseKw : dataStep[0].id,
      "year": phaseYear !== undefined ? phaseYear : dataStep[0].year,
    })
  };

  return (
    <Grid container>
      {loading ? <LoadingComponente /> : null}
      <RequestTestModalComponent
        open={openModalRequestTest}
        requestTests={requestTests}
        close={() => { setOpenModalRequestTest(false) }}
        onCancel={() => { setOpenModalRequestTest(false) }}
        onNext={() => {
          setOpenModalRequestTest(false);
        }} />
      <ModalComponent
        description={messageAlert}
        isOk={true}
        open={isError}
        close={() => { setIsError(false) }}
        onCancel={() => { setIsError(false) }}
        onNext={() => setIsError(false)}
      />
      <ModalVehicleComponent
        open={addVehicle}
        close={() => setAddVehicle(false)}
        onCancel={() => setAddVehicle(false)}
        onNext={() => setAddVehicle(false)}
        onSubimit={(value) => setAddVehicle(value)}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
        sx={{
          marginY: '25px',
          paddingY: '20px',
          paddingX: '20px',
          backgroundColor: '#dfe4e8',
          borderRadius: '4px',
          display: { xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex' },
          alignItems: 'stretch'
        }}>
        <Grid item xs={12} sm={12} md={12} lg={3.8} xl={3.8}
          sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' } }}>
          <Grid item xs={12} sm={6} md={12} lg={9} xl={9}
            sx={{
              paddingX: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around'
            }}>
            <LabelComponent text={phaseName} fontSize="20px" />
            <LabelComponent
              text={"Status: " + phaseStatus}
              fontSize="16px"
              fontWeight="normal"
              color="#001e50"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={3} xl={4.5}
            sx={{
              paddingTop: '8px',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'stretch',
            }}>
            <LabelComponent
              text={firstYear}
              fontSize="16px"
              fontWeight="normal"
              color="#6a767d"
            />
          </Grid>
        </Grid>
        {
          dataStep?.length > 0 ?
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <StepPhaseComponent
                phases={dataStep}
                activeStep={activePhase}
                setActiveStep={setActivePhase}
                onClick={(item: any) => {
                  setPhase(item.type)
                  setPhaseYear(item.year);
                  setPhaseKw(item.id)
                  if (categoryId !== undefined) {
                    fetchData({
                      "categoryId": categoryId,
                      "testPlanId": id,
                      "weekNumber": item.id,
                      "year": item.year,
                    });
                  }
                }}
              />
            </Grid>
            : <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LabelComponent
                text={'Lista de Fases não cadastrada'}
                fontSize="16px"
                fontWeight="normal"
                color="#001e50"
                display="flex"
              />
            </Grid>
        }
        <Grid item xs={12} sm={12} md={12} lg={3.8} xl={3.8}
          sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' } }}>
          <Grid item xs={12} sm={6} md={12} lg={3} xl={4.5}
            sx={{
              paddingTop: '8px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
            }}>
            <LabelComponent
              text={lastYear}
              fontSize="16px"
              fontWeight="normal"
              color="#6a767d"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={9} xl={9}
            sx={{
              paddingX: '10px',
              display: 'flex',
              alignItems: 'center',
            }}>
            <ButtonToggle
              value={value}
              textBtn1={'Sinergia temporal'}
              textBtn2={'Sinergia atemporal'}
              bgColor={'white'}
              textColor={'#6a767d'}
              bgColor2={'#021e51'}
              textColor1={'white'}
              width={'10vw'}
              onSelect={(event: any) => {
                if (event === 1) {
                  fetchAtemporal(id);
                }
                setValue(event)
              }}
              disabledBtn1={false}
              disabledBtn2={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "16px" }}>
        <Grid item sx={{ marginRight: "16px" }}>
          <ButtonAddComponent onClick={() => {
            setRequestTestes({
              ...requestTests,
              testPhase: phase === undefined ? dataStep[0].type : phase,
              testDate: format(new Date()),
              testPlanId: Number(id),
            });
            setOpenModalRequestTest(true);
          }
            //  window.open('/stapp/requisicao-teste/novo', JSON.stringify({ state: { testPlanId: id } }))
          } />
        </Grid>
        <Grid item sx={{ marginLeft: "16px" }}>
          <ButtonAddComponent text={"Adicionar novo veículo"} onClick={() => setAddVehicle(true)} />
        </Grid>
      </Grid>
      <TogglePanel value={value} index={1}>
        <TestPlanTab2Atemporal atemporalCars={atemporalCars} qtTests={qtTests} ></TestPlanTab2Atemporal>
      </TogglePanel>
      <TogglePanel value={value} index={0} onSelect={() => { }}>
        <TestPlanTab2Temporal
          testPlanId={id}
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          changeWeek={(nextWeek, categoryId) => {
            setLoading(true);
            if (nextWeek) {
              if (Number(weekId) == getWeeksYears(Number(yearId))) {
                fetchPreviousNextButton(Number(yearId) + 1, 1, categoryId, id);
              } else {
                fetchPreviousNextButton(Number(yearId), Number(weekId) + 1, categoryId, id);
              }
            } else {
              if (weekId == '1') {
                fetchPreviousNextButton(Number(yearId) - 1, getWeeksYears(Number(yearId)), categoryId, id);
              } else {
                fetchPreviousNextButton(Number(yearId), Number(weekId) - 1, categoryId, id);
              }
            }
          }}
          categories={categories}
          cardItemVds={cardItemVds}
          kw={dataStep && dataStep.length > 0 ? dataStep[0].id : null}
          monthId={monthId}
          months={months}
          weekId={weekId}
          weeks={weeks}
          yearId={yearId}
          years={years}
          isTemporal={true}
          fetchDamage={(value: any) => {
            let result;
            months.map((month: Months) => {
              month.weeks.map((week: Weeks) => {
                if (week.id === value.kw) {
                  result = month;
                }
              })
            });
            setMonthId(result.id);
            setWeeks(result.weeks);
            setWeekId(value.kw);
            setYearId(value.year);
            fetchPopulaCars({
              "categoryId": categoryId,
              "testPlanId": id,
              "weekNumber": value.kw,
              "year": value.year,
            });
          }}
          onSelectMonth={(event: any) => {
            setWeekId("");
            setMonthId(event.target.value);
            months.map((value: Months) => {
              if (value.id === event.target.value) {
                setWeeks(value.weeks);
              }
            });
          }}
          onSelectWeek={(event: any) => {
            setWeekId(event.target.value);
            if (event.target.value !== "" && yearId !== "") {
              fetchPopulaCars({
                "categoryId": categoryId,
                "testPlanId": id,
                "weekNumber": event.target.value,
                "year": yearId,
              });
            }
          }}
          onSelectYear={(event: any) => {
            setYearId(event.target.value);
            fetchDataYear(Number(event.target.value), Number(monthId));
            if (event.target.value !== "" && weekId !== "") {
              fetchPopulaCars({
                "categoryId": categoryId,
                "testPlanId": id,
                "weekNumber": weekId,
                "year": event.target.value,
              });
            }
          }}
          handleOnDragEnd={(requestTestId: any, date: Date) => {
            fetchUpdateDateTest(
              {
                "newDate": format(date),
                "requestTestId": requestTestId
              },
              {
                "categoryId": categoryId,
                "testPlanId": id,
                "weekNumber": weekId,
                "year": yearId,
              });
          }}
          handleUpdateDateAndVehicleTest={(projectCarId: number, requestTestId: number, date: Date) => {
            fetchUpdateDateAndVehicleTest(
              {
                "requestTestId": requestTestId,
                "projectCarId": projectCarId,
                "newDate": format(date),
              },
              {
                "categoryId": categoryId,
                "testPlanId": id,
                "weekNumber": weekId,
                "year": yearId,
              });
          }}
          handleAddModal={() => {
            fetchPopulaCars({
              "categoryId": categoryId,
              "testPlanId": id,
              "weekNumber": weekId,
              "year": yearId
            });
          }}
        />
      </TogglePanel>
    </Grid>
  );
}

export default TestPlanPageTab2;

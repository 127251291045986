import useLogin from "../../hooks/login/useLogin";

function LoginPage() {
    if (process.env.REACT_APP_ENVIRONMENT !== "TESTE") {
        const code = new URL(window.location.href).searchParams.get("code");
        useLogin(code);
    }
    return (<></>);
}

export default LoginPage;
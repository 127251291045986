import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";

const useSelectPagination = (url?: string, fieldInitials?: boolean, sharedSupervisionsInitials?: boolean, isModel?: boolean, isString?: boolean, isFilter?: boolean, status?: string) => {

    const [selectPaginationItens, setSelectPaginationItens] = useState<any>([]);
    const [selectPaginationItensPage, setSelectPaginationItensPage] = useState<number>(0);
    const [selectPaginationItensTotalPages, setSelectPaginationItensTotalPages] = useState<number>(0);

    const getSelectPaginationItens = (url: string, page: number, selectedItemId?: number) => {
        let resultFilter: any;
        let body: any;
        if (url === 'testPlans/select/all') {
            body = { "page": page, "size": 10, "status": status }
        } else {
            body = { "page": page, "size": 10 }
        }
        if (selectPaginationItensTotalPages > page || selectPaginationItensTotalPages === 0) {
            post(url, body).then((values) => {
                if (selectedItemId == undefined || selectedItemId == 0) {
                    if (url === "cars/all") {
                        let _values: any = [];
                        values.content.forEach((element: any) => _values.push({ id: element.id, name: element.model }));
                        setSelectPaginationItens(_values);
                    } else {
                        setSelectPaginationItens(values.content);
                    }
                    setSelectPaginationItensPage(page);
                    setSelectPaginationItensTotalPages(values.totalPages);
                } else {
                    if (fieldInitials) {
                        resultFilter = values.content.find((item: any) => item.initials == selectedItemId);
                    } else if (sharedSupervisionsInitials) {
                        resultFilter = values.content.find((item: any) => item.sharedSupervisionsInitials == selectedItemId);
                    } else if (isString) {
                        resultFilter = values.content.find((item: any) => item == selectedItemId);
                    } else if (isFilter) {
                        resultFilter = values.content.find((item: any) => item.name == selectedItemId);
                    } else {
                        resultFilter = values.content.find((item: any) => item.id == selectedItemId);
                    }

                    if (resultFilter === undefined) {
                        setSelectPaginationItensPage(page + 1);
                        getSelectPaginationItens(url, page + 1, selectedItemId);
                    } else {
                        if (url === "cars/all") {
                            let _values: any = [];
                            values.content.forEach((element: any) => {
                                _values.push({ id: element.id, name: element.model });
                            });
                            setSelectPaginationItens(_values);
                        } else {
                            setSelectPaginationItens(values.content);
                        }
                        setSelectPaginationItensPage(page);
                        setSelectPaginationItensTotalPages(values.totalPages);
                    }
                }
            });
        }
    };

    useEffect(() => {
        getSelectPaginationItens(url ? url : '', 0);
    }, [])

    return {
        getSelectPaginationItens,
        selectPaginationItens,
        setSelectPaginationItensPage,
        selectPaginationItensPage,
        selectPaginationItensTotalPages
    };
}

export default useSelectPagination;
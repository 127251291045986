import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header/headerComponent";
import ModalComponent from "../../components/modal/modalComponent";

function NotAuthorizedPage() {
    const navigate = useNavigate();
    return (
        <Grid container>
            <HeaderComponent text="Acesso não autorizado" access={true}/>
            <ModalComponent
                description={"O perfil não pode realizar a operação."}
                isOk={true}
                open={true}
                close={() => { navigate("/home") }}
                onCancel={() => { navigate("/home") }}
                onNext={() => { navigate("/home") }}
            />
        </Grid >);
}

export default NotAuthorizedPage;
import { ClickAwayListener, FormControl, InputLabel, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useSelectPagination from "../../hooks/selectPagination/useSelectPagination";

function SelectPaginationComponent(props: SelectParamsField) {

    const {
        getSelectPaginationItens,
        selectPaginationItens,
        setSelectPaginationItensPage,
        selectPaginationItensPage,
        selectPaginationItensTotalPages
    } = useSelectPagination(props.url, props.isFieldInitials, props.isSharedSupervisionsInitials, props.isModel, props.isString, props.isFilter, props.status);

    const onSelect = (e: any) => {
        if (e.target.dataset.value != undefined) {
            props.onSelect(e.target.dataset.value);
        } else {
            if (e.target.innerText === '') {
                getSelectPaginationItens(props.url ? props.url : '', 0, props.value);
            }
        }
    }

    const onPageChange = (event: any, newPageSize: any) => {
        getSelectPaginationItens(props.url ? props.url : '', newPageSize - 1);
        setSelectPaginationItensPage(newPageSize - 1);
    }

    useEffect(() => {
        if (props.value) {
            getSelectPaginationItens(props.url ? props.url : '', 0, props.value);
        } else {
            getSelectPaginationItens(props.url ? props.url : '', 0);
        }
    }, [props.value])

    return (
        <FormControl variant="standard" sx={{ width: "100%" }}
            disabled={props.disabled ? props.disabled : false}>
            <InputLabel
                sx={{
                    flex: props.flex ? props.flex : 1,
                    fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                    fontSize: props.fontSize ? props.fontSize : "16px",
                    fontWeight: props.fontWeight ? props.fontWeight : "normal",
                    fontStretch: props.fontStretch ? props.fontStretch : "normal",
                    fontStyle: props.fontStyle ? props.fontStyle : "normal",
                    lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                    letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                }}
                required={props.required}
                id={props.text}
            >
                {props.text}
            </InputLabel>
            <Select
                required={props.required ? props.required : false}
                value={selectPaginationItens.length > 0 ? props.value : ''}
                onClick={(e) => onSelect(e)}
                sx={{
                    color: "#001e50",
                    fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                    fontSize: props.fontSize ? props.fontSize : "16px",
                    fontWeight: props.fontWeight ? props.fontWeight : "normal",
                    fontStretch: props.fontStretch ? props.fontStretch : "normal",
                    fontStyle: props.fontStyle ? props.fontStyle : "normal",
                    lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                    letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                }}
            >
                <MenuItem value={""}>
                    <h1 style={{ margin: "0px", height: "22px" }}></h1>
                </MenuItem>
                {
                    props.isModel ?
                        selectPaginationItens.map((aux: any, key: number) => {
                            return <MenuItem key={key} value={aux?.id}>{aux?.model}</MenuItem>
                        })
                        : props.isFieldInitials ?
                            selectPaginationItens.map((aux: any, key: number) => {
                                return <MenuItem key={key} value={aux?.initials}>{aux?.initials}</MenuItem>
                            }) :
                            props.isSharedSupervisionsInitials ?
                                selectPaginationItens.map((aux: any, key: number) => {
                                    return <MenuItem key={key} value={aux?.sharedSupervisionsInitials}>{aux?.sharedSupervisionsInitials}</MenuItem>
                                }) :
                                props.isString ?
                                    selectPaginationItens.map((aux: any, key: number) => {
                                        return <MenuItem key={key} value={aux}>{aux}</MenuItem>
                                    }) :
                                    selectPaginationItens ? selectPaginationItens.map((aux: any, key: number) => {
                                        return <MenuItem key={key} value={props.isFilter ? aux?.name : aux?.id}>{aux?.name}</MenuItem>
                                    }) :
                                        []
                }

                <Pagination
                    count={selectPaginationItensTotalPages}
                    size="small"
                    page={selectPaginationItensPage + 1}
                    onChange={(event, newPageSize) => onPageChange(event, newPageSize)}
                    showFirstButton
                    showLastButton
                />
            </Select>
        </FormControl>
    );
}

export default SelectPaginationComponent;
import { Grid } from "@mui/material";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1SummaryOfCosts(props: any) {
    const { testPlanTotals, access } = useTestPlanTab1(props.currentModal, props.type, props.id);

    return (
        access ?
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "#001e50", borderRadius: "5px 5px 5px 5px", color: "#ffffff", marginBottom: "16px", padding: "10px 16px" }}>
                    Resumo dos custos
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: "0 8px 0 16px" }}>
                            <LabelComponent fontSize={"20px"} text="Soma dos custos" />
                        </Grid>

                        <Grid item xs={12} sm={6} md={9} lg={9} style={{ padding: "0 8px 0 16px" }}>
                            <Grid container sx={{
                                backgroundColor: '#dfe4e8',
                                borderRadius: "5px",
                                color: "#001e50",
                                fontFamily: "VWHead",
                                fontSize: "14px",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                letterSpacing: "normal",
                                marginTop: "16px",
                                padding: "20px",
                                textAlign: "center",
                            }}>
                                <Grid item xs={12} sm={6} md={2.1} lg={2.1}>
                                    <br />
                                    Custo total
                                    <br />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3.3} lg={3.3}>
                                    Administrativo<br /><br />
                                    {testPlanTotals && testPlanTotals?.totalAdministrative.length > 0 ? Number(Number(testPlanTotals.totalAdministrative) + testPlanTotals.minuteLabor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$0,00"}
                                </Grid>
                                <Grid item xs={12} sm={6} md={3.3} lg={3.3}>
                                    Viagens<br /><br />
                                    {testPlanTotals && testPlanTotals?.totalAdministrative.length > 0 ? Number(testPlanTotals.totalTravels).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$0,00"}
                                </Grid>
                                <Grid item xs={12} sm={6} md={3.3} lg={3.3}>
                                    Testes Externos<br /><br />
                                    {testPlanTotals && testPlanTotals?.totalAdministrative.length > 0 ? Number(testPlanTotals.totalExternalTests).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$0,00"}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3} style={{ padding: "0 16px 0 8px" }}>
                            <Grid container sx={{
                                backgroundColor: '#dfe4e8',
                                borderRadius: "5px",
                                color: "#001e50",
                                fontFamily: "VWHead",
                                fontSize: "14px",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                letterSpacing: "normal",
                                marginTop: "16px",
                                padding: "20px",
                                textAlign: "center",
                            }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    Soma de Todos os custos: <br /><br />
                                    {testPlanTotals && testPlanTotals?.totalAdministrative.length > 0 ? Number(testPlanTotals.totalAll).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$0,00"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1SummaryOfCosts;
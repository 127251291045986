import { SearchOutlined } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Input, InputAdornment, InputLabel, Popper, Tooltip } from "@mui/material";
import iconFilter from "../../assets/images/icon-filter.png";
import iconClose from "../../assets/images/icon-close.png";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useState } from "react";

function SearchComponent(props: ParamsField) {

    return <Grid container>
        <Grid item xs={12}>
            <Box sx={{
                flexDirection: "row",
                width: "100%",
                display: "flex"
            }}>
                {!props.hideButton &&
                    <IconButton
                        sx={{
                            padding: "0px",
                            display: props.display ? props.display : "flex",
                        }}
                        onClick={props.onClick}>
                        <img style={{
                            width: "44px",
                            height: "44px",
                            margin: "0 10px"
                        }} src={iconFilter} />
                    </IconButton>
                }
                <Input
                    error={props.error}
                    disabled={props.disabled ? props.disabled : false}
                    value={props.value}
                    sx={{
                        width: "100%",
                        alignItems: "flex-end",
                        marginBottom: "4px",
                        // marginTop: "16px"
                    }}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    startAdornment={
                        !props.value ?
                            <InputAdornment position="start"
                                sx={{
                                    ".MuiSvgIcon-root": {
                                        margin: "0px 0px 32px",
                                    }
                                }}>
                                <SearchOutlined />
                            </InputAdornment> : null
                    }
                    endAdornment={
                        props.value ?
                            <InputAdornment position="end">
                                <Button
                                    onClick={props.onCancel}
                                    sx={{
                                        margin: "0px 0px 32px 0px",
                                        fontFamily: "VWHead",
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "1.25",
                                        letterSpacing: "normal",
                                        textAlign: "right",
                                        color: "#00b0f0",
                                        textTransform: "none",
                                    }} variant="text">Cancelar
                                    <img style={{
                                        width: "16px",
                                        height: "16px",
                                        marginLeft: "8px"
                                    }} src={iconClose} />
                                </Button>
                            </InputAdornment> : null
                    }
                />
            </Box>
            {props.isClean === true ? <Button sx={{
                color: "#00b0f0",
                fontFamily: "VWHead",
                fontSize: "16px",
                textTransform: "none",
                padding: "0px",
                margin: "0px",
                marginLeft: "65px",
                marginTop: "-8px",
                marginBottom: "8px"
            }} variant="text"
                onClick={props.onClickClean}>
                {"Limpar Filtros"}
            </Button>
                : null}
        </Grid>
        <Grid item xs={12}
            hidden={props.error ? !props.error : true}
            sx={{
                padding: "4px 8px 4px 8px",
                borderRadius: "4px",
                backgroundColor: "#e4002c",
                color: "#fff",
                fontFamily: "VWText",
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.33",
                letterSpacing: "normal",
                width: "95%"
            }}>
            {props.helperText ? props.helperText : ""}
        </Grid>
    </Grid >;
}

export default SearchComponent;
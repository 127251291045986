type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post } from "../../api/api";
import { format, sumLastLine } from "../../utils/utils";

const useRequestTestTab3 = (state: Type) => {
    const [loading, setLoading] = useState(true);
    const [categoryActivities, setCategoryActivities] = useState<CategoryActivities[]>([]);
    const [activities, setActivities] = useState<CategoryActivities[]>([]);
    const [activity, setActivity] = useState<any>();
    const [categoryActivitiesId, setCategoryActivitiesId] = useState<string>("");
    const [activitiesId, setActivitiesId] = useState<string>("");
    const [listActivities, setListActivities] = useState<any[]>([]);
    const [calculatedActivities, setCalculatedActivities] = useState<any[]>([]);
    const [totalValue, setTotalValue] = useState<any>('00:00');
    const [openAlert, setOpenAlert] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [read, setRead] = useState<boolean>(false);

    useEffect(() => {
        fetchEdit(state.id);
    }, []);

    const fetchCategoryActivities = () => {
        setLoading(true);
        get("categoryActivities").then((value) => {
            setCategoryActivities(value);
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchEdit = (id: any) => {
        get("requestTests/activities/" + id).then((value) => {
            let valueActivities: any[] = [];
            value.activities.map((act: any) => {
                let activity = {
                    "active": act.active,
                    "activitiesSupervisions": act.activitiesSupervisions,
                    "activityId": act.activityId,
                    "date": act.date,
                    "name": act.name,
                    "quantity": act.quantity === 0 ? 1 : act.quantity,
                    "requestTestActivityId": act.requestTestActivityId,
                    "showCalendar": act.showCalendar,
                    "order": valueActivities.length
                }
                valueActivities.push(activity);
            });
            setListActivities(valueActivities);
            let activitiesSupervisions: any[] = [];
            value.activities.map((valueAct: any) => {
                valueAct.activitiesSupervisions.map((actSub: any) => {
                    if (activitiesSupervisions.length > 0) {
                        const result = activitiesSupervisions.find((act) => act.supervisionId === actSub.supervisionId);
                        if (result === undefined) {
                            activitiesSupervisions.push({
                                hoursMonth: actSub.hoursMonth * valueAct.quantity === 0 ? 0 : (actSub.hoursMonth * valueAct.quantity),
                                hoursPart: actSub.hoursPart * valueAct.quantity === 0 ? 0 : (actSub.hoursPart * valueAct.quantity),
                                hoursThird: actSub.hoursThird * valueAct.quantity === 0 ? 0 : (actSub.hoursThird * valueAct.quantity),
                                supervisionId: actSub.supervisionId,
                                supervisionInitials: actSub.supervisionInitials
                            });
                        } else {
                            result.hoursMonthOld = result.hoursMonth;
                            result.hoursMonth = result.hoursMonth + (actSub.hoursMonth * valueAct.quantity);
                            result.hoursPartOld = result.hoursPart;
                            result.hoursPart = result.hoursPart + (actSub.hoursPart * valueAct.quantity);
                            result.hoursThirdOld = result.hoursThird;
                            result.hoursThird = result.hoursThird + (actSub.hoursThird * valueAct.quantity);
                        }
                    } else {
                        activitiesSupervisions.push({
                            hoursMonth: actSub.hoursMonth * valueAct.quantity === 0 ? 0 : (actSub.hoursMonth * valueAct.quantity),
                            hoursPart: actSub.hoursPart * valueAct.quantity === 0 ? 0 : (actSub.hoursPart * valueAct.quantity),
                            hoursThird: actSub.hoursThird * valueAct.quantity === 0 ? 0 : (actSub.hoursThird * valueAct.quantity),
                            supervisionId: actSub.supervisionId,
                            supervisionInitials: actSub.supervisionInitials
                        });
                    }
                });
            });
            calculateSum(activitiesSupervisions, "start");
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchActivity = (id: any) => {
        get("activities/" + id).then((value) => {

            let isNew: boolean = true;

            listActivities.filter((itemActivity) => {
                if (itemActivity.activityId === value.id) {
                    isNew = false;
                    itemActivity.quantity++;
                }
            });

            if (isNew) {
                let activity = {
                    "id": value.id,
                    "activityId": value.id,
                    "name": value.name,
                    "order": listActivities.length,
                    "quantity": 1,
                    "requestTestActivityId": state.id,
                    "activitiesSupervisions": value.valueActivities,
                    "showCalendar": value.showCalendar
                };
                setListActivities([...listActivities, activity]);
            }

            calculateSum(value.valueActivities, "add");
        });
    }

    const calculateSum = (oldArray: any, type: string) => {
        let newArray: any = [];
        let lastLineCalculated: any = [];
        newArray = calculatedActivities;
        if (type === 'sub') {
            newArray.forEach((newArrayItem: any) => {
                oldArray.forEach((oldArrayItem: any) => {
                    if (newArrayItem.id === oldArrayItem.supervisionId) {
                        newArrayItem.hour = newArrayItem.hour - (oldArrayItem?.hoursPartOld !== undefined ? oldArrayItem.hoursPartOld : oldArrayItem.hoursPart);
                        newArrayItem.month = newArrayItem.month - (oldArrayItem?.hoursMonthOld !== undefined ? oldArrayItem.hoursMonthOld : oldArrayItem.hoursMonth);
                        newArrayItem.third = newArrayItem.third - (oldArrayItem?.hoursThirdOld !== undefined ? oldArrayItem.hoursThirdOld : oldArrayItem.hoursThird);
                        newArrayItem.totalRow = newArrayItem.totalRow -
                            (oldArrayItem?.hoursPartOld !== undefined ? oldArrayItem.hoursPartOld : oldArrayItem.hoursPart) -
                            (oldArrayItem?.hoursMonthOld !== undefined ? oldArrayItem.hoursMonthOld : oldArrayItem.hoursMonth) -
                            (oldArrayItem?.hoursThirdOld !== undefined ? oldArrayItem.hoursThirdOld : oldArrayItem.hoursThird);
                    }
                });
            });

            let listRemoved: any[] = [];
            newArray.forEach((arrayItem: any) => {
                if (arrayItem.hour > 0 || arrayItem.month > 0 || arrayItem.third > 0) {
                    listRemoved.push(arrayItem);
                }
            });
            newArray = listRemoved;

            newArray.length > 0 && (lastLineCalculated = sumLastLine(newArray));
            setTotalValue([]);
            setTotalValue(lastLineCalculated);
            setCalculatedActivities([]);
            setCalculatedActivities(newArray);

            if (listActivities.length === 0) {
                newArray = [];
                setCalculatedActivities([]);
                lastLineCalculated = [];
                setTotalValue({ id: 0, name: "Total", hour: 0, month: 0, third: 0, totalRow: 0 });
            }
        } else if (type === 'start') {
            let found: boolean;
            let calculateObj: any = [];
            oldArray.forEach((oldArrayActivities: any) => {
                let found = false;
                calculateObj.forEach((calculateObjItem: any) => {
                    if (oldArrayActivities.supervisionId === calculateObjItem.id) {
                        found = true;
                        calculateObjItem.id = oldArrayActivities.supervisionId;
                        calculateObjItem.name = oldArrayActivities.supervisionInitials;
                        calculateObjItem.hour = oldArrayActivities.hoursPart;
                        calculateObjItem.month = oldArrayActivities.hoursMonth;
                        calculateObjItem.third = oldArrayActivities.hoursThird;
                        calculateObjItem.totalRow = calculateObjItem.totalRow + oldArrayActivities.hoursPart + oldArrayActivities.hoursMonth + oldArrayActivities.hoursThird;
                    }
                });

                if (!found) {
                    calculateObj.push({
                        "id": oldArrayActivities.supervisionId,
                        "name": oldArrayActivities.supervisionInitials,
                        "hour": oldArrayActivities.hoursPart,
                        "month": oldArrayActivities.hoursMonth,
                        "third": oldArrayActivities.hoursThird,
                        "totalRow": oldArrayActivities.hoursPart + oldArrayActivities.hoursMonth + oldArrayActivities.hoursThird
                    });
                }
            });
            newArray = calculateObj;
            if (newArray.length > 0) {
                lastLineCalculated = sumLastLine(newArray);
            }
            setTotalValue([]);
            setTotalValue(lastLineCalculated);
            setCalculatedActivities([]);
            setCalculatedActivities(newArray);
        } else {
            if (newArray.length > 0) {
                let calculateObj: any = [];
                oldArray.map((oldArrayItem: any) => {
                    const result = newArray.find((act: any) => act.id === oldArrayItem.supervisionId);
                    if (result !== undefined) {
                        result.hour = result.hour + oldArrayItem.hoursPart;
                        result.month = result.month + oldArrayItem.hoursMonth;
                        result.third = result.third + oldArrayItem.hoursThird;
                        result.totalRow = result.totalRow + oldArrayItem.hoursPart + oldArrayItem.hoursMonth + oldArrayItem.hoursThird;
                        result.hoursMonthOld = result.hoursMonth;
                        result.hoursPartOld = result.hoursPart;
                        result.hoursThirdOld = result.hoursThird;
                    }
                    else {
                        calculateObj.push({
                            "id": oldArrayItem.supervisionId,
                            "name": oldArrayItem.supervisionInitials,
                            "hour": oldArrayItem.hoursPart,
                            "month": oldArrayItem.hoursMonth,
                            "third": oldArrayItem.hoursThird,
                            "totalRow": oldArrayItem.hoursPart + oldArrayItem.hoursMonth + oldArrayItem.hoursThird
                        });
                    }
                });
                if (calculateObj.length > 0) {
                    calculateObj.map((obj: any) => {
                        newArray.push(obj);
                    });
                }
            } else {
                let calculateObj: any = [];
                oldArray.forEach((oldArrayActivities: any) => {
                    if (calculateObj.id === oldArrayActivities.supervisionId) {
                        calculateObj.push({
                            "id": calculateObj.supervisionId,
                            "name": calculateObj.supervisionInitials,
                            "hour": calculateObj.hour + oldArrayActivities.hoursPart,
                            "month": calculateObj.month + oldArrayActivities.hoursMonth,
                            "third": calculateObj.third + oldArrayActivities.hoursThird,
                            "totalRow": calculateObj.totalRow + oldArrayActivities.hoursPart + oldArrayActivities.hoursMonth + oldArrayActivities.hoursThird,
                        });
                    } else {
                        calculateObj.push({
                            "id": oldArrayActivities.supervisionId,
                            "name": oldArrayActivities.supervisionInitials,
                            "hour": oldArrayActivities.hoursPart,
                            "month": oldArrayActivities.hoursMonth,
                            "third": oldArrayActivities.hoursThird,
                            "totalRow": oldArrayActivities.hoursPart + oldArrayActivities.hoursMonth + oldArrayActivities.hoursThird
                        });
                    }
                });
                newArray = calculateObj;
            }
            if (newArray.length > 0) {
                lastLineCalculated = sumLastLine(newArray);
            }
            setTotalValue([]);
            setTotalValue(lastLineCalculated);
            setCalculatedActivities([]);
            setCalculatedActivities(newArray);
        }
    }

    const fetchActivitiesActive = (id: number) => {
        get("activities/active/" + id).then((value) => {
            setActivities(value);
        });
    }

    const fetchActivitiesSubmit = (body: any) => {
        let activities: any[] = [];
        body.map((act: any) => {
            activities.push({
                "activityId": act.activityId,
                "date": act.date,
                "order": act.order,
                "quantity": act.quantity,
            });
        });
        let obj = {
            "activities": activities,
            "requestTestId": Number(state.id)
        }
        post("requestTests/activities", obj).then((value) => {
            if (value.codeStatus === 201) {
                setOpenAlert(true);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        });
    }

    return {
        categoryActivities, setCategoryActivities,
        activities, setActivities,
        activity, calculateSum,
        openAlert, setOpenAlert,
        categoryActivitiesId, setCategoryActivitiesId,
        activitiesId, setActivitiesId,
        listActivities, setListActivities,
        loading, setLoading, read, setRead,
        messageAlert, isError, setIsError, fetchEdit,
        fetchCategoryActivities, fetchActivitiesActive, fetchActivitiesSubmit,
        fetchActivity, calculatedActivities, totalValue
    };
}

export default useRequestTestTab3;
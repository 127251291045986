import { Button } from "@mui/material";

function ButtonTextComponent(props: ParamsField) {

    return (<Button sx={{
        color: props.color ? props.color : "#00b0f0",
        fontFamily: props.fontFamily ? props.fontFamily : "VWHead",
        fontSize: props.fontSize ? props.fontSize : "16px",
        fontWeight: props.fontWeight ? props.fontWeight : "normal",
        fontStretch: props.fontStretch ? props.fontStretch : "normal",
        fontStyle: props.fontStyle ? props.fontStyle : "normal",
        lineHeight: props.lineHeight ? props.lineHeight : "1.5",
        letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
        textAlign: props.textAlign ? props.textAlign : "center",
        textTransform: props.textTransform ? props.textTransform : "none",
        float: props.float ? props.float : "none"
    }} variant="text"
        onClick={props.onClick}>
        {props.text}</Button>);
}

export default ButtonTextComponent;
type Type = /*unresolved*/ any
import { Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import SearchComponent from "../../components/search/searchComponent";
import { useState } from 'react';
import CardComponent from "../../components/card/cardComponent";
import CardDNDComponent from "../../components/card/cardDNDComponent";
import SelectComponent from "../../components/select/selectComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { useLocation } from 'react-router-dom';
import useResourceTest from "../../hooks/resourceTest/useResourceTest";
import ModalReadPage from "../../components/modal/modalReadPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function ResourceTestPage() {
  const [teste, setTeste] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [idDelete, setIdDelete] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [filter1, setFilter1] = useState<string>("");
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const location = useLocation();
  const state = location.state as Type;

  const {
    resource,
    resourceTest, setResourceTest,
    laboratories,
    categoryResources,
    totalCostResource, setTotalCostResource,
    laborTypeTestId, setLaborTypeTestId,
    resourceTestDrop, setResourceTestDrop,
    laboratoryId, setLaboratoryId,
    laboratory, setLaboratory,
    testeId, setTesteId,
    testes, setTestes,
    openAlert, setOpenAlert,
    isEdit, setIsEdit,
    isDelete, read, setRead, access,
    valid, setValid,
    openModalCancel, setOpenModalCancel,
    fetchResourcesDrag,
    fetchSubmit, fetchEdit, fetchDelete
  } = useResourceTest(state);

  const handleSubmit = (e: any) => {
    let resources: any[] = [];
    if (resourceTestDrop.length > 0) {
      resourceTestDrop.map((value: any) => {
        resources.push({ "resourceId": value.id, "quantity": value.quantity });
      });
    }
    if (valid) {
      fetchSubmit({
        "laborTypeTestId": laborTypeTestId,
        "resources": resources,
      });
    }
  };

  const handleEdit = (id: any) => {
    fetchEdit(id);
  }

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const filterList = (event: any) => {
    setFilter(event.target.value);
    fetchResourcesDrag(event.target.value, filter1);
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalDelete = (e: any) => {
    setOpenModalCancel(false);
    setIdDelete("");
  };

  return (
    access ?
    <Grid container>
      <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Recursos" access={access}/>
      <SnackbarComponent text={
        isDelete ? "Exclusão salva com sucesso!" :
          isEdit ?
            "Associação editada com sucesso"
            : "Associação salva com sucesso"}
        open={openAlert}
        closeSnackbar={handleClose} />
      <ModalComponent text=""
        open={openModalCancel}
        description={"Este card será excluído com todas as Associações deste Laboratório, os testes não estarão mais disponíveis para serem solicitados ou adicionados em Plano de testes enquanto não houver uma nova associação e Gestão de ALTRA"}
        textButton1={"Cancelar"}
        textButton2={"Prosseguir"}
        close={handleCloseModalDelete}
        onCancel={() => { setOpenModalCancel(false) }}
        onNext={() => {
          fetchDelete(idDelete)
        }} />
      <ModalComponent text=""
        open={openModal}
        description={isEdit ? "Deseja realmente cancelar a edição de uma nova associação?" : "Deseja realmente cancelar a criação de uma nova associação?"}
        close={handleCloseModal}
        onCancel={() => { setOpenModal(false) }}
        onNext={() => {
          setResourceTest([]);
          setResourceTestDrop([]);
          setLaboratory("");
          setLaboratoryId("");
          setValid(false);
          setOpenModal(false);
          setTestes([]);
          setTesteId("");
          setIsEdit(false);
          setTotalCostResource(0);
          fetchResourcesDrag(filter, filter1);
        }} />
      <ModalFilterComponent
        open={openFilter}
        labelFilter1={"Categoria"}
        isPagination1={false}
        value1={filter1}
        filter1={categoryResources}
        onSelect1={(event: any) => {
          setFilter1(event.target.value);
          setIsFilter(true);
        }}
        close={handleCloseModalFilter}
        onCancel={() => {
          setOpenFilter(false)
        }}
        onNext={() => {
          fetchResourcesDrag(filter, filter1);
          setOpenFilter(false);
        }} />
      <ModalReadPage open={read} onCancel={() => setRead(false)} />
      <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LabelComponent text={"Associar "} fontSize="28px" />
            <LabelComponent text="Recursos com Teste" fontSize="28px" fontWeight="normal" />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "32px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <LabelComponent text="Arrastar os recursos para o teste selecionado" />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "16px" }}>
          <Grid item xs={12} sm={12} md={11.9} lg={5.88} xl={5.88} sx={{
            marginTop: "4px",
            marginRight: { md: '0px', lg: '10px', xl: '10px' }
          }}>
            <SearchComponent text="" placeholder="Buscar por …"
              value={filter}
              onChange={filterList}
              onClick={() => {
                setOpenFilter(true)
              }}
              onCancel={() => {
                setFilter("");
                fetchResourcesDrag("", "");
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.88} md={5.84} lg={2.84} xl={2.85} sx={{
            marginLeft: { md: '0px', lg: '10px', xl: '10px' },
            marginRight: { md: '10px', lg: '10px', xl: '10px' },
            marginBottom: { sm: '15px', md: '20px', lg: '0px', xl: '0px' },
          }}>
            <SelectComponent
              text="Laboratório"
              value={laboratoryId}
              values={laboratories}
              required={true}
              onSelect={(event: any, child: any) => {
                if (event.target.value === "") {
                  setValid(false);
                }
                setTotalCostResource(0);
                setLaboratory(child.props.children);
                setLaboratoryId(event.target.value);
                setTesteId("");
                setTeste("");
                setResourceTestDrop([]);
                setTestes([]);
                laboratories.map((lab) => {
                  if (lab.id === event.target.value) {
                    setTestes(lab.typesTests);
                    setTesteId("");
                    setTeste("");
                  }
                });
              }} />
          </Grid>
          <Grid item xs={12} sm={5.88} md={5.84} lg={2.84} xl={2.85} sx={{
            marginLeft: { xs: '10px', sm: '10px', md: '10px', lg: '10px', xl: '10px' },
            marginBottom: { md: '20px', lg: '0px', xl: '0px' },
          }}>
            <SelectComponent
              text="Teste"
              value={testeId}
              values={testes}
              required={true}
              isFilter={true}
              onSelect={(event: any, child: any) => {
                setTeste(child.props.children);
                setTesteId(event.target.value);
                setTotalCostResource(0);
                if (event.target.value === "") {
                  setTeste("");
                }
                if (event.target.value !== "" && laboratoryId !== "" && resourceTestDrop.length > 0) {
                  setValid(true);
                } else {
                  setValid(false);
                  setResourceTestDrop([]);
                }
                testes.map((value) => {
                  if (value.name === event.target.value) {
                    setLaborTypeTestId(value.laborTypeTestId);
                    handleEdit(value.laborTypeTestId);
                  }
                });
              }} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CardDNDComponent
            xsGdLeft={12}
            smGdLeft={12}
            mdGdLeft={12}
            lgGdLeft={5.88}
            xsGdRight={12}
            smGdRight={12}
            mdGdRight={12}
            lgGdRight={5.88}
            type="resource"
            value={totalCostResource}
            values={resourceTest}
            valuesDrop={resourceTestDrop}
            description={teste !== "" && laboratory !== "" ? teste + " no " + laboratory : ""}
            handleOnDragEnd={(old, newObj) => {
              var draggableId = newObj.draggableId.split(',');
              old.map((filter: any) => {
                filter.resources.map((resource: any) => {
                  if (resource.id.toString() === draggableId[0].toString()) {
                    const result = resourceTestDrop.find((value) => value.id.toString() === draggableId[0].toString());
                    if (result === undefined) {
                      resource.isSelect = true;
                      resource.quantity = 1;
                      setResourceTestDrop([...resourceTestDrop, resource]);
                      if (laboratoryId !== "" && testeId !== "") {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    } else {
                      resource.isSelect = true;
                      resource.quantity = result.quantity + 1;
                      if (resource.quantity !== result.quantity) {
                        result.quantity = resource.quantity;
                      }
                      setResourceTestDrop(resourceTestDrop);
                      setResourceTestDrop([...resourceTestDrop]);
                    }
                    setTotalCostResource(totalCostResource + Number(resource.valueByQuantity))
                  }
                });
                setResourceTest(old);
              });
            }}
            handleCancel={(value) => {
              resourceTest.map((resource: any) => {
                resource.resources.map((aux: any) => {
                  if (aux.id === value.id) {
                    const result = resourceTestDrop.find((value) => value.id.toString() === aux.id.toString());
                    if (result?.quantity !== aux.quantity) {
                      aux.quantity = result?.quantity;
                    }
                    if (aux.quantity > 1) {
                      if (resourceTestDrop.length > 0) {
                        resourceTestDrop.map((res) => {
                          if (res.id === aux.id) {
                            res.quantity = res.quantity - 1;
                          }
                        });
                      }
                      setResourceTestDrop(resourceTestDrop);
                      setResourceTestDrop([...resourceTestDrop]);
                    } else {
                      var index = resourceTestDrop.indexOf(value);
                      if (index > -1) {
                        resourceTestDrop.splice(index, 1);
                      }
                      if (laboratoryId !== "" && testeId !== "" && resourceTestDrop.length > 0) {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                      aux.isSelect = false;
                      aux.quantity = 0;
                      setResourceTestDrop(resourceTestDrop);
                      setResourceTestDrop([...resourceTestDrop]);
                    }
                    setTotalCostResource(totalCostResource - Number(aux.valueByQuantity))
                  }
                });
                setResourceTest(resourceTest);
              });
            }}
          />
        </Grid>

        <Grid container sx={{ marginTop: "40px", justifyContent: 'center' }}>
          <Grid item xs={5} sm={4} md={2} lg={1.6} xl={1} sx={{
            display: 'flex',
            justifyContent: 'center',
            marginX: '10px',
          }}>
            <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true) }} />
          </Grid>
          <Grid item xs={5} sm={4} md={2} lg={1.6} xl={1.14} sx={{
            display: 'flex',
            justifyContent: 'center',
            marginX: '10px',
          }}>
            <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "40px" }}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <LabelComponent text="Lista " fontSize="28px" />
            <LabelComponent text="de Associações" fontSize="28px" fontWeight="normal" />
          </Grid>
        </Grid>
        <Grid container >
          <CardComponent
            type="resource"
            values={resource}
            navigate={"/associar-recurso/details/"}
            onClickEdit={(value) => {
              handleEdit(value);
            }}
            onClickDelete={(value) => {
              setIdDelete(value)
              setOpenModalCancel(true);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    : <NotAuthorizedPage/>
  );
}

export default ResourceTestPage;
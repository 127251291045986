import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";

const useTestPlanTab3 = (state: any) => {

    const [loading, setLoading] = useState(true);
    const [dataStep, setDataStep] = useState<any>();
    const [phaseName, setPhaseName] = useState<string>();
    const [phaseStatus, setPhaseStatus] = useState<string>();
    const [lastYear, setLastYear] = useState<number>();
    const [firstYear, setFirstYear] = useState<number>();
    const [category, setCategory] = useState<Category[]>([]);
    const [user, setUser] = useState<User[]>([]);
    const [phase, setPhase] = useState<Phase[]>([]);
    const [componentTest, setComponentTest] = useState<ComponentTest[]>([]);
    const [testSubCategory, setTestSubCategory] = useState<SubCategory[]>([]);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [suggestDate, setSuggestDate] = useState<string>("");
    const [messageSave, setMessageSave] = useState<string>("");
    const [open, setOpen] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");

    let phaseList: any = [];

    useEffect(() => {
        if (state.id !== undefined) {
            fetchTestCategory()
            fetchPhasesList(state.id);
        }
    }, []);

    const fetchTestCategory = () => {
        setLoading(true);
        get<Category>("testPlans/category/tab3").then((value) => {
            setCategory(value)
        }).catch(() => {
            setCategory([])
        }).finally(() => setLoading(false));
    }

    const fetchPhase = () => {
        setLoading(true);
        get<Phase>("testPlans/phases").then((phase) => {
            setPhase(phase);
        }).catch(() => {
            setPhase([]);
        }).finally(() => setLoading(false));
    }

    const fetchPhasesList = (id: number) => {
        get<PhaseList>("testPlans/phase/tab3/" + id)
            .then((value: PhaseList) => {
                setPhaseName(value.name);
                setPhaseStatus(value.status);
                setLastYear(value.lastYear);
                setFirstYear(value.firstYear);
                value.listTPPhaseScheduleDto.map((item: ListPhaseSchedule) => {
                    phaseList.push({
                        type: item.type,
                        kw: item.kwName,
                        id: item.kw,
                        year: item.year,
                    })
                })
                setDataStep(phaseList);
            }).finally(() => setLoading(false));
    }

    const fetchComponentTest = (body: any) => {
        setLoading(true)
        post("testPlans/tab3/componentTest", body)
            .then((value) => {
                setComponentTest(value.content)
            }).catch((err) => {
                setComponentTest([])
            }).finally(() => setLoading(false));
    }

    const fetchStartModal = () => {
        fetchTestCategory();
        fetchPhase();
    }

    const fetchSubmitModal = (body: any) => {
        setLoading(true);
        post("testPlans/tab3/component", body)
            .then((value) => {
                if (value.codeStatus === 201) {
                    setOpenModal(false);
                    setOpenAlert(true);
                    setOpen(false);
                } else {
                    setMessageSave(value.errorMessage);
                }
            }).catch((err) => {
            }).finally(() => setLoading(false));
    }

    const fetchTestSubCategory = (id: any) => {
        if (id !== "") {
            get("laboratories/typeTests/subcategory/" + id).then((value) => {
                setTestSubCategory(value)
            });
        } else {
            setTestSubCategory([])
        }
    }

    const fetchSuggestDate = (testPlanId: any, phase: any) => {
        setLoading(true);
        get('testPlans/tab3/phase/date/' + testPlanId + '/' + phase).then((value) => {
            setSuggestDate(value.suggestDate);
        }).finally(() => setLoading(false));
    }

    const fetchDeactive = (id: number, body: any) => {
        setLoading(true);
        put('requestTests/disable/' + id)
            .then((value) => {
                if (value.codeStatus !== 200) {
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
                fetchComponentTest(body);
            }).finally(() => setLoading(false));
    }

    return {
        loading, setLoading,
        dataStep,
        phaseName,
        lastYear,
        firstYear,
        phaseStatus,
        category,
        phase, messageAlert, isError, setIsError,
        openAlert, setOpenAlert,
        openModal, setOpenModal,
        suggestDate, setSuggestDate,
        user, testSubCategory, setTestSubCategory,
        fetchComponentTest, fetchTestSubCategory,
        fetchSubmitModal, fetchStartModal,
        fetchSuggestDate, fetchDeactive,
        messageSave, setMessageSave,
        componentTest, open, setOpen
    }
}

export default useTestPlanTab3;
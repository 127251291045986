import { useState } from 'react';
import { Button, Card, Collapse, Grid, ButtonBase, IconButton } from '@mui/material';
import ContentCutIcon from '@mui/icons-material/ContentCut';

function cardTestPlanColorComponent(props: ParamsCardsGrid) {

  const [expand, setExpand] = useState<boolean>(false);
  const color = props.color ? props.color : 9;

  function colorDefine() {
    switch (color) {
      case 1: return "#9d4952";
      case 2: return "#da4553";
      case 3: return "#fb6e52";
      case 4: return "#f7bb43";
      case 5: return "#8cc051";
      case 6: return "#36bb9b";
      case 7: return "#3cafda";
      case 8: return "#4B89DC";
      case 9: return "#967bdc";
      case 10: return "#d870ad";
      case 11: return "#aab2bd";
      case 12: return "#434a54";
    }
  }

  const title = {
    margin: "8px",
    width: "145px",
    height: "17px",
    fontFamily: "VWHead",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: props.isColor === true ? props.color?.toString() : colorDefine()
  }

  const subTitleStyles = {
    fontFamily: "VWHead",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    margin: "0 16px 0 16px",
    color: "#000",
  }

  const descStyles = {
    fontFamily: "VWHead",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    margin: "0 16px 0 16px",
    color: "#6a767d",
    maxWidth: "20ch",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }

  return (
    <Card
      sx={{
        width: props.width ? props.width : "208px",
        margin: props.margin ? props.margin : "8px",
        borderRadius: "8px 0px 8px 0px",
        boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.03), 0 10px 20px 0 rgba(0, 0, 0, 0.05)",
        flexDirection: "row"
      }}>
      <Grid container sx={{ flexDirection: "row" }}>
        <Grid item sx={{
          width: "8px",
          height: "auto",
          backgroundColor: props.isColor === true ? props.color?.toString() : colorDefine
        }} />
        <Grid item>
          <Grid container sx={{ flexDirection: "column" }}>
            <Button onClick={() => setExpand(!expand)} sx={{ padding: 0 }}>
              <Grid container sx={{
                backgroundColor: props.isColor === true ? props.color?.toString() + "1A" : colorDefine() + "1A",
                width: "180px",
                height: "37px",
                margin: "8px",
                alignItems: "center",
                whiteSpace: "nowrap"
              }}>
                <Grid item sx={title}>
                  {props.text}
                </Grid>
              </Grid>
            </Button>
            <Collapse in={expand}>
              <Grid item sx={{ marginBottom: "8px" }}>
                {props.isDetails === true ?
                  <Button sx={{
                    width: "100%",
                    height: "17px",
                    textTransform: "none",
                    fontFamily: "VWHead",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    justifyContent: "end",
                    marginRight: "8px",
                    color: "#00b0f0"
                  }} variant="text"
                    onClick={props.onNavigate}>
                    {"Ver detalhes >"}
                  </Button>
                  : null}
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title1 ? props.title1 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text1 ? props.text1 : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: "8px" }}>
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title2 ? props.title2 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text2 ? props.text2 : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: "8px" }}>
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title3 ? props.title3 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text3 ? props.text3 : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: "8px" }}>
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title4 ? props.title4 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text4 ? props.text4 : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: "8px" }}>
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title5 ? props.title5 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text5 ? props.text5 : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ marginBottom: "8px" }}>
                <Grid container sx={{ flexDirection: "column", }}>
                  <Grid item sx={subTitleStyles}>
                    {props.title6 ? props.title6 : ''}
                  </Grid>
                  <Grid item sx={descStyles}>
                    {props.text6 ? props.text6 : ''}
                  </Grid>
                </Grid>
              </Grid>
              {props.isCopy === true ?
                <Grid item sx={{ textAlign: 'right' }}>
                  <IconButton onClick={(event) => {
                    if (props.isTestPlan === true) {
                      localStorage.setItem("copyTestPlan", JSON.stringify({ "id": props.id, "projectCarId": props.projectCarId }));
                    } else if (props.isScheduleSinergy === true) {
                      localStorage.setItem("copyScheduleSinergy", JSON.stringify({ "id": props.id, "projectCarId": props.projectCarId, "testPlanId": props.testPlanId }));
                    } else if (props.isScheduleCommon === true) {
                      localStorage.setItem("copyScheduleCommon", JSON.stringify({ "id": props.id, "supervisionInitials": props.supervisionInitials }));
                    }
                  }}>
                    <ContentCutIcon />
                  </IconButton>
                </Grid> : null}
            </Collapse>
          </Grid>
        </Grid>
        {props.hideButton || props.hideButton === undefined ? null :
          <Grid container sx={{
            height: "48px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#00b0f0",
            fontFamily: "VWHead",
            fontSize: "14px",
            fontWeight: "normal",
            color: "#fff",
            borderEndEndRadius: "8px",

          }}>
            <ButtonBase sx={{ width: "100%", height: "100%", }}
              onClick={props.onClick}>
              {props.textButton ? props.textButton : "Confirmar Programação"}
            </ButtonBase>
          </Grid>

        }
      </Grid>
    </Card>
  );
}

export default cardTestPlanColorComponent;

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButtonComponentYesOrNot(props: ParamsField) {
    return (
        <FormControl>
            <FormLabel focused={false} sx={{
                color: props.color ? props.color : "black",
                fontSize: props.fontSize ? props.fontSize : "20px",
                fontWeight: props.fontWeight ? props.fontWeight : "bold"
            }} id="demo-radio-buttons-group-label">{props.text}</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={props.value}
            >
                <FormControlLabel value={true} control={<Radio onChange={props.onChange} />} label="Sim" />
                <FormControlLabel value={false} control={<Radio onChange={props.onChange} />} label="Não" />
            </RadioGroup>
        </FormControl>
    );
}

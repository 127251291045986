import React, { useState } from 'react';
import { Grid, Button, Box, Collapse } from "@mui/material";
import SelectComponent from '../../components/select/selectComponent';
import CardTestPlanColor from "../../components/card/cardTestPlanColorComponent";
import FilterIcon from "../../assets/images/icon-filter.png";
import EyeIcon from "../../assets/images/eye.svg";
import EyeCloseIcon from "../../assets/images/eye-close.svg";
import ButtonsCalendar from "../../components/button/buttonToggleCalendarComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import useProgListCommon from "../../hooks/programing/useProgListCommon";
import { useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import LoadingComponente from '../../components/loading/loadingComponente';
import { format, getWeeksYears, leftPad } from '../../utils/utils';
import NotAuthorizedPage from '../notAuthorized/notAuthorized';
import InputCars from '../../components/uploadCars/uploadCarsComponent';
import TestPlanPageTab3Modal from '../testPlan/testPlanPageTab3Modal';
import useTestPlanTab3 from '../../hooks/testPlan/useTestPlanTab3';
import ModalComponent from '../../components/modal/modalComponent';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ScheduleProgCommunPageTab1() {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openEye, setOpenEye] = useState<boolean>(true);
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [supervisionId, setSupervisionId] = useState<string>("");
  const [testDate, setTestDate] = useState<string>("");
  const { type, id } = useParams();
  const [copy, setCopy] = useState<any>();
  const [openModalCopy, setOpenModalCopy] = useState(false);
  const {
    loading, fetchData,
    fetchCommon, fetchPopulaCommon,
    fetchConfirm, fetchPriority,
    fetchUpdateDateTest, fetchDisconfirm,
    monthId, setMonthId,
    months,
    weekId, setWeekId,
    weeks, setWeeks,
    yearId, setYearId,
    years, access,
    cardItemCommon, common,
    commonCars, activities, testsName, testPlans,
  } = useProgListCommon({ type, id });

  const {
    category,
    testSubCategory,
    phase,
    setOpenAlert,
    openModal, setOpenModal,
    fetchStartModal,
    fetchTestSubCategory,
    fetchSuggestDate,
  } = useTestPlanTab3({ id });

  const gridCalendar = {
    display: 'flex',
    backgroundColor: '#EFF1F3',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: '16px',
  }

  const boxCalendar = {
    width: "257px",
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#EEF1F3',
  }

  const boxTest = {
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
    borderLeft: "1px solid #e5e9ed",
    minWidth: "257px",
    height: "310px",
    overflowY: "auto",
    alignItems: "center",
  }

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const toggleVisibilityCard = () => {
    setOpenEye(!openEye)
  }

  function handleOnDragEndPriority(result: any) {
    var draggableId = result.draggableId.split(',');
    fetchPriority({
      "priority": String(result.destination.droppableId).substring(1, 2),
      "requestTestId": Number(draggableId[0])
    },
      {
        "laboratoryId": id,
        "kw": weekId,
        "year": yearId
      });
  }

  function handleOnDragEndDate(result: any) {
    var draggableId = result.draggableId.split(',');
    let month = '0';
    if (commonCars.demanda[0].testsOfWeek[0].dayOfMonth > result.destination.droppableId) {
      month = monthId + 1;
    } else {
      month = monthId;
    }
    if (draggableId[1] === "requestTestId") {
      fetchUpdateDateTest(
        1,
        {
          "newDate": format(new Date(Number(yearId), Number(month) - 1, result.destination.droppableId)),
          "requestTestId": draggableId[2]
        },
        {
          "laboratoryId": id,
          "kw": weekId,
          "year": yearId,
        });
    } else {
      fetchUpdateDateTest(
        2,
        {
          "newDate": format(new Date(Number(yearId), Number(monthId) - 1, result.destination.index)),
          "requestTestActivityId": draggableId[2]
        },
        {
          "laboratoryId": id,
          "kw": weekId,
          "year": yearId,
        });
    }
  }

  function changeWeek(nextWeek) {
    if (nextWeek) {
      if (Number(weekId) == getWeeksYears(Number(yearId))) {
        fetchCommon({
          "laboratoryId": id,
          "kw": 1,
          "year": Number(yearId) + 1,
        });
      } else {
        fetchCommon({
          "laboratoryId": id,
          "kw": Number(weekId) + 1,
          "year": Number(yearId),
        });
      }
    } else {
      if (weekId == '1') {
        fetchCommon({
          "laboratoryId": id,
          "kw": getWeeksYears(Number(yearId)),
          "year": Number(yearId) - 1,
        });
      } else {
        fetchCommon({
          "laboratoryId": id,
          "kw": Number(weekId) - 1,
          "year": Number(yearId),
        });
      }
    }
  }

  return (
    access ?
      <Grid>
        {loading ? <LoadingComponente /> : null}
        <ModalComponent
          description={"Há teste copiado, deseja colar nessa data e/ou supervisão?"}
          open={openModalCopy}
          textButton1={"Sim"}
          textButton2={"Não"}
          close={() => { setOpenModalCopy(false) }}
          onCancel={() => {
            setOpenModalCopy(false);
            fetchUpdateDateTest(
              1,
              {
                "newDate": testDate,
                "requestTestId": copy.id
              },
              {
                "laboratoryId": id,
                "kw": weekId,
                "year": yearId,
              });
            localStorage.removeItem("copyScheduleCommon");
          }}
          onNext={() => {
            setOpenModalCopy(false);
            setOpenModal(true);
          }}
        />
        <TestPlanPageTab3Modal
          isLabor={true}
          fetchSuggestDate={fetchSuggestDate}
          testDate={testDate}
          openModal={openModal}
          category={category}
          testSubCategory={testSubCategory}
          phase={phase}
          supervisionId={supervisionId}
          isChildren={false}
          fetchTestSubCategory={fetchTestSubCategory}
          fetchSubmitModal={(event: any, requestTests: any) => {
            setOpenModal(false);
            setOpenAlert(true);
            fetchPopulaCommon({
              "laboratoryId": id,
              "kw": weekId,
              "year": yearId,
            });
          }}
          onCancel={(event: any) => setOpenModal(false)}
        />
        <Grid container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Mês"
              required={true}
              value={monthId ? monthId : ""}
              values={months}
              onSelect={(event: any) => {
                setWeekId("");
                setMonthId(event.target.value);
                months.map((value: Months) => {
                  if (value.id === event.target.value) {
                    setWeeks(value.weeks);
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Semana"
              required={true}
              value={weekId ? weekId : ""}
              values={weeks}
              onSelect={(event: any) => {
                setWeekId(event.target.value);
                if (event.target.value !== "" && yearId !== "") {
                  fetchPopulaCommon({
                    "laboratoryId": id,
                    "kw": event.target.value,
                    "year": yearId,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5} sm={2.3} md={2} lg={1.5} xl={1.3} sx={{ marginX: '10px' }}>
            <SelectComponent
              fontSize='24px'
              fontFamily="VWHead"
              text="Ano"
              required={true}
              value={yearId ? yearId : ""}
              values={years}
              onSelect={(event: any) => {
                setYearId(event.target.value);
                fetchData(Number(event.target.value), Number(monthId));
                if (event.target.value !== "" && weekId !== "") {
                  fetchPopulaCommon({
                    "laboratoryId": id,
                    "kw": weekId,
                    "year": event.target.value,
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{
          backgroundColor: "#dfe4e8",
          height: "32px"
        }}>
          <Grid item xs={6} sx={{
            fontFamily: "VWHead",
            fontSize: "24px",
            fontWeight: "bold",
            height: "32px",
            textAlign: "right"
          }} >
            KW{weekId}
          </Grid>
          <Grid item xs={6} sx={{ height: "32px", textAlign: "right" }}>
            <Button onClick={() => changeWeek(false)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
              Semana Anterior
            </Button>
            <Button onClick={() => changeWeek(true)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              Próxima Semana
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px", transform: "rotate(180deg)" }} />
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ flexDirection: "column", overflowX: "auto" }}>
          <Grid container sx={{ flexDirection: "row", flexGrow: "1", flexWrap: 'nowrap' }}>
            <Grid container sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minWidth: "280px",
              maxWidth: "280px",
              height: "55px",
              backgroundColor: '#EEF1F3',
            }}>
              <Grid item sx={{
                fontSize: "16px",
                fontFamily: "VWHead",
                fontWeight: "bold",
              }} >
                Prioridades
              </Grid>
              <Grid item >
                <Button sx={{ margin: 0, padding: 0 }} onClick={() => toggleVisibilityCard()}>
                  <img style={{ width: "30px", margin: "0 15px" }} src={openEye ? EyeIcon : EyeCloseIcon} alt="Button Back" />
                </Button>
              </Grid>
            </Grid>
            {
              commonCars?.p1.map((card) => {
                return (
                  <Grid item >
                    <Box sx={boxCalendar}>
                      <ButtonsCalendar text={card.dayWeekName} value={card.dayOfMonth} />
                    </Box>
                  </Grid>
                )
              })
            }
          </Grid>
          <Collapse in={openEye}>
            <DragDropContext onDragEnd={handleOnDragEndPriority}>
              {common !== undefined ? common.map((value: any) => {
                return <Droppable droppableId={value.label}>
                  {(provided) => (
                    <Grid item className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                      <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed" }}>
                        <Grid item sx={{
                          display: "flex",
                          fontFamily: "VWHead",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "280px",
                          minHeight: "280px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#001e50",
                        }}>
                          {value.label}
                        </Grid>
                        {value.value.map((cardCar: any) => {
                          return <Grid item sx={boxTest} >
                            {cardCar.cards.map((card: any, index: number) => {
                              return <Draggable key={card.requestTestId} draggableId={card.requestTestId + "," + 2} index={1000 + index}>
                                {(provided) => (
                                  <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <CardTestPlanColor
                                      id={card.requestTestId}
                                      isCopy={false}
                                      text={card.testName !== null ? card.testName : card.activityName !== null ? card.activityName : ""}
                                      title1={card.requestTestActivityId !== null ? '' : 'PSE'}
                                      title2={card.requestTestActivityId !== null ? '' : 'Plano de teste'}
                                      title3={card.requestTestActivityId !== null ? '' : 'Dummies'}
                                      title4={card.requestTestActivityId !== null ? '' : 'Responsável'}
                                      title5={card.requestTestActivityId !== null ? '' : 'Motivo'}
                                      text1={card.testNumber ? card.testNumber : ""}
                                      text2={card.testPlanName ? card.testPlanName : ""}
                                      text3={card.dummies ? card.dummies : ""}
                                      text4={card.responsible ? card.responsible : ""}
                                      text5={card.reason ? card.reason : ""}
                                      isColor={true}
                                      color={card.color}
                                      open={openEye}
                                      textButton={"Confirmar Remoção"}
                                      hideButton={card.requestTestId ? false : true}
                                      isDetails={card.requestTestId !== null ? true : false}
                                      onNavigate={() => window.open("/stapp/requisicao-teste/editar/" + card.requestTestId)}
                                      onClick={(event: any) => {
                                        fetchDisconfirm(card.requestTestId, {
                                          "laboratoryId": id,
                                          "kw": weekId,
                                          "year": yearId
                                        })
                                      }}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            })}
                          </Grid>
                        })}
                      </Grid>
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              }) : []}
            </DragDropContext>
          </Collapse>
        </Grid >
        <Grid container sx={{
          height: "32px",
          backgroundColor: "#dfe4e8"
        }}>
          <Grid item xs={6} sx={{
            fontFamily: "VWHead",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#001e50",
            height: "32px",
            textAlign: "right"
          }}>
            Demanda
          </Grid>
          <Grid item xs={5} sx={{ height: "32px", textAlign: "right" }}>
            <Button onClick={() => changeWeek(false)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px" }} />
              Semana Anterior
            </Button>
            <Button onClick={() => changeWeek(true)}
              sx={{
                color: "#001e50",
                fontSize: "14px",
                textTransform: "capitalize",
                padding: "4px"
              }}>
              Próxima Semana
              <ArrowBackIcon sx={{ fontSize: "18px", marginRight: "4px", transform: "rotate(180deg)" }} />
            </Button>
          </Grid>
          <Grid item xs={1} sx={{ height: "32px", textAlign: "right" }}>
            <Button sx={{
              color: '#001e50',
              fontWeight: 'bold',
              fontSize: "16px",
              padding: 0,
              marginTop: "-10px",
              marginRight: "8px",
              fontFamily: 'VWText',
              textTransform: 'none'
            }}
              onClick={() => {
                setOpenFilter(true)
              }}>
              <img style={{ width: '50px', margin: "0px" }} src={FilterIcon} alt="Button Back" />
              Filtrar
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ flexDirection: "column", overflowX: "auto" }}>
          <Grid item >
            {
              commonCars?.demanda.map((dmd: any) => {
                return (
                  <Grid container sx={{ flexWrap: 'nowrap', borderBottom: "1px solid #e5e9ed", overflowX: "auto" }}>
                    <Grid item sx={{
                      display: "flex",
                      fontFamily: "VWHead",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "280px",
                      minHeight: "280px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#001e50",
                    }}>
                      {dmd.supervisionInitials}
                    </Grid>

                    <DragDropContext onDragEnd={handleOnDragEndDate}>
                      {dmd.testsOfWeek.map((dayBox: any, indexDay: number) => {
                        return (<Droppable droppableId={dayBox.dayOfMonth.toString()}>
                          {(provided) => (
                            <Grid item sx={boxTest} className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                              {dayBox?.cards.map((card: any) => {
                                if (card.isEmptyCard === false) {
                                  return <Draggable key={dayBox.dayOfMonth}
                                    draggableId={dayBox.dayOfMonth.toString().concat(",", (card.requestTestId !== null ? "requestTestId" : "requestTestActivityId")).concat(',', card.requestTestId !== null ? card.requestTestId : card.requestTestActivityId)} index={dayBox.dayOfMonth}>
                                    {(provided) => (
                                      <Grid ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <CardTestPlanColor
                                          id={card.requestTestId}
                                          supervisionInitials={dmd.supervisionInitials}
                                          isCopy={true}
                                          isScheduleCommon={true}
                                          text={card.testName !== null ? card.testName : card.activityName !== null ? card.activityName : ""}
                                          title1={card.requestTestActivityId !== null ? '' : 'PSE'}
                                          title2={card.requestTestActivityId !== null ? '' : 'Plano de teste'}
                                          title3={card.requestTestActivityId !== null ? '' : 'Dummies'}
                                          title4={card.requestTestActivityId !== null ? '' : 'Responsável'}
                                          title5={card.requestTestActivityId !== null ? '' : 'Motivo'}
                                          text1={card.testNumber ? card.testNumber : ""}
                                          text2={card.testPlanName ? card.testPlanName : ""}
                                          text3={card.dummies ? card.dummies : ""}
                                          text4={card.responsible ? card.responsible : ""}
                                          text5={card.reason ? card.reason : ""}
                                          isColor={true}
                                          color={card.color}
                                          hideButton={card.requestTestActivityId !== null ? true : card.priority ? true : false}
                                          isDetails={card.requestTestId !== null ? true : false}
                                          onNavigate={() => window.open("/stapp/requisicao-teste/editar/" + card.requestTestId)}
                                          onClick={(event: any) => {
                                            fetchConfirm(card.requestTestId, {
                                              "laboratoryId": id,
                                              "kw": weekId,
                                              "year": yearId
                                            })
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                }
                              })}
                              {provided.placeholder}
                              <Grid>
                                <InputCars
                                  border={"2px dashed #dfe4e8"}
                                  height={dayBox.cards[0].isEmptyCard ? "270px" : "70px"}
                                  imgIcon={"blue"}
                                  onClick={(event: any) => {
                                    let copy = JSON.parse(localStorage.getItem('copyScheduleCommon') || '{}');
                                    let isCopy = false;
                                    if (dmd.supervisionInitials === copy.supervisionInitials) {
                                      isCopy = true;
                                    }
                                    let month = '0';
                                    if (dmd.testsOfWeek[0].dayOfMonth > dayBox.dayOfMonth) {
                                      month = monthId + 1;
                                    } else {
                                      month = monthId;
                                    }
                                    if (copy.id === undefined || isCopy === false) {
                                      fetchStartModal();
                                      setSupervisionId(dmd.supervisionId);
                                      setTestDate(yearId + "-" + leftPad(month, 2) + "-" + leftPad(dayBox.dayOfMonth, 2));
                                      setOpenModal(true);
                                    } else {
                                      setCopy(copy)
                                      setTestDate(yearId + "-" + leftPad(month, 2) + "-" + leftPad(dayBox.dayOfMonth, 2));
                                      setOpenModalCopy(true);
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Droppable>
                        )
                      })}
                    </DragDropContext>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Nome do teste"}
          isPagination1={false}
          value1={filter1}
          filter1={testsName}
          isFilter1={true}
          onSelect1={(event: any) => {
            setFilter1(event.target.value);
          }}
          labelFilter2={"Nome do projeto"}
          isPagination2={false}
          value2={filter2}
          filter2={testPlans}
          isFilter2={false}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            fetchCommon({
              "testName": filter1,
              "laboratoryId": id,
              "testPlanId": filter2,
              "kw": weekId,
              "year": yearId
            });
            setOpenFilter(false);
          }}
        />
      </Grid>
      : <NotAuthorizedPage />
  )
}

export default ScheduleProgCommunPageTab1;
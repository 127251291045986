import React, { useState } from 'react';
import { Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import TabsComponent from '../../components/tabs/tabsComponent';
import TabPanelComponent from '../../components/tabs/tabPanelComponent';
import ModalComponent from '../../components/modal/modalComponent';
import RequestTestPageTab1 from './requestTestTab1';
import RequestTestPageTab2 from './requestTestTab2';
import RequestTestPageTab3 from './requestTestTab3';
import RequestTestPageTab4 from './requestTestTab4';
import RequestTestPageTab5 from './requestTestTab5';
import LabelComponent from '../../components/label/labelComponent';
import { useParams } from 'react-router-dom';

function RequestTestPage() {
    const { type, id } = useParams();
    const [value, setValue] = useState(0);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

    let tabs = [
        "Informações do teste",
        "Informações do veículo",
        "Definir Atividades",
        "Definir Recursos",
        "Lista de Peças"
    ];

    const handleCloseCancelModal = (e: any) => {
        setOpenCancelModal(false);
    };

    return (
        <Grid container>
            <HeaderComponent text="Requisição de Teste" access={true}/>
            <ModalComponent
                open={openCancelModal}
                description={"Deseja realmente cancelar? Ao cancelar todas as alterações serão perdidas."}
                close={handleCloseCancelModal}
                onCancel={() => { setOpenCancelModal(false) }}
                onNext={() => {
                    window.location.reload();
                }}
            />
            <Grid container sx={{ padding: "48px 48px 16px 48px" }}>
                <Grid item sx={{ marginBottom: "32px" }}>
                    <LabelComponent text={type === "novo" ? "Criar " : "Editar "} fontSize="28px" />
                    <LabelComponent text="Requisição de teste" fontSize="28px" fontWeight="normal" />
                </Grid>
                <TabsComponent disabled={id ? false : true} setvalue={setValue} tabs={tabs} value={value}></TabsComponent>
                <TabPanelComponent value={value} index={0}>
                    <RequestTestPageTab1></RequestTestPageTab1>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={1}>
                    <RequestTestPageTab2></RequestTestPageTab2>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={2}>
                    <RequestTestPageTab3></RequestTestPageTab3>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={3}>
                    <RequestTestPageTab4></RequestTestPageTab4>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={4}>
                    <RequestTestPageTab5></RequestTestPageTab5>
                </TabPanelComponent>
            </Grid>
        </Grid>
    )
}

export default RequestTestPage;
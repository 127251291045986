import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonComponent from "../../components/button/buttonComponent";
import CardComponent from "../../components/card/cardComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import CardDNDComponent from "../../components/card/cardDNDComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";
import useLaboratoryTest from "../../hooks/laboratoryTest/useLaboratoryTest";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalReadPage from "../../components/modal/modalReadPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function LaboratoryTestPage() {

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [idDelete, setIdDelete] = useState<string>("");

  const {
    loading,
    laboratoryTest, setLaboratoryTest,
    laboratories, setLaboratories,
    laboratory, setLaboratory,
    typeTests, setTypeTests,
    categories, setCategories,
    openModalCancel, setOpenModalCancel,
    isDelete, setIsDelete,
    openAlert, setOpenAlert,
    typeTestsDrop, setTypeTestsDrop,
    laboratoryId, setLaboratoryId,
    isEdit, setIsEdit,
    read, setRead, access,
    valid, setValid,
    messageSave, setMessageSave,
    filter, setFilter,
    filter1, setFilter1,
    fetchLaboratoryTest, fetchLaboratories,
    fetchTypeTests, fetchCategory,
    fetchHandleDelete, fetchHandleSubmit,
    fetchHandleEdit
  } = useLaboratoryTest();

  const filterList = (event: any) => {
    setFilter(event.target.value);
    fetchTypeTests(event.target.value, filter1);
  }

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModalDelete = (e: any) => {
    setOpenModalCancel(false);
    setIdDelete("");
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  return (
    access ?
      <Grid container>
        <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Laboratórios" access={access}/>
        <SnackbarComponent text={
          isDelete ? "Exclusão salva com sucesso!" :
            isEdit ?
              "Associação editada com sucesso"
              : "Associação salva com sucesso"}
          open={openAlert}
          closeSnackbar={handleClose} />
        <ModalComponent text=""
          open={openModal}
          description={isEdit ? "Deseja realmente cancelar a edição de uma nova associação?" : "Deseja realmente cancelar a criação de uma nova associação?"}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setFilter("")
            setTypeTests([]);
            setTypeTestsDrop([]);
            setLaboratory("");
            setLaboratoryId("");
            setValid(false);
            setOpenModal(false);
            setIsEdit(false);
            fetchTypeTests(filter, filter1);
          }} />
        <ModalComponent text=""
          open={openModalCancel}
          description={"Este card será excluído com todas as Associações deste Laboratório, os testes não estarão mais disponíveis para serem solicitados ou adicionados em Plano de testes enquanto não houver uma nova associação e Gestão de ALTRA"}
          textButton1={"Cancelar"}
          textButton2={"Prosseguir"}
          close={handleCloseModalDelete}
          onCancel={() => { setOpenModalCancel(false) }}
          onNext={() => {
            fetchHandleDelete(idDelete)
          }} />
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Categoria"}
          isPagination1={false}
          value1={filter1}
          filter1={categories}
          onSelect1={(event: any) => {
            setFilter1(event.target.value);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => {
            setOpenFilter(false)
          }}
          onNext={() => {
            fetchTypeTests(filter, filter1);
            setOpenFilter(false);
          }} />
        {loading ? <LoadingComponente /> : null}
        <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LabelComponent text={"Associar "} fontSize="28px" />
              <LabelComponent text="Testes com Laboratório" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LabelComponent text="Arrastar os testes para o laboratório selecionado" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} sm={12} md={12} lg={5.84} xl={5.86} sx={{ marginTop: "4px", marginRight: { md: '0px', lg: '10px' } }}>
              <SearchComponent text="" placeholder="Buscar por …"
                value={filter}
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  fetchTypeTests("", "");
                }} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5.84} xl={5.86} sx={{ marginLeft: { md: '10px', lg: '10px' } }}>
              <SelectComponent
                values={laboratories}
                text="Laboratório"
                value={laboratoryId}
                required={true}
                onSelect={(event: any, child: any) => {
                  setLaboratory(child.props.children);
                  setLaboratoryId(event.target.value);
                  fetchHandleEdit(event.target.value);
                  if (event.target.value != "" && typeTestsDrop.length > 0) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
          </Grid>
          <Grid container>
            <CardDNDComponent
              type="laboratory"
              values={typeTests}
              valuesDrop={typeTestsDrop}
              xsGdLeft={12}
              smGdLeft={12}
              mdGdLeft={12}
              lgGdLeft={5.88}
              xsGdRight={12}
              smGdRight={12}
              mdGdRight={12}
              lgGdRight={5.88}
              description={laboratory}
              handleOnDragEnd={(old, newObj) => {
                var draggableId = newObj.draggableId.split(',');
                old.map((type: any) => {
                  type.typeTests.map((typeTest: any) => {
                    if (typeTest.id.toString() === draggableId[0]) {
                      typeTest.isSelect = true;
                      setTypeTestsDrop([...typeTestsDrop, typeTest]);
                      if (laboratoryId != "") {
                        setValid(true);
                      } else {
                        setValid(false);
                      }
                    }
                  });
                  setTypeTests(old);
                });
              }}
              handleCancel={(value) => {
                var index = typeTestsDrop.indexOf(value);
                if (index > -1) {
                  typeTestsDrop.splice(index, 1);
                }
                if (typeTestsDrop.length > 0 && laboratoryId !== "") {
                  setValid(true);
                } else {
                  setValid(false);
                }
                typeTests.map((type: any) => {
                  type.typeTests.map((typeTest: any) => {
                    if (typeTest.id === value.id) {
                      typeTest.isSelect = false;
                      setTypeTestsDrop(typeTestsDrop);
                      setTypeTestsDrop([...typeTestsDrop]);
                    }
                  });
                  setTypeTests(typeTests);
                });
              }}
            />
            <ModalReadPage open={read} onCancel={() => setRead(false)} />
          </Grid>
          <Grid container sx={{ marginTop: "40px", justifyContent: 'center' }}>
            <Grid item xs={5} sm={4} md={2} lg={1.6} xl={1.2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={1.6} xl={1.2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonComponent text="Salvar" active={valid}
                onClick={fetchHandleSubmit}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Associações" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container>
            <CardComponent
              type="laboratory"
              values={laboratoryTest}
              navigate={"/associar-laboratorio/details/"}
              onClickEdit={(value) => {
                setValid(true);
                fetchHandleEdit(value);
              }}
              onClickDelete={(value) => {
                setIdDelete(value)
                setOpenModalCancel(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default LaboratoryTestPage;
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import ButtonComponent from "../../components/button/buttonComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import ModalComponent from "../../components/modal/modalComponent";
import SelectComponent from "../../components/select/selectComponent";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import { Link, useNavigate } from "react-router-dom";
import InputFile from "../../components/field/inputFile";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import DownloadFile from "../../components/field/downloadFile";
import { formatDate } from '../../utils/utils';
import useSelectPagination from "../../hooks/selectPagination/useSelectPagination";
import ModalReadPage from "../../components/modal/modalReadPage";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function VehiclePage() {
  const [vehicleChoosedData, setVehicleChoosedData] = useState({
    vds: '', //numeroVds
    positionVDS: '',//identificaçãoProvisoria
    carId: '', //nomeModelo
    color: '', //corVeiculo
    modelYear: '', //anoModelo
    engineNumber: '', //numeroMotor
    motorization: '', //motorizacao
    engineCode: '', //codigoMotor
    engineDescription: '', //versaoVeiculo
    chassis: '', //numeroChassi
    transmissionDescription: '', //caracteristicasTransmissao
    transmissionIdentification: '', //identificacaoTransmissao
    doorNumber: '', //quantidadePortas
    fuelType: '', //tipoCombustivel
    wheelsTires: '', //PneusRoda
    wheelsTiresDescription: '', //caracteristicaRoda
    steeringSide: '', //ladoDirecao
    steeringColumn: '', //colunaDirecao
    fase: '', //faseVeiculo
    provisionReceiving: '', //previsaoRecebimento
    steeringColumnAdjust: '', //ajustesColunaDirecao
    receivingDate: '', //dataRecebimento
    ped: '', //ped
    pet: '', //pet
    massPedPet: '', //Somente Leitura = PED+PET
    receivingStatus: '', //statusRecebimentoVeiculo
    refuseStatus: '', //refuseStatus,
    prListPDFMedia: '', //PDF para subir
    weighingPdfMedia: '' //PDF para subir
  });
  const [receivingDate, setReceivingDate] = useState<Date | null>(null);
  const [provisionReceiving, setProvisionReceiving] = useState<Date | null>(null);
  const [allSelectsList, setAllSelectsList] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageSave, setMessageSave] = useState<string>("");
  const [choosedId, setChoosedId] = useState<string>("vds");
  const [choosedCarModel, setChoosedCarModel] = useState<any>([]);
  const [read, setRead] = useState<boolean>(false);
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();

  const navigate = useNavigate();
  let downloadUploadIcon: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAVCAYAAACt4nWrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADFSURBVHgB7ZUxDoJAEEU/aKMWHkGPoLWN3gxOokfwBpoYe0uo1BtgFMPG4OpsssYsLjsUW8lrfsHM+4EAC8lkeRYS60yu3sklhEdaeSv/W3n2kGiCbb4iP92fmG5viFMBDnEi1DztOeWaKCmcBSSO0sJ6vSIf90NsZgOVdQVa/D1v0v21qBcW+1wVEKNe0EhMBPRrhAV6jlRAORl2cLiUn3SJnXKzwLyzOjHhfM9NEVes4J4qx7yU891VJZeAji54wuvn/wKTWw3hb/MQUQAAAABJRU5ErkJggg==";

  // #region Functions
  useEffect(() => {
    setAccess(fetchPage("cadastro-veiculos"));
    let id = window.location.pathname.replace('/stapp/cadastro-veiculos/', '');

    if (id.search('editar') >= 0) {
      setIsEdit(true);
      get("/projectCars/" + id.replace('editar/', '')).then((values) => {
        values.carId = values.car.id;
        setVehicleChoosedData(values);
        setChoosedCarModel(values.car);
        values.receivingDate == null ? null : setReceivingDate(new Date(values.receivingDate + " 00:00:00"));
        values.provisionReceiving == null ? null : setProvisionReceiving(new Date(values.provisionReceiving + " 00:00:00"));
      });
    } else if (id.search('clonar') >= 0) {
      setIsEdit(false);
      get("/projectCars/" + id.replace('clonar/', '')).then((values) => {
        delete values.id;
        delete values.positionVDS;
        values.carId = values.car.id;
        setVehicleChoosedData(values);
        setChoosedCarModel(values.car);
        values.receivingDate == null ? null : setReceivingDate(new Date(values.receivingDate + " 00:00:00"));
        values.provisionReceiving == null ? null : setProvisionReceiving(new Date(values.provisionReceiving + " 00:00:00"));
      });
    }

    getSelectsList();
  }, []);

  function getSelectsList() {
    get('projectCars/data').then((values) => {
      setAllSelectsList(values);
    });
  }

  function cleanObject(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj
  }

  const handleSubmit = (e: any) => {
    if (vehicleChoosedData.carId && vehicleChoosedData.modelYear && vehicleChoosedData.motorization && vehicleChoosedData.chassis && vehicleChoosedData.transmissionDescription && vehicleChoosedData.doorNumber && vehicleChoosedData.fase ? true : false) {
      vehicleChoosedData.receivingDate = receivingDate ? formatDate(receivingDate) : '';
      vehicleChoosedData.provisionReceiving = provisionReceiving ? formatDate(provisionReceiving) : '';
      let array = cleanObject(vehicleChoosedData);
      if (isEdit) {
        put<VehicleAdd[]>("projectCars", array).then((value: VehicleAdd) => {
          if (value.codeStatus === 200) {
            setOpenAlert(true);
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      } else {
        post<VehicleAdd[]>("projectCars", array).then((value: VehicleAdd) => {
          if (value.codeStatus === 201) {
            setOpenAlert(true);
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      }
    }
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    navigate('/cadastro-veiculos');
  };
  // #endregion Functions

  return (
    access ?
      <Grid container>
        <HeaderComponent text={`Cadastro do Sistema / Cadastro de Veículos / ${isEdit ? "Editar" : "Cadastrar"}`} access={access}/>
        <SnackbarComponent text={isEdit ? "Veículo editado com sucesso" : "Veículo salvo com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent
          description={isEdit === true ? "Deseja realmente cancelar a edição de um Veículo?" : "Deseja realmente cancelar a criação de um novo Veículo?"}
          open={openModal}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setOpenModal(false);
            navigate('/cadastro-veiculos');
          }}
          text=""
        />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
                <LabelComponent text={isEdit ? "Editar " : "Cadastro do "} fontSize="28px" />
                <LabelComponent text="Veículo" fontSize="28px" fontWeight="normal" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingRight: "20px" }}>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  {choosedId == "id-prov" && <div style={{ padding: "3px 10px" }}>
                    <FormControl>
                      <RadioGroup value={choosedId} onChange={(event) => setChoosedId(event.target.value)} aria-labelledby="demo-radio-buttons-group-label" defaultValue="vds" name="radio-buttons-group">
                        <FormControlLabel value="vds" control={<Radio />} label="Vds" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  }
                  {choosedId == "vds" && <TextFieldComponent
                    text="Número do VDS"
                    required={true}
                    helperText={messageSave?.includes('VDS') ? messageSave : ""}
                    error={messageSave?.includes('VDS')}
                    value={vehicleChoosedData.vds}
                    maxLength={100}
                    onChange={(event) => {
                      setMessageSave("");
                      setVehicleChoosedData({ ...vehicleChoosedData, vds: event.target.value });
                    }}
                  />
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <SelectPaginationComponent
                    text={"Nome do modelo"}
                    url="cars/all"
                    required={true}
                    value={vehicleChoosedData.carId}
                    onSelect={(event: string) => {
                      setVehicleChoosedData({ ...vehicleChoosedData, carId: event });
                      get('/cars/' + event).then((values) => setChoosedCarModel(values));
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingRight: "20px" }}>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  {choosedId == "vds" && <div style={{ padding: "3px 10px" }}>
                    <FormControl>
                      <RadioGroup value={choosedId} onChange={(event) => setChoosedId(event.target.value)} aria-labelledby="demo-radio-buttons-group-label" defaultValue="vds" name="radio-buttons-group">
                        <FormControlLabel value="id-prov" control={<Radio />} label="Identificação Provisória" />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  }
                  {choosedId == "id-prov" && <TextFieldComponent
                    text="Identificação Provisória"
                    helperText={messageSave?.includes('Identificação') ? messageSave : ""}
                    error={messageSave?.includes('Identificação')}
                    required={true}
                    value={vehicleChoosedData.positionVDS}
                    maxLength={100}
                    onChange={(event) => {
                      setMessageSave("");
                      setVehicleChoosedData({ ...vehicleChoosedData, positionVDS: event.target.value });
                    }}
                  />
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <SelectComponent
                    isFilter={true}
                    text="Cor do veículo"
                    values={allSelectsList?.colors}
                    value={vehicleChoosedData.color}
                    required={true}
                    onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, color: event.target.value }); }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ backgroundColor: "#ddd", paddingRight: "20px" }}>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <TextFieldComponent
                    value={choosedCarModel?.project != undefined ? choosedCarModel?.project : '-'}
                    maxLength={100}
                    readOnly={true}
                    text="Número do projeto"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <TextFieldComponent
                    value={choosedCarModel?.brand?.name != undefined ? choosedCarModel?.brand?.name : '-'}
                    maxLength={100}
                    readOnly={true}
                    text="Nome do fabricante"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ backgroundColor: "#ddd", paddingRight: "20px" }}>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <TextFieldComponent
                    value={choosedCarModel?.family != undefined ? choosedCarModel?.family : '-'}
                    maxLength={100}
                    readOnly={true}
                    text="Código da família"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={12} style={{ marginBottom: "32px" }}>
                  <TextFieldComponent
                    value={choosedCarModel?.category?.name != undefined ? choosedCarModel?.category?.name : '-'}
                    maxLength={100}
                    readOnly={true}
                    text="Tipo de carroceria"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Ano do modelo"
                  type="number"
                  required={true}
                  value={vehicleChoosedData.modelYear}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, modelYear: event.target.value.toString() }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Número do motor"
                  value={vehicleChoosedData.engineNumber}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, engineNumber: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Motorização"
                  required={true}
                  value={vehicleChoosedData.motorization}
                  maxLength={100}
                  onChange={(event) => {
                    setVehicleChoosedData({ ...vehicleChoosedData, motorization: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Código do motor"
                  value={vehicleChoosedData.engineCode}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, engineCode: event.target.value }); }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Versão do Veículo"
                  required={true}
                  value={vehicleChoosedData.engineDescription}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, engineDescription: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Número do chassi"
                  required={true}
                  helperText={messageSave?.includes('chassi') ? messageSave : ""}
                  error={messageSave?.includes('chassi')}
                  value={vehicleChoosedData.chassis}
                  maxLength={100}
                  onChange={(event) => {
                    setMessageSave("");
                    setVehicleChoosedData({ ...vehicleChoosedData, chassis: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  values={allSelectsList?.transmissionFeatures}
                  text="Características da transmissão"
                  value={vehicleChoosedData.transmissionDescription}
                  required={true}
                  onSelect={(event: any) => {
                    setVehicleChoosedData({ ...vehicleChoosedData, transmissionDescription: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Identificação da transmissão"
                  value={vehicleChoosedData.transmissionIdentification}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, transmissionIdentification: event.target.value }); }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Quantidade de portas"
                  values={allSelectsList?.doors}
                  value={vehicleChoosedData.doorNumber}
                  required={true}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, doorNumber: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Tipo de combustível"
                  values={allSelectsList?.fuelTypes}
                  value={vehicleChoosedData.fuelType}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, fuelType: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <TextFieldComponent
                  text="Pneus & Roda"
                  value={vehicleChoosedData.wheelsTires}
                  maxLength={100}
                  onChange={(event) => { setVehicleChoosedData({ ...vehicleChoosedData, wheelsTires: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Característica da Roda"
                  values={allSelectsList?.wheelsTiresType}
                  value={vehicleChoosedData.wheelsTiresDescription}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, wheelsTiresDescription: event.target.value }); }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Lado da direção"
                  values={allSelectsList?.steeringSides}
                  value={vehicleChoosedData.steeringSide}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, steeringSide: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Coluna de direção"
                  values={allSelectsList?.steeringColumns}
                  value={vehicleChoosedData.steeringColumn}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, steeringColumn: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Ajustes coluna direção"
                  values={allSelectsList?.adjustSteeringColumns}
                  value={vehicleChoosedData.steeringColumnAdjust}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, steeringColumnAdjust: event.target.value }); }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Fase do veículo"
                  values={allSelectsList?.fases}
                  value={vehicleChoosedData.fase}
                  required={true}
                  onSelect={(event: any) => {
                    setVehicleChoosedData({ ...vehicleChoosedData, fase: event.target.value });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px", position: "relative" }}>
                <TextFieldComponent
                  text="PED"
                  type="number"
                  value={vehicleChoosedData.ped}
                  maxLength={100}
                  onChange={(event) => {
                    let massTotal = (Number(event.target.value) > 0 ? Number(event.target.value) : 0) + (Number(vehicleChoosedData.pet) > 0 ? Number(vehicleChoosedData.pet) : 0);
                    setVehicleChoosedData({ ...vehicleChoosedData, ped: event.target.value, massPedPet: massTotal.toString() });
                  }}
                />
                <span style={{ position: "absolute", right: "40px", bottom: "5px" }}>kg</span>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px", position: "relative" }}>
                <TextFieldComponent
                  text="PET"
                  type="number"
                  value={vehicleChoosedData.pet}
                  maxLength={100}
                  onChange={(event) => {
                    let massTotal = (Number(vehicleChoosedData.ped) > 0 ? Number(vehicleChoosedData.ped) : 0) + (Number(event.target.value) > 0 ? Number(event.target.value) : 0);
                    setVehicleChoosedData({ ...vehicleChoosedData, pet: event.target.value, massPedPet: massTotal.toString() });
                  }}
                />
                <span style={{ position: "absolute", right: "40px", bottom: "5px" }}>kg</span>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px", position: "relative" }}>
                <TextFieldComponent
                  text="Massa de recebimento"
                  value={vehicleChoosedData.massPedPet}
                  maxLength={100}
                  readOnly={true}
                />
                <span style={{ position: "absolute", right: "40px", bottom: "5px" }}>kg</span>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Status recebimento do veículo"
                  values={allSelectsList?.statusReceivements}
                  value={vehicleChoosedData.receivingStatus}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, receivingStatus: event.target.value }); }}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <DateFieldComponent
                  text="Previsão de recebimento"
                  value={provisionReceiving}
                  onChangeDate={(newValue) => setProvisionReceiving(newValue)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <DateFieldComponent
                  text="Data de recebimento"
                  value={receivingDate}
                  onChangeDate={(newValue) => setReceivingDate(newValue)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={6} md={12} lg={3}>
                <LabelComponent text="Refugar " fontSize="28px" />
                <LabelComponent text="veículo" fontSize="28px" fontWeight="normal" />
              </Grid>
            </Grid>

            <Grid container sx={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <SelectComponent
                  isFilter={true}
                  text="Status de refugo"
                  values={allSelectsList?.statusRefuses}
                  value={vehicleChoosedData.refuseStatus}
                  onSelect={(event: any) => { setVehicleChoosedData({ ...vehicleChoosedData, refuseStatus: event.target.value }); }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <div style={{ alignItems: "center", borderRadius: "3px", boxShadow: "0px 0px 5px #ccc", color: "#00b0ef", cursor: "pointer", display: "flex", fontSize: "15px", fontWeight: "bold", padding: "25px 40px", width: "155px" }}>
                  <img style={{ marginRight: "10px" }} src={downloadUploadIcon} />
                  Ficha de refugo
                </div>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={6} md={12} lg={3}>
                <LabelComponent text="Informações " fontSize="28px" />
                <LabelComponent text="Complementares" fontSize="28px" fontWeight="normal" />
              </Grid>
            </Grid>

            <Grid container sx={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                {vehicleChoosedData.prListPDFMedia != '' ?
                  <a target="_blank" style={{ textDecoration: "none" }} href={vehicleChoosedData.prListPDFMedia}>
                    <DownloadFile image={downloadUploadIcon} text="Dados do veículo"></DownloadFile>
                  </a>
                  :
                  <DownloadFile image={downloadUploadIcon} text="Dados do veículo"></DownloadFile>
                }
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                {vehicleChoosedData.weighingPdfMedia != '' ?
                  <a target="_blank" style={{ textDecoration: "none" }} href={vehicleChoosedData.weighingPdfMedia}>
                    <DownloadFile image={downloadUploadIcon} text="Ficha de pesagem"></DownloadFile>
                  </a>
                  :
                  <DownloadFile image={downloadUploadIcon} text="Ficha de pesagem"></DownloadFile>
                }
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <InputFile
                  image={downloadUploadIcon}
                  text="Dados do veículo"
                  rotate={true}
                  handleFile={(file: any) => {
                    var formData = new FormData();
                    formData.append("file", file);
                    post<File>("medias", formData).then((value) => {
                      if (value.codeStatus === 200 || value.codeStatus === 201) {
                        setVehicleChoosedData({ ...vehicleChoosedData, prListPDFMedia: value.media });
                      } else {
                        setMessageSave(value.errorMessage);
                      }
                    });
                  }}
                ></InputFile>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} style={{ marginBottom: "32px", paddingRight: "20px" }}>
                <InputFile
                  image={downloadUploadIcon}
                  text="Ficha de pesagem"
                  rotate={true}
                  handleFile={(file: any) => {
                    var formData = new FormData();
                    formData.append("file", file);
                    post<File>("medias", formData).then((value) => {
                      if (value.codeStatus === 200 || value.codeStatus === 201) {
                        setVehicleChoosedData({ ...vehicleChoosedData, weighingPdfMedia: value.media });
                      } else {
                        setMessageSave(value.errorMessage);
                      }
                    });
                  }}
                ></InputFile>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={5} sm={4} md={2} lg={2}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => {
                setOpenModal(true);
              }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2}>
              <ButtonComponent text="Salvar" onClick={handleSubmit}
                active={
                  choosedId == 'vds' && vehicleChoosedData.vds == '' ? false :
                    choosedId == 'id-prov' && vehicleChoosedData.positionVDS == '' ? false :
                      vehicleChoosedData.carId &&
                        vehicleChoosedData.engineDescription &&
                        vehicleChoosedData.color &&
                        vehicleChoosedData.modelYear &&
                        vehicleChoosedData.motorization &&
                        vehicleChoosedData.chassis &&
                        vehicleChoosedData.transmissionDescription &&
                        vehicleChoosedData.doorNumber &&
                        vehicleChoosedData.fase ? true : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default VehiclePage;
import moment from "moment";
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import { SortArrayObjByName } from "../../utils/utils";
import useRoute from "../route/useRoute";
type Type = any;

const useProgListCommon = (state: Type) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [sinergyCommonItem, setSinergyCommonItem] = useState<LaboratoryList[]>([]);
    const [sinergyCommonFilter, setSinergyCommonFilter] = useState<LaboratoryListData>();
    const [totalElements, setTotalElements] = useState<number>(0);
    const [commonCars, setCommonCars] = useState<CommonProg | null>();
    const [common, setCommon] = useState<any>();
    const [cardItemCommon, setCardItemCommon] = useState<any>();
    const [monthId, setMonthId] = useState<string>();
    const [months, setMonths] = useState<Months[]>([]);
    const [weekId, setWeekId] = useState<string>();
    const [weeks, setWeeks] = useState<Weeks[]>([]);
    const [yearId, setYearId] = useState<string>();
    const [years, setYears] = useState<Years[]>([]);
    const [activities, setActivities] = useState<GeneralType[]>([]);
    const [testsName, setTestsName] = useState<GeneralType[]>([]);
    const [testPlans, setTestPlans] = useState<GeneralType[]>([]);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("programacao-laboratorios"));
        fetchFilterListCommon(state.id);
        fetchListSinergyCommon("", "", "", "", state.id);
        fetchCommon({ "laboratoryId": state.id, "kw": moment().format('W'), "year": moment().format('gggg') });
        fetchFilterSelect(state.id);
    }, [])


    const fetchFilterListCommon = (id: any) => {
        setLoading(true);
        get<LaboratoryListData>('/laboratories/schedule/comum/data/' + id).then((value) => {
            value.responsibles = value.responsibles.sort(SortArrayObjByName);
            value.tests = value.tests.sort(SortArrayObjByName);
            value.weeks = value.weeks.sort(SortArrayObjByName);
            setSinergyCommonFilter(value);
        }).finally(() => setLoading(false));
    }

    const fetchFilterSelect = (id: any) => {
        get('/laboratories/schedule/comum/testPlan/select/' + id).then((value) => {
            setTestsName(value.testsName);
            setActivities(value.activities);
            setTestPlans(value.testPlans);
        });
    }

    const fetchListSinergyCommon = (filter: string, filter1: string, filter2: string, filter3: string, id: any,) => {
        setLoading(true);
        setSinergyCommonItem([]);
        post('/laboratories/schedule/comum/list/all',
            {
                "param": filter,
                "kwYear": filter3,
                "test": filter2,
                "responsible": filter1,
                "laboratoryId": id
            })
            .then((value) => {
                setSinergyCommonItem(value);
                setTotalElements(value.totalElements);
                setLoading(false);
            });
    };

    const fetchCommon = (body: any) => {
        setLoading(true);
        setCommon(undefined)
        setCommonCars(undefined);
        post('/laboratories/schedule/comum/requestTest/all', body).then((value) => {
            value.demanda.map((demanda: any) => {
                demanda.testsOfWeek.map((testeOfWeek: any) => {
                    if (testeOfWeek.cards.length === 0) {
                        testeOfWeek.cards = [{
                            isEmptyCard: true
                        }]
                    } else {
                        testeOfWeek.cards.map((card: any) => {
                            card.isEmptyCard = false;
                        });
                    }
                });
            })
            let a: any = [];
            a.push({ 'label': 'P1', 'value': value.p1 });
            a.push({ 'label': 'P2', 'value': value.p2 });
            setCommon(a)
            setCommonCars(value);
            setMonthId(value.monthId);
            setWeekId(value.week);
            setYearId(value.year);
            get('testPlans/data').then((data) => {
                setYears(data.years);
                setMonths(data.months);
                data.months.map((month: any) => {
                    if (month.id === value.monthId) {
                        setWeeks(month.weeks);
                    }
                });
            });

        }).finally(() => setLoading(false));
    }

    const fetchPopulaCommon = (body: any) => {
        setLoading(true);
        setCommonCars(null);
        post('/laboratories/schedule/comum/requestTest/all', body).then((value) => {
            value.demanda.map((demanda: any) => {
                demanda.testsOfWeek.map((testeOfWeek: any) => {
                    if (testeOfWeek.cards.length === 0) {
                        testeOfWeek.cards = [{
                            isEmptyCard: true
                        }]
                    } else {
                        testeOfWeek.cards.map((card: any) => {
                            card.isEmptyCard = false;
                        });
                    }
                });
            });
            let a: any = [];
            a.push({ 'label': 'P1', 'value': value.p1 });
            a.push({ 'label': 'P2', 'value': value.p2 });
            setCommon(a)
            setCommonCars(value);
        }).finally(() => setLoading(false));
    }

    const fetchDisconfirm = (requestTestId: any, body2: any) => {
        setLoading(true);
        put("/laboratories/schedule/comum/requestTest/disconfirm/" + requestTestId)
            .then((value) => {
                if (value.codeStatus === 200) {
                    setLoading(true);
                    post('/laboratories/schedule/comum/requestTest/all', body2).then((value) => {
                        value.demanda.map((demanda: any) => {
                            demanda.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        })
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        setCommon(a)
                        setCommonCars(value);
                        setMonthId(value.monthId);
                        setWeekId(value.week);
                        setYearId(value.year);
                        get('testPlans/data').then((data) => {
                            setYears(data.years);
                            setMonths(data.months);
                            data.months.map((month: any) => {
                                if (month.id === value.monthId) {
                                    setWeeks(month.weeks);
                                }
                            });
                        });
                    }).finally(() => setLoading(false));
                }
            }).finally(() => setLoading(false));
    }

    const fetchConfirm = (requestTestId: any, body2: any) => {
        setLoading(true);
        put("/laboratories/schedule/comum/requestTest/confirm/" + requestTestId)
            .then((value) => {
                if (value.codeStatus === 200) {
                    setLoading(true);
                    post('/laboratories/schedule/comum/requestTest/all', body2).then((value) => {
                        value.demanda.map((demanda: any) => {
                            demanda.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        })
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        setCommon(a)
                        setCommonCars(value);
                        setMonthId(value.monthId);
                        setWeekId(value.week);
                        setYearId(value.year);
                        get('testPlans/data').then((data) => {
                            setYears(data.years);
                            setMonths(data.months);
                            data.months.map((month: any) => {
                                if (month.id === value.monthId) {
                                    setWeeks(month.weeks);
                                }
                            });
                        });
                    }).finally(() => setLoading(false));
                }
            }).finally(() => setLoading(false));
    }

    const fetchData = (year: number, monthId: number) => {
        get('testPlans/data?year=' + year).then((data) => {
            setYears(data.years);
            setMonths(data.months);
            data.months.map((month: any) => {
                if (month.id === monthId) {
                    setWeeks(month.weeks);
                }
            });
        }).finally(() => setLoading(false));
    }

    const fetchPriority = (body: any, body2: any) => {
        setLoading(true);
        put("/laboratories/schedule/priority", body)
            .then((value) => {
                if (value.codeStatus === 200) {
                    setLoading(true);
                    post('/laboratories/schedule/comum/requestTest/all', body2).then((value) => {
                        value.demanda.map((demanda: any) => {
                            demanda.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        })
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        setCommon(a)
                        setCommonCars(value);
                        setMonthId(value.monthId);
                        setWeekId(value.week);
                        setYearId(value.year);
                        get('testPlans/data').then((data) => {
                            setYears(data.years);
                            setMonths(data.months);
                            data.months.map((month: any) => {
                                if (month.id === value.monthId) {
                                    setWeeks(month.weeks);
                                }
                            });
                        });
                    }).finally(() => setLoading(false));
                }
            }).finally(() => setLoading(false));
    }

    const fetchUpdateDateTest = (type: number, body1: any, body2: any) => {
        setLoading(true);
        put(type === 1 ?
            "/laboratories/schedule/requestTest/date"
            : "/laboratories/schedule/activities/date"
            , body1)
            .then((value) => {
                if (value.codeStatus === 200) {
                    setLoading(true);
                    post('/laboratories/schedule/comum/requestTest/all', body2).then((value) => {
                        value.demanda.map((demanda: any) => {
                            demanda.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        })
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        setCommon(a)
                        setCommonCars(value);
                    }).finally(() => setLoading(false));
                }
            }).finally(() => setLoading(false));
    }

    return {
        fetchFilterListCommon, fetchListSinergyCommon, fetchCommon,
        fetchPopulaCommon, fetchUpdateDateTest, 
        loading, fetchData,
        totalElements,
        sinergyCommonItem, setSinergyCommonItem,
        sinergyCommonFilter, setSinergyCommonFilter,
        monthId, setMonthId,
        months,
        weekId, setWeekId,
        weeks, setWeeks,
        yearId, setYearId,
        years, access,
        cardItemCommon,
        commonCars, common,
        activities, testsName, testPlans, fetchConfirm, fetchPriority, fetchDisconfirm
    }
}

export default useProgListCommon;
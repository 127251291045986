import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import ButtonComponent from "../../components/button/buttonComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import LabelComponent from "../../components/label/labelComponent";
import HeaderComponent from "../../components/header/headerComponent";
import ModalComponent from "../../components/modal/modalComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import HourMoneyFieldComponent from "../../components/field/hourMoneyFieldComponent";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import { moneyToIntegerSubtraction } from "../../utils/utils";
import ModalReadPage from "../../components/modal/modalReadPage";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function ResourceTypePage() {
  const [name, setName] = useState("");
  const [resourceTypeList, setResourceTypeList] = useState<ResourceTypeList[]>([]);
  const [resourceCategory, setResourceCategory] = useState<ResourceCategory[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [filter, setFilter] = useState("");
  const [resourceList, setResourceList] = useState<ResourceList[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [categoryResource, setCategoryResource] = useState<string>("");
  const [typeListResource, setTypeListResource] = useState<string>("");
  const [messageSave, setMessageSave] = useState<string>("");
  const [valid, setValid] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [id, setId] = useState<number | null>(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [resourcesActive, setResourcesActive] = useState<any>("");
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [filter3, setFilter3] = useState<string>("");
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [resources, setResources] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [supervisionList, setSupervisionList] = useState([]);
  const [loadingSupervisionList, setLoadingSupervisionList] = useState(true);
  const [totalValue, setTotalValue] = useState<string>('0,00');
  const [totalUseValue, setTotalUseValue] = useState<string>('0,00');
  const [pageSelectActive, setPageSelectActive] = useState<number>(0);
  const [totalPagesSelectActive, setTotalPagesSelectName] = useState<number>(0);
  const [useQuantity, setUseQuantity] = useState<string>("");
  const [read, setRead] = useState<boolean>(false);
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();

  let quantityOnEdit: number;

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome do recurso", flex: 0.3, minWidth: 100, },
    {
      field: "typeCategory", headerName: "Categoria", flex: 0.3, minWidth: 100,
      valueGetter: (params: any) => {
        return params.row.typeCategory.name;
      }
    },
    {
      field: "typeResource", headerName: "Tipo", flex: 0.3, minWidth: 100,
      valueGetter: (params: any) => {
        return params.row.typeResource.name;
      }
    },
    {
      field: "totalValue", headerName: "Valor total", flex: 0.3, minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      },
      sortComparator: (value1: any, value2: any) => {
        return moneyToIntegerSubtraction(value1, value2);
      }
    },
    {
      field: "useValue", headerName: "Valor por uso", flex: 0.3, minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.useValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      },
      sortComparator: (value1: any, value2: any) => {
        return moneyToIntegerSubtraction(value1, value2);
      }
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.3,
      minWidth: 250,
      renderCell: (value: any) => {
        if (value.row.active) {
          return <Box flex={3}>
            <ButtonTextComponent text="Desativar" onClick={() => {
              setOpenDisable(true);
              setId(value.row.id);
            }} />
            <ButtonTextComponent text="Editar" onClick={() => {
              setId(value.row.id);
              setName(value.row.name);
              setCategoryResource(value.row.typeCategory.id);
              setTypeListResource(value.row.typeResource.id);
              setValid(true);
              setIsEdit(true);
              _getRegisterData(value.row.id);
            }} />
            <ButtonTextComponent text="Clonar" onClick={() => {
              setId(value.row);
              setName(value.row.name);
              setCategoryResource(value.row.typeCategory.id);
              setTypeListResource(value.row.typeResource.id);
              setValid(true);
              setIsEdit(false);
              _getRegisterData(value.row.id);
            }} />
          </Box>
        }
        else {
          return <Box flex={1}>
            <ButtonTextComponent text="Ativar" onClick={() => {
              setResources([]);
              setLoading(true);
              put("resources/" + value.row.id + "/active/" + true).then((value) => {
                getList(filter, filter1, filter2, filter3, page, pageSize);
              })

            }} />
          </Box>
        }
      }
    }
  ];

  useEffect(() => {
    setAccess(fetchPage("recursos"));
    getList(filter, filter1, filter2, filter3, page, pageSize);
    getResourceTypeList();
    getResourceCategory();
    getSupervisionList();
    getResourcesActive(pageSelectActive);
  }, []);

  function getList(filter: string, filter1: string, filter2: string, filter3: string, page: number, pageSize: number) {
    if (!isFilter) {
      post("resources/all", { "param": filter, "page": page, "size": pageSize }).then(
        (value) => {
          setResourceList(value.content);
          setTotalElements(value.totalElements);
          setTotalPages(value.totalPages);
          setLoading(false);
        }
      );
    } else {
      post('resources/all',
        {
          "param": filter,
          "name": filter1,
          "typeCategory": filter2,
          "typeResource": filter3,
          "page": page,
          "size": pageSize
        }).then((value) => {
          setResourceList(value.content);
          setTotalElements(value.totalElements);
          setTotalPages(value.totalPages);
          setLoading(false);
        });
    }
  };

  function getResourcesActive(page: any) {
    post("resources/active/true",
      {
        "page": page,
        "size": 10
      }).then((value) => {
        setResourcesActive(value.content);
        setTotalPagesSelectName(value.totalPages);
      });
  };

  function getResourceTypeList() {
    get("typeResources").then((value) => {
      setResourceTypeList(value);
    });
  }

  function getResourceCategory() {
    get("categoryResources").then((value) => {
      setResourceCategory(value);
    });
  }

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      setFilter3("");
      getList("", "", "", "", page, pageSize);

    } else {
      setFilter(event.target.value);
      getList(event.target.value, filter1, filter2, filter3, page, pageSize);
    }
  }

  const handleSubmit = (e: any) => {
    let resourceValues: any = [];
    rows.forEach((row: any) => {
      if (!(row.value == '0,00') && !(row.value == '0') && !(row.id == '0')) {
        resourceValues.push({
          "idSupervision": row.id,
          "value": row.value.replace('.', '').replace(',', '.')
        });
      }
    });
    if (valid) {
      if (!isEdit) {
        post<TypeTests[]>("resources", {
          "name": name,
          "typeCategory": categoryResource,
          "typeResource": typeListResource,
          "useQuantity": Number(useQuantity.replace(',', '.')),
          "valueResources": resourceValues
        }).then((value: any) => {
          if (value.codeStatus == 201) {
            setOpenAlert(true);
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      } else {
        put<TypeTests[]>("resources", {
          "id": id,
          "name": name,
          "typeCategory": categoryResource,
          "typeResource": typeListResource,
          "useQuantity": Number(useQuantity.replace(',', '.')),
          "valueResources": resourceValues
        }).then((value: Supervision) => {
          if (value.codeStatus === 200) {
            setOpenAlert(true)
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      }
    }
  }

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
    window.location.reload();
  };

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpenAlert(false);
    window.location.reload();
  };

  function getSupervisionList() {
    post('supervisions/active/true', { "size": 9999999, "initials": "", "name": "", "page": 0, "param": "", "user": "" }).then((value) => {
      setSupervisionList(value.content);
      createArrayForNewRegister(value.content);
      setLoadingSupervisionList(false);
    });
  };

  function createArrayForNewRegister(_supervisionList: any) {
    let newArray: any = [];
    newArray = _supervisionList.map((row: any) =>
      ({ id: row.id, name: row.name, value: '0,00' })
    );
    setTotalValue('0,00');
    setRows(newArray);
  }

  function _getRegisterData(id?: number) {
    get("resources/" + id).then((value) => {
      setUseQuantity(value.quantity.toString().replace('.', ','));
      quantityOnEdit = value.quantity;
      joinSupervisionAndResouceValuesForEdit(value);
    });
  }

  function joinSupervisionAndResouceValuesForEdit(resourceData: any) {
    let newArray: any = [];
    let hasValue: boolean;

    supervisionList.forEach((supervisionRow: any) => {
      hasValue = false;
      resourceData.resourcesSupervisions.forEach((resourceRow: any) => {
        if (supervisionRow.id === resourceRow.supervisionId) {
          hasValue = true;
          newArray.push({
            id: supervisionRow.id,
            name: supervisionRow.name,
            value: valueToString(resourceRow.value)
          })
        }
      });

      if (!hasValue) {
        newArray.push({
          id: supervisionRow.id,
          name: supervisionRow.name,
          value: '0,00',
        })
      }
    });
    setTotalValue(calculaUltimaLinha(newArray));
    setRows(newArray);
  }

  function stringToValue(value: any): number {
    return value != undefined ? parseFloat(value.replaceAll('.', '').replace(',', '.')) : 0;
  }

  function valueToString(value: number): string {
    return value.toString().replace('.', ',');
  }

  function calculaUltimaLinha(arr: any) {
    let totalValue = 0;
    arr.forEach((element: any) => {
      totalValue += stringToValue(element.value)
    });

    calculaSegundoTotal(totalValue);
    return totalValue.toString().replace('.', ',');
  }

  function calculaSegundoTotal(value: number) {
    let totalValue = value / (quantityOnEdit === undefined ? Number(useQuantity == '' ? 1 : useQuantity.replace(',', '.')) : quantityOnEdit);
    setTotalUseValue(totalValue.toFixed(2).replace('.', ','));
  }

  function calculaSegundoTotalAfterQuantity(useQuantity: string) {
    let quantity = Number(useQuantity == '' ? 1 : useQuantity.replace(',', '.'));
    let totalUseValue = Number(totalValue.replace(',', '.')) / quantity;
    setTotalUseValue(totalUseValue.toFixed(2).toString().replace('.', ','));
  }

  return (
    access ?
      <Grid container>
        <HeaderComponent text="Cadastro do Sistema / Tipos de Recursos" access={access}/>
        <SnackbarComponent text={isEdit ? "Tipo de Recurso editado com sucesso" : "Tipo de Recurso salvo com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent
          description="Atenção! Ao desativar este Tipo de Recurso, não será mais possível associar este tipo de recurso às atividades e testes enquanto ele estiver com status inativo."
          open={openDisable}
          close={handleCloseModalDisable}
          onCancel={() => { setOpenDisable(false) }}
          onNext={() => {
            setResourceList([]);
            setLoading(true);
            put('resources/' + id + '/active/' + false).then((value) => {
              getList(filter, filter1, filter2, filter3, page, pageSize);
              setId(null);
              setOpenDisable(false);
            });
          }} />

        <ModalComponent
          description={isEdit ? "Deseja realmente cancelar a edição do recurso?" : "Deseja realmente cancelar a criação de um novo recurso?"}
          open={openModal}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            window.location.reload();
          }} />

        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Nome do recurso"}
          isPagination1={true}
          value1={filter1}
          url1="resources/active/true"
          onSelect1={(paginationEvent: string) => {
            setFilter1(paginationEvent);
            setIsFilter(true);
          }}
          labelFilter2={"Categoria"}
          value2={filter2}
          filter2={resourceCategory}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
            setIsFilter(true);
          }}
          labelFilter3={"Tipo"}
          value3={filter3}
          filter3={resourceTypeList}
          onSelect3={(event: any) => {
            setFilter3(event.target.value);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            if (filter1 === "" && filter2 === "" && filter3 === "") {
              setPage(0);
              setPageSize(5);
            }
            getList(filter, filter1, filter2, filter3, page, pageSize);
            setOpenFilter(false);
          }}
        />

        <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" marginTop="48px" />
              <LabelComponent text="Recurso" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3} >
              <LabelComponent text="Dados do recurso" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <TextFieldComponent text="Nome do recurso"
                helperText={messageSave?.includes("Nome") ? messageSave : resourcesActive?.fields && name === "" ? "Campo obrigatório" : ""}
                error={messageSave?.includes("Nome") || resourcesActive?.fields && name === "" ? true : false}
                required={true}
                value={name}
                maxLength={100}
                onChange={(event) => {
                  setName(event.target.value);
                  setMessageSave("");
                  if (event.target.value != "" && categoryResource != "" && typeListResource != "") {
                    if (typeListResource === "1" && useQuantity != "") {
                      setValid(true);
                    } else if (typeListResource === "1" && useQuantity === "") {
                      setValid(false);
                    } else {
                      setValid(true);
                    }
                  } else {
                    setValid(false);
                  }

                }} />
            </Grid>
            <ModalReadPage open={read} onCancel={() => setRead(false)} />
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                helperText={messageSave?.includes('Categoria') ? messageSave :
                  resourcesActive?.fields && categoryResource === "" ? "Campo obrigatório" : ""}
                values={resourceCategory}
                text="Categoria"
                value={categoryResource}
                required={true}
                onSelect={(event: any) => {
                  setCategoryResource(event.target.value);
                  if (event.target.value != "" && name != "" && typeListResource != "") {
                    if (typeListResource === "1" && useQuantity != "") {
                      setValid(true);
                    } else if (typeListResource === "1" && useQuantity === "") {
                      setValid(false);
                    } else {
                      setValid(true);
                    }
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent text="Tipo de recurso"
                required={true}
                values={resourceTypeList}
                value={typeListResource}
                onSelect={(event: any) => {
                  setTypeListResource(event.target.value);
                  if (event.target.value != "" && name != "" && categoryResource != "") {
                    if (event.target.value === 1 && useQuantity != "") {
                      setValid(true);
                    } else if (event.target.value === 1 && useQuantity === "") {
                      setValid(false);
                    } else {
                      setUseQuantity("");
                      setValid(true);
                    }
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
          </Grid>

          <Grid container sx={{ display: typeListResource == "1" ? "block" : "none" }}>
            <Grid container sx={{ marginTop: "32px" }}>
              <Grid item xs={12} sm={6} md={12} lg={3} >
                <LabelComponent text="Valor do recurso" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
                <HourMoneyFieldComponent
                  text="Quantidade de uso"
                  decimalSeparator={","}
                  decimalScale={1}
                  required={true}
                  onChange={(event: any) => {
                    setUseQuantity(event.target.value);
                    calculaSegundoTotalAfterQuantity(event.target.value);
                    setMessageSave("");
                    if (
                      event.target.value != "" &&
                      categoryResource != "" &&
                      typeListResource != "" &&
                      name != ""
                    ) {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                  }}
                  value={useQuantity}
                ></HourMoneyFieldComponent>
              </Grid>
            </Grid>

            {rows ?
              <Grid container sx={{ marginTop: "32px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Box
                    sx={{
                      maxWidth: 450,
                      minWidth: 250,
                      backgroundColor: "#dfe4e8",
                      borderRadius: "8px",
                    }}>
                    <Grid container >
                      <Grid item xs={5}
                        sx={{
                          margin: "16px",
                          lineHeight: "1.5",
                          fontFamily: "VWHead",
                          fontSize: "16px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          color: "#001e50",
                          textAlign: "center"
                        }} >
                        Supervisão
                      </Grid>
                      <Grid item xs={5}
                        sx={{
                          margin: "16px",
                          lineHeight: "1.5",
                          fontFamily: "VWHead",
                          fontSize: "16px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          letterSpacing: "normal",
                          color: "#001e50",
                          textAlign: "center"
                        }} >
                        Valor
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid container style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {rows.map((value: any) =>
                          <DataTableEditComponent
                            name={value.name}
                            value={value.value}
                            onChange={(event) => value.value = event.target.value}
                            onBlur={(event) => {
                              if (event.target.value.length === 0) {
                                event.target.value = '0,00';
                                value.value = '0,00';
                              } else {
                                if (event.target.value.length <= 8 && event.target.value.search(",") === -1) {
                                  value.value = value.value + ",00";
                                } else if (event.target.value.length <= 8 && event.target.value.search(",") != -1) {
                                  let aux = event.target.value.split(',');
                                  if (aux[1].length < 2) {
                                    value.value = aux[0] + ',' + aux[1] + '0';
                                  } else {
                                    value.value = event.target.value;
                                  }
                                }
                              }
                              setTotalValue(calculaUltimaLinha(rows));
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ backgroundColor: '#dfe4e8', borderRadius: "8px", }}>
                      <Grid item xs={6} sx={{ fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", padding: "10px 0 15px 15px", color: "#001e50", textAlign: "center" }} >
                        Valor total
                      </Grid>
                      <Grid item xs={5} sx={{ fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", padding: "10px 0 15px 59px", color: "#6a767d" }} >
                        {Number(totalValue.replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Grid>
                      <Grid item xs={6} sx={{ fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", padding: "10px 0 15px 15px", color: "#001e50", textAlign: "center" }} >
                        Valor por uso
                      </Grid>
                      <Grid item xs={5} sx={{ fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal", padding: "10px 0 15px 59px", color: "#6a767d" }} >
                        {Number(totalUseValue.replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              : <LoadingComponente />}
          </Grid>

          <Grid container>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Recursos" fontWeight="normal" fontSize="28px" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
              <SearchComponent text=""
                value={filter}
                placeholder="Buscar por …"
                isClean={true}
                onClickClean={() => {
                  setFilter("");
                  setFilter1("");
                  setFilter2("");
                  setFilter3("");
                  getList("", "", "", "", page, pageSize);
                }}
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  getList("", filter1, filter2, filter3, page, pageSize);
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={resourceList}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onClick={filterList}
            />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default ResourceTypePage;

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

function SelectComponent(props: ParamsField) {
    return (
        <FormControl variant="standard"
            sx={{ width: "100%" }}>
            <InputLabel sx={{
                color: props.helperText ? "#e4002c" : null,
                flex: props.flex ? props.flex : 1,
                fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                fontSize: props.fontSize ? props.fontSize : "16px",
                fontWeight: props.fontWeight ? props.fontWeight : "normal",
                fontStretch: props.fontStretch ? props.fontStretch : "normal",
                fontStyle: props.fontStyle ? props.fontStyle : "normal",
                lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
            }}
                required={props.required}>{props.text}
            </InputLabel>

            <Select
                required={props.required ? props.required : false}
                value={props.values !== undefined ? props.values.length > 0 ? props.value : '' : ''}
                defaultValue={""}
                sx={{
                    color: "#001e50",
                    fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                    fontSize: props.fontSize ? props.fontSize : "16px",
                    fontWeight: props.fontWeight ? props.fontWeight : "normal",
                    fontStretch: props.fontStretch ? props.fontStretch : "normal",
                    fontStyle: props.fontStyle ? props.fontStyle : "normal",
                    lineHeight: props.lineHeight ? props.lineHeight : "1.5",
                    letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                }}
                onChange={props.onSelect}
            >
                <MenuItem value={""}>
                    <h1 style={{
                        margin: "0px",
                        height: "22px"
                    }}> </h1>
                </MenuItem>

                {props.isFieldInitials ?
                    props.values.map((aux: any, key: number) => {
                        return <MenuItem key={key} value={aux?.initials}>{aux?.initials}</MenuItem>
                    }) :
                    props.isString ?
                        props.values.map((aux: any, key: number) => {
                            return <MenuItem key={key} value={aux}>{aux}</MenuItem>
                        }) :
                        props.values ? props.values.map((aux: any, key: number) => {
                            return <MenuItem key={key} value={props.isFilter ? aux?.name : aux?.id}>{aux?.name}</MenuItem>
                        }) : []}
            </Select>
            {props.helperText ?
                <FormHelperText
                    sx={{
                        padding: props.padding ? props.padding : "4px 8px 4px 8px",
                        borderRadius: props.borderRadius ? props.borderRadius : "4px",
                        backgroundColor: props.backgroundColor ? props.backgroundColor : "#e4002c",
                        color: props.color ? props.color : "#fff",
                        fontFamily: props.fontFamily ? props.fontFamily : "VWText",
                        fontSize: props.fontSize ? props.fontSize : "12px",
                        fontWeight: props.fontWeight ? props.fontWeight : "normal",
                        fontStretch: props.fontStretch ? props.fontStretch : "normal",
                        fontStyle: props.fontStyle ? props.fontStyle : "normal",
                        lineHeight: props.lineHeight ? props.lineHeight : "1.33",
                        letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                    }}
                >{props.helperText}</FormHelperText>
                : null}
        </FormControl>
    );
}

export default SelectComponent;
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { Box } from "@mui/system";
import { get, post } from "../../api/api";
import RadioButtonLogComponent from "../../components/radio/radioButtonLogComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import { useNavigate } from "react-router-dom";
import { hourAndMinutesSubtraction } from "../../utils/utils";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function LogPage() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [auditsLogList, setAuditsLogList] = useState<AuditsLogList[]>([]);
  const [entities, setEntities] = useState<AuditEntities[]>([]);
  const [entity, setEntity] = useState<string>("");
  const [dateInit, setDateInit] = useState<Date | null>(null);
  const [dateFinal, setDateFinal] = useState<Date | null>(null);
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "rev", headerName: "ID do Log", flex: 0.1, minWidth: 100 },
    {
      field: "date", headerName: "Data/Hora", flex: 0.2, minWidth: 100,
      sortComparator: (value1: any, value2: any) => {
        return hourAndMinutesSubtraction(value1, value2);
      }
    },
    { field: "description", headerName: "Descrição", flex: 0.2, minWidth: 100 },
    { field: "user", headerName: "Responsável", flex: 0.2, minWidth: 100 },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.2,
      minWidth: 100,
      renderCell: (value: any) => {
        {
          return (
            <Box flex={3}>
              <ButtonTextComponent
                text="Ver Detalhes"
                onClick={() =>
                  navigate(
                    "/log-sistema/details/" +
                    value.row.entity +
                    "/" +
                    value.row.rev
                  )
                }
              />
            </Box>
          );
        }
      },
    },
  ];

  useEffect(() => {
    setAccess(fetchPage("log-sistema"));
    getAuditEntities();
  }, []);

  function getList(
    dateInit: Date | null,
    dateFinal: Date | null,
    filter: string,
    page: number,
    pageSize: number
  ) {
    post("audits", {
      endDate: dateFinal,
      entity: filter,
      page: page,
      size: pageSize,
      startDate: dateInit,
    }).then((value) => {
      setAuditsLogList(value.content);
      setTotalElements(value.totalElements);
      setTotalPages(value.totalPages);
      setLoading(false);
    });
  }

  function getAuditEntities() {
    get<AuditEntities[]>("audits/entities").then((value) => {
      setEntities(value);
    });
  }

  const filterList = (event: any) => {
    setEntity("");
    getAuditEntities();
    setDateInit(null);
    setDateFinal(null);
    setAuditsLogList([]);
    setTotalElements(0);
    setTotalPages(0);
    setLoading(false);
    setPage(0);
    setPageSize(5);
  }

  return (
    access ?
      <Grid container md={12}>
        <HeaderComponent text="Log de Sistema" access={access}/>
        <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text="Log " fontSize="28px" marginTop="32px" />
              <LabelComponent
                text="do Sistema"
                fontSize="28px"
                fontWeight="normal"
              />
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <DateFieldComponent
                text={"Data inicial"}
                value={dateInit}
                required={true}
                onChangeDate={(newValue) => {
                  setDateInit(newValue);
                  if (newValue !== null && dateFinal !== null && entity !== "") {
                    getList(newValue, dateFinal, entity, page, pageSize);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <DateFieldComponent
                text={"Data final"}
                value={dateFinal}
                required={true}
                onChangeDate={(newValue) => {
                  setDateFinal(newValue);
                  if (dateInit !== null && newValue !== null && entity !== "") {
                    getList(dateInit, newValue, entity, page, pageSize);
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <RadioButtonLogComponent
                text={"Módulo"}
                values={entities}
                value={entity}
                required={true}
                onChange={(event) => {
                  setEntity(event.target.value);
                  if (
                    dateInit !== null &&
                    dateFinal !== null &&
                    event.target.value !== ""
                  ) {
                    getList(
                      dateInit,
                      dateFinal,
                      event.target.value,
                      page,
                      pageSize
                    );
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid item sx={{ marginTop: "32px" }} xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={auditsLogList}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(dateInit, dateFinal, entity, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(dateInit, dateFinal, entity, page, pageSize);
              }}
              onClick={filterList} />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default LogPage;

import { useEffect, useState } from "react";
import { deleteR, get, post, put } from "../../api/api";
import useRoute from "../route/useRoute";

const useTestPlanList = () => {

    const [loading, setLoading] = useState(true);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [testPlanList, setTestPlanList] = useState<any>([]);
    const [codeStatus, setCodeStatus] = useState<number | null>(null);
    const [userList, setUserList] = useState<User[]>([]);
    const [access, setAccess] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("lista-plano-testes"));
    }, []);

    const fetchList = (filter: string, filter1: string, filter2: string, status: string, page: number, pageSize: number) => {
        setLoading(true);
        setTestPlanList([]);
        post('testPlans/all',
            {
                "param": filter,
                "name": filter1,
                "responsible": filter2,
                "activeStatus": true,
                "status": status,
                "page": page,
                "size": pageSize,
            })
            .then((value) => {
                setTotalElements(value.totalElements);
                setTestPlanList(value.content);
            }).finally(() => setLoading(false));
    };

    const fetchDeactive = (id: number | null, active: boolean, filter: string, filter1: string, filter2: string, status: string, page: number, pageSize: number) => {
        put('testPlans/disable/' + id).then((value) => {
            fetchList(filter, filter1, filter2, status, page, pageSize);
            setCodeStatus(value.codeStatus);
        });
    }

    const fetchUserList = (status: string) => {
        get('testPlans/responsibles/' + status).then((value) => {
            setUserList(value);
        });
    }

    const fetDeleteTestPlan = (id: any, status: string, page: number, pageSize: number) => {
        setLoading(true);
        deleteR('testPlans/' + id).then((del) => {
            if (del === undefined) {
                setTestPlanList([]);
                setLoading(true);
                post('testPlans/all',
                    {
                        "activeStatus": true,
                        "status": status,
                        "page": page,
                        "size": pageSize,
                    })
                    .then((value) => {
                        setTotalElements(value.totalElements);
                        setTestPlanList(value.content);
                    }).finally(() => setLoading(false));
            } else {
                setLoading(false);
                setIsError(true);
                if (del.errorMessage === 'Not Operation') {
                    setMessageAlert("O perfil não pode realizar a operação.");
                } else {
                    setMessageAlert(del.errorMessage);
                }
            }
        }).finally(() => setLoading(false));
    }

    return {
        fetchList, fetchUserList, loading, access,
        read, setRead,
        isError, setIsError, messageAlert,
        totalElements, testPlanList, fetchDeactive, fetDeleteTestPlan, codeStatus, userList
    };
}

export default useTestPlanList;
import { Box, Button, Card, Grid } from '@mui/material';
import Car from '../../assets/images/model.png';

import BackLow from '../../assets/images/back-low.png';
import FrontLow from '../../assets/images/front-low.png';
import RightLow from '../../assets/images/right-low.png';
import LeftLow from '../../assets/images/left-low.png';
import BackMedium from '../../assets/images/back-medium-1.png';
import FrontMedium from '../../assets/images/front-medium-1.png';
import RightMedium from '../../assets/images/right-medium-1.png';
import LeftMedium from '../../assets/images/left-medium-1.png';
import BackRed from '../../assets/images/back-red-1.png';
import FrontRed from '../../assets/images/front-red-1.png';
import RightRed from '../../assets/images/right-red-1.png';
import LeftRed from '../../assets/images/left-red-1.png';

function damageCar(positionDamage: string, typeDamage: string) {
  if ((positionDamage.includes("Frontal") ||
    positionDamage.includes("Cabeça") ||
    positionDamage.includes("Perna") ||
    positionDamage.includes("Pelvis")) && typeDamage === "Elevado") {
    return FrontRed;
  }
  if ((positionDamage.includes("Frontal") ||
    positionDamage.includes("Cabeça") ||
    positionDamage.includes("Perna") ||
    positionDamage.includes("Pelvis")) && typeDamage === "Médio") {
    return FrontMedium;
  }
  if ((positionDamage.includes("Frontal") ||
    positionDamage.includes("Cabeça") ||
    positionDamage.includes("Perna") ||
    positionDamage.includes("Pelvis")) && typeDamage === "Baixo") {
    return FrontLow;
  }
  if (positionDamage.includes("Traseiro") && typeDamage === "Elevado") {
    return BackRed;
  }
  if (positionDamage.includes("Traseiro") && typeDamage === "Médio") {
    return BackMedium;
  }
  if (positionDamage.includes("Traseiro") && typeDamage === "Baixo") {
    return BackLow;
  }
  if (positionDamage.includes("Lateral Esquerda") && typeDamage === "Elevado") {
    return LeftRed;
  }
  if (positionDamage.includes("Lateral Esquerda") && typeDamage === "Médio") {
    return LeftMedium;
  }
  if (positionDamage.includes("Lateral Esquerda") && typeDamage === "Baixo") {
    return LeftLow;
  }
  if (positionDamage.includes("Lateral Direita") && typeDamage === "Elevado") {
    return RightRed;
  }
  if (positionDamage.includes("Lateral Direita") && typeDamage === "Médio") {
    return RightMedium;
  }
  if (positionDamage.includes("Lateral Direita") && typeDamage === "Baixo") {
    return RightLow;
  }
}

const subTitleStyles = {
  fontFamily: "VWHead",
  fontSize: "12px",
  fontWeight: "bold",
  fontStretch: " normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  color: "#001e50",
}

const descStyles = {
  fontFamily: "VWHead",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: " normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  color: "#6a767d",
}

function stylePosition(positionDamage: string, qtPosition: number | undefined) {
  if ((positionDamage.includes("Frontal") ||
    positionDamage.includes("Cabeça") ||
    positionDamage.includes("Perna") ||
    positionDamage.includes("Pelvis"))) {
    return {
      width: "53px",
      height: "94px",
      margin: "0px 0px",
      top: "-85px",
      left: "115px",
      position: "absolute"
    } as React.CSSProperties;
  } else if (positionDamage.includes("Traseiro")) {
    return {
      width: "53px",
      height: "94px",
      margin: "0px 0px",
      top: "-85px",
      left: "-15px",
      position: "absolute"
    } as React.CSSProperties;
  } else if (positionDamage.includes("Lateral Esquerda")) {
    return {
      width: '113px',
      height: '39px',
      margin: '0px',
      top: "-90px",
      left: "17px",
      position: 'absolute',
    } as React.CSSProperties;
  } else if (positionDamage.includes("Lateral Direita")) {
    return {
      width: '113px',
      height: '39px',
      margin: '0px',
      top: "-28px",
      left: "17px",
      position: 'absolute',
    } as React.CSSProperties;
  }
}

function cardCarComponent(props: ParamsCardsGrid) {
  return (
    <Grid container sx={{
      width: "180px",
    }}>
      <Grid container sx={{ flexDirection: "column" }}>
        {props.isButtonTitle === true ?
          <Button
            sx={{
              height: "17px",
              fontFamily: "VWHead",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: " normal",
              fontStyle: "normal",
              lineHeight: "1.29",
              letterSpacing: "normal",
              color: "#001e50",
              padding: "0px",
              justifyContent: "start",
              textTransform: "none"
            }}
            variant="text"
            onClick={props.onNavigate}
          >{props.text.search('null') >= 0 ? '' : props.text}</Button> :
          props.text.search('null') >= 0 ? '' : <Grid item
            sx={{
              height: "17px",
              fontFamily: "VWHead",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: " normal",
              fontStyle: "normal",
              lineHeight: "1.29",
              letterSpacing: "normal",
              color: "#001e50"
            }}>
            {props.text ? props.text : ''}
          </Grid>
        }
        <Grid item >
          <img style={{ width: "154px", height: "68px", margin: "24px 0 0 0" }} src={Car} />
          {props.damagesPosition?.map((value: any) => {
            return <Box sx={{ position: 'relative' }}>
              <Button onClick={(event) => props.onClickDamagesPosition(event, value)}
                sx={{ padding: '0px', position: 'absolute', top: '0', left: '0', '&:hover': { backgroundColor: 'white' } }}
              >
                <img style={stylePosition(value.subCategory.name, props.damagesPosition?.length)}
                  src={damageCar(value.subCategory.name, value.typeDamage.name)} />
              </Button>
            </Box>
          })}
        </Grid>
        <Grid container
          sx={{
            flexDirection: "column",
            marginBottom: "8px"
          }}>
          <Grid item sx={subTitleStyles}>
            {props.titlePhase ? props.titlePhase : 'Fase'}
          </Grid>
          <Grid item sx={descStyles}>
            {props.textPhase ? props.textPhase : ''}
          </Grid>
        </Grid>
        <Grid container
          sx={{
            flexDirection: "column",
            marginBottom: "8px"
          }}>
          <Grid item sx={subTitleStyles}>
            {props.titleMotorization ? props.titleMotorization : 'Motorização/Câmbio'}
          </Grid>
          <Grid item sx={descStyles}>
            {props.textMotorization ? props.textMotorization : ''}
          </Grid>
        </Grid>
        <Grid container
          sx={{
            flexDirection: "column",
            marginBottom: "8px"
          }}>
          <Grid item sx={subTitleStyles}>
            {props.titlePosition ? props.titlePosition : 'Posição/Sequência'}
          </Grid>
          <Grid item sx={descStyles}>
            {props.textPosition ? props.textPosition : ''}
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default cardCarComponent;
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { StepLabel } from '@mui/material';

function StepPhaseComponent(props: ParamsStep) {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear alternativeLabel activeStep={props.activeStep}
        sx={{
          '.MuiStepConnector-line': {
            borderTopWidth: '10px',
            borderColor: '#001e50'
          }
        }}>
        {props.phases && props.phases.map((item: ListPhaseSchedule, index: number) => (
          <Step
            key={index}
            sx={{ paddingX: '0px', '.MuiStepConnector-root': { left: 'calc(-50% + 10px)' } }} >
            <StepButton
              color="inherit"
              icon={item.type}
              onClick={() => { 
                props.setActiveStep(index);
                props.onClick(item);
              }}
              sx={{
                padding: '24px 10px', margin: '-24px -16px',
                '.MuiStepLabel-iconContainer': {
                  padding: '0px'
                },
                '.MuiSvgIcon-root': {
                  width: '32px',
                  height: '32px',
                  color: 'white',
                  backgroundColor: '#001e50',
                  border: '1.5px solid #001e50',
                  borderRadius: '30px'
                },
                '.Mui-active': {
                  color: '#001e50 !important',
                  '.MuiStepIcon-text': {
                    fill: 'white',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'VWText',
                  }
                },
                '.MuiStepIcon-text': {
                  fill: '#001e50',
                  fontSize: '12px',
                  fontFamily: 'VWText',
                  fontWeight: 'normal',

                }
              }}>
              <StepLabel>{item.kw}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
export default StepPhaseComponent;
import { AccordionDetails, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionComponent from "../../components/accordion/accordionComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import LabelComponent from "../../components/label/labelComponent";
import SearchComponent from "../../components/search/searchComponent";
import useTestPlanTab4 from "../../hooks/testPlan/useTestPlanTab4";
import LoadingComponente from "../../components/loading/loadingComponente";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import { Box } from "@mui/system";
import StepPhaseComponent from "../../components/step/stepPhaseComponent";
import { useState } from "react";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { minutesToHours } from "../../utils/utils";
import NumberFormat from "react-number-format";
import ModalComponent from "../../components/modal/modalComponent";

function TestPlanPageTab4() {
    const navigate = useNavigate();
    const { type, id } = useParams();
    const { loading, loadingGrid,
        requestTestsComponent, messageAlert, isError, setIsError,
        requestTestsVehicle, totalsReviewCost,
        setTotalsReviewCost, fetchRequestTestComplexity,
        fetchTotalTestComplexity, fetchEngineeringCostReview,
        phaseName, dataStep, phaseStatus, firstYear, lastYear,
        categories, setSubCategory, subCategory, fetchCategoryList,
        choosedPhaseKw, setChoosedPhaseKw, choosedPhaseYear, setChoosedPhaseYear,
        typePhase, setTypePhase } = useTestPlanTab4({ type, id });
    const [activePhase, setActivePhase] = useState(0);
    const [editValues, setEditValues] = useState<any>({});
    const [filter, setFilter] = useState<string>("");
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const panelStyle = { backgroundColor: "transparent!important", boxShadow: "none" };
    const styleBox = {
        backgroundColor: '#dfe4e8',
        borderRadius: "4px",
        color: "#001e50",
        fontFamily: "VWHead",
        fontSize: "24px",
        fontStretch: "normal",
        fontStyle: "normal",
        fontWeight: "bold",
        letterSpacing: "normal",
        margin: "16px 0 24px",
        padding: "16px 16px"
    };
    const vehiclesComponentsColumns = (kind: string): GridColDef[] => [
        { field: "subCategory", headerName: "Subcategoria", flex: 0.1, minWidth: 100, },
        { field: "typeTest", headerName: "Nome do teste", flex: 0.2, minWidth: 100, },
        { field: "testNumber", headerName: "Nº PSE mãe", flex: 0.2, minWidth: 100, },
        { field: "repeatQtd", headerName: "Qtd de Repetições", flex: 0.15, minWidth: 100, },
        { field: "phase", headerName: "Fase do teste", flex: 0.15, minWidth: 100, },
        {
            field: "complexity", headerName: "Complexidade", flex: 0.15, minWidth: 100, renderCell: (value: GridRenderEditCellParams) =>
                <Box flex={1}>
                    <NumberFormat
                        style={{
                            backgroundColor: "transparent",
                            border: "hidden",
                            borderBottom: "1px solid black",
                            fontSize: "16px",
                            height: "40px",
                            paddingTop: "3px",
                            outline: "none",
                            width: "40px",
                            textAlign: "center"
                        }}
                        value={value.row.complexity}
                        displayType={"input"}
                        type="text"
                        decimalSeparator={","}
                        allowNegative={true}
                        onChange={(typedValue: any) => {
                            setEditValues({ "requestTestId": value.row.id, "complexity": Number(typedValue.target.value.replaceAll(',', '.')) });
                            kind == 'vehicles' && requestTestsVehicle.map((requestTestsVehicleRow: any) => {
                                requestTestsVehicleRow.requestTests.map((vehicleRequestTestsRow: any) => {
                                    if (vehicleRequestTestsRow.id == value.row.id && vehicleRequestTestsRow.testNumber == value.row.testNumber) {
                                        vehicleRequestTestsRow.complexity = typedValue.target.value.replaceAll('.', ',');
                                    }
                                })
                            });

                            kind == 'component' && requestTestsComponent.map((requestTestsComponentRow: any) => {
                                requestTestsComponentRow.requestTests.map((componentRequestTestsRow: any) => {
                                    if (componentRequestTestsRow.id == value.row.id && componentRequestTestsRow.testNumber == value.row.testNumber) {
                                        componentRequestTestsRow.complexity = typedValue.target.value.replaceAll('.', ',');
                                    }
                                })
                            });
                        }}
                        onBlur={() => { fetchRequestTestComplexity(editValues, dataStep[activePhase].type); }}
                    />
                    <LabelComponent text="%" color="#696f70" fontSize="18px" fontWeight="400" marginLeft="4px" />

                </Box>
        },
        { field: "supervision", headerName: "Supervisão Responsável", flex: 0.2, minWidth: 100 },
        {
            field: "total", headerName: "Total", flex: 0.1, minWidth: 100, renderCell: (value: any) =>
                <Box flex={1}>
                    {Number(value.row.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                </Box>
        },
        {
            field: "action", headerName: "Ação", flex: 0.1, minWidth: 250, renderCell: (value: any) =>
                <Box flex={1}>
                    <ButtonTextComponent text="Ver Detalhes" onClick={() => { window.open("/stapp/requisicao-teste/editar/" + value.row.id) }} />
                </Box>
        },
    ];

    const filterList = (event: any) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            fetchEngineeringCostReview(id ? id : '', typePhase, "", "", "");
        } else {
            setFilter(event.target.value);
            fetchEngineeringCostReview(id ? id : '', typePhase, filter1, filter2, event.target.value);
        }
    }

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    return (<Grid>
        {loading ? <LoadingComponente /> : null}
        <ModalComponent
            description={messageAlert}
            isOk={true}
            open={isError}
            close={() => { setIsError(false) }}
            onCancel={() => { setIsError(false) }}
            onNext={() => setIsError(false)}
        />
        <ModalFilterComponent
            open={openFilter}
            labelFilter1={"Categoria"}
            value1={filter1}
            filter1={categories}
            isFilter1={false}
            onSelect1={(event: any) => {
                setFilter1(event.target.value);
                if (event.target.value === "") {
                    setFilter2("");
                    setSubCategory([]);
                }
                else {
                    fetchCategoryList(event.target.value);
                }
            }}
            labelFilter2={"Sub-Categoria"}
            value2={filter2}
            filter2={subCategory}
            isFilter2={false}
            onSelect2={(event: any) => {
                setFilter2(event.target.value);
            }}
            close={handleCloseModalFilter}
            onCancel={() => { setOpenFilter(false) }}
            onNext={() => {
                fetchEngineeringCostReview(id ? id : '', typePhase, filter1, filter2);
                setOpenFilter(false)
            }}
        />

        <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
                <LabelComponent text="Dados " fontSize="18px" />
                <LabelComponent text="do Projeto" fontSize="18px" fontWeight="normal" />
            </Grid>
        </Grid>

        <Grid container sx={{ alignItems: 'stretch', backgroundColor: '#dfe4e8', borderRadius: '4px', marginY: '25px', paddingX: '20px', paddingY: '20px', }}>
            <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <LabelComponent text={phaseName} fontSize="20px" />
                <LabelComponent text={"Status: " + phaseStatus} fontSize="16px" fontWeight="normal" color="#001e50" />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={6} sx={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}> {firstYear} </Grid>
                {
                    dataStep?.length > 0 ?
                        <StepPhaseComponent
                            phases={dataStep}
                            activeStep={activePhase}
                            setActiveStep={setActivePhase}
                            onClick={(item: any) => {
                                setChoosedPhaseKw(item.id);
                                setChoosedPhaseYear(item.year);
                                setTypePhase(item.type);
                                fetchEngineeringCostReview(id ? id : '', item.type);
                            }}
                        />

                        :
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LabelComponent
                                text={'Lista de Fases não cadastrada'}
                                fontSize="16px"
                                fontWeight="normal"
                                color="#001e50"
                                display="flex"
                            />
                        </Grid>
                }
                <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}> {lastYear} </Grid>
            </Grid>
        </Grid>


        <Grid container sx={{ marginTop: "56px" }}>
            <Grid item xs={12} sm={12} md={5.9} lg={5.9} sx={{ minHeight: "342px" }}>
                {totalsReviewCost && <Grid container sx={{
                    backgroundColor: "#dfe4e8",
                    borderRadius: "8px",
                    height: "304px",
                    overflowX: "auto",
                    padding: "24px 24px 24px 0"
                }}>
                    <Grid container style={{
                        backgroundColor: '#dfe4e8',
                        borderRadius: "0 0 4px 4px",
                        color: "#001e50",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        letterSpacing: "normal",
                        textAlign: "center",
                    }}>
                        <Grid item xs={4}>Supervisão</Grid>
                        <Grid item xs={2}>Horista</Grid>
                        <Grid item xs={2}>Mensalista</Grid>
                        <Grid item xs={2}>Terceiro</Grid>
                        <Grid item xs={2}>Total Supervisão</Grid>
                    </Grid>

                    <Grid container style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {totalsReviewCost.supervisionsTotals && totalsReviewCost.supervisionsTotals.map((supervisionRow: any, index: number) =>
                            <DataTableEditComponent
                                key={index}
                                type="activity"
                                name={supervisionRow.supervisionInitials}
                                hour={minutesToHours(supervisionRow.hoursPart).toString()}
                                month={minutesToHours(supervisionRow.hoursMonth).toString()}
                                third={minutesToHours(supervisionRow.hoursThird).toString()}
                                totalRow={minutesToHours(supervisionRow.totalAll).toString()}
                                readOnlyHour={true}
                                readOnlyMonth={true}
                                readOnlyThird={true}
                            />
                        )}
                    </Grid>

                    <Grid container style={{ backgroundColor: '#dfe4e8', borderRadius: "0 0 4px 4px", fontFamily: "VWHead", alignSelf: "flex-end", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal" }}>
                        <Grid item xs={4} sx={{ color: "#001e50", textAlign: "center" }}>Total Cargo</Grid>
                        <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalsReviewCost.totalHoursPart)}h</Grid>
                        <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalsReviewCost.totalHoursMonth)}h</Grid>
                        <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalsReviewCost.totalHoursThird)}h</Grid>
                        <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalsReviewCost.totalHoursAll)}h</Grid>
                    </Grid>
                </Grid>}
            </Grid>

            <Grid item xs={0} sm={0.2} md={0.2} lg={0.2}>
            </Grid>

            <Grid item xs={12} sm={12} md={5.9} lg={5.9} sx={{ minHeight: "342px" }}>
                <Grid container sx={{ backgroundColor: "#dfe4e8", padding: "20px", borderRadius: "4px", marginBottom: "22px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <LabelComponent
                            text={"Exp. Material"}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#001e50"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "right" }}>
                        <LabelComponent
                            text={totalsReviewCost?.totalExMaterial ? Number(totalsReviewCost.totalExMaterial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#6a767d"}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: "#dfe4e8", padding: "20px", borderRadius: "4px", marginBottom: "22px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <LabelComponent
                            text={"Labor Base"}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#001e50"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "right" }}>
                        <LabelComponent
                            text={totalsReviewCost?.totalLB ? Number(totalsReviewCost.totalLB).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#6a767d"}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: "#dfe4e8", padding: "20px", borderRadius: "4px", marginBottom: "22px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <LabelComponent
                            text={"Purchase Service"}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#001e50"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "right" }}>
                        <LabelComponent
                            text={totalsReviewCost?.totalPS ? Number(totalsReviewCost.totalPS).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                            fontFamily={"VWHead"}
                            fontSize={"14px"}
                            fontStyle={"bold"}
                            color={"#6a767d"}
                        />

                    </Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: "#dfe4e8", padding: "20px", borderRadius: "4px", marginBottom: "22px" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <LabelComponent
                            text={"Total"}
                            fontFamily={"VWHead"}
                            fontSize={"18px"}
                            fontStyle={"bold"}
                            color={"#001e50"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ textAlign: "right" }}>
                        <LabelComponent
                            text={totalsReviewCost?.totalAll ? Number(totalsReviewCost.totalAll).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                            fontFamily={"VWHead"}
                            fontSize={"18px"}
                            fontStyle={"bold"}
                            color={"#6a767d"}
                        />

                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                <SearchComponent text="" value={filter} placeholder="Buscar por …"
                    onChange={filterList}
                    onClick={() => {
                        setOpenFilter(true)
                    }}
                    onCancel={() => {
                        setFilter("");
                        fetchEngineeringCostReview(id ? id : '', typePhase, filter1, filter2, "");
                    }}
                />
            </Grid>
        </Grid>

        <Accordion sx={panelStyle}>
            <AccordionComponent text="Plano de Teste de Veículos"></AccordionComponent>
            <AccordionDetails>
                {requestTestsVehicle && requestTestsVehicle.map((requestTestsVehicleItem: any, index: number) =>
                    <Accordion sx={panelStyle} key={index}>
                        <AccordionComponent text={requestTestsVehicleItem.categoryName} type="clean"></AccordionComponent>
                        <AccordionDetails>
                            <DataTableGridComponent
                                rows={requestTestsVehicleItem.requestTests}
                                columns={vehiclesComponentsColumns('vehicles')}
                                loading={loadingGrid}
                            />
                        </AccordionDetails>
                    </Accordion>
                )}
            </AccordionDetails>
        </Accordion>

        <Accordion sx={panelStyle}>
            <AccordionComponent text="Plano de Teste de Componentes"></AccordionComponent>
            <AccordionDetails>
                {requestTestsComponent && requestTestsComponent.map((requestTestsComponentItem: any, index: number) =>
                    <Accordion sx={panelStyle} key={index}>
                        <AccordionComponent text={requestTestsComponentItem.categoryName} type="clean"></AccordionComponent>
                        <AccordionDetails>
                            <DataTableGridComponent
                                rows={requestTestsComponentItem.requestTests}
                                columns={vehiclesComponentsColumns('component')}
                                loading={loading}
                            />
                        </AccordionDetails>
                    </Accordion>
                )}
            </AccordionDetails>
        </Accordion>

        <Accordion sx={panelStyle}>
            <AccordionComponent text="Custos Administrativos e Externos"></AccordionComponent>
            <AccordionDetails>
                <Grid>
                    <LabelComponent text="Prognose" fontSize="18px" margin="0 4px 0 0" />
                    <LabelComponent text="Administrativa" fontSize="18px" fontWeight="light" />

                    <Grid container sx={styleBox}>
                        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Custo total" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progAdmin?.total ? Number(totalsReviewCost?.progAdmin?.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Ajuste de complexidade" fontSize="18px" />

                            <Grid sx={{ display: "block", marginTop: "15px" }}>
                                <TextFieldComponent
                                    width={"100px"}
                                    marginRight={"0"}
                                    maxLength={10}
                                    text=""
                                    type="number"
                                    value={totalsReviewCost?.progAdmin?.complexity}
                                    onChange={(event: any) => setTotalsReviewCost({ ...totalsReviewCost, progAdmin: { ...totalsReviewCost.progAdmin, complexity: event.target.value } })}
                                    onBlur={() => fetchTotalTestComplexity()}
                                />
                                <LabelComponent text="%" color="#696f70" fontSize="18px" fontWeight="400" marginLeft="4px" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Total Final" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progAdmin?.totalFinal ? Number(totalsReviewCost?.progAdmin?.totalFinal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    <LabelComponent text="Prognose" fontSize="18px" margin="0 4px 0 0" />
                    <LabelComponent text="de Viagens" fontSize="18px" fontWeight="light" />

                    <Grid container sx={styleBox}>
                        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Custo total" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progTravel?.total ? Number(totalsReviewCost?.progTravel?.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Ajuste de complexidade" fontSize="18px" />

                            <Grid sx={{ display: "block", marginTop: "15px" }}>
                                <TextFieldComponent
                                    width={"100px"}
                                    marginRight={"0"}
                                    maxLength={10}
                                    text=""
                                    type="number"
                                    value={totalsReviewCost?.progTravel?.complexity}
                                    onChange={(event: any) => setTotalsReviewCost({ ...totalsReviewCost, progTravel: { ...totalsReviewCost.progTravel, complexity: event.target.value } })}
                                    onBlur={() => fetchTotalTestComplexity()}
                                />
                                <LabelComponent text="%" color="#696f70" fontSize="18px" fontWeight="400" marginLeft="4px" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Total Final" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progTravel?.totalFinal ? Number(totalsReviewCost?.progTravel?.totalFinal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    <LabelComponent text="Prognose" fontSize="18px" margin="0 4px 0 0" />
                    <LabelComponent text="de Testes Externos" fontSize="18px" fontWeight="light" />

                    <Grid container sx={styleBox}>
                        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Custo total" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progTestExternal?.total ? Number(totalsReviewCost?.progTestExternal?.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Ajuste de complexidade" fontSize="18px" />

                            <Grid sx={{ display: "block", marginTop: "15px" }}>
                                <TextFieldComponent
                                    width={"100px"}
                                    marginRight={"0"}
                                    maxLength={10}
                                    text=""
                                    type="number"
                                    value={totalsReviewCost?.progTestExternal?.complexity}
                                    onChange={(event: any) => setTotalsReviewCost({ ...totalsReviewCost, progTestExternal: { ...totalsReviewCost.progTestExternal, complexity: event.target.value } })}
                                    onBlur={() => fetchTotalTestComplexity()}
                                />
                                <LabelComponent text="%" color="#696f70" fontSize="18px" fontWeight="400" marginLeft="4px" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <LabelComponent text="Total Final" fontSize="18px" />
                            <LabelComponent text={totalsReviewCost?.progTestExternal?.totalFinal ? Number(totalsReviewCost?.progTestExternal?.totalFinal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''} fontSize="18px" color="#696f70" fontWeight="400" />
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </Grid>
    );
}

export default TestPlanPageTab4;
import { useEffect, useState } from "react";
import { post } from "../../api/api";
import { sendAuthorizationRequest, sendTokenRequest } from "../../services/login";

const useLogin = (code: any) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (code !== null) {
            fetchLogin(code);
        } else {
            sendAuthorizationRequest();
        }
    }, []);

    const fetchLogin = (code: any) => {
        sendTokenRequest(code).then(response => {
            console.log("3-TOKEN REQUEST SUCCESS", response);
            localStorage.setItem("access_token", response.access_token);
            localStorage.setItem("refresh_token",response.refresh_token);
            window.location.href = window.location.origin + '/stapp/home';
        }).catch((error => {
            console.log("TOKEN REQUEST ERROR", error);
            sendAuthorizationRequest();
        })).finally(() => setIsLoggedIn(false));
    }

    return {};
}

export default useLogin;
import React, { useState } from 'react';
import { Grid } from "@mui/material";
import TestPlanPageTab1 from "./testPlanPageTab1";
import HeaderComponent from "../../components/header/headerComponent";
import TestPlanPageTab2 from "./testPlanPageTab2";
import TestPlanPageTab3 from "./testPlanPageTab3";
import TestPlanPageTab4 from "./testPlanPageTab4";
import TestPlanPageTab5 from "./testPlanPageTab5";
import TabsComponent from '../../components/tabs/tabsComponent';
import TabPanelComponent from '../../components/tabs/tabPanelComponent';
import SnackbarComponent from '../../components/snackbar/snackbarComponent';
import ModalComponent from '../../components/modal/modalComponent';
import LabelComponent from '../../components/label/labelComponent';
import { useParams } from "react-router-dom";

function TestPlanPage() {
    const { type, id } = useParams();
    const [value, setValue] = useState(0);
    const [testPlanDisplayAlert, setTestPlanDisplayAlert] = useState<boolean>(false);
    const [testPlanMessageAlert, setTestPlanMessageAlert] = useState<string>("");
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [messageCancelModal, setMessageCancelModal] = useState<string>("Deseja realmente cancelar? Ao cancelar todas as alterações serão perdidas.");
    const [changedTab, setChangedTab] = useState<boolean>(false);
    const [tabNumber, setTabNumber] = useState<number>(0);

    let tabs = [
        "Dados do projeto",
        "Plano de teste de veículos",
        "Plano de teste de componentes",
        "Revisão de engenharia e custo",
        "Resumo do plano de teste"
    ];

    const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
        window.location.reload();
    };

    const testPlanChangeMessage = (message: string) => {
        setTestPlanMessageAlert(message);
        setTestPlanDisplayAlert(true);
    };

    const handleCloseCancelModal = (e: any) => {
        setOpenCancelModal(false);
    };

    const changeTab = (tabValue: number) => {
        setMessageCancelModal("Avançar para a próxima etapa significa perder todas as suas alterações não salvas, deseja continuar ?");
        setOpenCancelModal(true);
        setChangedTab(true);
        setTabNumber(tabValue);
    };

    return (
        <Grid container>
            <HeaderComponent text="Plano de Teste" access={true}/>
            <SnackbarComponent text={testPlanMessageAlert} open={testPlanDisplayAlert} closeSnackbar={handleCloseAlert} />
            <ModalComponent
                open={openCancelModal}
                description={messageCancelModal}
                close={handleCloseCancelModal}
                onCancel={() => setOpenCancelModal(false)}
                onNext={() => {
                    if (changedTab) {
                        setOpenCancelModal(false);
                        setValue(tabNumber);
                    } else {
                        window.location.reload()
                    }
                }}
            />
            <Grid container sx={{ padding: "48px 48px 16px 48px" }}>
                <Grid item sx={{ marginBottom: "32px" }}>
                    <LabelComponent text={type === "novo" ? "Criar " : "Editar "} fontSize="28px" />
                    <LabelComponent text="Plano de Teste" fontSize="28px" fontWeight="normal" />
                </Grid>

                <TabsComponent disabled={id ? false : true} setvalue={setValue} tabs={tabs} value={value} changeTab={changeTab}></TabsComponent>
                <TabPanelComponent value={value} index={0}>
                    <TestPlanPageTab1 testPlanChangeMessage={testPlanChangeMessage} setOpenCancelModal={setOpenCancelModal}></TestPlanPageTab1>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={1}>
                    <TestPlanPageTab2></TestPlanPageTab2>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={2}>
                    <TestPlanPageTab3></TestPlanPageTab3>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={3}>
                    <TestPlanPageTab4></TestPlanPageTab4>
                </TabPanelComponent>
                <TabPanelComponent value={value} index={4}>
                    <TestPlanPageTab5></TestPlanPageTab5>
                </TabPanelComponent>
            </Grid>
        </Grid>
    )
}

export default TestPlanPage;
import { Grid } from "@mui/material";
import React, { useState } from "react";
import LabelComponent from "../../components/label/labelComponent";
import SelectComponent from "../../components/select/selectComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import useRequestTestTab4 from "../../hooks/requestTest/useRequestTestTab4";
import ModalComponent from "../../components/modal/modalComponent";
import { useParams } from "react-router-dom";
import DraggableRequestTestComponent from "../../components/draggable/draggable";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalReadPage from "../../components/modal/modalReadPage";

function RequestTestPageTab4(props: any) {

    const { type, id } = useParams();
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const {
        loading, read, setRead,
        categoryResources, setCategoryResources,
        resources, setResources,
        resourcesSupervisions, setResourcesSupervisions,
        listResourcesConsumable, setListResourcesConsumable,
        listResourcesInventory, setListResourcesInventory,
        categoryResourcesId, setCategoryResourcesId,
        resourcesId, setResourcesId,
        valueTotal, valueByQuantity,
        openAlert, setOpenAlert,
        calc, messageAlert, isError, setIsError,
        fetchCategoryResources, fetchResourcesActive, fetchResourcesSubmit,
        fetchRequestTestsResource, fetchResource
    } = useRequestTestTab4({ id });

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenAlert(false)
    };

    return (<Grid container>
        <SnackbarComponent isTab={true} text={"Requisição de teste salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent text=""
            open={openModal}
            description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
            close={handleCloseModal}
            onCancel={() => { setOpenModal(false) }}
            onNext={() => {
                setOpenModal(false);
                setIsAdd(false);
                setCategoryResourcesId("");
                setResourcesId("");
                fetchRequestTestsResource(id);
            }} />
        {loading ? <LoadingComponente /> : null}
        <ModalComponent
            description={messageAlert}
            isOk={true}
            open={isError}
            close={() => { setIsError(false) }}
            onCancel={() => { setIsError(false) }}
            onNext={() => setIsError(false)}
        />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container xs={12} sm={12} md={12} lg={5.9}>
            <Grid container>
                <Grid item>
                    <LabelComponent text="Lista de " fontSize="24px" />
                    <LabelComponent text="Recursos" fontSize="24px" fontWeight="normal" />
                </Grid>
            </Grid>
            <DraggableRequestTestComponent
                listResourcesConsumable={listResourcesConsumable}
                listResourcesInventory={listResourcesInventory}
                listActivities={[]}
                isResources={true}
                isActivities={false}
                onChangeListResourcesConsumable={(value) => {
                    setListResourcesConsumable(value);
                }}
                onChangeListResourcesInventory={(value) => {
                    setListResourcesInventory(value);
                }}
                onChangeListActivities={(value) => { }}
                onClickDelete={(value, type) => {
                    let resourcesSupervisionsAux: any[] = []
                    resourcesSupervisions.map((res: any, index: number) => {
                        value.resourcesSupervisions.map((resValue: any) => {
                            if (resValue.supervisionInitials === res.supervisionInitials) {
                                res.value = (res.value - resValue.value)
                                res.valueByQuantity = (res.valueByQuantity - resValue.valueByQuantity)
                            }
                        });
                    });
                    resourcesSupervisions.map((res: any) => {
                        if (Number(res.value) !== 0) {
                            resourcesSupervisionsAux.push(res)
                        }
                    });
                    setResourcesSupervisions(resourcesSupervisionsAux);
                    setResourcesSupervisions([...resourcesSupervisionsAux]);
                    calc(value.resourcesSupervisions, "sub");
                    if (type === "consumable") {
                        var index = listResourcesConsumable.indexOf(value);
                        if (index > -1) {
                            if (listResourcesConsumable[index].quantity > 1) {
                                listResourcesConsumable[index].quantity--;
                            } else {
                                listResourcesConsumable.splice(index, 1);
                            }
                        }
                        setListResourcesConsumable(listResourcesConsumable);
                        setListResourcesConsumable([...listResourcesConsumable]);
                    } else if (type === "inventory") {
                        var index = listResourcesInventory.indexOf(value);
                        if (index > -1) {
                            if (listResourcesInventory[index].quantity > 1) {
                                listResourcesInventory[index].quantity--;
                            } else {
                                listResourcesInventory.splice(index, 1);
                            }
                        }
                        setListResourcesInventory(listResourcesInventory);
                        setListResourcesInventory([...listResourcesInventory]);
                    }
                }}
            />
        </Grid>
        <Grid container xs={0} sm={0} md={0} lg={0.2}></Grid>
        <Grid container xs={12} sm={12} md={12} lg={5.9}>
            <Grid container>
                <Grid item>
                    <LabelComponent text="Valores dos " fontSize="24px" />
                    <LabelComponent text="Recursos" fontSize="24px" fontWeight="normal" />
                </Grid>
            </Grid>
            <Grid container sx={{
                height: "264px",
                marginTop: "24px",
                borderRadius: "4px",
                backgroundColor: "#dfe4e8",
                display: "block"
            }}>
                <Grid container sx={{
                    marginTop: "24px",
                    marginBottom: "16px"
                }}>
                    <Grid item xs={7} sx={{
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.5",
                        letterSpacing: "normal",
                        color: "#001e50",
                        textAlign: "center"
                    }}>
                        Supervisão
                    </Grid>
                    <Grid item xs={5} sx={{
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.5",
                        letterSpacing: "normal",
                        color: "#001e50",
                        textAlign: "center"
                    }}>
                        Valor
                    </Grid>
                </Grid>

                <Grid container sx={{
                    overflowX: "auto",
                }}>
                    {resourcesSupervisions.map((value: any) => {
                        return <Grid container>
                            <Grid item xs={7} sx={{
                                fontFamily: "VWHead",
                                fontSize: "16px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                color: "#6a767d",
                                textAlign: "center",
                                marginBottom: "8px"
                            }}>
                                {value.supervisionInitials}
                            </Grid>
                            <Grid item xs={5} sx={{
                                fontFamily: "VWHead",
                                fontSize: "16px",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                color: "#000000",
                                textAlign: "center",
                                marginBottom: "8px"
                            }}>
                                R$ {Number(value.valueByQuantity).toFixed(2)}
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </Grid>
            <Grid container
                xs={12}
                sx={{
                    height: "57px",
                    marginTop: "8px",
                    borderRadius: "4px",
                    backgroundColor: "#dfe4e8"
                }}>
                <Grid item xs={9}
                    sx={{
                        alignSelf: "center",
                        textAlign: "left"
                    }}>
                    <LabelComponent
                        text={"Valor Total"}
                        color={"#001e50"}
                        fontSize={"14px"}
                        fontFamily={"VWHead"}
                        fontStretch={"normal"}
                        fontStyle={"normal"}
                        lineHeight={"1.71"}
                        letterSpacing={"normal"}
                        marginLeft={"32px !important"}
                    />
                </Grid>
                <Grid item xs={3}
                    sx={{
                        alignSelf: "center",
                        textAlign: "right"
                    }}>
                    <LabelComponent
                        text={"R$ " + valueTotal.toFixed(2)}
                        color={"#6a767d"}
                        fontSize={"14px"}
                        fontFamily={"VWHead"}
                        fontStretch={"normal"}
                        fontStyle={"normal"}
                        lineHeight={"1.71"}
                        letterSpacing={"normal"}
                        marginRight={"32px !important"}
                    />
                </Grid>
            </Grid>
            <Grid container
                xs={12}
                sx={{
                    height: "57px",
                    marginTop: "8px",
                    borderRadius: "4px",
                    backgroundColor: "#dfe4e8"
                }}>
                <Grid item xs={9}
                    sx={{
                        alignSelf: "center",
                        textAlign: "left"
                    }}>
                    <LabelComponent
                        text={"Valor por teste"}
                        color={"#001e50"}
                        fontSize={"14px"}
                        fontFamily={"VWHead"}
                        fontStretch={"normal"}
                        fontStyle={"normal"}
                        lineHeight={"1.71"}
                        letterSpacing={"normal"}
                        marginLeft={"32px !important"}
                    />
                </Grid>
                <Grid item xs={3}
                    sx={{
                        alignSelf: "center",
                        textAlign: "right"
                    }}>
                    <LabelComponent
                        text={"R$ " + valueByQuantity.toFixed(2)}
                        color={"#6a767d"}
                        fontSize={"14px"}
                        fontFamily={"VWHead"}
                        fontStretch={"normal"}
                        fontStyle={"normal"}
                        lineHeight={"1.71"}
                        letterSpacing={"normal"}
                        marginRight={"32px !important"}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={5.9}>
            <Grid item sx={{
                marginTop: "24px"
            }}>
                <ButtonComponent
                    width={"160px"}
                    text={"Adicionar novo"}
                    border={"1px dashed #c2cacf"}
                    backgroundColor={"#fff"}
                    borderRadius={"4px;"}
                    color={"#00b0f0"}
                    isAdd={true}
                    onClick={() => {
                        setIsAdd(!isAdd);
                        fetchCategoryResources();
                    }}
                />
            </Grid>
        </Grid>
        <Grid container xs={12}>
            {isAdd ?
                <Grid container xs={12} sm={12} md={12} lg={5.9}
                    sx={{ marginTop: "24px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <SelectComponent
                            values={categoryResources}
                            text="Categoria"
                            value={categoryResourcesId}
                            required={true}
                            onSelect={(event: any) => {
                                setMessageSave("")
                                setCategoryResourcesId(event.target.value);
                                if (event.target.value !== "") {
                                    fetchResourcesActive(event.target.value);
                                } else {
                                    setResourcesId("");
                                    setResources([]);
                                }
                            }} />
                    </Grid>
                    <Grid container xs={0} sm={0} md={0} lg={0.2}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <SelectComponent
                            helperText={messageSave !== "" ? messageSave : ""}
                            error={messageSave !== "" ? true : false}
                            required={true}
                            values={resources}
                            text="Nome do recurso"
                            value={resourcesId}
                            onSelect={(event: any) => {
                                setResourcesId(event.target.value);
                            }} />
                    </Grid>
                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item xs={5} sm={4} md={6} lg={3} sx={{ marginTop: "40px" }}>
                            <ButtonComponent
                                text="Cancelar"
                                disable={true}
                                color="#00b0f0"
                                borderColor="#00b0f0"
                                border="1px solid #00b0f0"
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={5} sm={4} md={6} lg={3} sx={{ marginTop: "40px" }} >
                            <ButtonComponent
                                text="Incluir"
                                border="1px solid #00b0f040"
                                backgroundColor={(categoryResourcesId !== "" && resourcesId !== "") ? "#00b0f0" : "#00b0f040"}
                                active={true}
                                onClick={() => {
                                    if (resourcesId !== "" && categoryResourcesId !== "") {
                                        fetchResource(resourcesId);
                                        setCategoryResources([]);
                                        setCategoryResourcesId("");
                                        setResources([]);
                                        setResourcesId("");
                                        setMessageSave("");
                                        setIsAdd(!isAdd);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                : null}
        </Grid>
        <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
                <ButtonComponent
                    text="Cancelar"
                    disable={true}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
                <ButtonComponent
                    text="Salvar"
                    active={true}
                    onClick={() => {
                        let resources: any[] = [];
                        if (listResourcesConsumable.length > 0) {
                            listResourcesConsumable.map((value) => {
                                resources.push({
                                    "order": value.order,
                                    "resourceId": value.id,
                                    "quantity": value.quantity
                                })
                            });
                        }
                        if (listResourcesInventory.length > 0) {
                            listResourcesInventory.map((value) => {
                                resources.push({
                                    "order": value.order,
                                    "resourceId": value.id,
                                    "quantity": value.quantity
                                })
                            });
                        }
                        fetchResourcesSubmit({
                            "requestTestId": id,
                            "resources": resources
                        });
                    }}
                />
            </Grid>
        </Grid>
    </Grid >);
}

export default RequestTestPageTab4;
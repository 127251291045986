import { Grid } from "@mui/material";
import NumberFormat from "react-number-format";
import LabelComponent from "../label/labelComponent";

function DataTableEditComponent(props: DataTableGridField) {

    return (props.type === "activity" ?
        <Grid container sx={{
            "&.MuiGrid-root": {
                alignItems: "baseline",
                height: "60px"
            },
        }}>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
                <LabelComponent color={"#6a767d"} fontSize={"16px"} text={props.name} />
            </Grid>
            <Grid item xs={2}>
                <div style={props.readOnlyHour ?
                    { textAlign: "center" } :
                    { textAlign: "center", backgroundColor: "#fff", border: "solid 0.5px #dfe4e8", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "90px" }}
                >
                    <NumberFormat
                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "40px" }}
                        allowNegative={false}
                        displayType={props.readOnlyHour ? "text" : "input"}
                        format={"##:##"}
                        onChange={props.onChangeHour}
                        onBlur={props.onBlurHour}
                        value={props.hour}
                        readOnly={props.readOnlyHour ? props.readOnlyHour : false}
                    />h
                </div>
            </Grid>
            <Grid item xs={2}>
                <div style={props.readOnlyMonth ?
                    { textAlign: "center" } :
                    { textAlign: "center", backgroundColor: "#fff", border: "solid 0.5px #dfe4e8", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "90px" }}
                >
                    <NumberFormat
                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "40px" }}
                        allowNegative={false}
                        displayType={props.readOnlyMonth ? "text" : "input"}
                        format={"##:##"}
                        onChange={props.onChangeMonth}
                        onBlur={props.onBlurMonth}
                        value={props.month}
                        readOnly={props.readOnlyMonth ? props.readOnlyMonth : false}
                    />h
                </div>
            </Grid>
            <Grid item xs={2}>
                <div style={props.readOnlyThird ?
                    { textAlign: "center" } :
                    { textAlign: "center", backgroundColor: "#fff", border: "solid 0.5px #dfe4e8", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "90px" }}
                >
                    <NumberFormat
                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "40px" }}
                        allowNegative={false}
                        displayType={props.readOnlyThird ? "text" : "input"}
                        format={"##:##"}
                        onChange={props.onChangeThird}
                        onBlur={props.onBlurThird}
                        value={props.third}
                        readOnly={props.readOnlyThird ? props.readOnlyThird : false}
                    />h
                </div>
            </Grid>
            <Grid item xs={2} sx={{ paddingTop: "8px", textAlign: "center" }}>
                <LabelComponent color={"#6a767d"} fontSize={"16px"} text={props.totalRow + "h"} />
            </Grid>
        </Grid >
        :
        <Grid container sx={{
            "&.MuiGrid-root": {
                alignItems: "baseline",
                height: "60px"
            },
        }}>
            <Grid item xs={6} sm={6} md={6} lg={7}
                sx={{
                    fontFamily: "VWHead",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.5",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#6a767d"
                }}
            >
                {props.name}
            </Grid>
            <Grid item xs={4} sm={6} md={6} lg={5}>
                <div style={{ textAlign: "center", backgroundColor: "#fff", border: "solid 0.5px #dfe4e8", borderRadius: "8px", boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)", width: "170px" }}>
                    R$
                    <NumberFormat
                        style={{ border: "none", fontSize: "15px", height: "40px", paddingTop: "3px", outline: "none", width: "130px" }}
                        decimalSeparator={","}
                        allowNegative={false}
                        displayType="input"
                        type="text"
                        maxLength={10}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        value={props.value}
                        readOnly={props.readOnly ? props.readOnly : false}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default DataTableEditComponent;
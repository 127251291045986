type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { deleteR, get, post, put } from "../../api/api";

const useRequestTestTab5 = (state: Type) => {
    const [selectedPart, setSelectedPart] = useState<any>();
    const [selectedParts, setSelectedParts] = useState<Part[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [messageSave, setMessageSave] = useState<string>("");
    const [openAlert, setOpenAlert] = useState(false);
    const [codeStatus, setCodeStatus] = useState<number | null>(null);
    const [allSelectsList, setAllSelectsList] = useState<PartSelects>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [part, setPart] = useState<Part>();
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [read, setRead] = useState<boolean>(false);

    useEffect(() => {
        fetchList(state.id);
        getPartsSelectsList();
    }, []);

    function getPartsSelectsList() {
        get('requestTests/parts/data').then((values) => {
            setAllSelectsList(values);
        });
    }

    const fetchList = (id: any) => {
        setLoading(true);
        setSelectedParts([]);
        get('requestTests/parts/' + id).then((value) => {
            setSelectedParts(value);
            setLoading(false);
        });
    };

    const fetchActiveOrDesactive = (id: number | null) => {
        deleteR('requestTests/parts/' + id).then((value) => {
            fetchList(state.id);
            setCodeStatus(value.codeStatus);
        });
    }

    const fetchSubmit = (body: any) => {
        if (isEdit) {
            body.partId = body.id;
            delete body.id;
        }
        post<Part[]>("requestTests/parts", body).then((value: any) => {
            if (value.codeStatus === 200 || value.codeStatus === 201) {
                setOpenAlert(true);
                setPart({});
                setIsAdd(false);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setSelectedPart(value);
                setMessageSave(value.errorMessage);
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        });
    };

    const fetchCleanMessageError = () => {
        setMessageSave("");
    }

    return {
        isEdit, setIsEdit,
        isAdd, setIsAdd,
        part, setPart,
        selectedPart,
        selectedParts,
        totalElements,
        loading, read, setRead,
        messageSave, setMessageSave,
        openAlert,
        setOpenAlert,
        allSelectsList,
        messageAlert,
        isError, setIsError,
        fetchList,
        fetchActiveOrDesactive,
        fetchSubmit,
    };
}

export default useRequestTestTab5;
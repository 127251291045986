import { AddBoxTwoTone } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import ButtonComponent from "../../../components/button/buttonComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1ExternalTests(props: any) {
    const { loading, displayAlert,
        isError, setIsError,
        messageAlert, externalTestsById,
        setExternalTestsById, externalTestsTotals,
        setExternalTestsTotals, fetchSaveExternalTests,
        read, setRead, access,
        testPlanTotals } = useTestPlanTab1(props.currentModal, props.type, props.id);

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    let inputBoxStyle = {
        align: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        border: "solid 0.5px #dfe4e8",
        borderRadius: "8px",
        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
        display: "flex",
        fontFamily: 'VWHead',
        fontSize: "16px",
        fontWeight: "light",
        justifyContent: "center",
        width: "calc(100% - 20px)",
    }

    const calculateTotalRow = (row: ExternalTestItem): number => {
        let numberTryCost =
            row.tryCost ?
                row.tryCost.toString().search(',') >= 0 ?
                    Number(row.tryCost.toString().replace(',', '.'))
                    : row.tryCost
                : 0
            ;

        let numberFreightCost =
            row.freightCost ?
                row.freightCost.toString().search(',') >= 0 ?
                    Number(row.freightCost.toString().replace(',', '.'))
                    : row.freightCost
                : 0
            ;

        let numberOtherCost =
            row.otherCost ?
                row.otherCost.toString().search(',') >= 0 ?
                    Number(row.otherCost.toString().replace(',', '.'))
                    : row.otherCost
                : 0
            ;

        let item = {
            quantityCost: row.quantityCost > 0 ? row.quantityCost : 0,
            tryCost: numberTryCost,
            quantityFreight: row.quantityFreight > 0 ? row.quantityFreight : 0,
            freightCost: numberFreightCost,
            otherCost: numberOtherCost
        }

        return item.quantityCost * item.tryCost + item.freightCost * item.quantityFreight + item.otherCost * 1;
    };

    const totalsCalculation = (row: ExternalTestItem[]) => {
        let externalTestsTotalTry: number = 0;
        let externalTestsTotalFreight: number = 0;
        let externalTestsTotalOtherCosts: number = 0;
        let externalTestsTotalSum: number = 0;
        row.map((item: ExternalTestItem) => {
            let number = item.tryCost ? item.tryCost.toString().search(',') >= 0 ? Number(item.tryCost.toString().replace(',', '.')) : item.tryCost : item.tryCost;
            externalTestsTotalTry = externalTestsTotalTry + item.quantityCost * number;
        });
        row.map((item: ExternalTestItem) => {
            let number = item.freightCost ? item.freightCost.toString().search(',') >= 0 ? Number(item.freightCost.toString().replace(',', '.')) : item.freightCost : item.freightCost;
            externalTestsTotalFreight = externalTestsTotalFreight + item.quantityFreight * number;
        });
        row.map((item: ExternalTestItem) => {
            let number = item.otherCost ? item.otherCost.toString().search(',') >= 0 ? Number(item.otherCost.toString().replace(',', '.')) : item.otherCost : item.otherCost;
            externalTestsTotalOtherCosts = externalTestsTotalOtherCosts + number * 1;
        });
        row.map((item: ExternalTestItem) => {
            let number = item.total ? item.total.toString().search(',') >= 0 ? Number(item.total.toString().replace(',', '.')) : item.total : item.total;
            externalTestsTotalSum = externalTestsTotalSum + number * 1;
        });
        return {
            externalTestsTotalTry: externalTestsTotalTry,
            externalTestsTotalFreight: externalTestsTotalFreight,
            externalTestsTotalOtherCosts: externalTestsTotalOtherCosts,
            externalTestsTotalSum: externalTestsTotalSum
        }
    };

    return (
        access ?
            <Grid container>
                {loading && <LoadingComponente></LoadingComponente>}

                <ModalComponent
                    description={messageAlert}
                    isOk={true}
                    open={isError}
                    close={() => { setIsError(false) }}
                    onCancel={() => { setIsError(false) }}
                    onNext={() => setIsError(false)}
                />
                <ModalReadPage open={read} onCancel={() => setRead(false)} />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelComponent fontSize={"20px"} text="Tabela de testes externos" />
                </Grid>

                {externalTestsById && externalTestsTotals && <Grid container>
                    <Grid container sx={{
                        backgroundColor: '#dfe4e8',
                        borderRadius: "5px",
                        color: "#001e50",
                        fontFamily: "VWHead",
                        fontSize: "14px",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        letterSpacing: "normal",
                        marginTop: "16px",
                        padding: "20px",
                        textAlign: "center",
                    }}>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}> Quantidade </Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3}> Custo do ensaio </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}> Quantidade </Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3}> Custo do frete </Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3}> Outros custos </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}> Descrição </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}> Custo total </Grid>
                        </Grid>

                        {externalTestsById.map((item: ExternalTestItem, index: number) =>
                            <Grid container sx={{ marginTop: "24px" }} key={index}>
                                <Grid item xs={12} sm={12} md={1} lg={1} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                    <div style={inputBoxStyle}>
                                        <NumberFormat
                                            style={{
                                                textAlign: "center",
                                                alignItems: "center",
                                                border: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                height: "40px",
                                                justifyContent: "center",
                                                outline: "none",
                                                paddingTop: "3px",
                                                width: "calc(100% - 60px)"
                                            }}
                                            displayType={"input"}
                                            type="text"
                                            allowNegative={false}
                                            value={item.quantityCost}
                                            onChange={(event: any) => {
                                                let newArr = [...externalTestsById];
                                                newArr[index] = { ...newArr[index], quantityCost: event.target.value === '' ? 0 : event.target.value };
                                                newArr[index] = { ...newArr[index], total: calculateTotalRow(newArr[index]) };
                                                setExternalTestsById([]);
                                                setExternalTestsById(newArr);
                                                setExternalTestsTotals(totalsCalculation(newArr));
                                            }}
                                        ></NumberFormat>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                    <div style={inputBoxStyle}>
                                        <span style={{ fontWeight: "500", fontFamily: 'VWHead' }}>R$</span>
                                        <NumberFormat
                                            style={{
                                                textAlign: "center",
                                                alignItems: "center",
                                                border: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                height: "40px",
                                                justifyContent: "center",
                                                outline: "none",
                                                paddingTop: "3px",
                                                width: "calc(100% - 60px)"
                                            }}
                                            displayType={"input"}
                                            type="text"
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            allowNegative={false}
                                            value={Number(item.tryCost.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            onChange={(event: any) => {
                                                let newArr = [...externalTestsById];
                                                newArr[index] = { ...newArr[index], tryCost: event.target.value };
                                                newArr[index] = { ...newArr[index], total: calculateTotalRow(newArr[index]) };
                                                setExternalTestsById([]);
                                                setExternalTestsById(newArr);
                                                setExternalTestsTotals(totalsCalculation(newArr));
                                            }}
                                        ></NumberFormat>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={1} lg={1} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                    <div style={inputBoxStyle}>
                                        <NumberFormat
                                            style={{
                                                textAlign: "center",
                                                alignItems: "center",
                                                border: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                height: "40px",
                                                justifyContent: "center",
                                                outline: "none",
                                                paddingTop: "3px",
                                                width: "calc(100% - 60px)"
                                            }}
                                            displayType={"input"}
                                            type="text"
                                            allowNegative={false}
                                            value={item.quantityFreight}
                                            onChange={(event: any) => {
                                                let newArr = [...externalTestsById];
                                                newArr[index] = { ...newArr[index], quantityFreight: event.target.value === '' ? 0 : event.target.value };
                                                newArr[index] = { ...newArr[index], total: calculateTotalRow(newArr[index]) };
                                                setExternalTestsById([]);
                                                setExternalTestsById(newArr);
                                                setExternalTestsTotals(totalsCalculation(newArr));
                                            }}
                                        ></NumberFormat>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ display: "flex!important", justifyContent: "center", alignItems: "center" }}>
                                    <Box style={inputBoxStyle}>
                                        <div style={{ display: "flex", justifyContent: "center", justifyItems: "center", alignItems: "center" }}>
                                            <span style={{ fontWeight: "500", fontFamily: 'VWHead' }}>R$</span>
                                            <NumberFormat
                                                style={{
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    border: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    justifyContent: "center",
                                                    outline: "none",
                                                    paddingTop: "3px",
                                                    width: "calc(100% - 60px)"
                                                }}
                                                displayType={"input"}
                                                type="text"
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                allowNegative={false}
                                                value={Number(item.freightCost.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                onChange={(event: any) => {
                                                    let newArr = [...externalTestsById];
                                                    newArr[index] = { ...newArr[index], freightCost: event.target.value };
                                                    newArr[index] = { ...newArr[index], total: calculateTotalRow(newArr[index]) };
                                                    setExternalTestsById([]);
                                                    setExternalTestsById(newArr);
                                                    setExternalTestsTotals(totalsCalculation(newArr));
                                                }}
                                            ></NumberFormat>
                                        </div>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ display: "flex!important", justifyContent: "center!important", textAlign: "center" }}>
                                    <div style={inputBoxStyle}>
                                        <span style={{ fontWeight: "500", fontFamily: 'VWHead' }}>R$</span>
                                        <NumberFormat
                                            style={{
                                                textAlign: "center",
                                                alignItems: "center",
                                                border: "none",
                                                display: "flex",
                                                fontSize: "16px",
                                                height: "40px",
                                                justifyContent: "center",
                                                outline: "none",
                                                paddingTop: "3px",
                                                width: "calc(100% - 60px)"
                                            }}
                                            displayType={"input"}
                                            type="text"
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            allowNegative={false}
                                            value={Number(item.otherCost.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            onChange={(event: any) => {
                                                let newArr = [...externalTestsById];
                                                newArr[index] = { ...newArr[index], otherCost: event.target.value };
                                                newArr[index] = { ...newArr[index], total: calculateTotalRow(newArr[index]) };
                                                setExternalTestsById([]);
                                                setExternalTestsById(newArr);
                                                setExternalTestsTotals(totalsCalculation(newArr));
                                            }}
                                        ></NumberFormat>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                    <div style={inputBoxStyle}>
                                        <input
                                            style={{
                                                border: "none",
                                                fontSize: "16px",
                                                height: "40px",
                                                outline: "none",
                                                paddingTop: "3px",
                                                textAlign: "left",
                                                width: "calc(100% - 20px)",
                                            }}
                                            type="text"
                                            value={item.description}
                                            onChange={(event: any) => {
                                                let newArr = [...externalTestsById];
                                                newArr[index] = { ...newArr[index], description: event.target.value };
                                                setExternalTestsById([]);
                                                setExternalTestsById(newArr);
                                                setExternalTestsTotals(totalsCalculation(newArr));
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={1} lg={1} sx={{ alignItems: "center", display: "flex!important", justifyContent: "center!important", color: "black" }}>
                                    <LabelComponent color={"#001e50"} fontSize={"14px"} text={Number(item.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} fontWeight="regular" />
                                </Grid>
                            </Grid>
                        )}

                        <Grid container sx={{ marginTop: "16px" }}>
                            <Grid item xs={12} sm={12} md={1} lg={1}> Total </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ color: "#6a767d" }}>
                                {Number(externalTestsTotals.externalTestsTotalTry ? externalTestsTotals.externalTestsTotalTry : "0").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ color: "#6a767d" }}>
                                {Number(externalTestsTotals.externalTestsTotalFreight ? externalTestsTotals.externalTestsTotalFreight : "0").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={12} sm={12} md={1.3} lg={1.3} sx={{ color: "#6a767d" }}>
                                {Number(externalTestsTotals.externalTestsTotalOtherCosts ? externalTestsTotals.externalTestsTotalOtherCosts : "0").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1} sx={{ color: "#6a767d" }}>
                                {Number(externalTestsTotals.externalTestsTotalSum ? externalTestsTotals.externalTestsTotalSum : "0").toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                            <ButtonComponent
                                text="Cancelar"
                                disable={true}
                                onClick={() => {
                                    props.setOpenCancelModal(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }} >
                            <ButtonComponent text="Salvar" active={true} onClick={() => {
                                fetchSaveExternalTests(externalTestsById, testPlanTotals, externalTestsTotals.externalTestsTotalSum);
                            }} />
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid >
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1ExternalTests;
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../utils/style.scss";
import ActivityTestDetails from "../pages/activityTest/activityTesteDetails";
import ActivityTestPage from "../pages/activityTest/activityTestPage";
import ActivityTypePage from "../pages/activityType/activityTypePage";
import AltraManagementPage from "../pages/altraManagement/altraManagement";
import HomePage from "./../pages/home/homePage";
import LaboratoryPage from "./../pages/laboratory/laboratoryPage";
import LaboratoryProgPage from "../pages/programing/laboratoryProgPage";
import ScheduleProgCommonPage from "../pages/programing/scheduleProgCommonPage"
import ScheduleProgSinergyPage from "../pages/programing/scheduleProgSinergyPage"
import LaboratoryTestDetails from "../pages/laboratoryTest/laboratoryTestDetails";
import LaboratoryTestPage from "../pages/laboratoryTest/laboratoryTestPage";
import LogDetails from "../pages/log/logDetails";
import LoginPage from "../pages/login/loginPage";
import LogPage from "../pages/log/logPage";
import RegisterModelPage from "../pages/registerModel/registerModelPage";
import ResourceTestDetails from "../pages/resourceTest/resourceTestDetails";
import ResourceTestPage from "../pages/resourceTest/resourceTestPage";
import ResourceTypePage from "../pages/resourceType/resourceTypePage";
import SupervisionPage from "./../pages/supervision/supervisionPage";
import TestPlanPage from "../pages/testPlan/testPlanPage";
import TestTypePage from "../pages/testType/testTypePage";
import VehiclePageList from "../pages/vehicle/vehiclePageList";
import VehiclePageNew from "../pages/vehicle/vehiclePageNew";
import TestPlanList from "../pages/testPlan/testPlanList";
import RequestTestPageList from "../pages/requestTest/requestTestList";
import RequestTestPage from "../pages/requestTest/requestTest";
import VehicleListPage from "../pages/vehicleList/vehicleListPage";
import SparePartsListPage from "../pages/sparePartsList/sparePartsListPage";
import NotAuthorizedPage from "../pages/notAuthorized/notAuthorized";

export function AppRoutes() {

  return (
    <BrowserRouter basename="/stapp">
      <Routes>
        <Route path={"/"} element={<LoginPage />} />
        <Route path={"/home"} element={<HomePage />} />
        <Route path={"/lista-plano-testes"} element={<TestPlanList />} />
        <Route path={"/plano-testes/:type"} element={<TestPlanPage />} />
        <Route path={"/plano-testes/:type/:id"} element={<TestPlanPage />} />
        <Route path={"/requisicao-teste"} element={<RequestTestPageList />} />
        <Route path={"/requisicao-teste/:type"} element={<RequestTestPage />} />
        <Route path={"/requisicao-teste/:type/:id"} element={<RequestTestPage />} />
        <Route path={"/associar-atividade"} element={<ActivityTestPage />} />
        <Route path={"/associar-atividade/details/:laborTypeTestId"} element={<ActivityTestDetails />} />
        <Route path={"/associar-laboratorio"} element={<LaboratoryTestPage />} />
        <Route path={"/associar-laboratorio/details/:id"} element={<LaboratoryTestDetails />} />
        <Route path={"/associar-recurso"} element={<ResourceTestPage />} />
        <Route path={"/associar-recurso/details/:laborTypeTestId"} element={<ResourceTestDetails />} />
        <Route path={"/atividades"} element={<ActivityTypePage />} />
        <Route path={"/cadastro-modelos"} element={<RegisterModelPage />} />
        <Route path={"/cadastro-veiculos"} element={<VehiclePageList />} />
        <Route path={"/cadastro-veiculos/clonar/:id"} element={<VehiclePageNew />} />
        <Route path={"/cadastro-veiculos/editar/:id"} element={<VehiclePageNew />} />
        <Route path={"/cadastro-veiculos/novo"} element={<VehiclePageNew />} />
        <Route path={"/gestao-altra/:laborTypeTestId"} element={<AltraManagementPage />} />
        <Route path={"/laboratorios"} element={<LaboratoryPage />} />
        <Route path={"/log-sistema"} element={<LogPage />} />
        <Route path={"/log-sistema/details/:type/:rev"} element={<LogDetails />} />
        <Route path={"/log-sistema/details/:type/:rev"} element={<LogDetails />} />
        <Route path={"/programacao-laboratorios"} element={<LaboratoryProgPage />} />
        <Route path={"/supervisao"} element={<SupervisionPage />} />
        <Route path={"/laboratorios-comum/:id"} element={<ScheduleProgCommonPage />} />
        <Route path={"/laboratorios-sinergia/:id"} element={<ScheduleProgSinergyPage />} />
        <Route path={"/recursos"} element={<ResourceTypePage />} />
        <Route path={"/tipos-testes"} element={<TestTypePage />} />
        <Route path={"/lista-veiculos"} element={<VehicleListPage />} />
        <Route path={"/lista-pecas-reposicao"} element={<SparePartsListPage />} />
      </Routes>
    </BrowserRouter>
  );
}

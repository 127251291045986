import { useEffect, useState } from "react";
import { get, post } from "../../api/api";
import useRoute from "../route/useRoute";

const useVehicle = () => {

    const [openAlert, setOpenAlert] = useState(false);
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [allSelectsList, setAllSelectsList] = useState<any>();
    const [addVehicle, setAddVehicle] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("lista-veiculos"));
        getSelectsList();
    }, []);

    const getSelectsList = () => {
        get('projectCars/data').then((values) => {
            setAllSelectsList(values);
        });
    }

    const fectchSubmit = (vehicleChoosedData: any) => {
        post<VehicleAdd[]>("projectCars", vehicleChoosedData).then((value: VehicleAdd) => {
            if (value.codeStatus === 201) {
                setOpenAlert(true);
                setAddVehicle(false);
            } else {
                setAddVehicle(true);
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setMessageSave(value.errorMessage);
            }
        });
    };

    return {
        openAlert, setOpenAlert,
        read, setRead,
        addVehicle, setAddVehicle,
        messageSave, setMessageSave,
        allSelectsList, fectchSubmit
    };
}

export default useVehicle;
type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post } from "../../api/api";

const useRequestTestTab2 = (state: Type) => {

    const [loading, setLoading] = useState(true);
    const [modelVDS, setModelVDS] = useState<ModelVDS>();
    const [idModelCar, setIdModelCar] = useState<CarsAll>();
    const [devices, setDevices] = useState<any>([]);
    const [infosVds, setInfoVds] = useState<PositionVDS>();
    const [openAlert, setOpenAlert] = useState(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [messageModal, setMessageModal] = useState<string>("");
    const [openModalError, setOpenModalError,] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [value, setValue] = useState(0);
    const [valid, setValid] = useState(false);
    const [isErrorVDS, setIsErrorVDS] = useState(false);
    const [isErrorPosition, setIsErrorPosition] = useState(false);
    const [read, setRead] = useState<boolean>(false);

    useEffect(() => {
        if (state.type === "editar") {
            setIsEdit(true);
            fetchRequestTestsProjectCar(state.id)
        }
        fetchDevices();
    }, []);

    const fetchCarId = (id: any) => {
        get('cars/' + id).then((value) => {
            setIdModelCar(value)
        });
    }

    const fetchDevices = () => {
        get('resources/devices').then((value) => {
            setDevices(value);
        })
    }

    const fetchSubmit = (body: any) => {
        post("requestTests/projectCar", body)
            .then((value) => {
                if (value.codeStatus === 400) {
                    setMessageModal(value.errorMessage)
                    setOpenModalError(true)
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    }
                    setModelVDS(value);
                    setOpenAlert(true)
                }
            });
    };

    const fetchRequestTestsProjectCar = (id: any) => {
        get<ModelVDS>("requestTests/projectCar/" + id)
            .then((value: ModelVDS) => {
                setModelVDS(undefined);
                setModelVDS(value);
                if (value?.projectCar?.car !== null && value.supervisionId !== null) {
                    setValid(true);
                }
                get("requestTests/" + id).then((category: any) => {
                    if (category.categoryId === 1 ||
                        category.categoryId === 2 ||
                        category.categoryId === 5 ||
                        category.categoryId === 11) {
                        setValue(0);
                    } else {
                        setValue(1);
                    }
                });
                if (value.projectCar?.car?.id !== undefined) {
                    fetchCarId(value.projectCar?.car?.id);
                } else {
                    fetchCarId("");
                }
            }).finally(() => {
                setLoading(false);
            });
    }

    const fetchVDS = (numberVds: any) => {
        get<ModelVDS>('projectCars/vds/' + numberVds).then((value) => {
            if (value.codeStatus === 200) {
                setValid(true);
                setIsErrorVDS(false);
                setModelVDS(value);
            } else {
                setValid(false);
                setIsErrorVDS(true);
                setModelVDS({});
            }
        });
    }

    const fetchPosition = (numberVds: any) => {
        get<ModelVDS>('projectCars/positionVDS/' + numberVds).then((value) => {
            if (value.codeStatus === 200) {
                setValid(true);
                setModelVDS(value);
                setIsErrorPosition(false);
            } else {
                setValid(false);
                setIsErrorPosition(true);
                setModelVDS({});
            }
        });
    }

    return {
        fetchCarId,
        fetchVDS,
        fetchPosition,
        fetchSubmit,
        fetchRequestTestsProjectCar,
        fetchDevices, read, setRead,
        modelVDS, setModelVDS,
        devices, setDevices,
        infosVds, setInfoVds,
        messageModal, setMessageModal,
        idModelCar, setIdModelCar,
        messageSave, openAlert, setOpenAlert,
        openModalError, setOpenModalError,
        isEdit, value, setValue,
        valid, setValid,
        loading, setLoading,
        isErrorVDS, setIsErrorVDS,
        isErrorPosition, setIsErrorPosition
    };
}

export default useRequestTestTab2;
import * as React from 'react';
import { Button, Card, Grid } from '@mui/material';
import { CardActionArea } from '@mui/material';
import noImage from "../../assets/images/sem-imagem-definida-labs-dashboard.png"
import { useNavigate, useParams } from "react-router-dom";

function CardLabProgComponent(props: CardImageText) {

  const navigate = useNavigate();

  const redirectType = () => {
    switch (props.type) {
      case 'Sinergia': return '/laboratorios-sinergia/';
      case 'Comum': return '/laboratorios-comum/'
    }
  }

  return (
    <Card
      onClick={() => { navigate(redirectType()! + props.id, { state: { testPlanName: props.text }} ) }}
      sx={{
        margin: "24px",
        boxShadow: "0px 8px 16px 0px rgba(0, 30, 80, 0.03), 0 8px 24px 0 rgba(0, 30, 80, 0.05)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}>
      <CardActionArea sx={{
        width: "300px",
        height: "290px",
        justifyContent: "start",
        flexDirection: "column",
      }}>
        <Grid container >
          <Grid item>
            <img style={{
              width: "300px",
              height: "188px",
              margin: "0 0 16px",
            }} src={props.media ? props.media : noImage} />
          </Grid>
          <Grid item
            sx={{
              fontFamily: "VWHead",
              fontSize: "18px",
              fontWeight: "bold",
              fontStretch: " normal",
              fontStyle: "normal",
              lineHeight: "1.29",
              letterSpacing: "normal",
              color: "#000000",
              margin: "0px 64px 0px 32px",
              textAlign: "left",
            }} >
            {props.text}
          </Grid>
          {props.description ?
            <Grid item
              sx={{
                fontFamily: "VWHead",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: " normal",
                fontStyle: "normal",
                lineHeight: "1.29",
                letterSpacing: "normal",
                color: "#6a767d",
                margin: "8px 32px 0",
                textAlign: "left",
              }} >
              <p style={{ margin: 0 }}>{
              `Supervisão ${props.description?.replaceAll(',', ', ')}`
              }</p>
            </Grid>
            : null}
        </Grid>
      </CardActionArea>
    </Card>
  );
}

export default CardLabProgComponent;
import React from 'react';
import styled from 'styled-components';
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
const InputFile = (props: any) => {
  const hiddenFileInput: any = React.useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    if (event.target.files[0].type == "application/pdf") {
      setErrorStatus(false);
      const fileUploaded = event.target.files[0];
      props.handleFile(fileUploaded);
    } else {
      setErrorStatus(true);
    }
  };

  const [errorStatus, setErrorStatus] = React.useState(false);

  return (
    <>
      <div
          onClick={handleClick}
          style={{ alignItems: "center", borderRadius: "3px", boxShadow: "0px 0px 5px #ccc", color: "#00b0ef", cursor: "pointer", display: "flex", fontSize: "15px", fontWeight: "bold", padding: "25px 40px", width: "190px" }}
      >
          <img style={{ marginRight: "10px", transform: props.rotate ? "rotate(180deg)" : "" }} src={props.image} />
          {props.text}
      </div>

      <input type="file"
        accept="application/pdf"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {errorStatus && <div id="alert" style={{ backgroundColor: "#e4002c", borderRadius: "4px", color: "#fff", fontFamily: "VWText", fontSize: "12px", letterSpacing: "normal", lineHeight: "1.33", marginTop: "6px", padding: "4px 8px 4px 8px", width: "255px", }}>Formato inválido!</div> }
    </>
  );
};
export default InputFile;
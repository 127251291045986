import { useEffect, useState } from "react";
import { get, post } from "../../api/api";
import useRoute from "../route/useRoute";

const useSparePartsList = () => {

    const [loading, setLoading] = useState(true);
    const [parts, setParts] = useState<Parts[]>([]);
    const [categories, setCategories] = useState<GeneralType[]>([]);
    const [subCategories, setSubCategories] = useState<GeneralType[]>([]);
    const [tests, setTests] = useState<GeneralType[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [access, setAccess] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("lista-pecas-reposicao"));
        fetchList("", "", "", "", 0, 5);
        fetchSelect();
    }, []);

    const fetchList = (filter: string, filter1: string, filter2: string, filter3: string, page: number, pageSize: number) => {
        setLoading(true);
        post('/requestTests/parts/all',
            {
                "param": filter,
                "categoryName": filter1,
                "subCategoryName": filter2,
                "testName": filter3,
                "page": page,
                "size": pageSize
            })
            .then((value) => {
                setParts(value.content);
                setTotalElements(value.totalElements);
            }).finally(() => setLoading(false));
    };

    const fetchSelect = () => {
        get('/requestTests/parts/all/data')
            .then((value) => {
                setCategories(value.categories);
                setSubCategories(value.subCategories);
                setTests(value.tests);
            });
    };

    const fetchUpdateTable = (body: any) => {
        setLoading(true);
        post('/requestTests/parts', body)
            .then((value) => {
                if (value.codeStatus === 200) {
                    fetchList("", "", "", "", 0, 5);
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    } else {
                        setMessageSave(value.errorMessage);
                        setIsError(true);
                    }
                }
            }).finally(() => setLoading(false));
    };

    return {
        loading, access, parts, categories, subCategories, tests,
        read, setRead,
        isError, setIsError, messageSave,
        totalElements, fetchList, fetchUpdateTable
    };

}

export default useSparePartsList;
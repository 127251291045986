import { Box, Grid, Pagination, Paper, Stack, Tooltip } from "@mui/material";
import ButtonComponent from "../../components/button/buttonComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import LabelComponent from "../../components/label/labelComponent";
import HeaderComponent from "../../components/header/headerComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import InputImage from "../../components/uploadImages/uploadImagesComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import iconNot from "../../assets/images/not.svg";
import check from "../../assets/images/check.svg";
import IconClose from "../../assets/images/close.png";
import { get, post, put, deleteR } from "../../api/api";
import React, { useState, useEffect } from "react";
import SelectCheckboxComponent from "../../components/button/buttonSelectCheckboxComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import useSelectPagination from "../../hooks/selectPagination/useSelectPagination";
import ModalReadPage from "../../components/modal/modalReadPage";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

export default function LaboratoryPage() {

  const [id, setId] = useState<number | null>(0);
  const [listSharedSupervisions, setListSharedSupervisions] = useState<string[]>([])
  const [listFilterSharedSupervisions, setListFilterSharedSupervisions] = useState<string[]>([])
  const [page, setPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageResponsible, setPageResponsible] = useState<number>(0);
  const [totalPagesResponsible, setTotalPagesResponsible] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(true);
  const [typeCalendarID, setTypeCalendarID] = useState<string>("");
  const [userId, setUserId] = useState<number | string>("");
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [filter3, setFilter3] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [messageSave, setMessageSave] = useState<string>("");
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [valid, setValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [typeCalendar, setTypeCalendar] = useState<TypeCalendars[]>([]);
  const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
  const [laboratory, setLaboratory] = useState<Laboratory>();
  const [laboratoriesResponsible, setLaboratoriesResponsible] = useState<Supervision[]>([]);
  const [users, setUsers] = useState<Supervision[]>([]);
  const [usersSelect, setUsersSelect] = useState<Supervision[]>([]);
  const [imageBackground, setImageBackground] = useState<T>(null);
  const [pageSelectUser, setPageSelectUser] = useState<number>(0);
  const [totalPagesSelectUser, setTotalPagesSelectUser] = useState<number>(0);
  const [pageShareSupervision, setPageShareSupervision] = useState<number>(0);
  const [totalShareSupervision, setTotalShareSupervision] = useState<number>(0);
  const [openDisable, setOpenDisable] = useState(false);
  const [read, setRead] = useState<boolean>(false);
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();
  let a: Supervision[] = [];
  let aux: string[] = [];

  const columns: GridColDef[] = [
    {
      field: "name", headerName: "Nome do laboratório", flex: 1, maxWidth: 300,
    },
    { field: "responsibleName", headerName: "Responsável", flex: 0.3, minWidth: 200 },
    { field: "typeCalendarName", headerName: "Tipo de agenda", flex: 0.3, minWidth: 100, },
    {
      field: "sharedSupervisionsInitials", headerName: "Compartilhamento", flex: 0.3, minWidth: 200,
    },
    {
      field: "media", headerName: "Background", flex: 0.3, minWidth: 150,
      renderCell: (value: any) => {
        if (value.row.media !== null) {
          return <img src={check} alt="" style={{ marginLeft: "30px" }} />;
        } else {
          return <img src={iconNot} alt="" style={{ marginLeft: "30px" }} />;
        }
      },
    },
    {
      field: "action", headerName: "Ação", flex: 0.3, minWidth: 150,
      renderCell: (value: any) => {
        if (value.row.active === true) {
          return (
            <Box flex={2}>
              <ButtonTextComponent
                text="Desativar"
                onClick={() => {
                  setOpenDisable(true);
                  setId(value.row.id);
                }}
              />
              <ButtonTextComponent
                text="Editar"
                onClick={() => {
                  const setIdEdit = value.row.id;
                  get<Laboratory[]>(`laboratories/${setIdEdit}`
                  ).then((value) => {
                    setId(value.id);
                    setImageBackground(value.media);
                    setName(value.name);
                    setListSharedSupervisions(value.sharedSupervisions);
                    setUserId(value.supervisionResponsibleId);
                    setTypeCalendarID(value.typeCalendar);
                    setUsersSelect([]);
                    getUsersSelect();
                    usersSelect.map((user) => {
                      value.sharedSupervisions.map((shared: any) => {
                        if (shared === user.id) {
                          user.select = true;
                        }
                      });
                    });
                    setUsersSelect(usersSelect);
                    setValid(true);
                    setIsEdit(true);
                    setPageSelectUser(0)
                  });
                }}
              />
            </Box>
          );
        } else {
          return (
            <Box flex={1}>
              <ButtonTextComponent
                text="Ativar"
                onClick={() => {
                  setLaboratories([]);
                  setLoading(true);
                  put("laboratories/" + value.row.id + "/active/" + true).then((value) => {
                    getList(filter, filter1, filter2, filter3, page, pageSize);
                  });
                }}
              />
            </Box>
          );
        }
      },
    },
  ];

  useEffect(() => {
    setAccess(fetchPage("laboratorios"));
    getList(filter, filter1, filter2, filter3, page, pageSize);
    getUsers(pageSelectUser);
    getUsersSelect();
    getCalendarType();
    getResponsible(pageResponsible);
    getSharedSupervisionsInitials(pageShareSupervision);
  }, []);

  function verifyUsers(userId: any, pageSelectUser: number) {
    const result = users.find((value) => value.id === userId.toString());
    if (result === undefined) {
      if (pageSelectUser === 0) {
        getUsers(pageSelectUser);
        verifyUsers(userId, pageSelectUser + 1);
      } else {
        getUsers(pageSelectUser);
        verifyUsers(userId, pageSelectUser + 1);
      }
    }
  }

  function getResponsible(pageResponsible: any) {
    post<Supervision[]>("supervisions/active/true",
      {
        "page": pageResponsible,
        "size": 10
      }).then(
        (value) => {
          setLaboratoriesResponsible(value.content);
          setTotalPagesResponsible(value.totalPages);
        }
      );
  }

  function getSharedSupervisionsInitials(pageShareSupervision: any) {
    post('laboratories/active/shared/supervisionInitials',
      {
        "page": pageShareSupervision,
        "size": 10
      }).then((value) => {
        setListFilterSharedSupervisions(value.content);
        setTotalShareSupervision(value.totalPages);
      });
  }

  function getList(filter: string, filter1: string, filter2: string, filter3: string, page: number, pageSize: number) {
    if (!isFilter) {
      post('laboratories/all', { "param": filter, "page": page, "size": pageSize }).then((value) => {
        setLaboratories(value.content);
        setTotalElements(value.totalElements);
        setTotalPages(value.totalPages);
        setLoading(false);
      });
    } else {
      post('laboratories/all',
        {
          "param": filter,
          "responsible": filter1,
          "typeCalendar": filter2,
          "sharedSupervisionsInitials": filter3,
          "page": page,
          "size": pageSize,

        }).then((value) => {
          setLaboratories(value.content);
          setTotalElements(value.totalElements);
          setTotalPages(value.totalPages);
          setLoading(false);
        });
    }

  }

  function getUsers(pageSelectUser: any) {
    post<Supervision[]>("supervisions/active/true",
      {
        "page": pageSelectUser,
        "size": 10
      }).then(
        (value) => {
          setUsers(value.content);
          setTotalPagesSelectUser(value.totalPages);
        }
      );
  }

  function getUsersSelect() {
    post<Supervision[]>("supervisions/active/true",
      {
        "page": 0,
        "size": 100
      }).then(
        (value) => {
          value.content.map((aux: any) => {
            aux.select = false;
            a.push(aux)
          });
          setUsersSelect(a);
        }
      );
  }

  function getCalendarType() {
    get("typeCalendars").then((value) => {
      setTypeCalendar(value);
    });
  }

  const uploadImages = (event: { target: { files: any } }) => {
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    post<Image>("medias", formData).then((value) => {
      if (value.codeStatus === 200 || value.codeStatus === 201) {
        setImageBackground(value.media);
      } else {
        setMessageSave(value.errorMessage);
      }
    });
  };

  const removeImages = () => {
    const formData = new FormData()
    deleteR<T>(`medias/?filename=${imageBackground}`, formData).then((url) => {
      setImageBackground(null);
    });
  };

  const handleSubmit = (e: any) => {
    if (valid) {
      if (!isEdit && id == 0) {
        post<Laboratory[]>("laboratories", {
          "media": imageBackground,
          "name": name,
          "sharedSupervisions": listSharedSupervisions,
          "supervisionResponsibleId": userId,
          "typeCalendar": typeCalendarID,
        }).then((value: Laboratory) => {
          if (value.codeStatus === 201) {
            setOpenAlert(true)
            setImageBackground(null);
            setName("");
            setUsers([]);
            setTypeCalendar([]);
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      }
      else {
        put<Laboratory[]>("laboratories", {
          "id": id,
          "media": imageBackground,
          "name": name,
          "sharedSupervisions": listSharedSupervisions,
          "supervisionResponsibleId": userId,
          "typeCalendar": typeCalendarID,
        }).then((value: Laboratory) => {
          if (value.codeStatus === 200) {
            setOpenAlert(true)
            setImageBackground(null);
            setName("");
            setUsers([]);
            setTypeCalendar([]);
          } else {
            if (value.errorMessage === 'Not Operation') {
              setRead(true)
            }
            setMessageSave(value.errorMessage);
          }
        });
      }
    }
  }

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      setFilter3("");
      getList("", "", "", "", page, pageSize);

    } else {
      setFilter(event.target.value);
      getList(event.target.value, filter1, filter2, filter3, page, pageSize);
    }
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModal = (e: any) => setOpenModal(false);
  const handleCloseModalFilter = (e: any) => setOpenFilter(false);

  return (
    access ?
      <Grid container md={12}>
        <HeaderComponent text="Cadastro de Sistema / Laboratórios" access={access}/>
        <SnackbarComponent text={isEdit ? "Laboratório editado com sucesso" : "Laboratório salvo com sucesso"} open={openAlert} closeSnackbar={handleClose} />

        <ModalComponent
          description="Ao desativar este Laboratório, não será mais possível atribuir novas interações à ele."
          open={openDisable}
          close={handleCloseModalDisable}
          onCancel={() => {
            setOpenDisable(false);
          }}
          onNext={() => {
            setLaboratories([]);
            setLoading(true);
            put("laboratories/" + id + "/active/" + false).then((value) => {
              getList(filter, filter1, filter2, filter3, page, pageSize);
              setId(null);
              setOpenDisable(false);
            });
          }}
        />
        <ModalComponent
          description={isEdit ? "Deseja realmente cancelar a edição de um novo laboratório?" : "Deseja realmente cancelar a criação de um novo laboratório?"}
          open={openModal}
          close={handleCloseModal}
          onCancel={() => {
            setOpenModal(false);
          }}
          onNext={() => {
            setName("");
            setTotalPagesSelectUser(0);
            setTypeCalendarID("");
            setUserId("");
            getUsersSelect();
            removeImages();
            setValid(false);
            setOpenModal(false);
            setIsEdit(false);
            setMessageSave("");
          }}
        />
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Responsável"}
          isPagination1={true}
          value1={filter1}
          url1="supervisions/active/true"
          onSelect1={(paginationEvent: string) => {
            setFilter1(paginationEvent);
            setIsFilter(true);
          }}
          labelFilter2={"Tipo de agenda"}
          value2={filter2}
          filter2={typeCalendar}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
            setIsFilter(true);
          }}
          labelFilter3={"Compartilhamento"}
          isPagination3={true}
          value3={filter3}
          url3="laboratories/active/shared/supervisionInitials"
          isString3={true}
          sharedSupervisionsInitials3={true}
          onSelect3={(paginationEvent: string) => {
            setFilter3(paginationEvent);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => {
            setOpenFilter(false);
          }}
          onNext={() => {
            if (filter1 === "" && filter2 === "" && filter3 === "") {
              setPage(0);
              setPageSize(5);
            }
            getList(filter, filter1, filter2, filter3, page, pageSize);
            setOpenFilter(false);
          }}
        />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" marginTop="48px" />
              <LabelComponent text="Laboratório" fontSize="28px" fontWeight="normal" marginTop="48px" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text="Dados do laboratório" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <TextFieldComponent
                helperText={messageSave?.includes('Laboratório') ? messageSave : laboratory?.fields && name === "" ? "Campo obrigatório" : ""}
                error={messageSave?.includes('Laboratório') || laboratory?.fields && name === "" ? true : false}
                text="Nome do laboratório"
                value={name}
                maxLength={100}
                required={true}
                onChange={(event) => {
                  setName(event.target.value);
                  setMessageSave("");
                  if (event.target.value != "" && userId != "" && typeCalendarID != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectPaginationComponent
                text={"Responsável"}
                url="supervisions/active/true"
                required={true}
                value={userId}
                onSelect={(event: string) => {
                  setUserId(event);
                  setMessageSave("");
                  if (event != "" && name != "" && typeCalendarID != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                required={true}
                text="Tipo de agenda"
                values={typeCalendar}
                value={typeCalendarID}
                onSelect={(event: any) => {
                  setTypeCalendarID(event.target.value);
                  if (event.target.value == "2") {
                    setListSharedSupervisions([]);
                  }
                  if (event.target.value != "" && name != "" && userId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>
          </Grid>
          {typeCalendarID == "1" ? (
            <>
              <Grid container sx={{ marginTop: "32px" }}>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <LabelComponent text="Quem compartilha esse laboratório?" />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container item xs={12}>
                  {usersSelect.map((user) => (
                    <li key={user.id} style={{ listStyle: "none", marginRight: "56px", marginTop: "16px" }}>
                      <SelectCheckboxComponent
                        select={user.select}
                        text={user.initials}
                        value={user.id}
                        count={totalPagesSelectUser}
                        onClickSelect={(event, value) => {
                          let index = listSharedSupervisions.indexOf(value);
                          if (index !== -1) {
                            listSharedSupervisions.splice(index, 1);
                          } else {
                            setListSharedSupervisions([...listSharedSupervisions, value]);
                          }
                        }}
                      />
                    </li>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : null}
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text="Upload de imagens" fontSize="20px" />
            </Grid>
          </Grid>
          <>
            {imageBackground == null ? (
              <Grid container sx={{ marginTop: "32px" }}>
                <Grid item xs={12} sm={6} md={12} lg={3}>
                  <InputImage text="Adicionar background" onChange={uploadImages} />
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ marginTop: "32px" }}>
                <Grid item xs={12} sm={6} md={12} lg={3}>
                  <div style={{ position: "relative", display: "block", width: "300px", height: "190px" }}>
                    <img src={imageBackground} alt="" style={{ width: "300px", height: "190px", borderRadius: "5px" }} />
                    <button
                      aria-label="delete"
                      onClick={removeImages}
                      style={{
                        position: "absolute",
                        zIndex: "999",
                        top: "54px",
                        right: "-50px",
                        width: "30px",
                        height: "30px",
                        padding: "0",
                        margin: "-40px 57px 0px -20px",
                        borderRadius: "50%",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <img src={IconClose} alt="" />
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}
          </>
          <Grid container sx={{ marginTop: "8px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text="Proporções sugeridas: 300 x 190" fontSize="14px" color="#6a767d" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Laboratórios" fontWeight="normal" fontSize="28px" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
              <SearchComponent text="" value={filter} placeholder="Buscar por …" onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  getList("", filter1, filter2, filter3, page, pageSize);
                }} />
            </Grid>
          </Grid>
          <Grid container xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <DataTableComponent
              rows={laboratories}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onClick={filterList}
            />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}
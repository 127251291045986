import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, Radio } from "@mui/material";
import { isValid } from "date-fns/esm";
import { useState } from "react";
import { post } from "../../api/api";
import logo from "../../assets/images/alert.png";
import ButtonComponent from "../../components/button/buttonComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import LabelComponent from "../../components/label/labelComponent";
import ModalComponent from "../../components/modal/modalComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import useRequestTestTab1 from "../../hooks/requestTest/useRequestTestTab1";
import useRequestTestTab2 from "../../hooks/requestTest/useRequestTestTab2";
import { format } from "../../utils/utils";

function RequestTestModalComponent(props: any) {

    const {
        requestTests, setRequestTestes,
        messageSave, setMessageSave,
        categories,
        subCategory, setSubCategory,
        testSubCategory, setTestSubCategory,
        phases,
        valid, setValid,
        fetchTestSubCategory
    } = useRequestTestTab1({
        "type": "modal",
        "categoryId": props.category
    });

    const {
        modelVDS, setModelVDS,
        isErrorVDS, setIsErrorVDS,
        isErrorPosition, setIsErrorPosition,
        fetchVDS, fetchPosition
    } = useRequestTestTab2({});

    const [isModel, setIsModel] = useState<boolean>(true);
    const [isVDS, setIsVDS] = useState<boolean>(true);
    const [numberIdent, setNumberIdent] = useState<string>("");
    const [selectedValue, setSelectedValue] = useState('a');
    const [VDS, setVDS] = useState<string>("");
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVDS("");
        setNumberIdent("");
        setSelectedValue(event.target.value);
    };

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    return (
        <Dialog
            open={props.open ? props.open : false}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onLoad={() => {
                // setValid(false);
                setRequestTestes({});
                setModelVDS({});
                if (props.car !== undefined) {
                    if (props.car.vds && VDS === "") {
                        fetchVDS(props.car.vds);
                        setVDS(props.car.vds);
                    } else if (props.car.position) {
                        fetchPosition(props.car.position);
                        setNumberIdent(props.car.position);
                    }
                }
                categories.map((category) => {
                    if (category.id === props.requestTests.categoryId) {
                        setSubCategory(category.subCategories);
                    }
                });
                if (requestTests === undefined) {
                    setRequestTestes({
                        ...requestTests,
                        categoryId: props.requestTests.categoryId,
                        testDate: props.requestTests.testDate,
                        testPlanId: props.requestTests.testPlanId,
                        testPhase: props.requestTests.testPhase,
                    });
                } else {
                    setRequestTestes({
                        ...requestTests
                    })
                }
                if (props.requestTests.categoryId !== undefined) {
                    if (props.requestTests.categoryId === 1 ||
                        props.requestTests.categoryId === 2 ||
                        props.requestTests.categoryId === 5 ||
                        props.requestTests.categoryId === 11) {
                        setIsModel(true);
                        setIsVDS(false);
                    } else {
                        setIsModel(false);
                        setIsVDS(true);
                    }
                } else {
                    setIsModel(true);
                    setIsVDS(false);
                }
            }}>
            <DialogTitle sx={{ paddingTop: "32px" }}>
                <Grid item xs={12}>
                    <LabelComponent text="Nova " fontSize="28px" />
                    <LabelComponent
                        text="requisição de teste"
                        fontSize="28px"
                        fontWeight="normal"
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <ModalComponent text=""
                    open={openModal}
                    description={"Deseja incluir o teste sem vincula-lo a um veiculo?"}
                    textButton1={"Não"}
                    textButton2={"Sim"}
                    close={handleCloseModal}
                    onCancel={() => {
                        setOpenModal(false);
                        setModelVDS(undefined);
                        setNumberIdent("");
                        setVDS("")
                        isValid(false)
                        setMessageSave("");
                        props.onNext();
                    }}
                    onNext={() => {
                        setOpenModal(false);
                        submit();
                    }} />
                <Grid container>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <TextFieldComponent text="Número do teste"
                            value={requestTests?.testNumber ? requestTests.testNumber : ""}
                            required={true}
                            helperText={messageSave !== "" ? messageSave : ""}
                            error={messageSave !== "" ? true : false}
                            onChange={(value) => {
                                setMessageSave("");
                                setRequestTestes({ ...requestTests, testNumber: value.target.value });
                                if ((value.target.value !== "" &&
                                    requestTests?.categoryId !== undefined &&
                                    requestTests?.subCategoryId !== undefined &&
                                    requestTests?.typeTestId !== undefined &&
                                    requestTests?.testPhase !== undefined &&
                                    requestTests?.testPlanId !== undefined &&
                                    requestTests?.testDate !== ""
                                )) {
                                    setValid(true);
                                } else {
                                    setValid(false);
                                }
                            }} />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <SelectComponent
                            text="Categoria"
                            required={true}
                            value={requestTests?.categoryId ? requestTests.categoryId : props.requestTests ? props.requestTests.categoryId : ""}
                            values={categories}
                            onSelect={(event: any) => {
                                if (event.target.value !== "") {
                                    categories.map((category) => {
                                        if (category.id === event.target.value) {
                                            setSubCategory(category.subCategories);
                                        }
                                    });
                                    setRequestTestes({ ...requestTests, categoryId: event.target.value });
                                    if (event.target.value === 1 ||
                                        event.target.value === 2 ||
                                        event.target.value === 5 ||
                                        event.target.value === 11) {
                                        setIsModel(true);
                                        setIsVDS(false);
                                    } else {
                                        setIsModel(false);
                                        setIsVDS(true);
                                    }
                                    if ((requestTests?.testNumber !== "" &&
                                        event.target.value !== undefined &&
                                        requestTests?.subCategoryId !== undefined &&
                                        requestTests?.typeTestId !== undefined &&
                                        requestTests?.testPhase !== undefined &&
                                        requestTests?.testPlanId !== undefined &&
                                        requestTests?.testDate !== ""
                                    )) {
                                        setValid(true);
                                    } else {
                                        setValid(false);
                                    }
                                } else {
                                    setSubCategory([]);
                                    setTestSubCategory([]);
                                    setValid(false);
                                    setRequestTestes({ ...requestTests, categoryId: undefined, subCategoryId: undefined, typeTestId: undefined });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <SelectComponent
                            text="Sub-categoria"
                            required={true}
                            value={requestTests?.subCategoryId ? requestTests.subCategoryId : ""}
                            values={subCategory}
                            onSelect={(event: any) => {
                                if (event.target.value !== "") {
                                    fetchTestSubCategory(event.target.value);
                                    setRequestTestes({ ...requestTests, subCategoryId: event.target.value });
                                    if ((requestTests?.testNumber !== "" &&
                                        requestTests?.categoryId !== undefined &&
                                        event.target.value !== undefined &&
                                        requestTests?.typeTestId !== undefined &&
                                        requestTests?.testPhase !== undefined &&
                                        requestTests?.testPlanId !== undefined &&
                                        requestTests?.testDate !== ""
                                    )) {
                                        setValid(true);
                                    } else {
                                        setValid(false);
                                    }
                                } else {
                                    setTestSubCategory([]);
                                    setRequestTestes({ ...requestTests, subCategoryId: undefined, typeTestId: undefined });
                                    setValid(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <SelectComponent
                            text="Nome do teste"
                            required={true}
                            value={requestTests?.typeTestId ? requestTests.typeTestId : ""}
                            values={testSubCategory}
                            onSelect={(event: any) => {
                                setRequestTestes({ ...requestTests, typeTestId: event.target.value });
                                if ((requestTests?.testNumber !== "" &&
                                    requestTests?.categoryId !== undefined &&
                                    requestTests?.subCategoryId !== undefined &&
                                    event.target.value !== undefined &&
                                    requestTests?.testPhase !== undefined &&
                                    requestTests?.testPlanId !== undefined &&
                                    requestTests?.testDate !== ""
                                )) {
                                    setValid(true);
                                } else {
                                    setValid(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <SelectComponent text="Fase do teste"
                            required={true}
                            value={requestTests?.testPhase ? requestTests.testPhase : ""}
                            values={phases}
                            isFilter={true}
                            onSelect={(event: any) => {
                                setRequestTestes({ ...requestTests, testPhase: event.target.value });
                                if ((requestTests?.testNumber !== "" &&
                                    requestTests?.categoryId !== undefined &&
                                    requestTests?.subCategoryId !== undefined &&
                                    requestTests?.typeTestId !== undefined &&
                                    event.target.value !== "" &&
                                    requestTests?.testPlanId !== undefined &&
                                    requestTests?.testDate !== ""
                                )) {
                                    setValid(true);
                                } else {
                                    setValid(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5.5} sx={{ paddingTop: "8px" }}>
                        <SelectPaginationComponent
                            text={"Plano de teste"}
                            url="testPlans/select/all"
                            status="All"
                            required={true}
                            value={requestTests?.testPlanId ? requestTests.testPlanId : props.requestTests ? props.requestTests.testPlanId : ""}
                            onSelect={(event: any) => {
                                setRequestTestes({ ...requestTests, testPlanId: event });
                                if ((requestTests?.testNumber !== "" &&
                                    requestTests?.categoryId !== undefined &&
                                    requestTests?.subCategoryId !== undefined &&
                                    requestTests?.typeTestId !== undefined &&
                                    requestTests?.testPhase !== undefined &&
                                    event !== undefined &&
                                    requestTests?.testDate !== ""
                                )) {
                                    setValid(true);
                                } else {
                                    setValid(false);
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={5.5} sx={{ paddingTop: "16px" }}>
                        <DateFieldComponent
                            text={"Data do teste"}
                            value={requestTests?.testDate ? new Date(requestTests?.testDate + "T00:00:00") :
                                props.requestTests ? props.requestTests.testDate : ""}
                            required={true}
                            onChangeDate={(newValue: Date) => {
                                if (newValue !== null) {
                                    if (newValue.toString() !== "Invalid Date") {
                                        setRequestTestes({ ...requestTests, testDate: format(newValue) });
                                    } else {
                                        setRequestTestes({ ...requestTests, testDate: "" });
                                    }
                                    if ((requestTests?.testNumber !== "" &&
                                        requestTests?.categoryId !== undefined &&
                                        requestTests?.subCategoryId !== undefined &&
                                        requestTests?.typeTestId !== undefined &&
                                        requestTests?.testPhase !== undefined &&
                                        requestTests?.testPlanId !== undefined &&
                                        newValue.toString() !== ""
                                    )) {
                                        setValid(true);
                                    } else {
                                        setValid(false);
                                    }
                                } else {
                                    setRequestTestes({ ...requestTests, testDate: "" });
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogTitle>
                <Grid item xs={12}>
                    <LabelComponent text="Informações " fontSize="24px" />
                    <LabelComponent
                        text="do Veículo/Modelo"
                        fontSize="24px"
                        fontWeight="normal"
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box hidden={isModel}>
                    <Grid item xs={5.5}>
                        <SelectPaginationComponent
                            text={"Nome do modelo"}
                            url="cars/active/true"
                            required={true}
                            isModel={true}
                            value={modelVDS?.projectCar?.car?.id ? modelVDS.projectCar.car.id : ""}
                            onSelect={(event: any) => {
                                setModelVDS({ ...modelVDS, projectCar: { car: { id: event != "" ? event : undefined } } });
                            }}
                        />
                    </Grid>
                </Box>
                <Box hidden={isVDS}>
                    <Grid container>
                        <Grid item xs={5.5}
                            sx={{ marginX: '8px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                            <Radio
                                checked={selectedValue === 'a'}
                                onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            <SearchComponent
                                error={isErrorVDS}
                                helperText={"VDS não encontrado."}
                                disabled={selectedValue === 'b'}
                                display="none"
                                placeholder="Número do VDS"
                                value={VDS}
                                onCancel={() => {
                                    setVDS("");
                                    setIsErrorVDS(false);
                                }}
                                onChange={(event: any) => {
                                    setIsErrorVDS(false);
                                    if (event.target.value === "") {
                                        setVDS("");
                                    } else {
                                        setVDS(event.target.value);
                                        fetchVDS(event.target.value);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={5.5}
                            hidden={isVDS}
                            sx={{ marginX: '8px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                            <Radio
                                checked={selectedValue === 'b'}
                                onChange={handleChange}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}
                            />
                            <SearchComponent
                                error={isErrorPosition}
                                helperText={"Id. provisória não encontrada."}
                                disabled={selectedValue === 'a'}
                                value={numberIdent}
                                display="none"
                                placeholder="Identificação Provisória"
                                onCancel={() => {
                                    setNumberIdent("");
                                    setIsErrorPosition(false);
                                }}
                                onChange={(event: any) => {
                                    setIsErrorPosition(false);
                                    setNumberIdent(event.target.value);
                                    if (event.target.value !== "") {
                                        fetchPosition(event.target.value);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    marginTop: "8px",
                    marginBottom: "30px",
                    justifyContent: "space-around"
                }}>
                <ButtonComponent
                    border="1px solid #00b0f0"
                    width="150px"
                    marginTop="0px"
                    text={"Cancelar"}
                    color="#00b0f0"
                    disable={true}
                    onClick={(event) => {
                        setVDS("");
                        setValid(false);
                        setRequestTestes(undefined);
                        props.onCancel(event);
                    }}
                />
                <ButtonComponent
                    width="150px"
                    marginTop="0px"
                    backgroundColor="#00b0f0"
                    text={"Salvar"}
                    marginLeft="64px"
                    disabled={!valid}
                    onClick={() => {
                        if (valid) {
                            if (VDS === "" && numberIdent === "") {
                                setOpenModal(true);
                            } else {
                                submit();
                            }

                            // post("requestTests", requestTests)
                            //     .then((valueRequest) => {
                            //         if (valueRequest.codeStatus === 200 || valueRequest.codeStatus === 201) {
                            //             let projectCar = {
                            //                 "projectCarId": modelVDS?.projectCar?.projectCarId,
                            //                 "carId": modelVDS?.projectCar?.car?.id,
                            //                 "requestTestId": valueRequest.id
                            //             }
                            //             setRequestTestes(undefined);
                            //             post("requestTests/projectCar", projectCar)
                            //                 .then((value) => {
                            //                     if (value.codeStatus === 400) {
                            //                     } else {
                            //                         setModelVDS(undefined);
                            //                         setNumberIdent("");
                            //                         setVDS("")
                            //                         isValid(false)
                            //                         props.onNext();
                            //                     }
                            //                 });
                            //         } else {
                            //             setMessageSave(valueRequest.errorMessage);
                            //         }

                            //     });
                        }
                    }} />
            </DialogActions>
        </Dialog>
    );


    function submit() {
        post("requestTests", requestTests)
            .then((valueRequest) => {
                if (valueRequest.codeStatus === 200 || valueRequest.codeStatus === 201) {
                    let projectCar = {
                        "projectCarId": modelVDS?.projectCar?.projectCarId,
                        "carId": modelVDS?.projectCar?.car?.id,
                        "requestTestId": valueRequest.id
                    }
                    setRequestTestes(undefined);
                    post("requestTests/projectCar", projectCar)
                        .then((value) => {
                            if (value.codeStatus === 400) {
                            } else {
                                setModelVDS(undefined);
                                setNumberIdent("");
                                setVDS("")
                                isValid(false)
                                props.onNext();
                            }
                        });
                } else {
                    setMessageSave(valueRequest.errorMessage);
                }

            });
    }
}

export default RequestTestModalComponent;
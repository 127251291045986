import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, post, put } from "../../api/api";
import { minutesToHours } from "../../utils/utils";
import useRoute from "../route/useRoute";

const useAltraManagement = (laborTypeTestId?: any) => {
    const [messageSave, setMessageSave] = useState<string>("");
    const [testLaboratory, setTestLaboratory] = useState<AltraList[]>([]);
    const [statusAltra, setStatusAltra] = useState<AltraStatus[]>([]);
    const [statusAltraIdEdit, setStatusAltraIdEdit] = useState<string>("");
    const [laborName, setLaborName] = useState<string>("");
    const [altraActivityList, setAltraActivityList] = useState<AltraActivities[]>([]);
    const [altraResourcesList, setAltraResourcesList] = useState<AltraResources[]>([]);
    const [altraSupervisionsList, setAltraSupervisionsList] = useState<AltraSupervisions[]>([]);
    const [typeTest, setTypeTest] = useState<String>("");
    const [totalCost, setTotalCost] = useState<number>(0);
    const [totalHours, setTotalHours] = useState<any>("00:00");
    const [openAlert, setOpenAlert] = useState(false);
    const [totalValue, setTotalValue] = useState<any>("00:00");
    const [read, setRead] = useState<boolean>(false);
    // const [access, setAccess] = useState<boolean>(false);
    // const { fetchPage } = useRoute();
    let auxTotalCost: number = 0;
    let auxTotalValue: number = 0;

    useEffect(() => {
        // setAccess(fetchPage("gestao-altra"));
        fetchList(laborTypeTestId);
    }, []);

    const fetchList = (laborTypeTestId: number) => {
        get<AltraList[]>("altra/managements/" + laborTypeTestId).then((value) => {
            setAltraActivityList(value.altra.activities);
            setAltraResourcesList(value.altra.resources);
            setAltraSupervisionsList(value.altra.supervisions);
            createArrayForNewRegister(value.altra.supervisions);
            setStatusAltra(value.altra.statusAltra);
            setTestLaboratory([value]);
            setTypeTest(value.typeTest);
            setLaborName(value.laborName);
            value.altra.statusAltra.map((sup: any) => {
                if (sup.name === value.status) {
                    setStatusAltraIdEdit(sup.id);
                }
            });
            value.altra.resources.map((aux: any) => {
                auxTotalCost += Number.parseFloat(aux.totalCost.replace(".", ","));
            });
            setTotalCost(auxTotalCost);
            value.altra.activities.map((aux: any) => {
                auxTotalValue += aux.totalHours;
            });
            setTotalHours(
                auxTotalValue !== 0 ? minutesToHours(auxTotalValue) : "00:00"
            );
        });
    };

    const fetchSubmit = (body: any) => {
        put<AltraList[]>("altra/managements/status", body
        ).then((value: AltraList) => {
            if (value.codeStatus === 200) {
                setOpenAlert(true);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setMessageSave(value.errorMessage);
            }
        });
    };

    function createArrayForNewRegister(_supervisionList: any) {
        let hoursMonth: number = 0;
        let hoursPart: number = 0;
        let hoursThird: number = 0;
        let hoursSubTotal: number = 0;
        _supervisionList.map((value: any) => {
            hoursMonth += value.hoursMonth;
            hoursPart += value.hoursPart;
            hoursThird += value.hoursThird;
            hoursSubTotal += value.hoursSubTotal;
        });

        setTotalValue({
            id: 0,
            name: "Total",
            hour: minutesToHours(hoursPart) + "h",
            month: minutesToHours(hoursMonth) + "h",
            third: minutesToHours(hoursThird) + "h",
            totalRow: minutesToHours(hoursSubTotal) + "h",
        });
    }

    return {
        testLaboratory, statusAltra, statusAltraIdEdit,
        altraActivityList, altraSupervisionsList, laborName,
        typeTest, totalCost, totalHours, totalValue,
        messageSave, openAlert, altraResourcesList,
        read, setRead,
        fetchList, fetchSubmit
    };
}

export default useAltraManagement;
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import ButtonComponent from "../button/buttonComponent";
import LabelComponent from "../label/labelComponent";
import SelectComponent from "../select/selectComponent";
import SelectPaginationComponent from "../select/selectPaginationComponent";

function ModalFilterComponent(props: FilterField) {
    return (
        <Dialog
            sx={{
                ".MuiFormControl-root": {
                    margin: "8px 8px 8px 16px",
                    padding: "0px",
                    width: "40%"
                },
                ".MuiDialogActions-root": {

                }
            }}
            open={props.open ? props.open : false}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    padding: "40px 40px 0px 40px"
                }}>
                <Grid container direction="row" >
                    <LabelComponent
                        text={props.text1 ? props.text1 : "Filtrar"}
                        fontFamily="VWText"
                        fontSize="24px"
                        color="#000000"
                        margin="0px 4px 0px 0px"
                    />
                    <LabelComponent
                        text={props.text2 ? props.text2 : " Lista"}
                        fontFamily="VWText"
                        fontSize="24px"
                        fontWeight="normal"
                        color="#000000"
                        marginTop="0px"
                        marginLeft="8px"
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                {props.labelFilter1 != undefined ?
                    props.isPagination1 === true ?
                        <SelectPaginationComponent
                            url={props.url1}
                            status={props.status1}
                            text={props.labelFilter1}
                            onSelect={props.onSelect1}
                            value={props.value1}

                            flex={0.5}
                            isFilter={props.isFilter1 === true || props.isFilter1 === false ? props.isFilter1 : true}
                            isFieldInitials={props.fieldInitials1 === true ? true : false}
                            isSharedSupervisionsInitials={props.sharedSupervisionsInitials1 === true ? true : false}
                            isString={props.isString1 === true ? true : false}
                        />
                        : <>
                            <SelectComponent
                                isFieldInitials={props.fieldInitials1 === true ? true : false}
                                isSharedSupervisionsInitials={props.sharedSupervisionsInitials1 === true ? true : false}
                                isString={props.isString1 === true ? true : false}
                                marginLeft="0px"
                                isFilter={props.isFilter1 === true || props.isFilter1 === false ? props.isFilter1 : true}
                                flex={0.5}
                                text={props.labelFilter1}
                                values={props.filter1}
                                value={props.value1}
                                onSelect={props.onSelect1} />
                        </>
                    : []}
                {props.labelFilter2 != undefined ?
                    props.isPagination2 === true ?
                        <SelectPaginationComponent
                            url={props.url2}
                            status={props.status2}
                            text={props.labelFilter2}
                            onSelect={props.onSelect2}
                            value={props.value2}
                            flex={0.5}
                            isFilter={true}
                            isFieldInitials={props.fieldInitials2 === true ? true : false}
                            isSharedSupervisionsInitials={props.sharedSupervisionsInitials2 === true ? true : false}
                            isString={props.isString2 === true ? true : false}
                        /> :
                        <SelectComponent
                            isFieldInitials={props.fieldInitials2 === true ? true : false}
                            isSharedSupervisionsInitials={props.sharedSupervisionsInitials2 === true ? true : false}
                            isString={props.isString2 === true ? true : false}
                            isFilter={props.isFilter2 === true || props.isFilter2 === false ? props.isFilter2 : true}
                            flex={0.5}
                            marginLeft="0px"
                            text={props.labelFilter2}
                            values={props.filter2}
                            value={props.value2}
                            onSelect={props.onSelect2} />
                    : []}
                {props.labelFilter3 != undefined ?
                    props.isPagination3 === true ?
                        <SelectPaginationComponent
                            url={props.url3}
                            status={props.status3}
                            text={props.labelFilter3}
                            onSelect={props.onSelect3}
                            value={props.value3}
                            flex={0.5}
                            isFilter={props.isFilter3 === true || props.isFilter3 === false ? props.isFilter3 : true}
                            isFieldInitials={props.fieldInitials3 === true ? true : false}
                            isSharedSupervisionsInitials={props.sharedSupervisionsInitials3 === true ? true : false}
                            isString={props.isString3 === true ? true : false}
                        /> :
                        <SelectComponent
                            isFieldInitials={props.fieldInitials3 === true ? true : false}
                            isSharedSupervisionsInitials={props.sharedSupervisionsInitials3 === true ? true : false}
                            isString={props.isString3 === true ? true : false}
                            isFilter={props.isFilter3 === true || props.isFilter3 === false ? props.isFilter3 : true}
                            flex={0.5}
                            marginLeft="0px"
                            text={props.labelFilter3}
                            values={props.filter3}
                            value={props.value3}
                            onSelect={props.onSelect3} />
                    : []}
            </DialogContent>
            <DialogActions
                sx={{
                    marginTop: "30px",
                    marginBottom: "30px",
                    justifyContent: "space-around"
                }}>
                <ButtonComponent
                    border="1px solid #00b0f0"
                    width="150px"
                    marginTop="0px"
                    text="Cancelar"
                    color="#00b0f0"
                    marginLeft="32px"
                    marginRight="32px"
                    disable={true}
                    onClick={props.onCancel} />
                <ButtonComponent
                    width="150px"
                    marginTop="0px"
                    backgroundColor="#00b0f0"
                    text="Prosseguir"
                    marginRight="32px"
                    onClick={props.onNext} />
            </DialogActions>
        </Dialog>
    );
}

export default ModalFilterComponent;

type Type = any;
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";

const useTestPlanTab2 = (state: Type) => {

    const [qtTests, setQtTests] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [phases, setPhases] = useState<Phase[]>([]);
    const [monthId, setMonthId] = useState<string>();
    const [months, setMonths] = useState<Months[]>([]);
    const [weekId, setWeekId] = useState<string>();
    const [weeks, setWeeks] = useState<Weeks[]>([]);
    const [yearId, setYearId] = useState<string>();
    const [years, setYears] = useState<Years[]>([]);
    const [cardItemVds, setCardItemVds] = useState<CardsVds[]>([]);
    const [categories, setCategories] = useState<GeneralType[]>([]);
    const [temporalListCars, setTemporalListCars] = useState<TemporalCars[]>([]);
    const [atemporalCars, setAtemporalCars] = useState<AtemporalCars>();
    const [categoryIdAccordion, setCategoryIdAccordion] = useState<number>();
    const [selectKw, setSelectKw] = useState<number>();
    const [selectYear, setSelectYear] = useState<number>();
    const [phaseName, setPhaseName] = useState<string>();
    const [phaseStatus, setPhaseStatus] = useState<string>();
    const [lastYear, setLastYear] = useState<number>();
    const [firstYear, setFirstYear] = useState<number>();
    const [activeStep, setActiveStep] = useState(0);
    const [dataStep, setDataStep] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    let phaseList: any = [];

    useEffect(() => {
        fetchCategoriesTestPlan();
        fetchPhasesList(state.id);
    }, []);

    const fetchData = (body: any) => {
        setLoading(true);
        post("testPlans/tab2/vds/week", body)
            .then((value) => {
                setTemporalListCars([]);
                setCardItemVds([]);
                value.cardsVds.map((cardVds: any) => {
                    cardVds.testsOfWeek.map((testeOfWeek: any) => {
                        if (testeOfWeek.cards.length === 0) {
                            testeOfWeek.cards = [{
                                isEmptyCard: true
                            }]
                        } else {
                            testeOfWeek.cards.map((card: any) => {
                                card.isEmptyCard = false;
                            });
                        }
                    });
                });
                setTemporalListCars(value);
                setCardItemVds(value.cardsVds);
                setMonthId(value.monthId);
                setWeekId(value.week);
                setYearId(value.year);
                get('testPlans/data').then((data) => {
                    setYears(data.years);
                    setMonths(data.months);
                    data.months.map((month: any) => {
                        month.weeks.map((monthWeek: any) => {
                            if (monthWeek.id === value.week) {
                                setWeeks(month.weeks);
                            }
                        });
                    });
                });
            }).finally(() => setLoading(false));
    }

    const fetchDataYear = (year: number, monthId: number) => {
        get('testPlans/data?year=' + year).then((data) => {
            setYears(data.years);
            setMonths(data.months);
            data.months.map((month: any) => {
                if (month.id === monthId) {
                    setWeeks(month.weeks);
                }
            });
        }).finally(() => setLoading(false));
    }

    const fetchCategoriesTestPlan = () => {
        get<GeneralType>('testPlans/category/tab2').then((value) => {
            setCategories(value);
        }).finally(() => setLoading(false));
    }

    const fetchPhasesList = (id: number) => {
        get<PhaseList>("testPlans/phase/tab3/" + id)
            .then((value: PhaseList) => {
                setPhaseName(value.name);
                setPhaseStatus(value.status);
                setLastYear(value.lastYear);
                setFirstYear(value.firstYear);
                value.listTPPhaseScheduleDto.map((item: ListPhaseSchedule) => {
                    phaseList.push({
                        type: item.type,
                        kw: item.kwName,
                        id: item.kw,
                        year: item.year,
                    })
                })
                setDataStep(phaseList);
            }).finally(() => setLoading(false));
    }

    const fetchPreviousNextButton = (phaseYear: number, phaseKw: number, categoryId: number, testPlanId?: string) => {
        get('testPlans/week/' + phaseYear + "/" + phaseKw).then((val) => {
            setMonthId(val.monthNumber);
            months.map((value: Months) => {
                if (value.id === val.monthNumber) {
                    setWeeks(value.weeks);
                    setWeekId("");
                    setWeekId(phaseKw.toString());

                    fetchData({
                        "categoryId": categoryId,
                        "testPlanId": testPlanId,
                        "weekNumber": phaseKw !== undefined ? phaseKw : dataStep[0].id,
                        "year": phaseYear !== undefined ? phaseYear : dataStep[0].year,
                    })
                }
            });
        });
    }

    const fetchPopulaCars = (body: any) => {
        setLoading(true);
        post("testPlans/tab2/vds/week", body)
            .then((value) => {
                setTemporalListCars([]);
                setCardItemVds([]);
                value.cardsVds.map((cardVds: any) => {
                    cardVds.testsOfWeek.map((testeOfWeek: any) => {
                        if (testeOfWeek.cards.length === 0) {
                            testeOfWeek.cards = [{
                                isEmptyCard: true
                            }]
                        } else {
                            testeOfWeek.cards.map((card: any) => {
                                card.isEmptyCard = false;
                            });
                        }
                    });
                });
                setTemporalListCars(value);
                setCardItemVds(value.cardsVds);
            }).finally(() => setLoading(false));
    }

    const fetchAtemporal = (id: any) => {
        setLoading(true);
        get<AtemporalCars[]>('testPlans/tab2/vds/atemporal/' + id)
            .then((value: any) => {
                let aux: any = null;
                value.cardsVds.map((cardsVds: any) => {
                    cardsVds.cardsTests.map((test: any) => {
                        test.isEmpty = false;
                    });
                    if (aux === null) {
                        aux = cardsVds.cardsTests.length;
                    } else {
                        if (aux < cardsVds.cardsTests.length) {
                            aux = cardsVds.cardsTests.length;
                        }
                    }
                })

                value.cardsVds.map((cardsVds: any) => {
                    if (cardsVds.cardsTests.length < aux) {
                        for (let i = cardsVds.cardsTests.length; i < aux; i++) {
                            cardsVds.cardsTests[i] = {
                                isEmpty: true
                            }
                        }
                    }
                });
                setQtTests(aux)
                setAtemporalCars(value);
            }).finally(() => setLoading(false));
    }

    const fetchUpdateDateTest = (body1: any, body2: any) => {
        setLoading(true);
        put("testPlans/tab2/requestTest/date", body1)
            .then((value) => {
                if (value.codeStatus !== 200) {
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
                post("testPlans/tab2/vds/week", body2)
                    .then((value) => {
                        setTemporalListCars([]);
                        setCardItemVds([]);
                        value.cardsVds.map((cardVds: any) => {
                            cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        });
                        setTemporalListCars(value);
                        setCardItemVds(value.cardsVds);
                    }).finally(() => setLoading(false));
            });
    }

    const fetchDamage = (body: any) => {
        setLoading(true);
        post('testPlans/tab2/vds/damage', body)
            .then((value) => {
                value.cardsVds.map((cardVds: any) => {
                    cardVds.testsOfWeek.map((testeOfWeek: any) => {
                        if (testeOfWeek.cards.length === 0) {
                            testeOfWeek.cards = [{
                                isEmptyCard: true
                            }]
                        } else {
                            testeOfWeek.cards.map((card: any) => {
                                card.isEmptyCard = false;
                            });
                        }
                    });
                });
                setTemporalListCars(value);
                setCardItemVds(value.cardsVds);
                setMonthId(value.monthId);
                setWeekId(value.week);
                setYearId(value.year);
            }).finally(() => setLoading(false));
    }


    const fetchUpdateDateAndVehicleTest = (body1: any, body2: any) => {
        setLoading(true);
        put("requestTests/projectCarAndData", body1)
            .then((value) => {
                if (value.codeStatus !== 200) {
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
                post("testPlans/tab2/vds/week", body2)
                    .then((value) => {
                        setTemporalListCars([]);
                        setCardItemVds([]);
                        value.cardsVds.map((cardVds: any) => {
                            cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        });
                        setTemporalListCars(value);
                        setCardItemVds(value.cardsVds);
                    });
            }).finally(() => setLoading(false));
    }

    return {
        qtTests,
        loading, setLoading,
        phaseName,
        dataStep,
        phaseStatus,
        firstYear, setFirstYear,
        lastYear, setLastYear,
        monthId, setMonthId,
        months, setMonths,
        weekId, setWeekId,
        weeks, setWeeks,
        yearId, setYearId,
        years, setYears,
        categories, setCategories,
        phases, setPhases,
        cardItemVds, setCardItemVds,
        temporalListCars, setTemporalListCars,
        categoryIdAccordion, setCategoryIdAccordion,
        selectYear, setSelectYear,
        selectKw, setSelectKw,
        activeStep, setActiveStep,
        atemporalCars, setAtemporalCars,
        messageAlert, isError, setIsError,
        fetchPopulaCars, fetchUpdateDateAndVehicleTest,
        fetchPreviousNextButton, fetchDamage,
        fetchData, fetchAtemporal,
        fetchUpdateDateTest, fetchDataYear
    };
}

export default useTestPlanTab2;
export function numberToString(totalMinutes: number): string {
    let time = totalMinutes.toString().split('.');
    let editedTime = `${time[0].padStart(2, '0')}:${time[1].padStart(2, '0')}`;
    return editedTime;
}

export function minutesToString(totalMinutes: number): string {
    let time = totalMinutes.toString().split('.');
    let editedTime = `${time[0].padStart(2, '0')}:${time[1].padStart(2, '0')}`;
    return editedTime;
}

export function fillTimeField(typedTime: any): string {
    if (typedTime.toString() === '' || typedTime === 0) {
        return '00:00';
    }
    if (typedTime.search(':') >= 0) {
        let time = typedTime.toString().split(':');
        let editedTime = `${time[0].replaceAll(' ', '').padStart(2, '0')}:${time[1].replaceAll(' ', '').padStart(2, '0')}`;
        return editedTime;
    } else {
        return typedTime.toString();
    }
}

export function hourToMinutes(value: string): number {
    let hourMinutes: any = value.split(':');
    return hourMinutes[0] * 60 + hourMinutes[1] * 1;
}

export function minutesToHours(value: number): string {
    if (value !== undefined) {
        var num = value;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return leftPad(rhours, 2) + ":" + leftPad(rminutes, 2);
    } else {
        return "00:00";
    }
}

export function timeToDecimal(t: string) {
    let arr: any = t.split(':');
    let arr1: any = (arr[1] / 6) * 10;
    arr1 = arr1.toString();
    let dec = parseInt(arr1, 10);
    let result = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    return result;
}

export function decimalToTime(t: any) {
    return rightPad(t, 4);
}

export function leftPad(value: any, totalWidth: any) {
    var length = totalWidth - value.toString().length + 1;
    return Array(length < 0 ? 0 : length).join('0' || '0') + value;
};

export function rightPad(value: any, totalWidth: any) {
    var length = totalWidth - value.toString().length + 1;
    return value + Array(length < 0 ? 0 : length).join('0' || '0');
};

export function moneyToIntegerSubtraction(value1: any, value2: any) {
    let v1 = value1.replace("R$", '').replace(',', '').replace('.', '');
    let v2 = value2.replace("R$", '').replace(',', '').replace('.', '');
    return (v1 - v2);
};

export function hourToMinutesSubtraction(value1: any, value2: any) {
    let v1 = hourToMinutes(value1.replace('h', ''));
    let v2 = hourToMinutes(value2.replace('h', ''));
    return (v1 - v2);
};

export function hourAndMinutesSubtraction(value1: any, value2: any) {
    let d1 = value1.split('-')
    let a1 = d1[2].split(' ');
    let h1 = a1[1].split(':');
    let date1 = new Date(a1[0], d1[1], d1[0], h1[0], h1[1], h1[2]);

    let d2 = value2.split('-')
    let a2 = d2[2].split(' ');
    let h2 = a2[1].split(':');
    let date2 = new Date(a2[0], d2[1], d2[0], h2[0], h2[1], h2[2]);

    return (Number(date1) - Number(date2))
};

export function calculaUltimaLinha(arr: any) {
    let totalRow = arr.reduce(
        (a: any, b: any) => {
            let values = {
                id: 0,
                name: "Total",
                hour: typeof a.hour === 'number' ? minutesToHours(a.hour) : minutesToHours(hourToMinutes(a.hour) + hourToMinutes(b.hour)),
                month: typeof a.hour === 'number' ? minutesToHours(a.month) : minutesToHours(hourToMinutes(a.month) + hourToMinutes(b.month)),
                third: typeof a.hour === 'number' ? minutesToHours(a.third) : minutesToHours(hourToMinutes(a.third) + hourToMinutes(b.third)),
                totalRow: typeof a.hour === 'number' ? minutesToHours(a.totalRow) : minutesToHours(hourToMinutes(a.totalRow) + hourToMinutes(b.totalRow)),
            };
            return values;
        }
    );
    totalRow.hour = totalRow.hour + 'h';
    totalRow.month = totalRow.month + 'h';
    totalRow.third = totalRow.third + 'h';
    totalRow.totalRow = minutesToHours(hourToMinutes(totalRow.hour.replace('h', '')) + hourToMinutes(totalRow.month.replace('h', '')) + hourToMinutes(totalRow.third.replace('h', '')));
    return totalRow;
}

export function sumLastLine(arr: any) {
    let totalRow = arr.reduce(
        (a: any, b: any) => {
            let values = {
                id: 0,
                name: "Total",
                hour: a.hour + b.hour,
                month: a.month + b.month,
                third: a.third + b.third,
                totalRow: a.totalRow + b.totalRow,
            };
            return values;
        }
    );
    totalRow.totalRow = totalRow.hour + totalRow.month + totalRow.third;
    return totalRow;
}

export function formatDate(data: Date): string {
    var day = data.getDate().toString().padStart(2, '0'),
        month = (data.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
        year = data.getFullYear();
    return year + "-" + month + "-" + day;
}

export function transformUsDateToBr(date: string): string {
    var splittedDate: string[];
    var formattedDate: string;

    if (date && date?.search("-") >= 0) {
        splittedDate = date.split('-')
        formattedDate = splittedDate[2] + '/' + splittedDate[1] + '/' + splittedDate[0];
    } else {
        formattedDate = date;
    }

    return formattedDate;
}

export function getWeeksYears(y: number) {
    var d, isLeap;
    d = new Date(y, 0, 1);
    isLeap = new Date(y, 1, 29).getMonth() === 1;
    return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
}

export function format(date: Date): string {
    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
}

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export function SortArrayObjByName(x: any, y: any) {
    if (x.name < y.name) { return -1; }
    if (x.name > y.name) { return 1; }
    return 0;
}

export function calculateAdministrativeProvisionTotals(_provisionsAdm: AdministrativeProvisions[], _testPlanTotal?: Totals) {
    const _qtdYear: number = 5;
    let resultedArray: any = [];
    if (_testPlanTotal) {
        let setValue1 = 0.0
        let setValue2 = 0.0
        let setValue3 = 0.0
        let setValue4 = 0.0
        let setValue5 = 0.0
        let fgValue1 = 0.0
        let fgValue2 = 0.0
        let fgValue3 = 0.0
        let fgValue4 = 0.0
        let fgValue5 = 0.0
        let kgValue1 = 0.0
        let kgValue2 = 0.0
        let kgValue3 = 0.0
        let kgValue4 = 0.0
        let kgValue5 = 0.0
        let vkValue1 = 0.0
        let vkValue2 = 0.0
        let vkValue3 = 0.0
        let vkValue4 = 0.0
        let vkValue5 = 0.0
        let pgValue1 = 0.0
        let pgValue2 = 0.0
        let pgValue3 = 0.0
        let pgValue4 = 0.0
        let pgValue5 = 0.0
        let phgValue1 = 0.0
        let phgValue2 = 0.0
        let phgValue3 = 0.0
        let phgValue4 = 0.0
        let phgValue5 = 0.0
        let tgValue1 = 0.0
        let tgValue2 = 0.0
        let tgValue3 = 0.0
        let tgValue4 = 0.0
        let tgValue5 = 0.0

        _provisionsAdm.map((_provisionsAdmItem: AdministrativeProvisions) => {
            if (_provisionsAdmItem.provisionsAdms.length > 0) {
                setValue1 = setValue1 + (_provisionsAdmItem.provisionsAdms[0].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[0].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                setValue2 = setValue2 + (_provisionsAdmItem.provisionsAdms[0].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[0].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                setValue3 = setValue3 + (_provisionsAdmItem.provisionsAdms[0].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[0].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                setValue4 = setValue4 + (_provisionsAdmItem.provisionsAdms[0].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[0].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                setValue5 = setValue5 + (_provisionsAdmItem.provisionsAdms[0].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[0].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                fgValue1 = fgValue1 + (_provisionsAdmItem.provisionsAdms[1].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[1].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                fgValue2 = fgValue2 + (_provisionsAdmItem.provisionsAdms[1].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[1].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                fgValue3 = fgValue3 + (_provisionsAdmItem.provisionsAdms[1].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[1].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                fgValue4 = fgValue4 + (_provisionsAdmItem.provisionsAdms[1].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[1].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                fgValue5 = fgValue5 + (_provisionsAdmItem.provisionsAdms[1].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[1].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                kgValue1 = kgValue1 + (_provisionsAdmItem.provisionsAdms[2].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[2].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                kgValue2 = kgValue2 + (_provisionsAdmItem.provisionsAdms[2].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[2].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                kgValue3 = kgValue3 + (_provisionsAdmItem.provisionsAdms[2].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[2].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                kgValue4 = kgValue4 + (_provisionsAdmItem.provisionsAdms[2].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[2].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                kgValue5 = kgValue5 + (_provisionsAdmItem.provisionsAdms[2].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[2].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                vkValue1 = vkValue1 + (_provisionsAdmItem.provisionsAdms[3].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[3].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                vkValue2 = vkValue2 + (_provisionsAdmItem.provisionsAdms[3].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[3].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                vkValue3 = vkValue3 + (_provisionsAdmItem.provisionsAdms[3].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[3].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                vkValue4 = vkValue4 + (_provisionsAdmItem.provisionsAdms[3].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[3].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                vkValue5 = vkValue5 + (_provisionsAdmItem.provisionsAdms[3].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[3].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                pgValue1 = pgValue1 + (_provisionsAdmItem.provisionsAdms[4].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[4].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                pgValue2 = pgValue2 + (_provisionsAdmItem.provisionsAdms[4].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[4].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                pgValue3 = pgValue3 + (_provisionsAdmItem.provisionsAdms[4].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[4].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                pgValue4 = pgValue4 + (_provisionsAdmItem.provisionsAdms[4].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[4].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                pgValue5 = pgValue5 + (_provisionsAdmItem.provisionsAdms[4].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[4].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                phgValue1 = phgValue1 + (_provisionsAdmItem.provisionsAdms[5].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[5].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                phgValue2 = phgValue2 + (_provisionsAdmItem.provisionsAdms[5].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[5].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                phgValue3 = phgValue3 + (_provisionsAdmItem.provisionsAdms[5].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[5].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                phgValue4 = phgValue4 + (_provisionsAdmItem.provisionsAdms[5].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[5].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                phgValue5 = phgValue5 + (_provisionsAdmItem.provisionsAdms[5].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[5].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)

                tgValue1 = tgValue1 + (_provisionsAdmItem.provisionsAdms[6].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[6].duration))) *
                    _testPlanTotal.lb1 * (_testPlanTotal.duration * _testPlanTotal.rateYear1) / 100)
                tgValue2 = tgValue2 + (_provisionsAdmItem.provisionsAdms[6].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[6].duration))) *
                    _testPlanTotal.lb2 * (_testPlanTotal.duration * _testPlanTotal.rateYear2) / 100)
                tgValue3 = tgValue3 + (_provisionsAdmItem.provisionsAdms[6].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[6].duration))) *
                    _testPlanTotal.lb3 * (_testPlanTotal.duration * _testPlanTotal.rateYear3) / 100)
                tgValue4 = tgValue4 + (_provisionsAdmItem.provisionsAdms[6].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[6].duration))) *
                    _testPlanTotal.lb4 * (_testPlanTotal.duration * _testPlanTotal.rateYear4) / 100)
                tgValue5 = tgValue5 + (_provisionsAdmItem.provisionsAdms[6].quantity *
                    timeToDecimal(minutesToHours(Number(_provisionsAdmItem.provisionsAdms[6].duration))) *
                    _testPlanTotal.lb5 * (_testPlanTotal.duration * _testPlanTotal.rateYear5) / 100)
            }
        });

        for (let i = 1; i < _qtdYear + 1; i++) {
            resultedArray.push({
                year: i  + "º ano",
                values: [
                    {
                        name: "SET", value: i === 1 ? setValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? setValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? setValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? setValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? setValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                         name: "FG", value: i === 1 ? fgValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? fgValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? fgValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? fgValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? fgValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                         name: "KG", value: i === 1 ? kgValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? kgValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? kgValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? kgValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? kgValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                        name: "VK",  value: i === 1 ? vkValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? vkValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? vkValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? vkValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? vkValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                        name: "PG", value:  i === 1 ? pgValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? pgValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? pgValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? pgValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? pgValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                        name: "PHG", value: i === 1 ? phgValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? phgValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? phgValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? phgValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? phgValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    { 
                        name: "TG", value:  i === 1 ? tgValue1.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 2 ? tgValue2.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 3 ? tgValue3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 4 ? tgValue4.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            i === 5 ? tgValue5.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) :
                                            0.0.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    },
                    {
                        name: "Total", value: (
                                            i === 1 ? setValue1 + fgValue1 + kgValue1 + vkValue1 + pgValue1 + phgValue1 + tgValue1:
                                            i === 2 ? setValue2 + fgValue2 + kgValue2 + vkValue2 + pgValue2 + phgValue2 + tgValue2:
                                            i === 3 ? setValue3 + fgValue3 + kgValue3 + vkValue3 + pgValue3 + phgValue3 + tgValue3:
                                            i === 4 ? setValue4 + fgValue4 + kgValue4 + vkValue4 + pgValue4 + phgValue4 + tgValue4:
                                            i === 5 ? setValue5 + fgValue5 + kgValue5 + vkValue5 + pgValue5 + phgValue5 + tgValue5:
                                            0.0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    }
                ]
            });
        }

        return resultedArray;
    }
};



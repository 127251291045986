import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function RadioButtonLogComponent(props: ParamsField) {
    return <FormControl>
        <FormLabel
            required={props.required ? props.required : false}
            sx={{
                fontFamily: "VWHead",
                fontSize: "20px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                color: "#000",
            }}
            focused={false}
        >{props.text}</FormLabel>
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={props.onChange}
            value={props.value}
        >
            {props.values ?
                props.values.map((value: any) => {
                    return <FormControlLabel
                        sx={{
                            ".MuiRadio-root.Mui-checked": {
                                color: "#001e50",
                            },
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            color: "#001e50"
                        }}
                        value={value.entity}
                        control={<Radio />}
                        label={value.description}
                    />
                }) : null}
        </RadioGroup>
    </FormControl >
}

export default RadioButtonLogComponent;
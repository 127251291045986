import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { Avatar, Box, Button, Stack } from "@mui/material";
import logo from "../../assets/images/shape.png";
import LabelComponent from "../label/labelComponent";
import { useState } from "react";

export default function DataTableComponent(props: DataTableField) {
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
        setPage(0);
        props.onPageSizeChange(0, newPageSize);
    }

    const onPageChange = (newPage: number) => {
        setPage(newPage);
        props.onPageChange(newPage, pageSize);
    }

    let showButton: boolean;

    if ((props.hasFilter == undefined) || (props.hasFilter != undefined && props.hasFilter === true)) {
        showButton = true;
    } else {
        showButton = false;
    }

    return (
        <Box
            sx={{
                height: 400,
                width: "100%",
                ".MuiDataGrid-columnHeadersInner": {
                    backgroundColor: "#dfe4e8"
                },
                ".MuiDataGrid-columnHeaderTitle": {
                    fontFamily: "VWHead",
                    fontSize: "16px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#001e50",
                },
                ".MuiDataGrid-cellContent": {
                    fontFamily: "VWHead",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#000"
                },
                '& .disable': {
                    ".MuiDataGrid-cellContent": {
                        opacity: 0.3,
                    },
                },
            }}
        >
            <DataGrid
                getRowId={(row) => Math.random()}
                disableSelectionOnClick
                disableColumnMenu
                pagination
                paginationMode="server"
                rowsPerPageOptions={props.isPagination === undefined ? [5, 10, 20, 30, 50, 100] : [1]}
                rows={props.rows}
                columns={props.columns}
                pageSize={pageSize}
                rowCount={props.totalElements}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                page={page}
                loading={props.loading}
                processRowUpdate={props.processRowUpdate}
                onProcessRowUpdateError={props.onProcessRowUpdateError}
                experimentalFeatures={{ newEditingApi: true }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack flexDirection="row" height="100%" alignItems="center" justifyContent="center">
                            <Avatar sx={{
                                width: "38px",
                                height: "49px",
                                margin: "0 20px 0 0",
                                objectFit: "contain",
                                borderRadius: "0px"
                            }} src={logo} />
                            <LabelComponent text={"Nenhum"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                margin={"0px 8px 0px 0px"} />
                            <LabelComponent text={"item encontrado com esses filtros. "}
                                margin-left={"8px !important"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                fontWeight={"normal"} />
                            {showButton && <Button sx={{
                                zIndex: 5,
                                cursor: "auto",
                                color: "#00b0f0",
                                fontFamily: "VWHead",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                textAlign: "center",
                                textTransform: "none",
                            }} variant="text"
                                onClick={props.onClick}>
                                {"Refazer seleção"} </Button>
                            }
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack flexDirection="row" height="100%" alignItems="center" justifyContent="center">
                            <Avatar sx={{
                                width: "38px",
                                height: "49px",
                                margin: "0 20px 0 0",
                                objectFit: "contain",
                                borderRadius: "0px"
                            }} src={logo} />
                            <LabelComponent text={"Nenhum"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                margin={"0px 8px 0px 0px"} />
                            <LabelComponent text={"item encontrado com esses filtros. "}
                                margin-left={"8px !important"}
                                fontSize={"18px"}
                                color={"#001e50"}
                                fontWeight={"normal"} />
                            {showButton && <Button sx={{
                                zIndex: 5,
                                cursor: "auto",
                                color: "#00b0f0",
                                fontFamily: "VWHead",
                                fontSize: "18px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "1.5",
                                letterSpacing: "normal",
                                textAlign: "center",
                                textTransform: "none",
                            }} variant="text"
                                onClick={props.onClick}>
                                {"Refazer seleção"}</Button>}
                        </Stack>
                    )
                }}
                getCellClassName={(params: GridCellParams<number>) => {
                    if (params.row.active === false && params.value !== undefined) {
                        return 'disable';
                    } else {
                        return '';
                    }
                }}
                componentsProps={{
                    pagination: {
                        component: "div",
                        labelRowsPerPage: "Linhas por página",
                        labelDisplayedRows: (value: any) => {
                            return `${value.from}–${value.to} de ${value.count !== -1 ? value.count : `mais do que ${value.to}`}`;
                        },
                        getItemAriaLabel: (type: string) => {
                            if (type === "next") {
                                return "Próxima página";
                            } else if (type === "previous") {
                                return "Página anterior";
                            }
                        }
                    }
                }}
            />
        </Box>
    );
}
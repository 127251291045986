import { Box, Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { GridColDef } from "@mui/x-data-grid";
import SearchComponent from "../../components/search/searchComponent";
import { useState } from "react";
import ModalComponent from "../../components/modal/modalComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { useNavigate, useParams } from "react-router-dom";
import useProgListCommon from "../../hooks/programing/useProgListCommon";
import { transformUsDateToBr } from "../../utils/utils";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function ScheduleProgListCommon() {
  const { type, id } = useParams();
  const {
    fetchListSinergyCommon,
    sinergyCommonItem,
    sinergyCommonFilter,
    loading,
    totalElements,
    access
  } = useProgListCommon({ type, id });

  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [filter, setFilter] = useState<string>("");
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [filter3, setFilter3] = useState<string>("");
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "kw", headerName: "KW", flex: 0.5, minWidth: 150, },
    { field: "userResponsible", headerName: "Responsável", flex: 0.5, minWidth: 200, },
    {
      field: "testDate", type: "date", headerName: "Data", flex: 0.5, minWidth: 150,
      valueGetter: (params: any) => {
        var date = params.row.testDate.split('-');
        return new Date(date[0], date[1] - 1, date[2]);
      }
      // transformUsDateToBr(params.row.testDate)
    },
    { field: "test", headerName: "Teste", flex: 0.5, minWidth: 200, valueGetter: (params: any) => { return params.row.testName; } },
    { field: "testPlanName", headerName: "Plano de Teste", flex: 0.5, minWidth: 200, },
    { field: "pse", headerName: "PSE", flex: 0.5, minWidth: 150, valueGetter: (params: any) => { return params.row.testNumber; } },
    { field: "index", headerName: "Motivo", flex: 0.5, minWidth: 200, valueGetter: (params: any) => { return params.row.reason; } },
    { field: "model", headerName: "Modelo", flex: 0.5, minWidth: 200, },
    { field: "dummy", headerName: "Dummy", flex: 0.5, minWidth: 200, },
    { field: "category", headerName: "Categoria", flex: 1, minWidth: 200 },
    { field: "subCategory", headerName: "Subcategoria", flex: 1, minWidth: 200 },
    { field: "liberation", headerName: "Liberação", flex: 1, minWidth: 150 },
    { field: "phase", headerName: "Fase do teste", flex: 1, minWidth: 150 },
    { field: "statusTest", headerName: "Status do Teste", flex: 1, minWidth: 150 },
    { field: "responsible", headerName: "Supervisão", flex: 0.5, minWidth: 200, },
    {
      field: "action", headerName: "Ação", flex: 0.5, minWidth: 150,
      renderCell: (value: any) => {
        {
          return (
            <Box flex={3}>
              <ButtonTextComponent text="Ver Detalhes"
                onClick={() => { window.open("/stapp/requisicao-teste/editar/" + value.row.requestTestId)}}
              />
            </Box>
          );
        }
      },
    },
  ]

  const filterListSinergyCommon = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      setFilter3("");
      fetchListSinergyCommon("", "", "", "", id);

    } else {
      setFilter(event.target.value);
      fetchListSinergyCommon(event.target.value, filter1, filter2, filter3, id);
    }
  }

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  return (
    access ?
      <Grid container sx={{ padding: "16px 48px 16px 48px", display: "flex" }}>
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Responsável"}
          isPagination1={false}
          filter1={sinergyCommonFilter?.responsibles}
          value1={filter1}
          onSelect1={(event: any) => {
            setFilter1(event.target.value);
            setIsFilter(true);
          }}
          labelFilter2={"Testes"}
          isPagination2={false}
          filter2={sinergyCommonFilter?.tests}
          value2={filter2}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
            setIsFilter(true);
          }}
          labelFilter3={"Semana"}
          isPagination3={false}
          filter3={sinergyCommonFilter?.weeks}
          value3={filter3}
          onSelect3={(event: any) => {
            setFilter3(event.target.value);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            fetchListSinergyCommon(filter, filter1, filter2, filter3, id)
            setOpenFilter(false);
          }} />
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LabelComponent text="Lista " fontSize="28px" />
            <LabelComponent text="de Testes" fontWeight="normal" fontSize="28px" />
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
            <SearchComponent text="" value={filter} placeholder="Buscar por …"
              onChange={filterListSinergyCommon}
              onClick={() => {
                setOpenFilter(true)
              }}
              onCancel={() => {
                setFilter("");
                fetchListSinergyCommon("", filter1, filter2, filter3, id);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTableGridComponent
            rows={sinergyCommonItem}
            columns={columns}
            loading={loading}
          />
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  )
}

export default ScheduleProgListCommon;
import { ToggleButton, Pagination, Box } from "@mui/material";
import { useState } from "react";

function SelectCheckboxComponent(props: ParamsField) {
  const [selected, setSelected] = useState(props.select);
  return (
    <ToggleButton
      value={props.value}
      selected={selected}
      onClick={props.onClickSelect}
      onChange={() => {
        setSelected(!selected);
      }}
      sx={{
        textTransform: props.textTransform ? props.textTransform : "none",
        width: "125px",
        height: "50px",
        fontFamily: "VWHead",
        fontSize: "16px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#000",
        objectFit: "contain",
        borderRadius: "8px",
        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
        border: "solid 0.5px #dfe4e8",
        backgroundColor: "#fff",
        "&.Mui-selected": {
          color: "white",
          backgroundColor: "#011e50",
        },
        "&.MuiButtonBase-root:hover": {
          bgcolor: "#011e50",
          color: "#ffffff"
        }
      }}
    >{props.text}
    </ToggleButton>
  )
}

export default SelectCheckboxComponent;
import Grid from '@mui/material/Grid';
import { Box, RadioGroup, Tooltip } from "@mui/material";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import HeaderComponent from "../../components/header/headerComponent";
import SearchComponent from "../../components/search/searchComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import RadioButtonColorComponent from "../../components/radio/radioButtonColorComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from 'react';
import { get, post, put } from '../../api/api';
import ModalReadPage from '../../components/modal/modalReadPage';
import useRoute from '../../hooks/route/useRoute';
import NotAuthorizedPage from '../notAuthorized/notAuthorized';

function TestTypePage() {
  const [id, setId] = useState<number | null>(0);
  const [name, setName] = useState<string>("");
  const [calendarColorsId, setCalendarColorsId] = useState<number>(0);
  const [subCategoryId, setSubCategoryId] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const [damagesId, setDamagesId] = useState<string>("");
  const [typeTests, setTypeTests] = useState<TypeTests[]>([]);
  const [typeTest, setTypeTest] = useState<TypeTests>();
  const [selectTypeTests, setSelectTypeTests] = useState<TypeTests[]>([]);
  const [categoryTypeTests, setCategoryTypeTests] = useState<CategoryTypeTests[]>([]);
  const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
  const [typeDamages, setTypeDamages] = useState<TypeDamage[]>([]);
  const [calendarColors, setCalendarColors] = useState<CalendarColors[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [valid, setValid] = useState(false);
  const [messageSave, setMessageSave] = useState<string>("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [pageSelectTypeTeste, setPageSelectTypeTeste] = useState<number>(0);
  const [totalSelectTypeTeste, setTotalSelectTypeTeste] = useState<number>(0);
  const [read, setRead] = useState<boolean>(false);
  const [access, setAccess] = useState<boolean>(false);
  const { fetchPage } = useRoute();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome do teste", flex: 1, minWidth: 200 },
    {
      field: "category", headerName: "Categoria", flex: 1, minWidth: 200,
      valueGetter: (params) => {
        return params.row.subCategory.category.name;
      }
    },
    {
      field: "subCategory", headerName: "Sub-categoria", flex: 1, minWidth: 200,
      valueGetter: (params) => {
        return params.row.subCategory.name;
      }
    },
    {
      field: "typeDemage", headerName: "Nível de dano", flex: 1, minWidth: 200,
      valueGetter: (params) => {
        return params.row.typeDamage.name;
      },
    },
    {
      field: "action", headerName: "Ação", flex: 1, minWidth: 250,
      renderCell: (value) => {
        if (value.row.active) {
          return <Box flex={3}>
            <ButtonTextComponent text="Desativar" onClick={() => {
              setOpenDisable(true);
              setId(value.row.id);
            }} />
            <ButtonTextComponent text="Editar" onClick={() => {
              setId(value.row.id);
              setName(value.row.name);
              setCategoryId(value.row.subCategory.category.id);
              let aux = [];
              categoryTypeTests.map((category: any) => {
                if (value.row.subCategory.category.id === category.id) {
                  setSubCategory(category.subCategories)
                }
              });
              setSubCategoryId(value.row.subCategory.id);
              setDamagesId(value.row.typeDamage.id);
              setCalendarColorsId(value.row.calendarColor.id);
              setValid(true);
              setIsEdit(true)
            }} />
            <ButtonTextComponent text="Clonar" onClick={() => {
              setName(value.row.name);
              setCategoryId(value.row.subCategory.category.id);
              let aux = [];
              categoryTypeTests.map((category: any) => {
                if (value.row.subCategory.category.id === category.id) {
                  setSubCategory(category.subCategories)
                }
              });
              setSubCategoryId(value.row.subCategory.id);
              setDamagesId(value.row.typeDamage.id);
              setCalendarColorsId(value.row.calendarColor.id);
              setValid(true);
              setIsEdit(false)
            }} />
          </Box>
        } else {
          return <Box flex={1}>
            <ButtonTextComponent text="Ativar" onClick={() => {
              setTypeTests([]);
              setLoading(true);
              put('typeTests/' + value.row.id + '/active/' + true).then((value) => {
                getList(filter, filter1, filter2, page, pageSize);
              });
            }} />
          </Box>
        }
      }
    },
  ]

  useEffect(() => {
    setAccess(fetchPage("tipos-testes"));
    getList(filter, filter1, filter2, page, pageSize);
    getSelectTypeTest(pageSelectTypeTeste);
    getCategoryTypeTests();
    getTypeDamages();
    getCalendarColors();
  }, []);

  function getSelectTypeTest(pageSupervison: any) {
    post('typeTests/all',
      {
        "page": pageSupervison,
        "size": 10
      }).then((value) => {
        setSelectTypeTests(value.content);
        setTotalSelectTypeTeste(value.totalPages);
      });
  }

  function getList(filter: string, filter1: string, filter2: string, page: number, pageSize: number) {
    if (!isFilter) {
      post('typeTests/all', { "param": filter, "page": page, "size": pageSize }).then((value) => {
        setTypeTests(value.content);
        setTotalElements(value.totalElements);
        setTotalPages(value.totalPages);
        setLoading(false);
      });
    } else {
      post('typeTests/all',
        {
          "param": filter,
          "name": filter1,
          "page": page,
          "category": filter2,
          "size": pageSize
        }).then((value) => {
          setTypeTests(value.content);
          setTotalElements(value.totalElements);
          setTotalPages(value.totalPages);
          setLoading(false);
        });
    }
  };

  function getCategoryTypeTests() {
    get('categoryTypeTests').then((value) => {
      setCategoryTypeTests(value);
    })
  }

  function getTypeDamages() {
    get('typeDamages').then((value) => {
      setTypeDamages(value);
    })
  }

  function getCalendarColors() {
    get('calendarColors').then((value) => {
      setCalendarColors(value);
    })
  }

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      getList("", "", "", page, pageSize);
    } else {
      setFilter(event.target.value);
      getList(event.target.value, filter1, filter2, page, pageSize);
    }
  }

  const handleSubmit = (e: any) => {
    if (!isEdit && id == 0) {
      post<TypeTests[]>("typeTests", {
        "calendarColorId": calendarColorsId,
        "name": name,
        "subCategoryId": subCategoryId,
        "typeDamage": damagesId,
      }).then((value: TypeTests) => {
        if (value.codeStatus === 201) {
          setOpenAlert(true)
        } else {
          if (value.errorMessage === 'Not Operation') {
            setRead(true)
          }
          setMessageSave(value.errorMessage);
        }
      });
    } else {
      put<TypeTests[]>("typeTests", {
        "id": id,
        "calendarColorId": calendarColorsId,
        "name": name,
        "subCategoryId": subCategoryId,
        "typeDamage": damagesId,
      }).then((value: TypeTests) => {
        if (value.codeStatus === 200) {
          setOpenAlert(true)
        } else {
          if (value.errorMessage === 'Not Operation') {
            setRead(true)
          }
          setMessageSave(value.errorMessage);
        }
      });
    }
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  return (
    access ?
      <Grid container>
        <HeaderComponent text="Cadastro de Sistema / Tipos de Testes" access={access}/>
        <SnackbarComponent text={isEdit ? "Tipo de Teste editado com sucesso" : "Tipo de Teste salvo com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent description="Ao desativar este Tipo de Teste, não será mais possível atribuir novas interações à ele."
          open={openDisable}
          close={handleCloseModalDisable}
          onCancel={() => { setOpenDisable(false) }}
          onNext={() => {
            setTypeTests([]);
            setLoading(true);
            put('typeTests/' + id + '/active/' + false).then((value) => {
              getList(filter, filter1, filter2, page, pageSize);
              setId(null);
              setOpenDisable(false);
            });
          }} />
        <ModalComponent text="Atenção!"
          description={isEdit ? "Deseja realmente cancelar a edição de um novo teste?" : "Deseja realmente cancelar a criação de um novo teste?"}
          open={openModal}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setName("");
            setCalendarColorsId(0);
            setCategoryId("");
            setSubCategoryId("");
            setSubCategory([]);
            setDamagesId("");
            setValid(false);
            setOpenModal(false);
            setMessageSave("");
          }} />
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Teste"}
          isPagination1={true}
          value1={filter1}
          url1="typeTests/all"
          onSelect1={(paginationEvent: string) => {
            setFilter1(paginationEvent);
            setIsFilter(true);
          }}
          labelFilter2={"Categoria"}
          value2={filter2}
          filter2={categoryTypeTests}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            if (filter1 === "" && filter2 === "") {
              setPage(0);
              setPageSize(5);
            }
            getList(filter, filter1, filter2, page, pageSize);
            setOpenFilter(false);
          }} />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" />
              <LabelComponent text="Teste" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3} >
              <LabelComponent text="Dados do teste" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <TextFieldComponent
                helperText={messageSave?.includes('Nome') ? messageSave : typeTest?.fields && name === "" ? "Campo obrigatório" : ""}
                error={messageSave?.includes('Nome') || typeTest?.fields && name === "" ? true : false}
                text="Nome do teste"
                required={true}
                maxLength={100}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  setMessageSave("");
                  if (event.target.value != "" && categoryId != "" && subCategoryId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                text="Categoria"
                required={true}
                value={categoryId}
                values={categoryTypeTests}
                onSelect={(event: any) => {
                  setCategoryId(event.target.value)
                  if (event.target.value != "" && name != "" && subCategoryId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                    setSubCategory([]);
                  }
                  categoryTypeTests.map((category) => {
                    if (category.id === event.target.value) {
                      setSubCategory(category.subCategories);
                    }
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                required={true}
                value={subCategoryId}
                values={subCategory}
                text="Sub-categoria"
                onSelect={(event: any) => {
                  setSubCategoryId(event.target.value)
                  if (event.target.value != "" && name != "" && categoryId != "" && damagesId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                text="Nível de dano"
                required={true}
                value={damagesId}
                values={typeDamages}
                onSelect={(event: any) => {
                  setDamagesId(event.target.value)
                  if (event.target.value != "" && name != "" && categoryId != "" && subCategoryId != "" && calendarColorsId !== 0) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <LabelComponent text="Selecionar cor do teste para agenda *" />
          </Grid>
          <Grid container columnSpacing={{ xs: 0.5, sm: 0.5, md: 2, lg: 0.5, xl: 0 }}
            sx={{ paddingX: "0px", paddingY: "30px" }} >
            <RadioGroup name="use-radio-group"
              value={calendarColorsId}
              defaultValue={0}
              onClick={(event: any) => {
                setCalendarColorsId(parseInt(event.target.id));
                if (event.target.value != "" && name != "" && categoryId != "" && subCategoryId != "" && damagesId != "") {
                  setValid(true);
                } else {
                  setValid(false);
                }
              }}
              sx={{ flexDirection: "row", }}>
              {calendarColors.map((color) => (
                <Grid item sm={1.8} md={1} lg={1} xl={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <RadioButtonColorComponent
                    text={color.id}
                    value={color.id}
                    labelItem={color.name}
                    sizeRadio={'44px'}
                    colorRadio={color.hex}
                    idItem={color.id}
                  />
                </Grid>
              ))}
            </RadioGroup>
          </Grid>
          <Grid container>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Testes" fontWeight="normal" fontSize="28px" />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
              <SearchComponent
                text=""
                value={filter}
                placeholder="Buscar por …"
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  getList('', filter1, filter2, page, pageSize);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={typeTests}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getList(filter, filter1, filter2, page, pageSize);
              }}
              onClick={filterList}
            />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  )
}
export default TestTypePage;
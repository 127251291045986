import Cookies from "universal-cookie";
import { CONFIG } from "./config";


const cookies = new Cookies();

export function setCookie(cookieName: any, cookieValue: any, cookiePath = CONFIG.COOKIE_PATH) {
    cookies.set(cookieName, cookieValue,
        {
            path: cookiePath,
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            secure: true
        },
    );
}

export function removeCookie(cookieName: any, cookiePath = CONFIG.COOKIE_PATH) {
    cookies.remove(
        cookieName,
        {
            path: cookiePath
        }
    );
}

export function getCookie(cookieName: any) {
    return cookies.get(cookieName);
}

import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AccordionComponent(props: ParamsField) {
    let style = {};
    let arrowStyle = {};

    if (props.type == "clean") {
        style = { borderBottom: "1px solid black", padding: '0' };
        arrowStyle = { color: "#000000" };
    } else {
        style = { backgroundColor: "#001e50", borderRadius: "5px 5px 5px 5px", color: "#ffffff", marginBottom: "16px" };
        arrowStyle = { color: "#ffffff" };
    }

    return (
        <AccordionSummary sx={style} expandIcon={<ExpandMoreIcon sx={arrowStyle} />} aria-controls="panel1bh-content" id="panel1bh-header">
            {props.text}
        </AccordionSummary>
    );
}

export default AccordionComponent;
import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionComponent from "../../components/accordion/accordionComponent";
import TestPlanPageTab1ProjectDetails from "./accordeonsTab1/projectDetails";
import TestPlanPageTab1RateByYear from "./accordeonsTab1/rateByYear";
import TestPlanPageTab1Phases from "./accordeonsTab1/phases";
import TestPlanPageTab1AdministrativeProvision from "./accordeonsTab1/administrativeProvision";
import TestPlanPageTab1TravelProvision from "./accordeonsTab1/travelProvision";
import TestPlanPageTab1ExternalTests from "./accordeonsTab1/externalTests";
import TestPlanPageTab1SummaryOfCosts from "./accordeonsTab1/summaryOfCosts";
import { useParams } from "react-router-dom";

function TestPlanPageTab1(props: any) {
    const { type, id } = useParams();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [currentModal, setCurrentModal] = useState<string>();
    const handleChange = (panel: string, modal: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        setCurrentModal(modal);
    };

    return (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', 'projectDetails')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Detalhes do projeto"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1ProjectDetails
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1ProjectDetails>
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={type === "novo" ? true : false} expanded={expanded === 'panel2'} onChange={handleChange('panel2', 'rateByYear')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Rate por ano"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1RateByYear
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1RateByYear>
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={type === "novo" ? true : false} expanded={expanded === 'panel3'} onChange={handleChange('panel3', 'phases')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Cronograma das fases"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1Phases
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1Phases>
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={type === "novo" ? true : false} expanded={expanded === 'panel4'} onChange={handleChange('panel4', 'administrativeProvision')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Provisão administrativa"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1AdministrativeProvision
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1AdministrativeProvision>
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={type === "novo" ? true : false} expanded={expanded === 'panel5'} onChange={handleChange('panel5', 'travelProvision')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Provisão de viagens"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1TravelProvision
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1TravelProvision>
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={type === "novo" ? true : false} expanded={expanded === 'panel6'} onChange={handleChange('panel6', 'externalTests')} sx={{ backgroundColor: "transparent!important", boxShadow: "none" }}>
                <AccordionComponent text="Testes externos"></AccordionComponent>
                <AccordionDetails>
                    <TestPlanPageTab1ExternalTests
                        testPlanChangeMessage={props.testPlanChangeMessage}
                        setOpenCancelModal={props.setOpenCancelModal}
                        currentModal={currentModal}
                        type={type}
                        id={id}
                    ></TestPlanPageTab1ExternalTests>
                </AccordionDetails>
            </Accordion>

            <TestPlanPageTab1SummaryOfCosts
                testPlanChangeMessage={props.testPlanChangeMessage}
                setOpenCancelModal={props.setOpenCancelModal}
                currentModal={currentModal}
                type={type}
                id={id}
            ></TestPlanPageTab1SummaryOfCosts>
        </>
    )
}

export default TestPlanPageTab1;
import { Card, CardContent, Grid, Button, CardActions, Avatar } from "@mui/material";
import greenIcon from "../../assets/images/green-damage-level.png";
import yellowIcon from "../../assets/images/yellow-damage-level.png";
import redIcon from "../../assets/images/red-damage-level.png";
import notLevelIcon from "../../assets/images/not-damage-level.png";
import closeIcon from "../../assets/images/close-blue.png";
import LabelComponent from "../label/labelComponent";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default function CardDNDComponent(props: DraggableParams) {
  const green = "#00c971";
  const yellow = "#ffd100";
  const red = "#ff335c";
  const notLevel = "#dfe4e8";

  function handleOnDragEnd(result: any) {
    var draggableId = result.draggableId.split(',');
    props.values.map((type: any) => {
      if (props.type === "laboratory") {
        type.typeTests.map((typeTest: any) => {
          if (typeTest.id.toString() === draggableId[0]) {
            typeTest.isSelect = false;
          }
        });
      } else if (props.type === "resource") {
        type.resources.map((resource: any) => {
          if (resource.id.toString() === draggableId[0]) {
            resource.isSelect = false;
          }
        });
      } else if (props.type === "activity") {
        type.activities.map((activity: any) => {
          if (activity.id.toString() === draggableId[0]) {
            activity.isSelect = false;
          }
        });
      }
    });
    props.handleOnDragEnd(props.values, result)
  }

  function cancel(result: any) {
    props.handleCancel(result);
  }

  return <>
    <Grid container>
      <Grid item
        xs={props.xsGdLeft}
        sm={props.smGdLeft}
        md={props.mdGdLeft}
        lg={props.lgGdLeft}
        sx={{
          width: "627px",
          height: "430px",
          borderRadius: "4px",
          backgroundColor: "#dfe4e8",
          overflow: "scroll",
          marginY: "10px",
          marginRight: { md: '0px', lg: '10px' },
        }}
      >
        <Grid item xs={12}>
          {props.values ? props.values.map((type: any) => {
            return <Grid container sx={{ marginTop: "16px" }}>
              <Grid item xs={12}>
                <LabelComponent
                  text={props.type === "laboratory" ? type.category : props.type === "resource" ? type.resourceCategory : props.type === "activity" ? type.activityCategory : ""}
                  color={"#6a767d"}
                  fontSize={"16"}
                  margin={"16px"}
                />
              </Grid>
              <Grid item xs={12}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <Grid item md={12} className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                        {type.typeTests ? type.typeTests.map((teste: any, index: number) => {
                          if (!teste.isSelect) {
                            return <Draggable key={teste.id} draggableId={teste.id + "," + teste.name} index={index}>
                              {(provided) => (
                                <Grid item md={9}
                                  sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}
                                  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  <Card
                                    sx={{
                                      display: "inline-flex",
                                      flexWrap: 'nowrap',
                                      marginX: "12px",
                                      marginY: "12px",
                                      width: "282px",
                                      height: "84px",
                                      margin: "16px 8px 8px 16px",
                                      borderRadius: "8px",
                                      boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                      backgroundColor: "#ffffff",
                                      cursor: 'move'
                                    }}>
                                    <CardContent>
                                      <Grid container md={12}>
                                        <img style={{
                                          width: "44px",
                                          height: "44px",
                                          marginTop: "5px",
                                        }} src={teste.typeDamage.id === 1 ? greenIcon : teste.typeDamage.id === 2 ? yellowIcon : teste.typeDamage.id === 3 ? redIcon : notLevelIcon} />
                                        <Grid direction={"column"}>
                                          <Grid item
                                            sx={{
                                              fontFamily: "VWHead",
                                              fontSize: "14px",
                                              fontWeight: "normal",
                                              fontStretch: " normal",
                                              fontStyle: "normal",
                                              lineHeight: "1.29",
                                              letterSpacing: "normal",
                                              color: "#000000",
                                              marginTop: "8px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "200px",
                                            }}>
                                            {teste.name}
                                          </Grid>
                                          <Grid item
                                            sx={{
                                              fontFamily: "VWHead",
                                              fontSize: "14px",
                                              fontWeight: "normal",
                                              fontStretch: " normal",
                                              fontStyle: "normal",
                                              lineHeight: "1.29",
                                              letterSpacing: "normal",
                                              color: teste.typeDamage.id === 1 ? green : teste.typeDamage.id === 2 ? yellow : teste.typeDamage.id === 3 ? red : notLevel
                                            }}>
                                            {teste.typeDamage.name}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card >
                                </Grid>
                              )}
                            </Draggable>
                          }
                        }) : type.resources ? type.resources.map((resource: any, index: number) => {
                          return <Draggable key={resource.id} draggableId={resource.id + "," + resource.resourceName} index={index}>
                            {(provided) => (
                              <Grid item md={9}
                                sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}
                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Card
                                  sx={{
                                    display: "inline-flex",
                                    flexWrap: 'nowrap',
                                    marginX: "12px",
                                    marginY: "12px",
                                    width: "190px",
                                    height: "76px",
                                    margin: "16px 8px 8px 16px",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                    backgroundColor: "#ffffff",
                                    cursor: 'move'
                                  }}>
                                  <CardContent>
                                    <Grid direction={"column"}>
                                      <Grid item
                                        sx={{
                                          fontFamily: "VWHead",
                                          fontSize: "14px",
                                          fontWeight: "normal",
                                          fontStretch: " normal",
                                          fontStyle: "normal",
                                          lineHeight: "1.29",
                                          letterSpacing: "normal",
                                          color: "#000000",
                                          marginTop: "8px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "160px",
                                        }}>
                                        {resource.resourceName}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card >
                              </Grid>
                            )}
                          </Draggable>
                        }) : type.activities ? type.activities.map((activity: any, index: number) => {
                          return <Draggable key={activity.id} draggableId={activity.id + "," + activity.name} index={index}>
                            {(provided) => (
                              <Grid item md={9}
                                sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}
                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Card
                                  sx={{
                                    display: "inline-flex",
                                    flexWrap: "nowrap",
                                    marginX: "12px",
                                    marginY: "12px",
                                    width: "190px",
                                    height: "76px",
                                    margin: "16px 8px 8px 16px",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                    backgroundColor: "#ffffff",
                                    cursor: 'move'
                                  }}>
                                  <CardContent>
                                    <Grid direction={"column"}>
                                      <Grid item
                                        sx={{
                                          fontFamily: "VWHead",
                                          fontSize: "14px",
                                          fontWeight: "normal",
                                          fontStretch: " normal",
                                          fontStyle: "normal",
                                          lineHeight: "1.29",
                                          letterSpacing: "normal",
                                          color: "#000000",
                                          marginTop: "8px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "160px",
                                        }}>
                                        {activity.name}
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card >
                              </Grid>
                            )}
                          </Draggable>
                        }) : []}
                        {provided.placeholder}
                      </Grid>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </Grid>
          }) : null}
        </Grid>
      </Grid>

      <Grid item
        xs={props.xsGdRight}
        sm={props.smGdRight}
        md={props.mdGdRight}
        lg={props.lgGdRight}
        sx={{
          maxWidth: "627px",
          minWidth: props.type === "laboratory" ? "282px" : (props.type === "resource" || props.type === "activity") ? "190px" : "0px",
          maxHeight: props.type === "laboratory" ? "430px" : props.type === "resource" ? "430px" : props.type === "activity" ? "430px" : "auto",
          minHeight: props.type === "laboratory" ? "430px" : props.type === "resource" ? "430px" : props.type === "activity" ? "430px" : "auto",
          marginY: "10px",
          marginLeft: { md: '0px', lg: '10px' },
        }}>
        <Grid
          sx={{
            maxHeight: props.type === "laboratory" ? "430px" : props.type === "resource" ? "368px" : props.type === "activity" ? "430px" : "auto",
            minHeight: props.type === "laboratory" ? "430px" : props.type === "resource" ? "368px" : props.type === "activity" ? "430px" : "auto",
            borderRadius: "4px",
            backgroundColor: "#dfe4e8",
            overflow: "scroll",
          }}>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} >
              <LabelComponent
                text={props.description}
                color={"#6a767d"}
                fontSize={"16px"}
                margin={"16px"}
              />
              <Grid item xs={12} >
                {props.valuesDrop?.length > 0 ?
                  props.valuesDrop.map((teste: any) => {
                    return <>
                      <Card
                        sx={{
                          display: "inline-flex",
                          flexWrap: 'nowrap',
                          minWidth: props.type === "laboratory" ? "282px" : (props.type === "resource" || props.type === "activity") ? "190px" : "0px",
                          width: props.type === "laboratory" ? "282px" : (props.type === "resource" || props.type === "activity") ? "190px" : "0px",
                          height: "84px",
                          margin: "16px 8px 8px 16px",
                          borderRadius: "8px",
                          boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                          backgroundColor: "#ffffff",
                        }}>
                        <CardContent sx={{ minWidth: props.type === "laboratory" ? "185px" : (props.type === "resource" || props.type === "activity") ? "158px" : "0px", }}>
                          <Grid container>
                            {props.type === "laboratory" ?
                              <Grid item>
                                <img style={{ width: "44px", height: "44px", marginTop: "5px", }}
                                  src={teste.typeDamage.id === 1 ? greenIcon : teste.typeDamage.id === 2 ? yellowIcon : teste.typeDamage.id === 3 ? redIcon : notLevelIcon} />
                              </Grid>
                              : null}
                            <Grid direction={"column"} columns={3}>
                              {props.type === "laboratory" ?
                                <Grid item
                                  sx={{
                                    fontFamily: "VWHead",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: " normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.29",
                                    letterSpacing: "normal",
                                    color: "#000000",
                                    marginTop: "8px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "160px",
                                  }}>
                                  {teste.name}
                                </Grid>
                                : null}
                              {props.type === "laboratory" ?
                                <Grid item
                                  sx={{
                                    fontFamily: "VWHead",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.29",
                                    letterSpacing: "normal",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "160px",
                                    color: teste.typeDamage.id === 1 ? green : teste.typeDamage.id === 2 ? yellow : teste.typeDamage.id === 3 ? red : notLevel
                                  }}>
                                  {teste.typeDamage.name}
                                </Grid>
                                : <Grid item
                                  sx={{
                                    fontFamily: "VWHead",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                    fontStretch: " normal",
                                    fontStyle: "normal",
                                    lineHeight: "1.29",
                                    letterSpacing: "normal",
                                    color: "#000000",
                                    marginTop: "8px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "160px",
                                  }}>
                                  {props.type === "resource" ? teste.resourceName : props.type === "activity" ? (teste.activityName ? teste.activityName : teste.name) : ""}
                                </Grid>}
                              <Grid item sx={{ textAlign: "right" }}>
                              </Grid>
                            </Grid>
                          </Grid>
                          <CardActions sx={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: props.type === "laboratory" ? "0px 3px 0px 60px" : (props.type === "resource" || props.type === "activity") ? "15px 0px 0px 10px" : "0px",
                            marginTop: props.type === "laboratory" ? "-10px" : (props.type === "resource" || props.type === "activity") ? "0px" : "0px",
                          }}>
                            {(props.type === "resource" || props.type === "activity") ?
                              <Avatar sx={{
                                width: "16px",
                                height: "16px",
                                fontSize: "12px",
                                backgroundColor: "#c2cacf"
                              }}>{teste.quantity}
                              </Avatar>
                              : null}
                            <Button sx={{ minWidth: '30px', padding: '0px' }}
                              onClick={(event) => cancel(teste)}>
                              <img style={{
                                width: "16px",
                                height: "16px",
                              }} src={closeIcon} />
                            </Button>
                          </CardActions>
                        </CardContent>
                      </Card >

                    </>
                  })
                  :
                  <>
                    <Grid item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: props.type === "laboratory" ? "23em" : props.type === "resource" ? "19em" : props.type === "activity" ? "23em" : "auto",
                        textAlign: 'center',
                        paddingX: { md: '0px', lg: '10px' },
                      }}>
                      <LabelComponent
                        opacity="0.5"
                        fontFamily="VWText"
                        fontSize="16px"
                        fontWeight="bold"
                        fontStretch="normal"
                        fontStyle="normal"
                        lineHeight="1.5"
                        letterSpacing="normal"
                        textAlign="center"
                        color="#6a767d"
                        text={props.type === "resource" && props.description === undefined || props.description === "" ? "Nenhum teste e laboratório selecionado! Escolha no campo acima o teste e laboratório para associar os recursos."
                          : props.type === "activity" && props.description === undefined || props.description === "" ? "Nenhum teste e laboratório selecionado! Escolha no campo acima o teste e laboratório para associar as atividades." :
                            props.type === "laboratory" && props.description === undefined || props.description === "" ? "Nenhum laboratório selecionado! Escolha no campo acima o laboratório para associar os testes." : ""} />

                    </Grid>

                  </>
                }
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        {props.type === "resource"
          ?
          <>
            <Grid sx={{
              backgroundColor: "#dfe4e8",
              borderRadius: "8px",
              marginTop: '12px',
              paddingY: "15px",
              paddingLeft: '10px',
              paddingRight: '40px',
            }}>
              <Grid container style={{
                fontFamily: "VWHead",
                fontSize: "16px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "normal",
                justifyContent: 'space-between',
              }}>
                <Grid item sx={{ color: "#001e50", textAlign: "center" }}>
                  Total do custo dos recursos consumíveis
                </Grid>
                <Grid item sx={{ color: "#6a767d" }} >
                  <div>
                    R$ {props.value ? props.value.toFixed(2).replace('.', ',') : "0,00"}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
          : null}
      </Grid>
    </Grid>
  </>
}
import React, { useState } from "react";
import { Alert } from "@mui/material";

function AlertComponent(props: AlertParamsField) {
    
    return (
        <Alert
            severity={props.success ? "success" : "error"}
            sx={{
                width: "100%",
                backgroundColor: props.success ?  "#5b08a4": "#ff0057" ,
                fontFamily: "VWText",
                fontSize: "16px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "1.25",
                letterSpacing: "normal",
                color: "#fff",
                marginBottom: "32px",
                "& .MuiAlert-icon": {
                    color: "#ffff"
                }
            }}>
            {props.text}
        </Alert>
    );
}

export default AlertComponent;
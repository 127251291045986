import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import ButtonComponent from "../../../components/button/buttonComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1TravelProvision(props: any) {
    const { loading, displayAlert,
        isError, setIsError,
        messageAlert, provisionById, read, setRead,
        setProvisionById, provisionByIdTotals, access,
        setProvisionByIdTotals, fetchSaveTravelProvision } = useTestPlanTab1(props.currentModal, props.type, props.id);

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    const totalsCalculation = (row: TravelProvisionPost) => {
        let n7: number = 0;
        let n15: number = 0;
        let i15: number = 0;
        let i30: number = 0;
        row.supervisions.map((item: SupervisionsTravel) => {
            item.travels.map((travel: Travel) => {
                let travelCost = travel.cost ? travel.cost.toString().search(',') >= 0 ? Number(travel.cost.toString().replace(',', '.')) : travel.cost : 0;
                switch (travel.type) {
                    case "N7":
                        n7 = n7 + travel.quantity * travelCost;
                        break;
                    case "N15":
                        n15 = n15 + travel.quantity * travelCost;
                        break;
                    case "I15":
                        i15 = i15 + travel.quantity * travelCost;
                        break;
                    case "I30":
                        i30 = i30 + travel.quantity * travelCost;
                        break;
                    default:
                        break;
                }
            });
        });
        return { n7: n7, n15: n15, i15: i15, i30: i30 }
    };

    return (
        access ?
            <Grid container>
                {loading && <LoadingComponente></LoadingComponente>}
                <ModalReadPage open={read} onCancel={() => setRead(false)} />
                <ModalComponent
                    description={messageAlert}
                    isOk={true}
                    open={isError}
                    close={() => { setIsError(false) }}
                    onCancel={() => { setIsError(false) }}
                    onNext={() => setIsError(false)}
                />

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelComponent fontSize={"20px"} text="Custos de viagem por supervisão" />
                </Grid>

                {provisionById &&
                    <Grid container>
                        <Grid container sx={{
                            backgroundColor: '#dfe4e8',
                            borderRadius: "5px",
                            color: "#001e50",
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            letterSpacing: "normal",
                            marginTop: "16px",
                            padding: "20px",
                            textAlign: "center",
                        }}>

                            <Grid container>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}></Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Nacionais<br />(7 dias)
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Nacionais<br />(15 dias)
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Internacionais<br />(15 dias)
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Internacionais<br />(30 dias)
                                </Grid>
                            </Grid>

                            {provisionById.supervisions.map((item: SupervisionsTravel, index: number) =>
                                <Grid key={index} container sx={{ marginTop: "24px" }}>
                                    <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        {item.supervisionInitials}
                                    </Grid>

                                    {item.travels.map((subItem: Travel, subItemIndex: number) =>
                                        <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                            <div style={{
                                                textAlign: "center",
                                                alignItems: "center",
                                                backgroundColor: "#fff",
                                                border: "solid 0.5px #dfe4e8",
                                                borderRadius: "8px",
                                                boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                display: "flex",
                                                fontFamily: 'VWHead',
                                                fontSize: "16px",
                                                fontWeight: "light",
                                                justifyContent: "center",
                                                width: "200px",
                                            }}>
                                                <NumberFormat
                                                    style={{
                                                        textAlign: "center",
                                                        alignItems: "center",
                                                        border: "none",
                                                        display: "flex",
                                                        fontSize: "16px",
                                                        height: "40px",
                                                        justifyContent: "center",
                                                        outline: "none",
                                                        paddingTop: "3px",
                                                        width: "calc(100% - 50px)",
                                                    }}
                                                    displayType={"input"}
                                                    type="text"
                                                    decimalSeparator={""}
                                                    allowNegative={false}
                                                    value={subItem.quantity}
                                                    onChange={(event: any) => {
                                                        let newArr = provisionById;
                                                        newArr.supervisions[index].travels[subItemIndex] = {
                                                            ...newArr.supervisions[index].travels[subItemIndex],
                                                            quantity: Number(event.target.value)
                                                        };
                                                        setProvisionById({ ...provisionById, supervisions: [] });
                                                        setProvisionById({ ...provisionById, supervisions: newArr.supervisions });
                                                        setProvisionByIdTotals(totalsCalculation(newArr));
                                                    }}
                                                ></NumberFormat>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            )}

                            <Grid container sx={{ marginTop: "24px" }}>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Custo individual
                                </Grid>

                                {provisionById.supervisions[0].travels.map((totalRow: any, index: number) =>
                                    <Grid key={index} item xs={12} sm={12} md={2.4} lg={2.4} sx={{ display: "flex!important", justifyContent: "center!important" }}>
                                        <div style={{
                                            textAlign: "center",
                                            alignItems: "center",
                                            backgroundColor: "#fff",
                                            border: "solid 0.5px #dfe4e8",
                                            borderRadius: "8px",
                                            boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                            display: "flex",
                                            fontFamily: 'VWHead',
                                            fontSize: "16px",
                                            fontWeight: "light",
                                            justifyContent: "center",
                                            width: "200px",
                                        }}>
                                            <span style={{ fontWeight: "500", fontFamily: 'VWHead' }}>R$</span>
                                            <NumberFormat
                                                style={{
                                                    textAlign: "center",
                                                    alignItems: "center",
                                                    border: "none",
                                                    display: "flex",
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    justifyContent: "center",
                                                    outline: "none",
                                                    paddingTop: "3px",
                                                    width: "calc(100% - 50px)",
                                                }}
                                                displayType={"input"}
                                                type="text"
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                allowNegative={false}
                                                value={Number(totalRow.cost.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                onChange={(event: any) => {
                                                    let newArr = provisionById;
                                                    newArr.supervisions.map((item, subIndex) => {
                                                        item.travels[index] = {
                                                            ...newArr.supervisions[subIndex].travels[index],
                                                            cost: event.target.value
                                                        };
                                                    });
                                                    setProvisionById({ ...provisionById, supervisions: [] });
                                                    setProvisionById({ ...provisionById, supervisions: newArr.supervisions });
                                                    setProvisionByIdTotals(totalsCalculation(newArr));
                                                }}
                                                onBlur={() => setProvisionById({ ...provisionById })}
                                            ></NumberFormat>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container sx={{ marginTop: "16px" }}>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                                    Custo total
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ color: "#6a767d" }}>
                                    {Number(provisionByIdTotals.n7).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ color: "#6a767d" }}>
                                    {Number(provisionByIdTotals.n15).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ color: "#6a767d" }}>
                                    {Number(provisionByIdTotals.i15).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2.4} lg={2.4} sx={{ color: "#6a767d" }}>
                                    {Number(provisionByIdTotals.i30).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                                <ButtonComponent
                                    text="Cancelar"
                                    disable={true}
                                    onClick={() => {
                                        props.setOpenCancelModal(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }} >
                                <ButtonComponent
                                    text="Salvar"
                                    active={true}
                                    onClick={() => {
                                        fetchSaveTravelProvision(provisionById);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1TravelProvision;
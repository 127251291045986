import { Box, Grid } from "@mui/material";
import { MenuComponent } from "../../components/menu/menuComponent";
import HeaderComponent from "../../components/header/headerComponent";
import gifHome from '../../assets/images/gifHome.gif';
import useHome from "../../hooks/home/useHome";
import ModalComponent from "../../components/modal/modalComponent";

function HomePage() {

    const { name, access } = useHome();

    return (
        <Box>
            <div
                style={{
                    background: 'linear-gradient(0deg, rgba(0,30,80,1) 0%, rgba(0,67,122,1) 100%)',
                    position: 'absolute',
                    height: '100vh',
                    paddingLeft: '24px',
                    paddingTop: '4px',
                    width: '44px',
                    zIndex: 1
                }}
            >
            </div>

            <HeaderComponent isHome={true} access={access}/>

            <img src={gifHome} style={{ position: "fixed", opacity: "0.1" }} />

            <Grid container style={{
                alignItems: "center",
                fontFamily: 'VWHead',
                height: "100vh",
                justifyContent: "end",
            }}>
                <Grid item md={6}>
                    <p style={{ fontSize: 24, fontWeight: '300', width: '60%' }}>Olá {name}</p>
                    <p style={{ fontSize: 72, margin: '40px 0' }}>
                        <strong>Seja bem vindo ao</strong><br />
                        <span style={{ fontWeight: '300' }}>Sistema</span> <strong>"STapp"</strong>
                    </p>
                    <p style={{ fontSize: 40 }}><i>Safety Tests application</i></p>
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomePage;
import { Box, Button, Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { GridColDef } from "@mui/x-data-grid";
import SearchComponent from "../../components/search/searchComponent";
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import ModalComponent from "../../components/modal/modalComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { Navigate, useLocation, Link, useNavigate } from "react-router-dom";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function VehiclePageList() {
    const [id, setId] = useState<number | null>(null);
    const [name, setName] = useState<string>("");
    const [initials, setInitials] = useState<string>("");
    const [userId, setUerId] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [errorDisable, setErrorDisable] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");
    const [vehicles, setVehicles] = useState<Supervision[]>([]);
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);
    const [openDisableError, setOpenDisableError] = useState(false);
    const navigate = useNavigate();
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    const columns: GridColDef[] = [
        { field: "vds", headerName: "Número do VDS", flex: 0.2, minWidth: 100, },
        { field: "positionVDS", headerName: "Posição VDS", flex: 0.12, minWidth: 100, },
        { field: "chassis", headerName: "Chassi", flex: 0.15, minWidth: 100 },
        { field: "carBrandName", headerName: "Fabricante", flex: 0.1, minWidth: 100 },
        { field: "carCategoryName", headerName: "Categoria", flex: 0.1, minWidth: 100 },
        { field: "carModel", headerName: "Modelo", flex: 0.13, minWidth: 100 },
        {
            field: "action", headerName: "Ação", flex: 0.2, minWidth: 250,
            renderCell: (value) => {
                if (value.row.active) {
                    return <Box flex={3}>
                        <ButtonTextComponent text="Desativar" onClick={() => {
                            setOpenDisable(true);
                            setId(value.row.id);
                        }} />
                        <ButtonTextComponent text={<Link style={{ color: "#00b0f0", textDecoration: "none" }} to={"editar/" + value.row.id}>Editar</Link>} />
                        <ButtonTextComponent text={<Link style={{ color: "#00b0f0", textDecoration: "none" }} to={"clonar/" + value.row.id}>Clonar</Link>} />
                    </Box>
                } else {
                    return <Box flex={1}>
                        <ButtonTextComponent text="Ativar" onClick={() => {
                            setVehicles([]);
                            setLoading(true);
                            put('projectCars/' + value.row.id + '/active/' + true).then((value) => {
                                getList(filter, filter1, filter2, page, pageSize);
                            });
                        }} />
                    </Box>
                }
            }
        },
    ];

    const [carBrandList, setCarBrandList] = useState<CarsBrand[]>([]);
    const [carCategoriesList, setCarCategoriesList] = useState<CarsCategory[]>([]);

    // #region Functions
    useEffect(() => {
        setAccess(fetchPage("cadastro-veiculos"));
        getList(filter, filter1, filter2, page, pageSize);
        getCarBrandList();
        getCarCategoriesList();
    }, []);

    function getCarBrandList() {
        get('carBrands').then((values) => {
            setCarBrandList(values);
        });
    }

    function getCarCategoriesList() {
        get('carCategories').then((values) => {
            setCarCategoriesList(values);
        });
    }

    function getList(filter: string, filter1: string, filter2: string, page: number, pageSize: number) {
        if (!isFilter) {
            post('projectCars/all', { "param": filter, "page": page, "size": pageSize }).then((value) => {
                setVehicles(value.content);
                setTotalElements(value.totalElements);
                setTotalPages(value.totalPages);
                setLoading(false);
            });
        } else {
            post('projectCars/all', {
                "param": filter,
                "carBrand": filter2,
                "carCategory": filter1,
                "page": page,
                "size": pageSize
            }).then((value) => {
                setVehicles(value.content);
                setTotalElements(value.totalElements);
                setTotalPages(value.totalPages);
                setLoading(false);
            }
            );
        }
    };

    const filterList = (event: any) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            getList("", "", "", page, pageSize);
        } else {
            setFilter(event.target.value);
            getList(event.target.value, filter1, filter2, page, pageSize);
        }
    }

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    const handleCloseModalDisable = (e: any) => {
        setOpenFilter(false);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenAlert(false);
        window.location.reload();
    };
    // #endregion Functions

    return (
        access ?
        <Grid container>
            <HeaderComponent text="Cadastro de Sistema / Cadastro de Veículos" access={access}/>

            <ModalComponent description="Ao desativar este Veículo, não será mais possível atribuir novas interações à ele."
                onNext={() => {
                    put('projectCars/' + id + '/active/' + false).then((value) => {
                        if (value.codeStatus === 200) {
                            setVehicles([]);
                            setLoading(true);
                            getList(filter, filter1, filter2, page, pageSize);
                            setId(null);
                            setOpenDisable(false);
                        } else {
                            setOpenDisable(false);
                            setErrorDisable("Não é possível desativar este Veículo.");
                            setOpenDisableError(true);
                        }
                    });
                }}
                onCancel={() => { setOpenDisable(false) }}
                open={openDisable}
                close={handleCloseModalDisable}
                text=""
            />

            <ModalFilterComponent
                open={openFilter}
                labelFilter1={"Categoria"}
                value1={filter1}
                filter1={carCategoriesList}
                onSelect1={(event: any) => {
                    setFilter1(event.target.value);
                    setIsFilter(true);
                }}

                labelFilter2={"Fabricante"}
                value2={filter2}
                filter2={carBrandList}
                onSelect2={(event: any) => {
                    setFilter2(event.target.value);
                    setIsFilter(true);
                }}

                close={handleCloseModalFilter}
                onCancel={() => { setOpenFilter(false) }}
                onNext={() => {
                    if (filter1 === "" && filter2 === "") {
                        setPage(0);
                        setPageSize(5);
                    }
                    getList(filter, filter1, filter2, page, pageSize);
                    setOpenFilter(false);
                }}
            />

            <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
                <Grid container sx={{ paddingBottom: "20px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <LabelComponent text="Lista " fontSize="28px" />
                        <LabelComponent text="de Veículos" fontWeight="normal" fontSize="28px" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                        <Link to="/cadastro-veiculos/novo" style={{ textDecoration: "none" }}>
                            <Button variant="contained" style={{ padding: "8px 40px", textTransform: "capitalize" }}>Criar Novo</Button>
                        </Link>
                    </Grid>
                </Grid>

                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                        <SearchComponent text="" value={filter} placeholder="Buscar por …"
                            onChange={filterList}
                            onClick={() => {
                                setOpenFilter(true)
                            }}
                            onCancel={() => {
                                setFilter("");
                                getList('', filter1, filter2, page, pageSize);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataTableComponent
                        rows={vehicles}
                        columns={columns}
                        loading={loading}
                        page={page}
                        pageSize={pageSize}
                        totalElements={totalElements}
                        onPageSizeChange={(page, pageSize) => {
                            setPage(page)
                            setPageSize(pageSize)
                            getList(filter, filter1, filter2, page, pageSize);
                        }}
                        onPageChange={(page, pageSize) => {
                            setPage(page)
                            setPageSize(pageSize)
                            getList(filter, filter1, filter2, page, pageSize);
                        }}
                        onClick={filterList} />
                </Grid>
            </Grid>
        </Grid>
        : <NotAuthorizedPage/>
    );
}

export default VehiclePageList;
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import LabelComponent from "../../components/label/labelComponent";
import HeaderComponent from "../../components/header/headerComponent";
import SelectComponent from "../../components/select/selectComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import SearchComponent from "../../components/search/searchComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { calculaUltimaLinha, hourToMinutes, hourToMinutesSubtraction, minutesToHours, } from "../../utils/utils";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import RadioButtonComponentYesOrNot from "../../components/radio/radioButtonYesOrNotComponent";
import ModalReadPage from "../../components/modal/modalReadPage";
import useRoute from "../../hooks/route/useRoute";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function ActivityTypePage() {
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const [activityCategoryList, setActivityCategoryList] = useState<ActivityCategory[]>([]);
    const [activitiesList, setActivitiesList] = useState<ActivityList[]>([]);
    const [loading, setLoading] = useState(true);
    const [activityActive, setActivityActive] = useState<any>("");
    const [supervisionList, setSupervisionList] = useState([]);
    const [loadingSupervisionList, setLoadingSupervisionList] = useState(true);
    const [rows, setRows] = useState<any>([]);
    const [openDisable, setOpenDisable] = useState(false);
    const [valid, setValid] = useState(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [id, setId] = useState<number | null>(0);
    const [name, setName] = useState<string>("");
    const [activityTypeCategory, setActivityTypeCategory] = useState<string>("");
    const [showCalendar, setShowCalendar] = useState<string>("false");
    const [pageSelectActive, setPageSelectActive] = useState<number>(0);
    const [totalPagesSelectActive, setTotalPagesSelectName] = useState<number>(0);
    const [totalValue, setTotalValue] = useState<any>('00:00');
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    // #region Funções da Página
    const handleSubmit = (e: any) => {
        let activitiesHours: any = [];
        let totalHours: number = 0;

        rows.forEach((row: any) => {
            if (!(row.hour === '00:00') || !(row.month === '00:00') || !(row.third === '00:00')) {
                activitiesHours.push({
                    "supervisionId": row.id,
                    "hoursPart": hourToMinutes(row.hour),
                    "hoursMonth": hourToMinutes(row.month),
                    "hoursThird": hourToMinutes(row.third)
                });
            }
        });

        totalHours = hourToMinutes(totalValue.hour.replace('h', '')) + hourToMinutes(totalValue.month.replace('h', '')) + hourToMinutes(totalValue.third.replace('h', ''));

        if (name && activityTypeCategory && (showCalendar == "true" || showCalendar == "false") ? true : false) {
            if (!isEdit) {
                post<ActivityList[]>("activities", {
                    "name": name,
                    "typeActivityCategory": activityTypeCategory,
                    "active": "true",
                    "showCalendar": showCalendar,
                    "totalActivitiesHours": totalHours,
                    "valueActivities": activitiesHours
                }).then((value: any) => {
                    if (value.codeStatus == 201) {
                        setOpenAlert(true);
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setMessageSave(value.errorMessage);
                    }
                });
            } else {
                put<ActivityList[]>("activities", {
                    "id": id,
                    "name": name,
                    "typeActivityCategory": activityTypeCategory,
                    "showCalendar": showCalendar,
                    "active": "true",
                    "totalActivitiesHours": totalHours,
                    "valueActivities": activitiesHours
                }).then((value: Supervision) => {
                    if (value.codeStatus === 200) {
                        setOpenAlert(true)
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setMessageSave(value.errorMessage);
                    }
                });
            }
        }
    }

    const columnsActivities: GridColDef[] = [
        {
            field: "name", headerName: "Nome da atividade", flex: 1, minWidth: 200,
        },
        { field: "typeActivityCategory", headerName: "Categoria", flex: 0.3, minWidth: 200 },
        {
            field: "showCalendar", headerName: "Exibido no calendario", flex: 0.3, minWidth: 200,
            valueGetter: (params: any) => {
                return params.row.showCalendar ? "Sim" : "Não";
            }
        },
        {
            field: "totalActivitiesHours", headerName: "Duração", flex: 0.3, minWidth: 200,
            valueGetter: (params: any) => {
                return minutesToHours(params.row.totalActivitiesHours.toString().replace('.', ':')) + " h";
            },
            sortComparator: (value1: any, value2: any) => {
                return hourToMinutesSubtraction(value1, value2);
            }
        },
        {
            field: "action", headerName: "Ação", flex: 0.4, minWidth: 250,
            renderCell: (value: any) => {
                if (value.row.active) {
                    return <Box flex={3}>
                        <ButtonTextComponent text="Desativar" onClick={() => {
                            setOpenDisable(true);
                            setId(value.row.id);
                        }} />
                        <ButtonTextComponent text="Editar" onClick={() => {
                            setId(value.row.id);
                            setName(value.row.name);
                            setShowCalendar(value.row.showCalendar.toString());
                            setValid(true);
                            setIsEdit(true);
                            _getRegisterData(value.row.id);
                        }} />
                        <ButtonTextComponent text="Clonar" onClick={() => {
                            setId(value.row.id);
                            setName(value.row.name);
                            setShowCalendar(value.row.showCalendar.toString());
                            setValid(true);
                            setIsEdit(false)
                            _getRegisterData(value.row.id);
                        }} />
                    </Box>
                } else {
                    return <Box flex={1}>
                        <ButtonTextComponent text="Ativar" onClick={() => {
                            setActivitiesList([]);
                            setLoading(true);
                            put('activities/' + value.row.id + '/active/' + true).then((value) => {
                                getList(filter, filter1, filter2, page, pageSize);
                            });
                        }} />
                    </Box>
                }
            }
        },
    ];

    useEffect(() => {
        setAccess(fetchPage("atividades"));
        getList(filter, filter1, filter2, page, pageSize);
        getSupervisionList();
        getActivityCategoryList();
        getActivityActive(pageSelectActive);
    }, []);

    function getList(filter: string, filter1: string, filter2: string, page: number, pageSize: number) {
        if (!isFilter) {
            post('activities/all', { "param": filter, "page": page, "size": pageSize }).then((value) => {
                setActivitiesList(value.content);
                setTotalElements(value.totalElements);
                setTotalPages(value.totalPages);
                setLoading(false);
            });
        } else {
            post('activities/all',
                {
                    "param": filter,
                    "name": filter1,
                    "typeActivityCategory": filter2,
                    "page": page,
                    "size": pageSize
                }).then((value) => {
                    setActivitiesList(value.content);
                    setTotalElements(value.totalElements);
                    setTotalPages(value.totalPages);
                    setLoading(false);
                });
        }
    };

    function getActivityActive(page: any) {
        post("activities/active/true",
            {
                "page": page,
                "size": 10
            }).then((value) => {
                setActivityActive(value.content);
                setTotalPagesSelectName(value.totalPages);
            });
    };

    function getActivityCategoryList() {
        get<ActivityCategory>("categoryActivities").then((value) => {
            setActivityCategoryList(value);
        });
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenAlert(false);
        window.location.reload();
    };

    const filterList = (event: any) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            getList("", "", "", page, pageSize);
        } else {
            setFilter(event.target.value);
            getList(event.target.value, filter1, filter2, page, pageSize);
        }
    }

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
        window.location.reload();
    };

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    const handleCloseModalDisable = (e: any) => {
        setOpenFilter(false);
    };

    // #region 1 - (Abre a tela) Pega a lista de supervisões e lista com os valores zerados (só que em string H:M), já deixando pronto para um novo cadastro
    function getSupervisionList() {
        post('supervisions/active/true', { "size": 9999999, "initials": "", "name": "", "page": 0, "param": "", "user": "" }).then((value) => {
            setSupervisionList(value.content);
            createArrayForNewRegister(value.content);
            setLoadingSupervisionList(false);
        });
    };

    function createArrayForNewRegister(_supervisionList: any) {
        let newArray: any = [];
        newArray = _supervisionList.map((row: any) =>
            ({ id: row.id, name: row.name, hour: '00:00', month: '00:00', third: '00:00', totalRow: '00:00' })
        );
        setTotalValue({ id: 0, name: "Total", hour: '00:00h', month: '00:00h', third: '00:00h', totalRow: '00:00' });
        setRows(newArray);
    }
    // #endregion

    // #region 2 - (Edição de registro) Se clicar em editar, chama no banco a api de edição de atividade
    function _getRegisterData(id?: number) {
        get("activities/" + id).then((value) => {
            setActivityTypeCategory(value.categoryActivity.id)
            joinSupervisionAndActivitiesValuesAndCalcuteForEdit(value);
        });
    }
    // #endregion

    // #region 3 - (Edição de registro) Cria um nova variável que vai pegar o que veio do back, e manda pra função principal do componente de calcula, depois mostra na tela os dados atualizados
    function joinSupervisionAndActivitiesValuesAndCalcuteForEdit(activityData: any) {
        let newArray: any = [];
        let hasValue: boolean;

        supervisionList.forEach((supervisionRow: any) => {
            hasValue = false;
            activityData.valueActivities.forEach((activityRow: any) => {
                if (supervisionRow.id === activityRow.supervisionId) {
                    hasValue = true;
                    newArray.push({
                        id: supervisionRow.id,
                        name: supervisionRow.name,
                        hour: minutesToHours(activityRow.hoursPart),
                        month: minutesToHours(activityRow.hoursMonth),
                        third: minutesToHours(activityRow.hoursThird),
                        totalRow: minutesToHours(activityRow.hoursPart + activityRow.hoursMonth + activityRow.hoursThird)
                    })
                }
            });

            if (!hasValue) {
                newArray.push({
                    id: supervisionRow.id,
                    name: supervisionRow.name,
                    hour: '00:00',
                    month: '00:00',
                    third: '00:00',
                    totalRow: '00:00'
                })
            }
        });
        setTotalValue(calculaUltimaLinha(newArray));
        setRows(newArray);
    }
    // #endregion

    return (
        access ?
            <Grid container md={12}>
                <HeaderComponent text="Cadastro do Sistema / Tipos de Atividades" access={access}/>
                <SnackbarComponent text={isEdit ? "Tipo de Atividade editada com sucesso" : "Tipo de Atividade salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
                <ModalComponent
                    description="Atenção! Ao desativar este tipo de atividade, não será mais possível associá-la aos tipos de testes existentes enquanto ela estiver com status inativo."
                    open={openDisable}
                    close={handleCloseModalDisable}
                    onCancel={() => { setOpenDisable(false) }}
                    onNext={() => {
                        setActivitiesList([]);
                        setLoading(true);
                        put('activities/' + id + '/active/' + false).then((value) => {
                            getList(filter, filter1, filter2, page, pageSize);
                            setId(null);
                            setOpenDisable(false);
                        });
                    }}
                />
                <ModalFilterComponent
                    open={openFilter}
                    labelFilter1={"Nome da atividade"}
                    isPagination1={true}
                    value1={filter1}
                    url1="activities/active/true"
                    onSelect1={(paginationEvent: string) => {
                        setFilter1(paginationEvent);
                        setIsFilter(true);
                    }}
                    labelFilter2={"Categoria"}
                    value2={filter2}
                    filter2={activityCategoryList}
                    onSelect2={(event: any) => {
                        setFilter2(event.target.value);
                        setIsFilter(true);
                    }}
                    close={handleCloseModalFilter}
                    onCancel={() => { setOpenFilter(false) }}
                    onNext={() => {
                        if (filter1 === "" && filter2 === "") {
                            setPage(0);
                            setPageSize(5);
                        }
                        getList(filter, filter1, filter2, page, pageSize);
                        setOpenFilter(false);
                    }} />
                <ModalComponent
                    description={isEdit ? "Deseja realmente cancelar a edição da Atividade?" : "Deseja realmente cancelar a criação de uma nova Atividade?"}
                    open={openModal}
                    close={handleCloseModal}
                    onCancel={() => {
                        setOpenModal(false);
                    }}
                    onNext={() => {
                        setName("");
                        setActivityTypeCategory("");
                        setShowCalendar("false");
                        setValid(false);
                        setOpenModal(false);
                        window.location.reload();
                    }}
                />
                <ModalReadPage open={read} onCancel={() => setRead(false)} />
                <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={12} lg={3}>
                            <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" />
                            <LabelComponent text="Atividade" fontSize="28px" fontWeight="normal" />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: "32px" }}>
                        <Grid item xs={12} sm={6} md={12} lg={3} >
                            <LabelComponent text="Dados da atividade" />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
                            <TextFieldComponent
                                text="Nome da atividade"
                                helperText={messageSave?.includes('Nome') ? messageSave : activityActive?.fields && name === "" ? "Campo obrigatório" : ""}
                                error={messageSave?.includes('Nome') || activityActive?.fields && name === "" ? true : false}
                                required={true}
                                value={name}
                                maxLength={100}
                                onChange={(event: any) => {
                                    setName(event.target.value);
                                    setMessageSave("");
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
                            <SelectComponent
                                helperText={messageSave?.includes('Categoria') ? messageSave : activityActive?.fields && activityTypeCategory === "" ? "Campo obrigatório" : ""}
                                values={activityCategoryList}
                                text="Categoria"
                                value={activityTypeCategory}
                                required={true}
                                onSelect={(event: any) => {
                                    setActivityTypeCategory(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={1}></Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ margin: "0 56px 0px 0px" }}>
                            <RadioButtonComponentYesOrNot
                                text="Exibir atividade no calendário?"
                                value={showCalendar}
                                required={true}
                                onChange={(event: any) => {
                                    setShowCalendar(event.target.value);
                                }}
                            ></RadioButtonComponentYesOrNot>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: "32px" }}>
                        <Grid item xs={12} sm={6} md={12} lg={3} >
                            <LabelComponent text="Horas da atividade" />
                        </Grid>
                    </Grid>

                    {rows ?
                        <Grid
                            container sx={{
                                backgroundColor: "#dfe4e8",
                                borderRadius: "8px",
                                marginTop: "32px",
                                marginBottom: "16px",
                                padding: "32px 24px",
                                width: "700px",
                                overflowX: "auto",
                                minWidth: "700px"
                            }}>
                            <Grid container style={{
                                backgroundColor: '#dfe4e8',
                                borderRadius: "0 0 4px 4px",
                                color: "#001e50",
                                fontFamily: "VWHead",
                                fontSize: "16px",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                letterSpacing: "normal",
                                textAlign: "center",
                            }}>
                                <Grid item xs={4}>Supervisão</Grid>
                                <Grid item xs={2}>Horista</Grid>
                                <Grid item xs={2}>Mensalista</Grid>
                                <Grid item xs={2}>Terceiro</Grid>
                                <Grid item xs={2}>Total Supervisão</Grid>
                            </Grid>
                            <Grid container style={{ maxHeight: "300px", overflowY: "auto" }}>
                                {rows.map((value: any) => {
                                    return <DataTableEditComponent
                                        type="activity"
                                        name={value.name}
                                        hour={value.hour}
                                        month={value.month}
                                        third={value.third}
                                        totalRow={value.totalRow}
                                        onChangeHour={(event) => value.hour = event.target.value}
                                        onBlurHour={(event) => {
                                            let typedValue = event.target.value.replaceAll(' ', '').replace(':', '');
                                            if (event.target.value.length === 0) {
                                                event.target.value = '00:00';
                                                value.hour = '00:00';
                                            } else if (typedValue.length === 1) {
                                                event.target.value = typedValue + '0:00';
                                                value.hour = typedValue + '0:00';
                                            } else if (typedValue.length === 2) {
                                                event.target.value = typedValue + ':00';
                                                value.hour = typedValue + ':00';
                                            } else if (typedValue.length === 3) {
                                                event.target.value = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                                value.hour = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                            } else {
                                                value.hour = event.target.value;
                                            }
                                            value.totalRow = (minutesToHours(
                                                hourToMinutes(value.hour ? value.hour : "00:00") +
                                                hourToMinutes(value.month ? value.month : "00:00") +
                                                hourToMinutes(value.third ? value.third : "00:00")));
                                            setTotalValue(calculaUltimaLinha(rows));
                                        }}
                                        onChangeMonth={(event) => value.month = event.target.value}
                                        onBlurMonth={(event) => {
                                            let typedValue = event.target.value.replaceAll(' ', '').replace(':', '');
                                            if (event.target.value.length === 0) {
                                                event.target.value = '00:00';
                                                value.month = '00:00';
                                            } else if (typedValue.length === 1) {
                                                event.target.value = typedValue + '0:00';
                                                value.month = typedValue + '0:00';
                                            } else if (typedValue.length === 2) {
                                                event.target.value = typedValue + ':00';
                                                value.month = typedValue + ':00';
                                            } else if (typedValue.length === 3) {
                                                event.target.value = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                                value.month = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                            } else {
                                                value.month = event.target.value;
                                            }
                                            value.totalRow = (minutesToHours(
                                                hourToMinutes(value.hour ? value.hour : "00:00") +
                                                hourToMinutes(value.month ? value.month : "00:00") +
                                                hourToMinutes(value.third ? value.third : "00:00")));
                                            setTotalValue(calculaUltimaLinha(rows));
                                        }}
                                        onChangeThird={(event) => value.third = event.target.value}
                                        onBlurThird={(event) => {
                                            let typedValue = event.target.value.replaceAll(' ', '').replace(':', '');
                                            if (event.target.value.length === 0) {
                                                event.target.value = '00:00';
                                                value.third = '00:00';
                                            } else if (typedValue.length === 1) {
                                                event.target.value = typedValue + '0:00';
                                                value.third = typedValue + '0:00';
                                            } else if (typedValue.length === 2) {
                                                event.target.value = typedValue + ':00';
                                                value.third = typedValue + ':00';
                                            } else if (typedValue.length === 3) {
                                                event.target.value = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                                value.third = typedValue[0] + typedValue[1] + ':' + typedValue[2] + '0';
                                            } else {
                                                value.third = event.target.value;
                                            }
                                            value.totalRow = (minutesToHours(
                                                hourToMinutes(value.hour ? value.hour : "00:00") +
                                                hourToMinutes(value.month ? value.month : "00:00") +
                                                hourToMinutes(value.third ? value.third : "00:00")));
                                            setTotalValue(calculaUltimaLinha(rows))
                                        }}
                                    />
                                })}
                            </Grid>
                            <Grid container style={{ backgroundColor: '#dfe4e8', borderRadius: "0 0 4px 4px", fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal" }}>
                                <Grid item xs={4} sx={{ color: "#001e50", padding: "10px 0 15px 15px", textAlign: "center" }}>Total Cargo</Grid>
                                <Grid item xs={8} sx={{ padding: "10px 0 15px 0", color: "#6a767d" }} >
                                    <div style={{ display: "inline-flex", paddingLeft: '20px', width: '82px' }}>{totalValue.hour}</div>
                                    <div style={{ display: "inline-flex", paddingLeft: '20px', width: '80px' }}>{totalValue.month}</div>
                                    <div style={{ display: "inline-flex", paddingLeft: '20px', width: '82px' }}>{totalValue.third}</div>
                                    <div style={{ display: "inline-flex", paddingLeft: '50px', width: '80px' }}>{totalValue.totalRow}h</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        : <LoadingComponente />
                    }
                    <Grid container>
                        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
                            <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
                        </Grid>
                        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
                            <ButtonComponent text="Salvar" active={name && activityTypeCategory && (showCalendar == "true" || showCalendar == "false") ? true : false} onClick={handleSubmit} />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
                            <LabelComponent text="Lista " fontSize="28px" />
                            <LabelComponent text="de Atividades" fontWeight="normal" fontSize="28px" />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                            <SearchComponent text=""
                                value={filter}
                                placeholder="Buscar por …"
                                onChange={filterList}
                                isClean={true}
                                onClickClean={() => {
                                    setFilter("");
                                    setFilter1("");
                                    setFilter2("");
                                    getList("", "", filter2, page, pageSize);
                                }}
                                onClick={() => { setOpenFilter(true) }}
                                onCancel={() => {
                                    setFilter("");
                                    getList('', filter1, filter2, page, pageSize);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DataTableComponent
                            rows={activitiesList}
                            columns={columnsActivities}
                            loading={loading}
                            page={page}
                            pageSize={pageSize}
                            totalElements={totalElements}
                            onPageSizeChange={(page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                                getList(filter, filter1, filter2, page, pageSize);
                            }}
                            onPageChange={(page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                                getList(filter, filter1, filter2, page, pageSize);
                            }}
                            onClick={filterList} />
                    </Grid>
                </Grid>
            </Grid>
            : <NotAuthorizedPage />
    );
}

export default ActivityTypePage;
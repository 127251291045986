import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";

const useTestPlanTab4 = (state: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGrid, setLoadingGrid] = useState<boolean>(true);
    const [requestTestsComponent, setRequestTestsComponent] = useState<any>();
    const [requestTestsVehicle, setRequestTestsVehicle] = useState<any>();
    const [totalsReviewCost, setTotalsReviewCost] = useState<any>();
    const [phaseName, setPhaseName] = useState<string>();
    const [phaseStatus, setPhaseStatus] = useState<string>();
    const [firstYear, setFirstYear] = useState<number>();
    const [lastYear, setLastYear] = useState<number>();
    const [dataStep, setDataStep] = useState<any>();
    const [categoryList, setCategoryList] = useState<any>();
    const [categories, setCategories] = useState<CategoryTypeTests[]>([]);
    const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
    const [choosedPhaseKw, setChoosedPhaseKw] = useState<string>();
    const [choosedPhaseYear, setChoosedPhaseYear] = useState<string>();
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [typePhase, setTypePhase] = useState<string>("");

    let phaseList: any = [];

    useEffect(() => {
        if (state.id !== undefined) {
            fetchPhasesList(state.id);
            fetchCategoryList();
        }
    }, []);

    const fetchEngineeringCostReview = (id: string, testPhase: string, kw?: string, year?: string, category?: string, subcategory?: string, param?: string) => {
        setLoadingGrid(true);
        let body = {
            "categoryId": category,
            "param": param,
            "subCategoryId": subcategory,
            "testPlanId": id,
            "weekNumber": kw,
            "year": year,
            "testPhase": testPhase
        }

        post("testPlans/tab4/requestTest", body).then((value) => {
            setRequestTestsComponent(value.requestTestsComponent);
            setRequestTestsVehicle(value.requestTestsVehicle);
            setTotalsReviewCost(value.totals);
        }).finally(() => setLoadingGrid(false));
    }

    const fetchPhasesList = (id: number) => {
        setLoading(true);
        get<PhaseList>("testPlans/phase/tab3/" + id).then((value: PhaseList) => {
            setPhaseName(value.name);
            setPhaseStatus(value.status);
            setLastYear(value.lastYear);
            setFirstYear(value.firstYear);
            value.listTPPhaseScheduleDto.map((item: ListPhaseSchedule) => {
                phaseList.push({
                    type: item.type,
                    kw: item.kwName,
                    id: item.kw,
                    year: item.year,
                })
            })
            setDataStep(phaseList);
            setChoosedPhaseKw(phaseList[0].kw.replace('KW', ''));
            setChoosedPhaseYear(phaseList[0].year);
            fetchEngineeringCostReview(state.id, phaseList[0].type, phaseList[0].year);
        }).finally(() => setLoading(false));
    }

    const fetchRequestTestComplexity = (body: any, typePhase: string) => {
        setLoading(true);
        put("testPlans/tab4/requestTest/complexity", body)
            .then((value: any) => {
                if (value.codeStatus !== 200) {
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
                fetchEngineeringCostReview(state.id, typePhase);
            }).finally(() => setLoading(false));
    };

    const fetchTotalTestComplexity = () => {
        setLoading(true);

        let body = {
            complexityAdmin: totalsReviewCost.progAdmin.complexity,
            complexityTestExternal: totalsReviewCost.progTestExternal.complexity,
            complexityTravel: totalsReviewCost.progTravel.complexity,
            testPlanId: state.id
        };

        put("testPlans/tab4/complexity", body)
            .then((value) => {
                if (value.codeStatus !== 200) {
                    setIsError(true);
                    setMessageAlert(value.errorMessage);
                }
                fetchEngineeringCostReview(state.id, typePhase);
            }).finally(() => setLoading(false));
    };

    const fetchCategoryList = (categoryName?: number) => {
        setLoading(true);
        get("categoryTypeTests").then((valueCategory) => {
            setCategories(valueCategory);
            valueCategory.map((cat: any) => {
                if (cat.id === categoryName) {
                    setSubCategory(cat.subCategories);
                }
            });
        }).finally(() => setLoading(false));
    }

    return {
        loading, loadingGrid,
        messageAlert, isError, setIsError,
        requestTestsComponent,
        requestTestsVehicle,
        totalsReviewCost,
        setTotalsReviewCost,
        setRequestTestsVehicle,
        fetchRequestTestComplexity,
        fetchTotalTestComplexity,
        fetchEngineeringCostReview,
        phaseName,
        dataStep,
        phaseStatus,
        firstYear,
        setFirstYear,
        lastYear,
        setLastYear,
        categories,
        setSubCategory,
        subCategory,
        fetchCategoryList,
        choosedPhaseKw,
        setChoosedPhaseKw,
        choosedPhaseYear,
        setChoosedPhaseYear,
        typePhase, setTypePhase
    }
}

export default useTestPlanTab4;
import { useEffect, useState } from "react";
import { deleteR, get, post } from "../../api/api";
import useRoute from "../route/useRoute";

const useLaboratoryTest = () => {
    const [laboratoryTest, setLaboratoryTest] = useState<LaboratoryTest[]>([]);
    const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
    const [laboratory, setLaboratory] = useState<string>("");
    const [typeTests, setTypeTests] = useState<TypeTestsAssociation[]>([]);
    const [categories, setCategories] = useState<CategoryTypeTests[]>([]);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [typeTestsDrop, setTypeTestsDrop] = useState<TypeTestsAssociation[]>([]);
    const [laboratoryId, setLaboratoryId] = useState<string>("");
    const [valid, setValid] = useState<boolean>(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [filter, setFilter] = useState<string>("");
    const [filter1, setFilter1] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("associar-laboratorio"));
        fetchLaboratoryTest();
        fetchLaboratories();
        fetchTypeTests(filter, filter1);
        fetchCategory();
    }, []);

    const fetchLaboratoryTest = () => {
        setLoading(true);
        post<LaboratoryTest[]>("laboratories/listTypeTest",
            {
                "page": 0,
                "size": 9999
            }).then((value) => {
                setLaboratoryTest(value.content);
            }).finally(() => setLoading(false));
    };

    const fetchLaboratories = () => {
        setLoading(true);
        get<Laboratory[]>("laboratories/active/true")
            .then((value) => {
                setLaboratories(value);
            }).finally(() => setLoading(false));
    }

    const fetchTypeTests = (filter: string, filter1: string) => {
        setLoading(true);
        post<TypeTestsAssociation[]>("typeTests/orderByCategories/active/true",
            {
                "page": 0,
                "size": 9999,
                "param": filter,
                "category": filter1
            })
            .then((value) => {
                value.content.map((category: any) => {
                    category.typeTests.map((typeTest: any) => {
                        typeTest.isSelect = false;
                    });
                });
                setTypeTests(value.content)
            }).finally(() => setLoading(false));
    }

    const fetchCategory = () => {
        setLoading(true);
        get("categoryTypeTests")
            .then((value) => {
                setCategories(value);
            }).finally(() => setLoading(false));
    }

    const fetchHandleDelete = (id: any) => {
        deleteR("laboratories/typeTests/" + id).then((value) => {
            setOpenModalCancel(false);
            setIsDelete(true);
            setOpenAlert(true);
        })
    }

    const fetchHandleSubmit = (e: any) => {
        let typeTestsIds: any[] = [];
        if (typeTestsDrop.length > 0) {
            typeTestsDrop.map((value: any) => {
                typeTestsIds.push(value.id);
            });
        }
        if (valid) {
            post("laboratories/typeTests", {
                "laborId": laboratoryId,
                "typeTestsIds": typeTestsIds,
            }).then((value: Supervision) => {
                if (value.codeStatus === 201) {
                    setOpenAlert(true);
                } else {
                    if (value.errorMessage === 'Not Operation') {
                        setRead(true)
                    }
                    setMessageSave(value.errorMessage);
                }
            });
        }
    };

    const fetchHandleEdit = (id: any) => {
        setLoading(true);
        get("laboratories/typeTests/" + id)
            .then((value) => {
                setLaboratory(value.name);
                setLaboratoryId(value.id);
                setIsEdit(true);
                if (value.typeTests.length > 0) {
                    value.typeTests.map((drop: any) => {
                        typeTests.map((type: any) => {
                            type.typeTests.map((typeTest: any) => {
                                if (typeTest.id === drop.id) {
                                    typeTest.isSelect = true;
                                    setTypeTestsDrop(value.typeTests);
                                    // setTypeTestsDrop([...value.typeTests]);
                                }
                            });
                            setTypeTests(typeTests);
                        });
                    });
                } else {
                    setIsEdit(false);
                }
            }).finally(() => setLoading(false));
    }

    return {
        loading, read, setRead, access,
        laboratoryTest, setLaboratoryTest,
        laboratories, setLaboratories,
        laboratory, setLaboratory,
        typeTests, setTypeTests,
        categories, setCategories,
        openModalCancel, setOpenModalCancel,
        isDelete, setIsDelete,
        openAlert, setOpenAlert,
        typeTestsDrop, setTypeTestsDrop,
        laboratoryId, setLaboratoryId,
        isEdit, setIsEdit,
        valid, setValid,
        messageSave, setMessageSave,
        filter, setFilter,
        filter1, setFilter1,
        fetchLaboratoryTest, fetchLaboratories,
        fetchTypeTests, fetchCategory,
        fetchHandleDelete, fetchHandleSubmit,
        fetchHandleEdit
    };
}

export default useLaboratoryTest;
import { Button, Grid, TextField, Avatar } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useState } from 'react';
import closeIcon from "../../assets/images/close-blue.png";
import { format } from '../../utils/utils';
import DateFieldComponent from '../field/dateFieldComponent';

export default function CardRequestTestComponent(props: CardRequestTestParams) {

    const [value, setValue] = useState<any>(props.date);

    return (<Grid container sx={{
        width: "370px",
        height: "88px",
        backgroundColor: "#ffff",
        margin: "32px",
        marginLeft: "16px",
        marginRight: "16px",
        marginBottom: "8px",
        borderRadius: "8px",
    }}>
        <Grid container
            sx={{
                height: props.showCalendar ? "30px" : "88px",
                fontFamily: "VWHead",
                fontSize: "14px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                color: "#000",
                alignContent: props.showCalendar ? "end" : "center",
                marginBottom: "4px",
                marginLeft: "16px",
            }}>
            <Grid item xs={10} sx={{
                maxWidth: "25ch",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}>
                {props.text}
            </Grid>
            <Grid item xs={1.5} sx={{
                marginRight: "8px",
                display: "flex",
            }}>
                <Avatar sx={{
                    width: "16px",
                    height: "16px",
                    fontSize: "12px",
                    marginRight: "5px",
                    backgroundColor: "#c2cacf"
                }}>{props.quantity}
                </Avatar>
                <Button sx={{ minWidth: '16px', padding: '0px' }}
                    onClick={(event) => props.onClickDelete(event)}>
                    <img style={{
                        width: "16px",
                        height: "16px",
                    }} src={closeIcon} />
                </Button>
            </Grid>
        </Grid>
        {props.showCalendar ?
            <Grid container sx={{
                height: "50px",
                width: "185px",
                marginBottom: "4px",
                marginLeft: "16px"
            }}>
                <DateFieldComponent
                    text={"Data"}
                    value={value ? new Date(value + "T00:00:00") : ""}
                    onChangeDate={(newValue: Date) => {
                        if (newValue !== null) {
                            if (newValue.toString() !== "Invalid Date") {
                                setValue(format(newValue));
                                props.onChangeDate(format(newValue));
                            } else {
                                setValue("");
                                props.onChangeDate("");
                            }
                        }
                    }}
                />
            </Grid>
            : null}
    </Grid >);
}
import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Snackbar } from '@mui/material';
import { Grid } from "@mui/material";

export default function SnackbarComponent(props: ParamsField) {

    return (
        <Stack>
            <div
                style={{
                    display: props.open ? "block" : "none",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "fixed",
                    width: "100%",
                    height: "112vh",
                    left: "0px",
                    opacity: "0.9",
                    zIndex: "1",
                    top: "0px"
                }}>
            </div>
            <Snackbar open={props.open}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                    marginTop: "64px",
                    width: "100%",
                }}>
                <Alert onClose={props.closeSnackbar} sx={{
                    "& .MuiAlert-icon": {
                        color: "white"
                    },
                    width: "100%",
                    color: "white",
                    backgroundColor: "#5b08a4",
                    borderRadius: "0px",
                }} >{props.text}</Alert>
            </Snackbar>
        </Stack >
    );
}
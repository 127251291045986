import NumberFormat from "react-number-format";

function HourMoneyFieldComponent(props: ParamsField) {

    return <>
        <small style={{color: "#6c6c6c", fontSize:"12px"}}>{props.text}</small>
        {props.required && <small style={{color: "#6c6c6c", fontSize:"12px"}}>*</small>}
        <NumberFormat
            style={{
                border: "none",
                borderBottom: "1px solid black",
                fontSize: "15px",
                height: "25px",
                outline: "none",
                paddingTop: "3px",
                width: "290px"
            }}
            isAllowed={(values:any) => {
                const {floatValue} = values ? values : '1';
                return floatValue >= 1 &&  floatValue <= 9999999999;
            }}
            decimalSeparator={props.decimalSeparator}
            decimalScale={props.decimalScale ? props.decimalScale : 2}
            type="text"
            maxLength={10}
            allowNegative={false}
            displayType="input"
            format={props.format}
            onBlur={props.onBlur}
            onChange={props.onChange}
            value={props.value}
        />
    </>
}

export default HourMoneyFieldComponent;
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import AlertComponent from "../../../components/alert/alert";
import ButtonComponent from "../../../components/button/buttonComponent";
import LabelComponent from "../../../components/label/labelComponent";
import LoadingComponente from "../../../components/loading/loadingComponente";
import ModalComponent from "../../../components/modal/modalComponent";
import ModalReadPage from "../../../components/modal/modalReadPage";
import useTestPlanTab1 from "../../../hooks/testPlan/useTestPlanTab1";
import { fillTimeField, hourToMinutes, minutesToHours, numberToString } from "../../../utils/utils";
import NotAuthorizedPage from "../../notAuthorized/notAuthorized";

function TestPlanPageTab1RateByYear(props: any) {
    const { loading, isError, setIsError,
        displayAlert, messageAlert, read, setRead, access,
        testPlansSupervisions, setTestPlansSupervisions, fetchTestPlansSupervisions, fetchCalendarization,
        testPlanInputRate, setTestPlanInputRate, inputRateCalc, inputRateCalcOnBlur,
        isSuccess, isSuccessColumn, fetchCalcRow, rateTotalRow,
        fetchSubmitTestPlansSupervisions, fetchSubmitInputRate, rowDist
    } = useTestPlanTab1(props.currentModal, props.type, props.id);

    useEffect(() => {
        (displayAlert && changeValue(messageAlert))
    }, [displayAlert, messageAlert]);

    const changeValue = (message: string) => {
        props.testPlanChangeMessage(message);
    };

    return (
        access ?
            loading ? <LoadingComponente></LoadingComponente> :
                <Grid container>

                    <ModalComponent
                        description={messageAlert}
                        isOk={true}
                        open={isError}
                        close={() => { setIsError(false) }}
                        onCancel={() => { setIsError(false) }}
                        onNext={() => setIsError(false)}
                    />

                    <AlertComponent
                        text={isSuccess && isSuccessColumn ? "Ok, Distribuição coerente do rate!" : "Atenção, distribuição não é corente com o rate!"}
                        success={isSuccess && isSuccessColumn}
                    />
                    <ModalReadPage open={read} onCancel={() => setRead(false)} />
                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <Grid container sx={{ marginBottom: "16px" }}>
                            <LabelComponent text={"Distribuição do rate"} />
                        </Grid>

                        <Grid container sx={{ marginBottom: "8px" }}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <ButtonComponent width={"100%"} text="Reiniciar %" active={true} onClick={() => fetchCalendarization()} />
                            </Grid>
                        </Grid>

                        <div style={{
                            backgroundColor: '#dfe4e8',
                            borderRadius: "4px 4px 0px 0px",
                            color: "#001e50",
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            height: "300px",
                            letterSpacing: "normal",
                            marginTop: "16px",
                            maxHeight: "300px",
                            overflowY: "auto",
                            textAlign: "center",
                            position: "relative",
                        }}>
                            <div style={{
                                backgroundColor: '#dfe4e8',
                                paddingTop: "32px",
                                paddingBottom: "8px",
                                width: "calc(100% - 5px)",
                                position: "sticky",
                                top: "0",
                                left: "0",
                                zIndex: "1000"
                            }}>
                                <Grid container>
                                    <Grid item xs={2}>Supervisão</Grid>
                                    {testPlansSupervisions && testPlansSupervisions?.supervisions?.length > 0 && testPlansSupervisions.supervisions[0].distRate.map((dist, index) => {
                                        return <Grid item key={index} xs={2}>
                                            <div style={{ width: "100px", textAlign: "center" }}>
                                                {dist.year}
                                            </div>
                                        </Grid>
                                    })}
                                </Grid>
                            </div>

                            <Grid container sx={{ maxHeight: "240px", height: "240px", display: "block" }}>
                                {testPlansSupervisions && testPlansSupervisions?.supervisions?.length > 0 && testPlansSupervisions.supervisions.map((value: any, index: number) =>
                                    <Grid container key={index} sx={{ "&.MuiGrid-root": { alignItems: "baseline", height: "60px" } }}>
                                        <Grid item xs={2} sx={{ textAlign: "center" }}>
                                            <LabelComponent color={"#6a767d"} fontSize={"14px"} text={value.supervisionInitial} />
                                        </Grid>
                                        {value.distRate.map((rate: any, index: number) =>
                                            <Grid item key={index} xs={2}>
                                                <div style={{
                                                    textAlign: "center",
                                                    backgroundColor: "#fff",
                                                    border: "solid 0.5px #dfe4e8",
                                                    borderRadius: "8px",
                                                    boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                    width: "100px"
                                                }}>
                                                    <NumberFormat
                                                        style={{
                                                            border: "none",
                                                            fontSize: "16px",
                                                            height: "40px",
                                                            paddingTop: "3px",
                                                            outline: "none",
                                                            width: "40px",
                                                            textAlign: "center"
                                                        }}
                                                        displayType={"input"}
                                                        type="text"
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        allowNegative={false}
                                                        value={rate.rate}
                                                        maxLength={rate.rate >= 100 ? 4 : undefined}
                                                        onChange={(event: any) => {
                                                            rate.rate = event.target.value === '' ? 0 : event.target.value;
                                                        }}
                                                        onBlur={(event: any) => {
                                                            fetchCalcRow(value, testPlansSupervisions.supervisions, testPlansSupervisions.supervisions.length, testPlanInputRate ? testPlanInputRate.inputRates : []);
                                                        }}
                                                    />%
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <Grid container sx={{
                            backgroundColor: '#dfe4e8',
                            padding: "16px 0",
                            borderRadius: "0px 0px 4px 4px",
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal"
                        }}>
                            <Grid container sx={{ width: "calc(100% - 15px)" }}>
                                <Grid item xs={2} sx={{ color: "#001e50", textAlign: "center" }}>{"% Trabalho"}</Grid>
                                <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}><div style={{ width: "100px" }}>{rowDist.year1}</div></Grid>
                                <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}><div style={{ width: "100px" }}>{rowDist.year2}</div></Grid>
                                <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}><div style={{ width: "100px" }}>{rowDist.year3}</div></Grid>
                                <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}><div style={{ width: "100px" }}>{rowDist.year4}</div></Grid>
                                <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}><div style={{ width: "100px" }}>{rowDist.year5}</div></Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={0} sm={0} md={0} lg={0.2}></Grid>

                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <Grid container sx={{ marginBottom: "16px" }}>
                            <LabelComponent text={"Input do rate"} />
                        </Grid>

                        <Grid container sx={{ marginBottom: "8px" }}>
                            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
                                <ButtonComponent
                                    width={"100%"}
                                    text="Calcular inflação com base em LB/PS"
                                    active={true}
                                    onClick={() => inputRateCalc('inflation', testPlanInputRate ? testPlanInputRate.inputRates : [])}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={1}>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
                                <ButtonComponent
                                    width={"100%"}
                                    text="Calcular LB/PS com base na inflação"
                                    active={true}
                                    onClick={() => inputRateCalc('lbps', testPlanInputRate ? testPlanInputRate.inputRates : [])}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={{
                            marginTop: "16px",
                            backgroundColor: '#dfe4e8',
                            borderRadius: "4px",
                            fontFamily: "VWHead",
                            fontSize: "14px",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                        }}>
                            <Grid container sx={{ marginTop: "32px" }}>
                                <Grid container sx={{ marginBottom: "8px" }}>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={9}>
                                        <Grid container>
                                            {testPlanInputRate?.inputRates.map((dist, index) => <Grid container key={index} sx={{ marginRight: "16px" }} xs={2} direction={"column"}>
                                                <Grid item xs={2} sx={{ color: "#001e50", fontWeight: "bold", width: "100px" }}>{dist.year}</Grid>
                                            </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ height: "293px", overflowY: "auto" }}>
                                    <Grid item xs={3}>
                                        <Grid container direction={"column"}>
                                            <Grid item xs={2} sx={{ textAlign: "center", marginTop: "32px" }}>
                                                <LabelComponent color={"#6a767d"} fontSize={"16px"} text={"Labor Base"} />
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: "center", marginTop: "35px" }}>
                                                <LabelComponent color={"#6a767d"} fontSize={"16px"} text={"Purchase Service"} />
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: "center", marginTop: "42px" }}>
                                                <LabelComponent color={"#6a767d"} fontSize={"16px"} text={"Horas Úteis"} />
                                            </Grid>
                                            <Grid item xs={2} sx={{ textAlign: "center", marginTop: "42px" }}>
                                                <LabelComponent color={"#001e50"} fontSize={"16px"} text={"Inflação"} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container>
                                            {testPlanInputRate?.inputRates.map((input: any, index: number) =>
                                                <Grid container key={index} sx={{ marginRight: "16px" }} xs={2} direction={"column"}>
                                                    <Grid item xs={2} sx={{
                                                        marginTop: "16px",
                                                        marginBottom: "16px",
                                                        textAlign: "center",
                                                        backgroundColor: "#fff",
                                                        border: "solid 0.5px #dfe4e8",
                                                        borderRadius: "8px",
                                                        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                        width: "100px",
                                                    }}>
                                                        R$
                                                        <NumberFormat
                                                            style={{
                                                                border: "none",
                                                                fontSize: "16px",
                                                                height: "40px",
                                                                paddingTop: "3px",
                                                                outline: "none",
                                                                width: "40px",
                                                                textAlign: "center"
                                                            }}
                                                            displayType="input"
                                                            type="text"
                                                            decimalSeparator={","}
                                                            decimalScale={2}
                                                            allowNegative={true}
                                                            maxLength={10}
                                                            value={Number(input.labor.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            onChange={(event: any) => input.labor = event.target.value.replaceAll('.', '').replaceAll(',', '.')}
                                                            onBlur={() => inputRateCalcOnBlur(testPlanInputRate.inputRates)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{
                                                        marginBottom: "16px",
                                                        textAlign: "center",
                                                        backgroundColor: "#fff",
                                                        border: "solid 0.5px #dfe4e8",
                                                        borderRadius: "8px",
                                                        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                        width: "100px"
                                                    }}>
                                                        R$
                                                        <NumberFormat
                                                            style={{
                                                                border: "none",
                                                                fontSize: "16px",
                                                                height: "40px",
                                                                paddingTop: "3px",
                                                                outline: "none",
                                                                width: "40px",
                                                                textAlign: "center"
                                                            }}
                                                            displayType="input"
                                                            type="text"
                                                            decimalSeparator={","}
                                                            decimalScale={2}
                                                            allowNegative={true}
                                                            maxLength={10}
                                                            value={Number(input.purchase.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            onChange={(event: any) => input.purchase = event.target.value.replaceAll('.', '').replaceAll(',', '.')}
                                                            onBlur={() => inputRateCalcOnBlur(testPlanInputRate.inputRates)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{
                                                        marginBottom: "16px",
                                                        textAlign: "center",
                                                        backgroundColor: "#fff",
                                                        border: "solid 0.5px #dfe4e8",
                                                        borderRadius: "8px",
                                                        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                        width: "100px"
                                                    }}>
                                                        <input
                                                            style={{
                                                                border: "none",
                                                                fontSize: "16px",
                                                                height: "40px",
                                                                paddingTop: "3px",
                                                                outline: "none",
                                                                width: "50px",
                                                                textAlign: "right"
                                                            }}
                                                            type="text"
                                                            value={input.minutes}
                                                            onChange={(event: any) => {
                                                                event.target.value = event.target.value.replace(/[^\d]/g, "");
                                                                event.target.value = event.target.value == '' ? 0 : Number(event.target.value.replaceAll(' ', '').replaceAll('.', '').replaceAll(',', ''));
                                                                input.minutes = event.target.value;
                                                                inputRateCalcOnBlur(testPlanInputRate.inputRates);
                                                            }}
                                                        />h
                                                    </Grid>
                                                    <Grid item xs={2} sx={{
                                                        marginBottom: "24px",
                                                        textAlign: "center",
                                                        backgroundColor: "#fff",
                                                        border: "solid 0.5px #dfe4e8",
                                                        borderRadius: "8px",
                                                        boxShadow: "0 4px 16px 0 rgba(0, 30, 80, 0.03), 0 10px 20px 0 rgba(0, 30, 80, 0.05)",
                                                        width: "100px",
                                                    }}>
                                                        <NumberFormat
                                                            style={{
                                                                border: "none",
                                                                fontSize: "16px",
                                                                height: "40px",
                                                                paddingTop: "3px",
                                                                outline: "none",
                                                                width: "40px",
                                                                textAlign: "center"
                                                            }}
                                                            allowNegative={true}
                                                            displayType={"input"}
                                                            decimalScale={2}
                                                            type="text"
                                                            decimalSeparator={","}
                                                            value={Number(input.inflation.toString().replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            onChange={(event: any) => input.inflation = event.target.value.replaceAll('.', '').replaceAll(',', '.')}
                                                            onBlur={() => inputRateCalcOnBlur(testPlanInputRate.inputRates)}
                                                        />%
                                                    </Grid>

                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container style={{
                        backgroundColor: '#dfe4e8',
                        borderRadius: "8px",
                        color: "#001e50",
                        height: "64px",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        letterSpacing: "normal",
                        textAlign: "center",
                        marginTop: "24px",
                        alignContent: "center",
                    }}>
                        <Grid item xs={4}>
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"bold"}
                                color={"#001e50"}
                                margin={"16px"}
                                text={"Rate ponderado L. Base"} />
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"normal"}
                                text={Number(rateTotalRow.lb).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"bold"}
                                color={"#001e50"}
                                margin={"16px"}
                                text={"Rate ponderado P. Service"} />
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"normal"}
                                text={Number(rateTotalRow.ps).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"bold"}
                                color={"#001e50"}
                                margin={"16px"}
                                text={"Status"} />
                            <LabelComponent
                                fontFamily={"VWHead"}
                                fontSize={"14px"}
                                fontWeight={"normal"}
                                text={rateTotalRow.status + "%"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
                            <ButtonComponent text="Cancelar" disable={true} onClick={() => {
                                props.setOpenCancelModal(true);
                            }}
                            />
                        </Grid>
                        <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
                            <ButtonComponent text="Salvar" active={isSuccessColumn} onClick={() => {
                                if (isSuccessColumn) {
                                    fetchSubmitTestPlansSupervisions(testPlansSupervisions);
                                    fetchSubmitInputRate(testPlanInputRate);
                                }
                            }} />
                        </Grid>
                    </Grid>
                </Grid >
            : <NotAuthorizedPage />
    )
}

export default TestPlanPageTab1RateByYear;
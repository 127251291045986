import { useEffect, useState } from "react";
import { get } from "../../api/api";
import useRoute from "../route/useRoute";

const useProgPage = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [schedule, setSchedule] = useState<LaboratoryProg[]>([]);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("programacao-laboratorios"));
        fetchProgSchedule()
    }, [])

    const fetchProgSchedule = () => {
        get<LaboratoryProg>('laboratories/schedule/active').then((schedule) => {
            setSchedule(schedule);
        }).finally(() => setLoading(false));
    }

    return {
        loading,
        schedule,
        access
    }
}

export default useProgPage;
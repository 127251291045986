type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post } from "../../api/api";

const useRequestTestTab4 = (state: Type) => {

    const [loading, setLoading] = useState(true);
    const [categoryResources, setCategoryResources] = useState<CategoryResources[]>([]);
    const [resources, setResources] = useState<CategoryResources[]>([]);
    const [categoryResourcesId, setCategoryResourcesId] = useState<string>("");
    const [resourcesId, setResourcesId] = useState<string>("");
    const [listResourcesConsumable, setListResourcesConsumable] = useState<any[]>([]);
    const [listResourcesInventory, setListResourcesInventory] = useState<any[]>([]);
    const [valueTotal, setValueTotal] = useState<number>(0.00);
    const [valueByQuantity, setValueByQuantity] = useState<number>(0.00);
    const [resourcesSupervisions, setResourcesSupervisions] = useState<ResourcesSupervisions[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [read, setRead] = useState<boolean>(false);
    let v1 = 0.00;
    let v2 = 0.00;

    useEffect(() => {
        fetchRequestTestsResource(state.id);
    }, []);

    const fetchCategoryResources = () => {
        setLoading(true);
        get("categoryResources").then((value) => {
            setCategoryResources(value);
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchResource = (id: any) => {
        get("resources/" + id).then((value) => {
            if (value.typeResource.id === 1) {
                calc(value.resourcesSupervisions, "add");
                calcSupervision(value.resourcesSupervisions);
                let resourcesSupervisions: any[] = []
                value.resourcesSupervisions.map((resSup: any) => {
                    resourcesSupervisions.push(
                        {
                            resourceId: resSup.resourceId,
                            supervisionId: resSup.supervisionId,
                            supervisionInitials: resSup.supervisionInitials,
                            value: resSup.value,
                            valueByQuantity: resSup.valueByQuantity
                        }
                    );
                });
                const result = listResourcesConsumable.find((valueList) => valueList.id.toString() === value.id.toString());
                if (result === undefined) {
                    let resource = {
                        id: value.id,
                        name: value.name,
                        quantity: 1,
                        resourcesSupervisions: resourcesSupervisions,
                        order: listResourcesConsumable.length
                    };
                    setListResourcesConsumable([...listResourcesConsumable, resource]);
                } else {
                    listResourcesConsumable.map((list) => {
                        if (list.id === result.id) {
                            list.quantity = result.quantity + 1;
                        }
                    });
                    setListResourcesConsumable([]);
                    setListResourcesConsumable(listResourcesConsumable);
                }
            } else if (value.typeResource.id === 2) {
                calc(value.resourcesSupervisions, "add");
                calcSupervision(value.resourcesSupervisions);
                let resourcesSupervisions: any[] = []
                value.resourcesSupervisions.map((resSup: any) => {
                    resourcesSupervisions.push(
                        {
                            resourceId: resSup.resourceId,
                            supervisionId: resSup.supervisionId,
                            supervisionInitials: resSup.supervisionInitials,
                            value: resSup.value,
                            valueByQuantity: resSup.valueByQuantity
                        }
                    );
                });
                const result = listResourcesInventory.find((valueList) => valueList.id.toString() === value.id.toString());
                if (result === undefined) {
                    let resource = {
                        id: value.id,
                        name: value.name,
                        quantity: 1,
                        resourcesSupervisions: resourcesSupervisions,
                        order: listResourcesInventory.length
                    };
                    setListResourcesInventory([...listResourcesInventory, resource]);
                } else {
                    listResourcesInventory.map((list) => {
                        if (list.id === result.id) {
                            list.quantity = result.quantity + 1;
                        }
                    });
                    setListResourcesInventory([]);
                    setListResourcesInventory(listResourcesInventory);
                }
            }
        });
    }

    const fetchRequestTestsResource = (id: any) => {
        get("requestTests/resources/" + id).then((value) => {
            if (value.resourcesConsumable.length > 0) {
                let resourcesConsumable: any[] = [];
                let resourcesSupervisions: any[] = []
                value.resourcesConsumable.map((res: any) => {
                    res.resourcesSupervisions.map((resSup: any) => {
                        resourcesSupervisions.push(
                            {
                                resourceId: resSup.resourceId,
                                supervisionId: resSup.supervisionId,
                                supervisionInitials: resSup.supervisionInitials,
                                value: resSup.value,
                                valueByQuantity: resSup.valueByQuantity
                            }
                        );
                    });
                    let resource = {
                        id: res.resourceId,
                        name: res.resourceName,
                        quantity: res.quantity,
                        resourcesSupervisions: res.resourcesSupervisions,
                        order: value.resourcesConsumable.length
                    };
                    resourcesConsumable.push(resource);
                });
                resourcesConsumable.map((res: any) => {
                    if (res.quantity > 1) {
                        for (let i = 0; i < res.quantity; i++) {
                            calc(res.resourcesSupervisions, "add");
                            calcSupervision(res.resourcesSupervisions);
                        }
                    } else {
                        calc(res.resourcesSupervisions, "add");
                        calcSupervision(res.resourcesSupervisions);
                    }
                });
                setListResourcesConsumable(resourcesConsumable);
            }
            if (value.resourcesInventorable.length > 0) {
                let resourcesInventorable: any[] = [];
                let resourcesSupervisions: any[] = []
                value.resourcesInventorable.map((res: any) => {
                    res.resourcesSupervisions.map((resSup: any) => {
                        resourcesSupervisions.push(
                            {
                                resourceId: resSup.resourceId,
                                supervisionId: resSup.supervisionId,
                                supervisionInitials: resSup.supervisionInitials,
                                value: resSup.value,
                                valueByQuantity: resSup.valueByQuantity
                            }
                        );
                    });
                    let resource = {
                        id: res.resourceId,
                        name: res.resourceName,
                        quantity: res.quantity,
                        resourcesSupervisions: resourcesSupervisions,
                        order: resourcesInventorable.length
                    };
                    resourcesInventorable.push(resource);
                });
                resourcesInventorable.map((res: any) => {
                    if (res.quantity > 1) {
                        for (let i = 0; i < res.quantity; i++) {
                            calc(resourcesSupervisions, "add");
                            calcSupervision(resourcesSupervisions);
                        }
                    } else {
                        calc(resourcesSupervisions, "add");
                        calcSupervision(resourcesSupervisions);
                    }
                });
                setListResourcesInventory(resourcesInventorable);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchResourcesActive = (id: number) => {
        get("resources/active/" + id).then((value) => {
            setResources(value);
        });
    }

    const fetchResourcesSubmit = (body: any) => {
        post("requestTests/resources", body).then((value) => {
            if (value.codeStatus === 201) {
                setOpenAlert(true);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setIsError(true);
                setMessageAlert(value.errorMessage);
            }
        });
    }

    const calc = (values: any, type: string) => {
        if (type === "add") {
            values.map((value: any) => {
                v1 += Number(value.value)
                v2 += Number(value.valueByQuantity)
            });
            setValueTotal(valueTotal + v1)
            setValueByQuantity(valueByQuantity + v2)
        } else {
            values.map((value: any) => {
                v1 += Number(value.value)
                v2 += Number(value.valueByQuantity)
            });
            setValueTotal((valueTotal - v1))
            setValueByQuantity((valueByQuantity - v2))
        }
    }

    const calcSupervision = (values: any) => {
        if (resourcesSupervisions.length > 0) {
            values.map((value: any) => {
                const result = resourcesSupervisions.find((res) => res.supervisionId === value.supervisionId);
                if (result === undefined) {
                    resourcesSupervisions.push({
                        resourceId: value.resourceId,
                        supervisionId: value.supervisionId,
                        supervisionInitials: value.supervisionInitials,
                        value: value.value,
                        valueByQuantity: value.value,
                    });
                    setResourcesSupervisions(resourcesSupervisions);
                } else {
                    if (result.supervisionId === value.supervisionId) {
                        result.value = String(Number(result.value) + Number(value.value));
                        result.valueByQuantity = String(Number(result.valueByQuantity) + Number(value.valueByQuantity));
                    }
                }
            });
        } else {
            values.map((value: any) => {
                resourcesSupervisions.push({
                    resourceId: value.resourceId,
                    supervisionId: value.supervisionId,
                    supervisionInitials: value.supervisionInitials,
                    value: value.value,
                    valueByQuantity: value.valueByQuantity,
                });
                setResourcesSupervisions(resourcesSupervisions);
            });
        }
    }

    return {
        loading,
        categoryResources, setCategoryResources,
        resources, setResources,
        resourcesSupervisions, setResourcesSupervisions,
        listResourcesConsumable, setListResourcesConsumable,
        listResourcesInventory, setListResourcesInventory,
        categoryResourcesId, setCategoryResourcesId,
        valueTotal, valueByQuantity,
        resourcesId, setResourcesId,
        openAlert, setOpenAlert, read, setRead,
        calc, messageAlert, isError, setIsError,
        fetchCategoryResources, fetchResourcesActive, fetchResourcesSubmit,
        fetchRequestTestsResource, fetchResource
    };
}

export default useRequestTestTab4;
type Type = /*unresolved*/ any
import { Grid } from "@mui/material";
import { useState } from "react";
import ButtonComponent from "../../components/button/buttonComponent";
import CardComponent from "../../components/card/cardComponent";
import CardDNDComponent from "../../components/card/cardDNDComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import ModalComponent from "../../components/modal/modalComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import SearchComponent from "../../components/search/searchComponent";
import SelectComponent from "../../components/select/selectComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import { calculaUltimaLinha, hourToMinutes, minutesToHours, minutesToString } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import useActivityTest from "../../hooks/activityTest/useActivityTest";
import ModalReadPage from "../../components/modal/modalReadPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function ActivityTestPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [teste, setTeste] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [filter1, setFilter1] = useState<string>("");
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>("");
  const location = useLocation();
  const state = location.state as Type;

  const {
    supervisionList, setSupervisionList,
    loadingSupervisionList, setLoadingSupervisionList,
    totalValue, setTotalValue,
    rows, setRows,
    activity, setActivity,
    activityTest, setActivityTest,
    laboratories, setLaboratories,
    categoryActivities, setCategoryActivities,
    openAlert, setOpenAlert,
    messageSave, setMessageSave,
    isEdit, setIsEdit,
    testes, setTestes,
    testeId, setTesteId,
    valid, setValid,
    activityTestDrop, setActivityTestDrop,
    laborTypeTestId, setLaborTypeTestId,
    laboratoryId, setLaboratoryId,
    laboratory, setLaboratory,
    openModalCancel, setOpenModalCancel,
    isDelete, setIsDelete,
    read, setRead, access,
    fetchSupervisionList, fetchActivities, fetchActivitiesDrag,
    fetchListLaboratoriesActive, fetchCategory, fetchSubmit,
    fetchEdit, fetchDelete
  } = useActivityTest(state);

  const handleSubmit = (e: any) => {
    let activities: any[] = [];
    if (activityTestDrop.length > 0) {
      activityTestDrop.map((value: any) => {
        activities.push({ "activityId": value.id, "quantity": value.quantity });
      });
    }
    if (valid) {
      fetchSubmit({
        "laborTypeTestId": laborTypeTestId,
        "activities": activities,
      });
    }
  };

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const filterList = (event: any) => {
    setFilter(event.target.value);
    fetchActivitiesDrag(event.target.value, filter1);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
    window.location.reload();
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalDelete = (e: any) => {
    setOpenModalCancel(false);
    setIdDelete("");
  };

  const handleEdit = (id: any) => {
    fetchEdit(id, false);
  };

  return (
    access ?
      <Grid container xs={12}>
        <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Atividades" access={access}/>
        <SnackbarComponent text={
          isDelete ? "Exclusão salva com sucesso!" :
            isEdit ?
              "Associação editada com sucesso"
              : "Associação salva com sucesso"}
          open={openAlert}
          closeSnackbar={handleClose}
        />
        <ModalComponent text=""
          open={openModalCancel}
          description={"Este card será excluído com todas as Associações deste Laboratório, os testes não estarão mais disponíveis para serem solicitados ou adicionados em Plano de testes enquanto não houver uma nova associação e Gestão de ALTRA"}
          textButton1={"Cancelar"}
          textButton2={"Prosseguir"}
          close={handleCloseModalDelete}
          onCancel={() => { setOpenModalCancel(false) }}
          onNext={() => {
            fetchDelete(idDelete);
            setOpenModalCancel(false)
            setIsDelete(true);
            setOpenAlert(true);
          }} />
        <ModalComponent text=""
          open={openModal}
          description={isEdit ? "Deseja realmente cancelar a edição de uma nova associação?" : "Deseja realmente cancelar a criação de uma nova associação?"}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setFilter("")
            setActivityTest([]);
            setActivityTestDrop([]);
            setTestes([]);
            setTeste("");
            setTesteId("");
            setLaboratoryId("");
            setValid(false);
            setOpenModal(false);
            setIsEdit(false);
            fetchActivitiesDrag(filter, filter1);
            fetchSupervisionList();
            fetchActivitiesDrag("", "");
          }} />
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Categoria"}
          isPagination1={false}
          value1={filter1}
          filter1={categoryActivities}
          onSelect1={(event: any) => {
            setFilter1(event.target.value);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => {
            setOpenFilter(false)
          }}
          onNext={() => {
            fetchActivitiesDrag(filter, filter1);
            setOpenFilter(false);
          }} />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container md={12} sx={{ margin: { xs: '48px 20px', sm: '48px 20px', md: '48px 20px', lg: '48px 48px 16px 48px', xl: '48px 48px 16px 48px' } }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LabelComponent text={"Associar "} fontSize="28px" />
              <LabelComponent text="Atividades com Teste" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <LabelComponent text="Arrastar as atividades para o teste selecionado" />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={7.8} sx={{ marginTop: "16px", }}>
            <Grid item xs={12} sm={12} md={12} lg={5.8} xl={5.8} sx={{
              marginRight: { md: '0px', lg: '20px' },
              marginTop: '4px'
            }}>
              <SearchComponent text="" placeholder="Buscar por …"
                value={filter}
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  fetchActivitiesDrag("", "");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5.9} lg={2.8} xl={2.7} sx={{
              marginLeft: { xs: '0px', sm: '0px', md: '0px', lg: '5px', xl: '5px' },
              marginRight: { xs: '0px', sm: '0px', md: '10px', lg: '5px', xl: '5px' },
              marginBottom: { xs: '10px', sm: '10px', md: '15px', lg: '5px', xl: '5px' }
            }}>
              <SelectComponent
                text="Laboratório"
                values={laboratories}
                value={laboratoryId}
                required={true}
                onSelect={(event: any) => {
                  if (event.target.value === "") {
                    fetchSupervisionList();
                    setValid(false);
                  }
                  setLaboratoryId(event.target.value);
                  setTeste("");
                  setTestes([]);
                  setTesteId("");
                  setTeste("");
                  setActivityTestDrop([]);
                  laboratories.map((testTypes: any) => {
                    if (testTypes.id === event.target.value) {
                      setTestes(testTypes.typesTests);
                    }
                  });
                }} />
            </Grid>
            <Grid item xs={12} sm={12} md={5.9} lg={2.8} xl={2.8} sx={{
              marginLeft: { md: '0px', lg: '10px' },
              marginRight: { md: '0px', lg: '10px' },
              marginBottom: { xs: '10px', sm: '10px', md: '15px', lg: '5px', xl: '5px' }
            }}>
              <SelectComponent
                text="Teste"
                values={testes}
                value={testeId}
                required={true}
                onSelect={(event: any, child: any) => {
                  setTeste(child.props.children);
                  setTesteId(event.target.value);
                  if (event.target.value === "") {
                    fetchSupervisionList();
                    setTeste("");
                  }
                  if (event.target.value !== "" && laboratoryId !== "" && activityTestDrop.length > 0) {
                    setValid(true);
                  } else {
                    setValid(false);
                    setActivityTestDrop([]);
                  }
                  testes.map((type: any) => {
                    if (type.id === event.target.value) {
                      setLaborTypeTestId(type.laborTypeTestId);
                      handleEdit(type.laborTypeTestId);
                    }
                  })
                }} />
            </Grid>
            <Grid item xs={12}>
              <CardDNDComponent
                xsGdLeft={12}
                smGdLeft={12}
                mdGdLeft={12}
                lgGdLeft={5.84}
                xsGdRight={12}
                smGdRight={12}
                mdGdRight={12}
                lgGdRight={5.84}
                type="activity"
                values={activityTest}
                valuesDrop={activityTestDrop}
                description={teste !== "" ? teste : ""}
                handleOnDragEnd={(old, newObj) => {
                  var draggableId = newObj.draggableId.split(',');
                  old.map((filter: any) => {
                    filter.activities.map((activity: any) => {
                      if (activity.id.toString() === draggableId[0]) {
                        const result = activityTestDrop.find((value) => value.id.toString() === draggableId[0].toString());
                        if (result === undefined) {
                          activity.isSelect = true;
                          activity.quantity = 1;
                          setActivityTestDrop([...activityTestDrop, activity]);
                        } else {
                          activity.isSelect = true;
                          activity.quantity = result.quantity + 1;
                          if (activity.quantity !== result.quantity) {
                            result.quantity = activity.quantity;
                          }
                          setActivityTestDrop(activityTestDrop);
                          setActivityTestDrop([...activityTestDrop]);
                        }
                        activity.valueActivities.map((valueActivity: any) => {
                          rows.map((supervison: any) => {
                            if (supervison.id === valueActivity.supervisionId) {
                              supervison.month = (supervison.month === "00:00" ?
                                minutesToHours(valueActivity.hoursMonth) :
                                minutesToHours(hourToMinutes(supervison.month) + valueActivity.hoursMonth));
                              supervison.hour = (supervison.hour === "00:00" ?
                                minutesToHours(valueActivity.hoursPart) :
                                minutesToHours(hourToMinutes(supervison.hour) + valueActivity.hoursPart));
                              supervison.third = (supervison.third === "00:00" ?
                                minutesToHours(valueActivity.hoursThird) :
                                minutesToHours(hourToMinutes(supervison.third) + valueActivity.hoursThird));
                              supervison.totalRow = minutesToHours(
                                hourToMinutes(supervison.totalRow) +
                                valueActivity.hoursPart +
                                valueActivity.hoursMonth +
                                valueActivity.hoursThird);
                            }
                          });
                        });
                      }
                    });
                    if (laboratoryId !== "" && testeId !== "") {
                      setValid(true);
                    } else {
                      setValid(false);
                    }
                    setActivityTest(old);
                    setRows(rows)
                    setTotalValue(calculaUltimaLinha(rows));
                  });
                }}
                handleCancel={(value) => {
                  activityTest.map((activity: any) => {
                    activity.activities.map((aux: any) => {
                      if (aux.id === value.id) {
                        const result = activityTestDrop.find((value) => value.id.toString() === aux.id.toString());
                        if (result?.quantity !== aux.quantity) {
                          aux.quantity = result?.quantity;
                        }
                        if (aux.quantity > 1) {
                          if (activityTestDrop.length > 0) {
                            activityTestDrop.map((res) => {
                              if (res.id === aux.id) {
                                res.quantity = res.quantity - 1;
                              }
                            });
                          }
                          setActivityTestDrop(activityTestDrop);
                          setActivityTestDrop([...activityTestDrop]);
                        } else {
                          var index = activityTestDrop.indexOf(value);
                          if (index > -1) {
                            activityTestDrop.splice(index, 1);
                          }
                          if (laboratoryId !== "" && testeId !== "" && activityTestDrop.length > 0) {
                            setValid(true);
                          } else {
                            setValid(false);
                          }
                          aux.isSelect = false;
                          aux.quantity = 0;
                          setActivityTestDrop(activityTestDrop);
                          setActivityTestDrop([...activityTestDrop]);
                        }

                        aux.valueActivities.map((valueActivity: any) => {
                          rows.map((supervison: any) => {
                            if (supervison.id === valueActivity.supervisionId) {
                              supervison.month =
                                supervison.month !== "00:00" ?
                                  (minutesToHours(Math.abs(hourToMinutes(supervison.month) - valueActivity.hoursMonth))) :
                                  supervison.month;
                              supervison.hour =
                                supervison.hour !== "00:00" ?
                                  (minutesToHours(Math.abs(hourToMinutes(supervison.hour) - valueActivity.hoursPart))) :
                                  supervison.hour;
                              supervison.third =
                                supervison.third !== "00:00" ?
                                  (minutesToHours(Math.abs(hourToMinutes(supervison.third) - valueActivity.hoursThird))) :
                                  supervison.third;
                              supervison.totalRow = minutesToHours(
                                hourToMinutes(supervison.month) +
                                hourToMinutes(supervison.hour) +
                                hourToMinutes(supervison.third));
                            }
                          });
                        });
                      }
                    });
                    setActivityTest(activityTest);
                    setRows(rows);
                    setTotalValue(calculaUltimaLinha(rows));
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={5.8} xl={4.2} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              sx={{ marginLeft: '5px', marginBottom: { md: '0px', lg: '18px' }, marginTop: "8px" }}>
              <LabelComponent text="Horas das Atividades" fontSize="20px" fontWeight="normal" color="#001e50" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '10px 5px', }}>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{
                backgroundColor: "#dfe4e8",
                borderRadius: "4px 4px 0px 0px",
              }}>
                <Grid container xs={12} style={{
                  color: "#001e50",
                  fontFamily: "VWHead",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  letterSpacing: "normal",
                  textAlign: "center",
                  padding: '15px 10px',
                  alignItems: 'center',
                }}>
                  <Grid item xs={3.8}>Supervisão</Grid>
                  <Grid item xs={2}>Horista</Grid>
                  <Grid item xs={2}>Mensalista</Grid>
                  <Grid item xs={2}>Terceiro</Grid>
                  <Grid item xs={2}>Total Supervisão</Grid>
                </Grid>
                <Grid container style={{ height: "236px", overflowY: "auto", padding: '0 10px' }}>
                  {rows.map((value: any) => {
                    return <DataTableEditComponent
                      type="activity"
                      name={value.name}
                      hour={value.hour}
                      month={value.month}
                      third={value.third}
                      totalRow={value.totalRow}
                      readOnlyHour={true}
                      readOnlyMonth={true}
                      readOnlyThird={true}
                    />
                  })}
                </Grid>
              </Grid>
              <Grid container style={{ backgroundColor: '#dfe4e8', padding: "20px", borderRadius: "0 0 4px 4px", fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal" }}>
                <Grid container xs={12}>
                  <Grid item xs={4} md={4} lg={4} sx={{ color: "#001e50", textAlign: "center" }}>Total Cargo</Grid>
                  <Grid item xs={2.1} md={1.1} lg={2} sx={{ color: "#6a767d", textAlign: "center" }}>{totalValue.hour}</Grid>
                  <Grid item xs={2} md={2.9} lg={2} sx={{ color: "#6a767d", textAlign: "center" }}>{totalValue.month}</Grid>
                  <Grid item xs={2.1} md={1.2} lg={2} sx={{ color: "#6a767d", textAlign: "center" }}>{totalValue.third}</Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{
                backgroundColor: "#dfe4e8",
                borderRadius: "8px",
                marginTop: '8px',
                height: '57px',
                margin: "10px 0 0",
                padding: "17px 20px 16px",
              }}>
                <Grid container style={{
                  fontFamily: "VWHead",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  letterSpacing: "normal",
                  justifyContent: 'space-between',
                }}>
                  <Grid item sx={{
                    color: "#001e50",
                    textAlign: "center"
                  }}>
                    Total das atividades
                  </Grid>
                  <Grid item sx={{ color: "#6a767d" }} >
                    <div>
                      {totalValue.totalRow}h
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: "40px"
          }}>
            <Grid item xs={4} sm={3} md={2} lg={2} xl={1.2} >
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={4} sm={2} md={2} lg={2} xl={1}>
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Associações" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container >
            <CardComponent
              values={activity}
              type={"activity"}
              navigate={"/associar-atividade/details/"}
              onClickEdit={(value) => {
                handleEdit(value);
                setValid(true)
              }}
              onClickDelete={(value) => {
                setIdDelete(value)
                setOpenModalCancel(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      : <NotAuthorizedPage />
  );
}

export default ActivityTestPage;

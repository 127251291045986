import { Accordion, AccordionDetails, Box, Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccordionComponent from "../../components/accordion/accordionComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import ModalComponent from "../../components/modal/modalComponent";
import SearchComponent from "../../components/search/searchComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import useTestPlanList from "../../hooks/testPlan/useTestPlanList";
import useRoles from "../../hooks/roles/useRoles";
import ButtonLinkComponent from "../../components/button/buttonLinkComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { transformUsDateToBr } from "../../utils/utils";
import { GridColumns } from "@mui/x-data-grid";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function TestPlanList() {
    const [id, setId] = useState<number | null>(null);
    const [status, setStatus] = useState<string>("");
    const [openDisable, setOpenDisable] = useState(false);
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filter, setFilter] = useState<string>("");
    const [listStatus, setListStatus] = useState<string>("");
    const navigate = useNavigate();
    const [openFilter, setOpenFilter] = useState(false);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [errorDisable, setErrorDisable] = useState<string>("");
    const { fetchList, loading, totalElements, testPlanList, fetchDeactive, access,
        fetDeleteTestPlan, fetchUserList, codeStatus, userList,
        read, setRead,
        isError, setIsError, messageAlert } = useTestPlanList();

    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (panel: string, status: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setFilter("");
        setFilter1("");
        setFilter2("");
        setListStatus(status);
        setExpanded(isExpanded ? panel : false);
        fetchUserList(status);
        fetchList("", "", "", status, page, pageSize);
    };

    const columns = useMemo<GridColumns<any>>(
        () => [
            { field: "name", headerName: "Nome do projeto", flex: 0.1, minWidth: 100, },
            { field: "familyCode", headerName: "Código da família", flex: 0.1, minWidth: 100, valueGetter: (params: any) => { return params.row.familyCode; } },
            { field: "dateStart", headerName: "Ano de início", flex: 0.1, minWidth: 100, valueGetter: (params: any) => params.row.dateStart.substring(0, 4) },
            { field: "endDate", headerName: "Ano de término", flex: 0.1, minWidth: 100, valueGetter: (params: any) => params.row.endDate !== null ? params.row.endDate.substring(0, 4) : null },
            { field: "duration", headerName: "Duração (em mês)", flex: 0.1, minWidth: 100 },
            { field: "responsible", headerName: "Responsável", flex: 0.2, minWidth: 100, valueGetter: (params: any) => { return params.row.responsible.name; } },
            { field: "updatedAt", headerName: "Última modificação", flex: 0.1, minWidth: 100, valueGetter: (params: any) => { return transformUsDateToBr(params.row.updatedAt) } },
            {
                field: 'actions', type: 'actions', headerName: "Ação", flex: 0.1, minWidth: 100,
                renderCell: (value) => {
                    if (value.row.status === "Draft" || value.row.status === "Cancelado") {
                        return <Box flex={2}>
                            <ButtonTextComponent text="Excluir" onClick={() => {
                                setOpenDisable(true);
                                setId(value.row.id);
                                setStatus(value.row.status)
                            }} />
                            <ButtonTextComponent text="Ver Detalhes" onClick={() => navigate("/plano-testes/editar/" + value.row.id)} />
                        </Box>
                    } else {
                        return <Box flex={1}>
                            <ButtonTextComponent text="Ver Detalhes" onClick={() => navigate("/plano-testes/editar/" + value.row.id)} />
                        </Box>
                    }
                }
            },
        ],
        [],
    );

    const columnsAprov = useMemo<GridColumns<any>>(
        () => [
            { field: "name", headerName: "Nome do projeto", flex: 0.1, minWidth: 100, },
            { field: "familyCode", headerName: "Código da família", flex: 0.1, minWidth: 100, valueGetter: (params: any) => { return params.row.familyCode; } },
            { field: "dateStart", headerName: "Ano de início", flex: 0.1, minWidth: 100, valueGetter: (params: any) => params.row.dateStart.substring(0, 4) },
            { field: "endDate", headerName: "Ano de término", flex: 0.1, minWidth: 100, valueGetter: (params: any) => params.row.endDate !== null ? params.row.endDate.substring(0, 4) : null },
            { field: "duration", headerName: "Duração (em mês)", flex: 0.1, minWidth: 100 },
            { field: "responsible", headerName: "Responsável", flex: 0.1, minWidth: 100, valueGetter: (params: any) => { return params.row.responsible.name; } },
            { field: "updatedAt", headerName: "Última modificação", flex: 0.1, minWidth: 100, valueGetter: (params: any) => { return transformUsDateToBr(params.row.updatedAt) } },
            { field: "status", headerName: "Status", flex: 0.1, minWidth: 100 },
            {
                field: 'actions', type: 'actions', headerName: "Ação", flex: 0.1, minWidth: 100,
                renderCell: (value) => {
                    if (value.row.status === "Draft" || value.row.status === "Cancelado") {
                        return <Box flex={2}>
                            <ButtonTextComponent text="Excluir" onClick={() => {
                                setOpenDisable(true);
                                setId(value.row.id);
                                setStatus(value.row.status)
                            }} />
                            <ButtonTextComponent text="Ver Detalhes" onClick={() => navigate("/plano-testes/editar/" + value.row.id)} />
                        </Box>
                    } else {
                        return <Box flex={1}>
                            <ButtonTextComponent text="Ver Detalhes" onClick={() => navigate("/plano-testes/editar/" + value.row.id)} />
                        </Box>
                    }
                }
            },
        ],
        [],
    );
    const userRoles = useRoles();
    const showNewTestPlanButton = !userRoles.some(role => ['vwag_vwdb_stapp_adm', 'vwag_vwdb_stapp_engpro', 'vwdb_stapp_adm', 'vwdb_stapp_engpro'].includes(role));

    // #region Functions
    const filterList = (event: any, status: string) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            fetchList("", "", "", status, page, pageSize);
        } else {
            setFilter(event.target.value);
            fetchList(event.target.value, filter1, filter2, status, page, pageSize);
        }
    }

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    const handleCloseModalDisable = (e: any) => {
        setOpenFilter(false);
    };
    // #endregion Functions

    return (
        access ?
            <Grid container>
                <HeaderComponent text="Plano de Testes" access={access}/>
                <ModalComponent description="Todos os testes previstos nele serão excluidos e não será possível reverter a exclusão. Deseja realmente excluir esse plano de teste ?"
                    open={openDisable}
                    close={handleCloseModalDisable}
                    onCancel={() => { setOpenDisable(false) }}
                    onNext={() => {
                        fetDeleteTestPlan(id, status, page, pageSize);
                        if (codeStatus === 200) {
                            setId(null);
                            setOpenDisable(false);
                        } else {
                            setOpenDisable(false);
                            setErrorDisable("Não é possível excluir este Plano de Teste.");
                        }
                    }} />
                <ModalComponent
                    description={messageAlert}
                    isOk={true}
                    open={isError}
                    close={() => { setIsError(false) }}
                    onCancel={() => { setIsError(false) }}
                    onNext={() => setIsError(false)}
                />
                <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
                    <Grid container sx={{ paddingBottom: "20px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LabelComponent text="Lista " fontSize="28px" />
                            <LabelComponent text="dos Planos de teste" fontWeight="normal" fontSize="28px" />
                        </Grid>
                        <Grid hidden={showNewTestPlanButton} item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "right" }}>
                            <ButtonLinkComponent text={"Criar Novo"} link={"/plano-testes/novo"} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <ModalFilterComponent
                            open={openFilter}
                            labelFilter1={"Nome do projeto"}
                            isPagination1={true}
                            value1={filter1}
                            url1={"testPlans/all"}
                            status1={listStatus}
                            onSelect1={(paginationEvent: string) => {
                                setFilter1(paginationEvent);
                                setIsFilter(true);
                            }}
                            labelFilter2={"Responsável"}
                            isPagination2={false}
                            value2={filter2}
                            filter2={userList}
                            onSelect2={(event: any) => {
                                setFilter2(event.target.value);
                                setIsFilter(true);
                            }}
                            close={handleCloseModalFilter}
                            onCancel={() => { setOpenFilter(false) }}
                            onNext={() => {
                                if (filter1 === "" && filter2 === "") {
                                    setPage(0);
                                    setPageSize(5);
                                }
                                fetchList(filter, filter1, filter2, listStatus, page, pageSize);
                                setOpenFilter(false);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1', 'Draft')} sx={{ boxShadow: "none" }} >
                            <AccordionComponent text="Draft"></AccordionComponent>
                            <AccordionDetails>
                                {testPlanList ?
                                    <Box>
                                        <SearchComponent text="" value={filter} placeholder="Buscar por …"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                                fetchList(event.target.value, filter1, filter2, listStatus, page, pageSize);
                                            }}
                                            onClick={() => {
                                                setOpenFilter(true)
                                            }}
                                            onCancel={() => {
                                                setFilter('');
                                                fetchList('', filter1, filter2, listStatus, page, pageSize);
                                            }}
                                        />
                                        <DataTableComponent
                                            rows={testPlanList}
                                            columns={columns}
                                            hasFilter={filter || filter1 || filter2 ? true : false}
                                            loading={loading}
                                            page={page}
                                            pageSize={pageSize}
                                            totalElements={totalElements}
                                            onPageSizeChange={(page, pageSize) => {
                                                setPage(page)
                                                setPageSize(pageSize)
                                                fetchList(filter, filter1, filter2, listStatus, page, pageSize);
                                            }}
                                            onPageChange={(page, pageSize) => {
                                                setPage(page)
                                                setPageSize(pageSize)
                                                fetchList(filter, filter1, filter2, listStatus, page, pageSize);
                                            }}
                                            onClick={(event) => filterList(event, "Draft")}
                                        />
                                    </Box>
                                    : 'Carregando'
                                }
                            </AccordionDetails>
                        </Accordion>

                        {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2', 'Em Aprovação')} sx={{ boxShadow: "none" }} >
                            <AccordionComponent text="Em Aprovação"></AccordionComponent>
                            <AccordionDetails>
                                {testPlanList ?
                                    <Box>
                                        <SearchComponent text="" value={filter} placeholder="Buscar por …"
                                            onChange={(event) => {
                                                setFilter(event.target.value);
                                                fetchList(event.target.value, filter1, filter2, listStatus, page, pageSize);
                                            }}
                                            onClick={() => {
                                                setOpenFilter(true)
                                            }}
                                            onCancel={() => {
                                                setFilter('');
                                                fetchList('', filter1, filter2, listStatus, page, pageSize);
                                            }}
                                        />
                                        <DataTableComponent
                                            rows={testPlanList}
                                            columns={columns}
                                            hasFilter={filter || filter1 || filter2 ? true : false}
                                            loading={loading}
                                            page={page}
                                            pageSize={pageSize}
                                            totalElements={totalElements}
                                            onPageSizeChange={(page, pageSize) => {
                                                setPage(page)
                                                setPageSize(pageSize)
                                                fetchList(filter, filter1, filter2, "Em Aprovação", page, pageSize);
                                            }}
                                            onPageChange={(page, pageSize) => {
                                                setPage(page)
                                                setPageSize(pageSize)
                                                fetchList(filter, filter1, filter2, "Em Aprovação", page, pageSize);
                                            }}
                                            onClick={(event) => filterList(event, "Em Aprovação")}
                                        />
                                    </Box>
                                    : 'Carregando'}
                            </AccordionDetails>
                        </Accordion> */}

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3', 'Aprovados')} sx={{ boxShadow: "none" }}>
                            <AccordionComponent text="Aprovados"></AccordionComponent>
                            <AccordionDetails>
                                {testPlanList ? <Box>
                                    <SearchComponent text="" value={filter} placeholder="Buscar por …"
                                        onChange={(event) => {
                                            setFilter(event.target.value);
                                            fetchList(event.target.value, filter1, filter2, listStatus, page, pageSize);
                                        }}
                                        onClick={() => {
                                            setOpenFilter(true)
                                        }}
                                        onCancel={() => {
                                            setFilter('');
                                            fetchList('', filter1, filter2, listStatus, page, pageSize);
                                        }}
                                    />
                                    <DataTableComponent
                                        rows={testPlanList}
                                        columns={columnsAprov}
                                        hasFilter={filter || filter1 || filter2 ? true : false}
                                        loading={loading}
                                        page={page}
                                        pageSize={pageSize}
                                        totalElements={totalElements}
                                        onPageSizeChange={(page, pageSize) => {
                                            setPage(page)
                                            setPageSize(pageSize)
                                            fetchList(filter, filter1, filter2, "Aprovados", page, pageSize);
                                        }}
                                        onPageChange={(page, pageSize) => {
                                            setPage(page)
                                            setPageSize(pageSize)
                                            fetchList(filter, filter1, filter2, "Aprovados", page, pageSize);
                                        }}
                                        onClick={(event) => filterList(event, "Aprovados")}
                                    />
                                </Box>
                                    : 'Carregando'}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4', 'Cancelados')} sx={{ boxShadow: "none" }}>
                            <AccordionComponent text="Reprovados"></AccordionComponent>
                            <AccordionDetails>
                                {testPlanList ? <Box>
                                    <SearchComponent text="" value={filter} placeholder="Buscar por …"
                                        onChange={(event) => {
                                            setFilter(event.target.value);
                                            fetchList(event.target.value, filter1, filter2, listStatus, page, pageSize);
                                        }}
                                        onClick={() => {
                                            setOpenFilter(true)
                                        }}
                                        onCancel={() => {
                                            setFilter('');
                                            fetchList('', filter1, filter2, listStatus, page, pageSize);
                                        }}
                                    />
                                    <DataTableComponent
                                        rows={testPlanList}
                                        columns={columns}
                                        hasFilter={filter || filter1 || filter2 ? true : false}
                                        loading={loading}
                                        page={page}
                                        pageSize={pageSize}
                                        totalElements={totalElements}
                                        onPageSizeChange={(page, pageSize) => {
                                            setPage(page)
                                            setPageSize(pageSize)
                                            fetchList(filter, filter1, filter2, "Cancelados", page, pageSize);
                                        }}
                                        onPageChange={(page, pageSize) => {
                                            setPage(page)
                                            setPageSize(pageSize)
                                            fetchList(filter, filter1, filter2, "Cancelados", page, pageSize);
                                        }}
                                        onClick={(event) => filterList(event, "Cancelados")}
                                    />
                                </Box>
                                    : 'Carregando'}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            : <NotAuthorizedPage />
    )
}

export default TestPlanList;
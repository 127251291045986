import { ToggleButton, Box } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import LabelComponent from "../../components/label/labelComponent";

function ButtonsCalendar(props: ParamsField) {
  const [selected, setSelected] = useState(props.select);


  return (
    <Button variant="text"
      sx={{
        padding: props.padding ? props.padding : '16px',
        width: props.width ? props.width : '58px',
        height: props.height ? props.height : '55px',
        border: props.border ? props.border : '0',
        margin: props.margin ? props.margin : 0,
        backgroundColor: props.backgroundColor ? props.backgroundColor : '#001d50',
        color: props.color ? props.color : 'white',
        borderRadius: props.borderRadius ? props.borderRadius : '1px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <LabelComponent
        text={props.text}
        fontSize={props.fontSize ? props.fontSize : "18px"}
        fontWeight={props.fontWeight ? props.fontWeight : "bold"}
        color={props.color ? props.color : "white"}
      />
      <LabelComponent
        text={props.value}
        fontSize={props.fontSize ? props.fontSize : "18px"}
        fontWeight="normal"
        color={props.color ? props.color : "white"}
      />
    </Button>
  )
}

export default ButtonsCalendar;
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { minutesToHours } from "../../utils/utils";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import { get, put } from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { Box } from "@mui/system";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import SelectComponent from "../../components/select/selectComponent";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import useAltraManagement from "../../hooks/altraManagement/useAltraManagement";
import ModalReadPage from "../../components/modal/modalReadPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function AltraManagementPage() {
  const { laborTypeTestId } = useParams();
  const [valid, setValid] = useState(false);
  const [status, setStatus] = useState<string>("");
  const [statusAltraId, setStatusAltraId] = useState<string>("");
  const navigate = useNavigate();

  const { testLaboratory, statusAltra, statusAltraIdEdit,
    altraActivityList, altraSupervisionsList,
    typeTest, totalCost, totalHours, totalValue, read, setRead,
    laborName, openAlert, altraResourcesList, fetchSubmit
  } = useAltraManagement(laborTypeTestId);

  const columns: GridColDef[] = [
    { field: "typeTest", headerName: "Teste", flex: 2, minWidth: 100 },
    {
      field: "subCategory",
      headerName: "Sub-categoria",
      flex: 2,
      minWidth: 100,
    },
    { field: "category", headerName: "Categoria", flex: 2, minWidth: 100 },
    {
      field: "totalHours",
      headerName: "Duração total",
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return minutesToHours(params.row.totalHours) + " h";
      },
    },
    {
      field: "totalCost",
      headerName: "Custo consumível",
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return "R$ " + params.row.totalCost.replace(".", ",");
      },
    },
  ];

  const columnsActivity: GridColDef[] = [
    { field: "activityName", headerName: "Atividades", flex: 2, minWidth: 100 },
    {
      field: "activityCategory",
      headerName: "Categoria",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "totalHours",
      headerName: "Duração total",
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return minutesToHours(params.row.totalHours) + "h";
      },
    },
  ];

  const columnsResources: GridColDef[] = [
    { field: "resourceName", headerName: "Recursos", flex: 2, minWidth: 100 },

    {
      field: "resourceType",
      headerName: "Tipo do recurso",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "totalCost",
      headerName: "Valor total",
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return "R$ " + params.row.totalCost.replace(".", ",");
      },
    },
  ];

  const handleSubmit = (e: any) => {
    if (valid) {
      fetchSubmit({
        "laborTypeTestId": laborTypeTestId,
        "statusAltra": status,
      });
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    window.location.reload();
  };

  return (
    <Grid container md={12}>
      <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Laboratórios / Detalhes / Gestão Altra" access={true} />
      <SnackbarComponent
        text={"Status editado com sucesso!"}
        open={openAlert}
        closeSnackbar={handleClose}
      />
      <ModalReadPage open={read} onCancel={() => setRead(false)} />
      <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LabelComponent text="Gestão " fontSize="28px" marginTop="32px" />
            <LabelComponent text="Altra" fontSize="28px" fontWeight="normal" />
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: "32px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <LabelComponent text={"Testes associados ao laboratório " + "\"" + laborName + "\""} fontSize="20px" fontWeight="normal" color="#001e50" />
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "24px" }} xs={12} sm={12} md={12} lg={12}>
          <DataTableGridComponent
            rows={testLaboratory}
            columns={columns}
            loading={false}
            isOneRegister={true}
          />
        </Grid>
        <Grid container sx={{ marginTop: "32px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5.8}
            lg={5.86}
            sx={{ marginRight: "30px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: "#001e50",
              }}
            >
              <p>Atividades associadas ao {typeTest}</p>
              <ButtonTextComponent
                text="Ir para associação "
                onClick={() => navigate("/associar-atividade", { state: { laborTypeTestId: laborTypeTestId } })}
              />
            </Box>
            <DataTableGridComponent
              rows={altraActivityList}
              columns={columnsActivity}
              loading={false}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5.8} lg={5.86}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: "#001e50",
              }}
            >
              <p>Recursos associadas ao {typeTest}</p>
              <ButtonTextComponent
                text="Ir para associação "
                onClick={() => navigate("/associar-recurso", { state: { laborTypeTestId: laborTypeTestId } })}
              />
            </Box>
            <DataTableGridComponent
              rows={altraResourcesList}
              columns={columnsResources}
              loading={false}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={5.8}
            lg={5.86}
            sx={{
              marginRight: "30px",
              backgroundColor: "#dfe4e8",
              borderRadius: "8px",
              marginTop: "32px",
              marginBottom: "16px",
              padding: "32px 24px",
              width: "630px",
              overflowX: "auto",
              minWidth: "655px",
            }}
          >
            <Grid
              container
              xs={12}
              sx={{
                backgroundColor: "#dfe4e8",
                borderRadius: "0 0 4px 4px",
                color: "#001e50",
                fontFamily: "VWHead",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                fontWeight: "bold",
                letterSpacing: "normal",
                textAlign: "center",
                marginBottom: "16px"
              }}
            >
              <Grid item xs={4}>
                Supervisão
              </Grid>
              <Grid item xs={2}>
                Horista
              </Grid>
              <Grid item xs={2}>
                Mensalista
              </Grid>
              <Grid item xs={2}>
                Terceiro
              </Grid>
              <Grid item xs={2}>
                Total supervisão
              </Grid>
            </Grid>
            <Grid container style={{ maxHeight: "300px", overflowY: "auto" }}>
              {altraSupervisionsList.map((value: any) => {
                return (
                  <DataTableEditComponent
                    type="activity"
                    readOnlyHour={true}
                    readOnlyMonth={true}
                    readOnlyThird={true}
                    name={value.initials}
                    hour={minutesToHours(value.hoursPart)}
                    month={minutesToHours(value.hoursMonth)}
                    third={minutesToHours(value.hoursThird)}
                    totalRow={minutesToHours(value.hoursSubTotal)}
                  />
                );
              })}
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                borderRadius: "0 0 4px 4px",
                fontFamily: "VWHead",
                fontSize: "16px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                letterSpacing: "normal",
                textAlign: "center",
              }}
            >
              <Grid item xs={4}
                sx={{
                  color: "#001e50",
                }}
              >
                Total cargo
              </Grid>

              <Grid item xs={2} sx={{ color: "#6a767d" }}>
                {totalValue.hour}
              </Grid>

              <Grid item xs={2} sx={{ color: "#6a767d" }}>
                {totalValue.month}
              </Grid>

              <Grid item xs={2} sx={{ color: "#6a767d" }}>
                {totalValue.third}
              </Grid>

              <Grid item xs={2} sx={{ color: "#6a767d" }}>
                {totalValue.totalRow}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={5.8} lg={5.86}>
            <Grid
              sx={{
                borderRadius: "10px",
                backgroundColor: "#dfe4e8",
                color: "#001e50",
                fontFamily: "VWHead",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                fontWeight: "bold",
                marginTop: "32px",
                display: "flex",
                padding: "14px",
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    Total do custo dos recursos consumíveis
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ color: "#6a767d", textAlign: "right" }}
                  >
                    R$ {totalCost.toFixed(2).replace('.', ',')}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#dfe4e8",
                marginTop: "15px",
                display: "flex",
                padding: "14px",
                color: "#001e50",
                fontFamily: "VWHead",
                fontSize: "16px",
                fontStretch: "normal",
                fontStyle: "normal",
                fontWeight: "bold",
              }}
            >
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    Total das atividades
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ color: "#6a767d", textAlign: "right" }}
                  >
                    {totalHours}h
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              sx={{
                borderRadius: "10px",
                backgroundColor: "#dfe4e8",
                marginTop: "15px",
                padding: "14px",
              }}
            >
              <SelectComponent
                text="Status"
                values={statusAltra}
                value={statusAltraId === "" && statusAltraIdEdit !== "" ? statusAltraIdEdit : statusAltraId}
                required={true}
                onSelect={(event: any, child: any) => {
                  setStatus(child.props.children);
                  setStatusAltraId(event.target.value);
                  if (event.target.value !== "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}
              />
            </Grid>

            <Grid
              container
              sx={{
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{ marginTop: "32px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  disable={true}
                  onClick={() => {
                    setStatusAltraId("");
                  }}
                />
              </Grid>
              <Grid
                sx={{ marginTop: "32px", marginLeft: "8%" }}
              >
                <ButtonComponent
                  text="Salvar"
                  active={valid}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>);
}
export default AltraManagementPage;

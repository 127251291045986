import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "../../api/api";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { useNavigate, useParams } from "react-router-dom";
import { hourToMinutesSubtraction, minutesToHours, moneyToIntegerSubtraction } from "../../utils/utils";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";

function LaboratoryTestDetails() {
    const [laboratory, setLaboratory] = useState<LaboratoryTestDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { id } = useParams();

    const columns: GridColDef[] = [
        { field: "typeTest", headerName: "Testes", flex: 2, minWidth: 100, },
        { field: "subCategory", headerName: "Sub-categoria", flex: 2, minWidth: 100 },
        { field: "category", headerName: "Categoria", flex: 2, minWidth: 100 },
        {
            field: "totalHours", headerName: "Duração Total", flex: 2, minWidth: 100,
            valueGetter: (params: any) => {
                return minutesToHours(params.row.totalHours) + ' h';
            },
            sortComparator: (value1: any, value2: any) => {
                return hourToMinutesSubtraction(value1, value2);
            }
        },
        {
            field: "totalCost", headerName: "Custo consumível", flex: 2, minWidth: 100,
            valueGetter: (params: any) => {
                return 'R$ ' + params.row.totalCost.replace('.', ',');
            },
            sortComparator: (value1: any, value2: any) => {
                return moneyToIntegerSubtraction(value1, value2);
            }
        },
        {
            field: "status", headerName: "Status", flex: 1, minWidth: 100,
            valueGetter: (params: any) => {
                return params.row.status;
            }
        },
        {
            field: "altra", headerName: "Ação", flex: 1, minWidth: 100,
            renderCell: (value) => {
                return <Box flex={1}>
                    <ButtonTextComponent text="Gestão Altra" onClick={() => navigate("/gestao-altra/" + value.row.laborTypeTestId)} />
                </Box>
            }
        },
    ];

    useEffect(() => {
        getList();
    }, []);

    function getList() {
        get<LaboratoryTestDetails[]>("altra/managements/details/" + id).then((value) => {
            setLaboratory(value);
            setLoading(false);
        });
    }

    return (
        <Grid container md={12}>
            <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Laboratórios / Detalhes" access={true}/>
            <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <LabelComponent text={"Detalhes "} fontSize="28px" />
                        <LabelComponent text="da Associação dos Testes" fontSize="28px" fontWeight="normal" />
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: "32px", marginBottom: "24px" }}>
                    <Grid item xs={12} >
                        <LabelComponent text="Testes no laboratório" />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataTableGridComponent
                        rows={laboratory}
                        columns={columns}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Grid>);
}
export default LaboratoryTestDetails;
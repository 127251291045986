import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { deleteR, get } from "../../api/api";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import { GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { useNavigate, useParams } from "react-router-dom";
import { hourToMinutesSubtraction, minutesToHours } from "../../utils/utils";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";

function ActivityTestDetails() {
    const [laboratory, setLaboratory] = useState<LaborTypeTestActivities[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { laborTypeTestId } = useParams();

    const columns: GridColDef[] = [
        { field: "quantity", headerName: "Quantidade", flex: 2, minWidth: 100, },
        { field: "activityName", headerName: "Atividades", flex: 3, minWidth: 100, },
        { field: "category", headerName: "Categoria", flex: 3, minWidth: 100 },
        {
            field: "totalHours", headerName: "Duração Total", flex: 3, minWidth: 100,
            valueGetter: (params: any) => {
                return minutesToHours(params.row.totalHours) + ' h';
            },
            sortComparator: (value1: any, value2: any) => {
                return hourToMinutesSubtraction(value1, value2);
            }
        },
        {
            field: "altra", headerName: "Ação", flex: 3, minWidth: 100,
            renderCell: (value) => {
                return <Box flex={1}>
                    <ButtonTextComponent text="Excluir" onClick={() => deleteActivity(value.row.laborTypeTestActivityId)} />
                </Box>
            }
        },
    ];

    useEffect(() => {
        getList();
    }, []);

    function getList() {
        get<any[]>("laboratories/typeTests/activities/" + laborTypeTestId).then((value) => {
            setLaboratory(value.laborTypeTestActivities);
            setLoading(false);
        });
    }

    function deleteActivity(id: number) {
        deleteR("laboratories/typeTests/activities/" + id).then((value: any) => {
            setLoading(true);
            getList();
        });
    }

    return (
        <Grid container md={12}>
            <HeaderComponent text="Cadastro de Sistema / Associação / Testes com Atividades / Detalhes" access={true}/>
            <Grid container md={12} sx={{ margin: "48px 48px 16px 48px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <LabelComponent text={"Detalhes "} fontSize="28px" />
                        <LabelComponent text="da Associação dos Testes" fontSize="28px" fontWeight="normal" />
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: "32px", marginBottom: "24px" }}>
                    <Grid item xs={12} >
                        <LabelComponent text="Testes no laboratório" />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <DataTableGridComponent
                        rows={laboratory}
                        columns={columns}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Grid>);
}
export default ActivityTestDetails;
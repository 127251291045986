import { TextField } from "@mui/material";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function DateFieldComponent(props: DateParamsField) {

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      inputFormat="dd/MM/yyyy"
      label={props.text}
      value={props.value}
      onChange={props.onChangeDate}
      renderInput={(params) => <TextField
        sx={{ width: "94%" }}
        required={props.required ? props.required : false} variant="standard" {...params}
        disabled={props.disabled ? props.disabled : false}
      />}
    />
  </LocalizationProvider>;
}

export default DateFieldComponent;

import { useEffect, useState } from "react";
import { post } from "../../api/api";

const useHome = () => {
    const [name, setName] = useState<string>("Usuário, você não tem perfil vinculado à sua conta, para solicitar acesso à esta aplicação, procure o Administrador.")
    const [access, setAccess] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem("access_token") !== null) {
            fetchWegasUser();
        }
    }, []);

    const fetchWegasUser = () => {
        post<User>('users/screen')
            .then((value: User) => {
                localStorage.setItem("userId", value.id.toString());
                setAccess(value.screens.length > 0 ? true : false);
                if (value.screens.length > 0) {
                    setName(value.name.toString() + ',');
                }
            });
    }

    return { name, access };
}

export default useHome;
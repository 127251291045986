import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { MenuComponent } from '../menu/menuComponent';
import logo from '../../assets/images/logo.png';
import { sendLogout } from '../../services/login';

export default function HeaderComponent(props: Header) {

    return (
        <AppBar sx={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            position: props.isHome ? 'absolute' : 'static'
        }}>

            <Toolbar>
                {props.access ? <MenuComponent isHome={props.isHome} /> : ""}

                <Typography component="div" sx={{
                    flexGrow: 1,
                    color: '#001e50',
                    textAlign: 'left',
                    fontSize: 16,
                    lineHeight: 1.25,
                    fontFamily: 'VWText',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    letterSpacing: 'normal'
                }}>
                    {props.text}
                </Typography>

                <Box
                    component="img"
                    sx={{
                        backgroundColor: props.isHome ? '' : 'white',
                        borderRadius: '50px',
                        height: 48,
                        left: '0',
                        marginLeft: 'calc(69% - 25px)',
                        marginTop: 6,
                        objectFit: 'contain',
                        paddingLeft: '1%',
                        paddingRight: '1%',
                        position: 'absolute',
                        width: '50px',
                        zIndex: 1
                    }}
                    alt="VW"
                    src={logo}
                />

                {props.access ?
                    <Button sx={{
                        color: '#001e50',
                        textAlign: 'right',
                        fontWeight: 'bold',
                        fontSize: 16,
                        lineHeight: 1.25,
                        fontFamily: 'VWText',
                        textTransform: 'none'
                    }}
                    onClick={sendLogout}
                    >Logout</Button>
                    : ""}
            </Toolbar>

            {
                props.isHome
                    ?
                    <>
                        <div style={{ borderBottom: '2px solid #001e50', left: '68px', marginTop: '-8px', position: 'absolute', top: '62px', width: 'calc(69% - 85px)' }}></div>
                        <div style={{ borderBottom: '2px solid #001e50', marginTop: '-8px', position: 'absolute', right: 0, top: '62px', width: 'calc(29% - 25px)' }}></div>
                    </>
                    :
                    <div style={{ borderBottom: '2px solid #001e50', left: 0, marginTop: '-8px', position: 'absolute', top: '62px', width: '100%' }}></div>
            }

        </AppBar>

    );
}
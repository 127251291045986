import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import LabelComponent from "../../components/label/labelComponent";
import SelectComponent from "../../components/select/selectComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import useRequestTestTab3 from "../../hooks/requestTest/useRequestTestTab3";
import ModalComponent from "../../components/modal/modalComponent";
import DraggableRequestTestComponent from "../../components/draggable/draggable";
import DataTableEditComponent from "../../components/dataTable/dataTableEditComponent";
import { minutesToHours } from "../../utils/utils";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalReadPage from "../../components/modal/modalReadPage";

function RequestTestPageTab3() {
    const { type, id } = useParams();
    const {
        categoryActivities, setCategoryActivities,
        activities, setActivities, calculateSum,
        categoryActivitiesId, setCategoryActivitiesId,
        activitiesId, setActivitiesId,
        listActivities, setListActivities,
        openAlert, setOpenAlert,
        loading, fetchEdit, read, setRead,
        messageAlert, isError, setIsError,
        fetchCategoryActivities, fetchActivitiesActive, fetchActivitiesSubmit,
        fetchActivity, calculatedActivities, totalValue
    } = useRequestTestTab3({ type, id });

    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenAlert(false)
    };

    return (<Grid container>
        <SnackbarComponent isTab={true} text={"Requisição de teste salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent text=""
            open={openModal}
            description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
            close={handleCloseModal}
            onCancel={() => { setOpenModal(false) }}
            onNext={() => {
                fetchEdit(id);
                setCategoryActivitiesId("");
                setActivitiesId("");
                setOpenModal(false);
                setIsAdd(false);
            }} />
        {loading ? <LoadingComponente /> : null}
        <ModalComponent
            description={messageAlert}
            isOk={true}
            open={isError}
            close={() => { setIsError(false) }}
            onCancel={() => { setIsError(false) }}
            onNext={() => setIsError(false)}
        />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container item xs={12} sm={12} md={12} lg={5.9}>
            <Grid container>
                <Grid item>
                    <LabelComponent text="Lista de " fontSize="24px" />
                    <LabelComponent text="Atividades" fontSize="24px" fontWeight="normal" />
                </Grid>
            </Grid>
            <DraggableRequestTestComponent
                listResourcesConsumable={[]}
                listResourcesInventory={[]}
                listActivities={listActivities}
                isResources={false}
                isActivities={true}
                onChangeListResourcesConsumable={(value) => { }}
                onChangeListResourcesInventory={(value) => { }}
                onChangeListActivities={(value) => { }}
                onChangeDate={(date: Date, value: any) => {
                    value.date = date;
                }}
                onClickDelete={(value: any) => {
                    var index = listActivities.indexOf(value);
                    if (index > -1) {
                        if (listActivities[index].quantity > 1) {
                            listActivities[index].quantity--;
                        } else {
                            listActivities.splice(index, 1);
                        }
                    }
                    setListActivities(listActivities);
                    setListActivities([...listActivities]);
                    calculateSum(value.activitiesSupervisions, "sub");
                }}
            />
        </Grid>
        <Grid container item xs={0} sm={0} md={0} lg={0.2}></Grid>
        <Grid container item xs={12} sm={12} md={12} lg={5.9}>
            <Grid container>
                <Grid item>
                    <LabelComponent text="Horas das " fontSize="24px" />
                    <LabelComponent text="Atividades" fontSize="24px" fontWeight="normal" />
                </Grid>
            </Grid>

            <Grid container sx={{
                backgroundColor: "#dfe4e8",
                borderRadius: "8px",
                height: "304px",
                marginTop: "24px",
                overflowX: "auto",
                padding: "24px 24px 24px 0"
            }}>
                {calculatedActivities ? <>
                    <Grid container style={{
                        backgroundColor: '#dfe4e8',
                        borderRadius: "0 0 4px 4px",
                        color: "#001e50",
                        fontFamily: "VWHead",
                        fontSize: "16px",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        letterSpacing: "normal",
                        textAlign: "center",
                    }}>
                        <Grid item xs={4}>Supervisão</Grid>
                        <Grid item xs={2}>Horista</Grid>
                        <Grid item xs={2}>Mensalista</Grid>
                        <Grid item xs={2}>Terceiro</Grid>
                        <Grid item xs={2}>Total Supervisão</Grid>
                    </Grid>

                    <Grid container style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {calculatedActivities.map((value: any) => {
                            return <DataTableEditComponent
                                type="activity"
                                name={value.name}
                                hour={minutesToHours(value.hour).toString()}
                                month={minutesToHours(value.month).toString()}
                                third={minutesToHours(value.third).toString()}
                                totalRow={minutesToHours(value.totalRow).toString()}
                                readOnlyHour={true}
                                readOnlyMonth={true}
                                readOnlyThird={true}
                            />
                        })}
                    </Grid>
                    {totalValue.totalRow > 0 ?
                        <Grid container style={{ backgroundColor: '#dfe4e8', borderRadius: "0 0 4px 4px", fontFamily: "VWHead", fontSize: "16px", fontWeight: "bold", fontStretch: "normal", fontStyle: "normal", letterSpacing: "normal" }}>
                            <Grid item xs={4} sx={{ color: "#001e50", textAlign: "center" }}>Total Cargo</Grid>
                            <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalValue.hour)}h</Grid>
                            <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalValue.month)}h</Grid>
                            <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalValue.third)}h</Grid>
                            <Grid item xs={2} sx={{ color: "#6a767d", textAlign: "center" }}>{minutesToHours(totalValue.totalRow)}h</Grid>
                        </Grid>
                        : null
                    }
                </>
                    : null
                }
            </Grid>

            <Grid container sx={{
                backgroundColor: "#dfe4e8",
                borderRadius: "8px",
                marginTop: "24px",
                overflowX: "auto",
                padding: "24px"
            }}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <LabelComponent text="Total das Atividades" color="#001e50" fontSize="14px" />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} sx={{ textAlign: "center" }}>
                    <LabelComponent text={minutesToHours(totalValue.totalRow) + "h"} color="#6a767d" fontSize="14px" />
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={5.9}>
            <Grid item sx={{ marginTop: "24px" }}>
                <ButtonComponent
                    width={"160px"}
                    text={"Adicionar novo"}
                    border={"1px dashed #c2cacf"}
                    backgroundColor={"#fff"}
                    borderRadius={"4px;"}
                    color={"#00b0f0"}
                    isAdd={true}
                    onClick={() => {
                        setIsAdd(!isAdd)
                        fetchCategoryActivities();
                    }}
                />
            </Grid>
        </Grid>
        <Grid container item xs={12}>
            {isAdd ?
                <Grid container item xs={12} sm={12} md={12} lg={5.9} sx={{ marginTop: "24px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <SelectComponent
                            values={categoryActivities}
                            text="Categoria"
                            value={categoryActivitiesId}
                            required={true}
                            onSelect={(event: any) => {
                                setCategoryActivitiesId(event.target.value);
                                fetchActivitiesActive(event.target.value);
                            }} />
                    </Grid>
                    <Grid container item xs={0} sm={0} md={0} lg={0.2}></Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5.9}>
                        <SelectComponent
                            values={activities}
                            text="Nome da atividade"
                            value={activitiesId}
                            required={true}
                            onSelect={(event: any) => {
                                setActivitiesId(event.target.value);
                            }} />
                    </Grid>

                    <Grid container style={{ justifyContent: 'center' }}>
                        <Grid item xs={6} sm={6} md={6} lg={3} sx={{ marginTop: "40px", textAlign: "center" }}>
                            <ButtonComponent
                                text="Cancelar"
                                disable={true}
                                color="#00b0f0"
                                borderColor="#00b0f0"
                                border="1px solid #00b0f0"
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3} sx={{ marginTop: "40px", textAlign: "center" }} >
                            <ButtonComponent
                                text="Incluir"
                                backgroundColor={(categoryActivitiesId !== "" && activitiesId !== "") ? "#00b0f0" : "#00b0f040"}
                                border="1px solid #00b0f040"
                                active={true}
                                onClick={() => {
                                    if (categoryActivitiesId !== "" && activitiesId !== "") {
                                        fetchActivity(activitiesId);
                                        setCategoryActivities([])
                                        setCategoryActivitiesId("")
                                        setActivities([])
                                        setActivitiesId("")
                                        setIsAdd(!isAdd);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                : null}
        </Grid>
        <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                <ButtonComponent
                    text="Cancelar"
                    disable={true}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }} >
                <ButtonComponent
                    text="Salvar"
                    active={true}
                    onClick={() => {
                        fetchActivitiesSubmit(listActivities);
                    }}
                />
            </Grid>
        </Grid>
    </Grid >);
}

export default RequestTestPageTab3;
import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import { SortArrayObjByName } from "../../utils/utils";
import useRoute from "../route/useRoute";
type Type = any;

const useProgListSinergy = (state: Type) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [sinergyItem, setSinergyItem] = useState<LaboratoryList[]>([]);
    const [sinergyFilter, setSinergyFilter] = useState<LaboratoryListData>();
    const [totalElements, setTotalElements] = useState<number>(0);
    const [sinergyCars, setSinergyCars] = useState<SinergyProg>();
    const [sinergy, setSinergy] = useState<any>();
    const [days, setDays] = useState<any[]>([]);
    const [monthId, setMonthId] = useState<string>();
    const [months, setMonths] = useState<Months[]>([]);
    const [weekId, setWeekId] = useState<string>();
    const [weeks, setWeeks] = useState<Weeks[]>([]);
    const [yearId, setYearId] = useState<string>();
    const [years, setYears] = useState<Years[]>([]);
    const [activities, setActivities] = useState<GeneralType[]>([]);
    const [testPlans, setTestPlans] = useState<GeneralType[]>([]);
    const [access, setAccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("programacao-laboratorios"));
        fetchFilterList(state.id);
        fetchFilterSelect(state.id);
        fetchListSinergy("", "", "", "", state.id);
        fetchSinergy({ "laboratoryId": state.id });
    }, [])

    const fetchFilterList = (id: any) => {
        setLoading(true);
        get<LaboratoryListData>('laboratories/schedule/sinergia/data/' + id).then((value) => {
            value.vds = value.vds.sort(SortArrayObjByName);
            value.tests = value.tests.sort(SortArrayObjByName);
            value.weeks = value.weeks.sort(SortArrayObjByName);
            setSinergyFilter(value);
        }).finally(() => setLoading(false));
    }

    const fetchFilterSelect = (id: any) => {
        setLoading(true);
        get('laboratories/schedule/sinergia/testPlan/select/' + id).then((value) => {
            setActivities(value.activities);
            setTestPlans(value.testPlans);
        }).finally(() => setLoading(false));
    }

    const fetchListSinergy = (filter: string, filter1: string, filter2: string, filter3: string, id: any,) => {
        setLoading(true);
        setSinergyItem([]);
        post('/laboratories/schedule/sinergia/list/all',
            {
                "param": filter,
                "vds": filter1,
                "test": filter2,
                "kwYear": filter3,
                "laboratoryId": id
            })
            .then((value) => {
                setSinergyItem(value);
                setTotalElements(value.totalElements);
            }).finally(() => setLoading(false));
    };


    const fetchSinergy = (body: any) => {
        setLoading(true);
        post('/laboratories/schedule/sinergia/requestTests', body).then((date) => {
            setMonthId(date.monthId);
            setWeekId(date.week);
            setYearId(date.year);
            let newBody = {
                "laboratoryId": body['laboratoryId'],
                "kw": date.week,
                "year": date.year
            }
            setLoading(true);
            post('/laboratories/schedule/sinergia/requestTests', newBody)
                .then((value) => {
                    value.sinergiaTests.map((sinergia: any) => {
                        sinergia.cardsVds.map((cardVds: any) => {
                            cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        });
                    });
                    if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                        value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                    }
                    if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                        value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                    }
                    if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                        value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                    }
                    if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                        value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                    }
                    if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                        value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                    }
                    if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                        value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                    }
                    if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                        value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                    }
                    let a: any = [];
                    a.push({ 'label': 'P1', 'value': value.p1 });
                    a.push({ 'label': 'P2', 'value': value.p2 });
                    a.push({ 'label': 'P3', 'value': value.p3 });
                    a.push({ 'label': 'P4', 'value': value.p4 });
                    setSinergy(a)
                    setSinergyCars(value);
                    setTotalElements(value.totalElements);
                    setDays([value.p1]);
                    setLoading(true);
                    get('testPlans/data').then((data) => {
                        setYears(data.years);
                        setMonths(data.months);
                        data.months.map((month: any) => {
                            if (month.id === value.sinergiaTests[0].monthId) {
                                setWeeks(month.weeks);
                            }
                        });
                    }).finally(() => setLoading(false));
                }).finally(() => setLoading(false));
        }).finally(() => setLoading(false));
    };

    const fetchPopulaSinergy = (body: any) => {
            setLoading(true);
            post('/laboratories/schedule/sinergia/requestTests', body)
                .then((value) => {
                    value.sinergiaTests.map((sinergia: any) => {
                        sinergia.cardsVds.map((cardVds: any) => {
                            cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                if (testeOfWeek.cards.length === 0) {
                                    testeOfWeek.cards = [{
                                        isEmptyCard: true
                                    }]
                                } else {
                                    testeOfWeek.cards.map((card: any) => {
                                        card.isEmptyCard = false;
                                    });
                                }
                            });
                        });
                    });
                    if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                        value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                    }
                    if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                        value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                    }
                    if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                        value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                    }
                    if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                        value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                    }
                    if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                        value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                    }
                    if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                        value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                    }
                    if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                        value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                    }
                    let a: any = [];
                    a.push({ 'label': 'P1', 'value': value.p1 });
                    a.push({ 'label': 'P2', 'value': value.p2 });
                    a.push({ 'label': 'P3', 'value': value.p3 });
                    a.push({ 'label': 'P4', 'value': value.p4 });
                    setSinergy(a)
                    setSinergyCars(value);
                    setTotalElements(value.totalElements);
                    setDays([value.p1]);
                    setLoading(true);
                }).finally(() => setLoading(false));
    };

    const fetchData = (year: number, monthId: number) => {
        get('testPlans/data?year=' + year).then((data) => {
            setYears(data.years);
            setMonths(data.months);
            data.months.map((month: any) => {
                if (month.id === monthId) {
                    setWeeks(month.weeks);
                }
            });
        }).finally(() => setLoading(false));
    }

    const fetchPopulaTests = (body: any) => {
        setLoading(true);
        setSinergyCars(undefined);
        setSinergy(undefined);
        post('/laboratories/schedule/sinergia/requestTests', body)
            .then((value) => {
                value.sinergiaTests.map((sinergia: any) => {
                    sinergia.cardsVds.map((cardVds: any) => {
                        cardVds.testsOfWeek.map((testeOfWeek: any) => {
                            if (testeOfWeek.cards.length === 0) {
                                testeOfWeek.cards = [{
                                    isEmptyCard: true
                                }]
                            } else {
                                testeOfWeek.cards.map((card: any) => {
                                    card.isEmptyCard = false;
                                });
                            }
                        });
                    });
                });
                if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                    value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                }
                if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                    value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                }
                if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                    value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                }
                if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                    value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                }
                if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                    value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                }
                if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                    value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                }
                if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                    value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                }
                let a: any = [];
                a.push({ 'label': 'P1', 'value': value.p1 });
                a.push({ 'label': 'P2', 'value': value.p2 });
                a.push({ 'label': 'P3', 'value': value.p3 });
                a.push({ 'label': 'P4', 'value': value.p4 });
                setSinergy(a)
                setDays([value.p1]);
                setSinergyCars(value);
            }).finally(() => setLoading(false));
    }

    const fetchUpdateDateTest = (type: number, body1: any, body2: any) => {
        setLoading(true);
        put(type === 1 ?
            "/laboratories/schedule/requestTest/date"
            : "/laboratories/schedule/activities/date"
            , body1)
            .then(() => {
                post("/laboratories/schedule/sinergia/requestTests", body2)
                    .then((value) => {
                        value.sinergiaTests.map((sinergia: any) => {
                            sinergia.cardsVds.map((cardVds: any) => {
                                cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                    if (testeOfWeek.cards.length === 0) {
                                        testeOfWeek.cards = [{
                                            isEmptyCard: true
                                        }]
                                    } else {
                                        testeOfWeek.cards.map((card: any) => {
                                            card.isEmptyCard = false;
                                        });
                                    }
                                });
                            });
                        });
                        if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                            value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                        }
                        if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                            value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                        }
                        if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                            value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                        }
                        if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                            value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                        }
                        if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                            value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                        }
                        if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                            value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                        }
                        if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                            value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                        }
                        setDays([value.p1]);
                        setSinergyCars(value);
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        a.push({ 'label': 'P3', 'value': value.p3 });
                        a.push({ 'label': 'P4', 'value': value.p4 });
                        setSinergy(a)
                    }).finally(() => setLoading(false));
            });
    }

    const fetchUpdateDateAndVehicleTest = (body1: any, body2: any) => {
        setLoading(true);
        put("/requestTests/projectCarAndData", body1).then((requestTest) => {
            if (requestTest.codeStatus === 200) {
                post("/laboratories/schedule/sinergia/requestTests", body2)
                    .then((value) => {
                        value.sinergiaTests.map((sinergia: any) => {
                            sinergia.cardsVds.map((cardVds: any) => {
                                cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                    if (testeOfWeek.cards.length === 0) {
                                        testeOfWeek.cards = [{
                                            isEmptyCard: true
                                        }]
                                    } else {
                                        testeOfWeek.cards.map((card: any) => {
                                            card.isEmptyCard = false;
                                        });
                                    }
                                });
                            });
                        });
                        if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                            value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                        }
                        if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                            value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                        }
                        if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                            value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                        }
                        if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                            value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                        }
                        if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                            value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                        }
                        if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                            value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                        }
                        if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                            value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                        }
                        setDays([value.p1]);
                        setSinergyCars(value);
                        let a: any = [];
                        a.push({ 'label': 'P1', 'value': value.p1 });
                        a.push({ 'label': 'P2', 'value': value.p2 });
                        a.push({ 'label': 'P3', 'value': value.p3 });
                        a.push({ 'label': 'P4', 'value': value.p4 });
                        setSinergy(a)
                    }).finally(() => setLoading(false));
            } else {
                if (requestTest.errorMessage === 'Not Operation') {
                    setMessage("O perfil não pode realizar a operação.");
                } else {
                    setMessage(requestTest.errorMessage);
                }
                setLoading(false);
                setError(true);
            }
        });
    }

    const fetchPriority = (body: any, body2: any) => {
        setLoading(true);
        put("/laboratories/schedule/priority", body)
            .then((value) => {
                if (value.codeStatus === 200) {
                    setLoading(true);
                    post("/laboratories/schedule/sinergia/requestTests", body2)
                        .then((value) => {
                            value.sinergiaTests.map((sinergia: any) => {
                                sinergia.cardsVds.map((cardVds: any) => {
                                    cardVds.testsOfWeek.map((testeOfWeek: any) => {
                                        if (testeOfWeek.cards.length === 0) {
                                            testeOfWeek.cards = [{
                                                isEmptyCard: true
                                            }]
                                        } else {
                                            testeOfWeek.cards.map((card: any) => {
                                                card.isEmptyCard = false;
                                            });
                                        }
                                    });
                                });
                            });
                            if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                                value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                            }
                            if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                                value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                            }
                            if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                                value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                            }
                            if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                                value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                            }
                            if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                                value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                            }
                            if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                                value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                            }
                            if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                                value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                            }
                            setDays([value.p1]);
                            setSinergyCars(value);
                            let a: any = [];
                            a.push({ 'label': 'P1', 'value': value.p1 });
                            a.push({ 'label': 'P2', 'value': value.p2 });
                            a.push({ 'label': 'P3', 'value': value.p3 });
                            a.push({ 'label': 'P4', 'value': value.p4 });
                            setSinergy(a)
                        }).finally(() => setLoading(false));
                }
            }).finally(() => setLoading(false));
    }

    const fetchDamage = (body: any) => {
        setLoading(true);
        post('laboratories/schedule/sinergia/requestTest/damage', body)
            .then((value) => {
                value.sinergiaTests.map((sinergia: any) => {
                    sinergia.cardsVds.map((cardVds: any) => {
                        cardVds.testsOfWeek.map((testeOfWeek: any) => {
                            if (testeOfWeek.cards.length === 0) {
                                testeOfWeek.cards = [{
                                    isEmptyCard: true
                                }]
                            } else {
                                testeOfWeek.cards.map((card: any) => {
                                    card.isEmptyCard = false;
                                });
                            }
                        });
                    });
                });
                if (value.p1[0].dayOfMonth, value.p2[0].dayOfMonth, value.p3[0].dayOfMonth, value.p4[0].dayOfMonth) {
                    value.day1 = value.p1[0].cards.length + value.p2[0].cards.length + value.p3[0].cards.length + value.p4[0].cards.length;
                }
                if (value.p1[1].dayOfMonth, value.p2[1].dayOfMonth, value.p3[1].dayOfMonth, value.p4[1].dayOfMonth) {
                    value.day2 = value.p1[1].cards.length + value.p2[1].cards.length + value.p3[1].cards.length + value.p4[1].cards.length;
                }
                if (value.p1[2].dayOfMonth, value.p2[2].dayOfMonth, value.p3[2].dayOfMonth, value.p4[2].dayOfMonth) {
                    value.day3 = value.p1[2].cards.length + value.p2[2].cards.length + value.p3[2].cards.length + value.p4[2].cards.length;
                }
                if (value.p1[3].dayOfMonth, value.p2[3].dayOfMonth, value.p3[3].dayOfMonth, value.p4[3].dayOfMonth) {
                    value.day4 = value.p1[3].cards.length + value.p2[3].cards.length + value.p3[3].cards.length + value.p4[3].cards.length;
                }
                if (value.p1[4].dayOfMonth, value.p2[4].dayOfMonth, value.p3[4].dayOfMonth, value.p4[4].dayOfMonth) {
                    value.day5 = value.p1[4].cards.length + value.p2[4].cards.length + value.p3[4].cards.length + value.p4[4].cards.length;
                }
                if (value.p1[5].dayOfMonth, value.p2[5].dayOfMonth, value.p3[5].dayOfMonth, value.p4[5].dayOfMonth) {
                    value.day6 = value.p1[5].cards.length + value.p2[5].cards.length + value.p3[5].cards.length + value.p4[5].cards.length;
                }
                if (value.p1[6].dayOfMonth, value.p2[6].dayOfMonth, value.p3[6].dayOfMonth, value.p4[6].dayOfMonth) {
                    value.day7 = value.p1[6].cards.length + value.p2[6].cards.length + value.p3[6].cards.length + value.p4[6].cards.length;
                }
                let a: any = [];
                a.push({ 'label': 'P1', 'value': value.p1 });
                a.push({ 'label': 'P2', 'value': value.p2 });
                a.push({ 'label': 'P3', 'value': value.p3 });
                a.push({ 'label': 'P4', 'value': value.p4 });
                setSinergy(a)
                setSinergyCars(value);
                setTotalElements(value.totalElements);
                setDays([value.p1]);
                setMonthId(value.sinergiaTests[0].monthId);
                setWeekId(value.sinergiaTests[0].week);
                setYearId(value.sinergiaTests[0].year);
                get('testPlans/data').then((data) => {
                    setYears(data.years);
                    setMonths(data.months);
                    data.months.map((month: any) => {
                        if (month.id === value.sinergiaTests[0].monthId) {
                            setWeeks(month.weeks);
                        }
                    });
                });
            }).finally(() => setLoading(false));
    }

    return {
        fetchListSinergy, fetchFilterList, fetchSinergy, fetchPopulaTests,
        fetchUpdateDateTest, fetchPriority, fetchDamage, fetchUpdateDateAndVehicleTest,
        fetchData, fetchPopulaSinergy,
        loading,
        totalElements,
        sinergyFilter, setSinergyFilter,
        sinergyItem, setSinergyItem,
        sinergyCars, sinergy,
        monthId, setMonthId,
        months, setMonths,
        weekId, setWeekId,
        weeks, setWeeks,
        yearId, setYearId,
        years, setYears,
        days, setDays,
        activities, testPlans,
        message, setMessage,
        error, setError,
        access
    }
}

export default useProgListSinergy;
import React, { useState } from "react";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Link } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import './menuComponent.scss';

import menuIcon from '../../assets/images/menu@3x.png';
import closeIcon from '../../assets/images/close@3x.png';
import backIcon from '../../assets/images/arrow-back.svg';
import arrow from '../../assets/images/chevron-down@3x.png';
import useMenu from "../../hooks/menu/useMenu";

type Anchor = 'left';

export function MenuComponent(props: Menu) {

  const { screens, fetchMenu } = useMenu();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) { return; }
      setState({ ...state, [anchor]: open });
    };

  const [showSubmenuSystemRegister, setShowSubmenuSystemRegister] = useState(false);
  const [showSubmenuAssociations, setShowSubmenuAssociations] = useState(false);
  const [showSubmenuConsult, setShowSubmenuConsult] = useState(false);
  const [currentPage, setCurrentPage] = useState('');

  let url = 'http://localhost:3000/'; // Tem que pegar a url por ambiente, talvez por env

  useState(() => {
    setCurrentPage(window.location.href.replace(url, ''));
  });

  const applyBold = (page: string): string => currentPage === page ? 'bold' : '';

  return <>
    {(['left'] as const).map((anchor) => (
      <React.Fragment key={anchor}>
        <IconButton
          onClick={toggleDrawer(anchor, true)}
          size="large"
          edge="start"
          color="primary"
          aria-label="menu"
          sx={{ mr: 2 }}>
          <img style={{ filter: props.isHome ? 'invert(1)' : '', width: '24px' }}
            src={menuIcon}
            alt="Botão Menu"
          />
        </IconButton>

        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          <div style={{
            display: !showSubmenuSystemRegister && !showSubmenuConsult ? 'block' : 'none'
          }}>
            <div
              style={{
                backgroundColor: 'rgb(0,30,80)',
                background: 'linear-gradient(0deg, rgba(0,30,80,1) 0%, rgba(0,67,122,1) 100%)',
                color: 'white',
                height: '100vh',
                width: '410px',
                display: 'inline-table',
                overflowY: 'auto'
              }}
            >
              <div style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'VWText',
                fontSize: 14,
                padding: 20
              }}
                onClick={toggleDrawer(anchor, false)}
              >
                <img style={{ width: '24px' }} src={closeIcon} alt="Botão Fechar" />
                <span style={{ marginLeft: 5 }}>Fechar menu</span>
              </div>

              <div className="main-menu" >
                <Link style={{ fontWeight: applyBold('home') }} to="/home">Inicio</Link>
                {screens.map((value) => {
                  if (value.level === 0) {
                    if (value.toPage !== null) {
                      return <Link style={{ fontWeight: applyBold(value.toPage) }} to={"/" + value.toPage}>{value.screenName}</Link>
                    }
                    else {
                      if (value.id === 1) {
                        return <div onClick={() => setShowSubmenuSystemRegister(true)}>{value.screenName}</div>
                      } else if (value.id === 5) {
                        return <div onClick={() => setShowSubmenuConsult(true)}>{value.screenName}</div>
                      }
                    }
                  }
                })}
              </div>
            </div>
          </div>

          <div style={{ display: showSubmenuSystemRegister ? 'block' : 'none' }}>
            <div
              style={{
                backgroundColor: 'rgb(0,30,80)',
                background: 'linear-gradient(0deg, rgba(0,30,80,1) 0%, rgba(0,67,122,1) 100%)',
                color: 'white',
                float: 'left',
                height: '100vh',
                width: '60px'
              }}
              onClick={() => setShowSubmenuSystemRegister(false)}
            >
              <div style={{
                cursor: 'pointer',
                padding: 20
              }}>
                <img style={{ width: '24px' }} src={closeIcon} alt="Botão Fechar" />
              </div>
            </div>
            <div className="submenu"
              style={{
                backgroundColor: 'white',
                color: 'black',
                float: 'left',
                height: '100vh',
                width: '350px'
              }}
            >
              <div onClick={() => setShowSubmenuSystemRegister(false)} style={{ borderBottom: '1px solid #001e50', color: '#001e50', display: 'inline-flex', fontSize: '16px', marginTop: '10px' }}>
                <img style={{ marginRight: '5px', width: '12px' }} src={backIcon} alt="Button Back" />
                <small>Voltar</small>
              </div>
              <div
                style={{
                  display: 'block',
                  fontSize: '28px',
                  fontWeight: 'bold',
                  margin: '32px 16px'
                }}
              >
                Cadastro <br />do Sistema
              </div>
              {screens.map((value) => {
                if (value.level === 1 && value.levelFather === 1) {
                  if (value.toPage !== null) {
                    return <Link style={{ fontWeight: applyBold(value.toPage) }} to={"/" + value.toPage}>{value.screenName}</Link>
                  } else {
                    if (value.id === 12) {
                      return <div
                        style={{ fontWeight: showSubmenuAssociations ? 'bold' : '' }}
                        onClick={() => setShowSubmenuAssociations(!showSubmenuAssociations)}>{value.screenName}
                        <img
                          style={{
                            float: 'right',
                            marginRight: '8px',
                            transform: !showSubmenuAssociations ? 'rotate(0)' : 'rotate(180deg)',
                            width: '24px'
                          }}
                          src={arrow} alt="Abrir e Fechar Collapse"
                        />
                        <div style={{ display: showSubmenuAssociations ? 'block' : 'none', paddingTop: "16px" }}>
                          {screens.map((value) => {
                            if (value.level === 2 && value.levelFather === 12) {
                              if (value.toPage !== null) {
                                return <Link style={{ fontWeight: applyBold(value.toPage) }} to={"/" + value.toPage}>{value.screenName}</Link>
                              }
                            }
                          })}
                        </div>
                      </div>
                    }
                  }
                }
              })}
            </div>
          </div>
          <div style={{ display: showSubmenuConsult ? 'block' : 'none' }}>
            <div
              style={{
                backgroundColor: 'rgb(0,30,80)',
                background: 'linear-gradient(0deg, rgba(0,30,80,1) 0%, rgba(0,67,122,1) 100%)',
                color: 'white',
                float: 'left',
                height: '100vh',
                width: '60px'
              }}
              onClick={() => setShowSubmenuConsult(false)}
            >
              <div style={{ cursor: 'pointer', padding: 20 }}>
                <img style={{ width: '24px' }} src={closeIcon} alt="Botão Fechar" />
              </div>
            </div>

            <div className="submenu" style={{ backgroundColor: 'white', color: 'black', float: 'left', height: '100vh', width: '350px' }} >
              <div onClick={() => setShowSubmenuConsult(false)} style={{ borderBottom: '1px solid #001e50', color: '#001e50', display: 'inline-flex', fontSize: '16px', marginTop: '10px' }}>
                <img style={{ marginRight: '5px', width: '12px' }} src={backIcon} alt="Button Back" />
                <small>Voltar</small>
              </div>

              <div style={{ display: 'block', fontSize: '28px', fontWeight: 'bold', margin: '32px 16px' }} >Consultas</div>
              {screens.map((value) => {
                if (value.level === 1 && value.levelFather === 5) {
                  if (value.toPage !== null) {
                    return <Link style={{ fontWeight: applyBold(value.toPage) }} to={"/" + value.toPage}>{value.screenName}</Link>
                  }
                }
              })}
            </div>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    ))}
  </>;
}
import { Button, Grid } from "@mui/material";
import TextFieldComponent from "../../components/field/textFieldComponent";
import LabelComponent from "../../components/label/labelComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import SelectComponent from "../../components/select/selectComponent";
import useTestPlanTab5 from "../../hooks/testPlan/useTestPlanTab5";

import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StepPhaseComponent from "../../components/step/stepPhaseComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { Box } from "@mui/system";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import ModalComponent from "../../components/modal/modalComponent";
import DataTableCollapsibleComponent from "../../components/dataTable/dataTableCollapsibleComponent";

function TestPlanPageTab5() {
  const { id } = useParams();
  const [activePhase, setActivePhase] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [currentModal, setCurrentModal] = useState<string>();
  const [valid, setValid] = useState(false);

  const {
    loading,
    status,
    user,
    resume,
    firstYear,
    dataStep,
    componentTest,
    phaseName,
    phaseStatus,
    lastYear, messageAlert, isError, setIsError,
    totalTable, codeStatus, setPhaseStatus,
    fetchResume, fetchChangeStatus, fetchCleanMessageError,
    listUser, openAlert, setOpenAlert, setListUser, selectedUser, setSelectedUser, fetchSelectUser
  } = useTestPlanTab5({ id });


  const [totalVdsTestsTitle, setTotalVdsTestsTitle] = useState("Plano de Teste de Veículos " + 0 + " Subcategorias");
  const [totalComponentTestsTitle, setTotalComponentTestsTitle] = useState("Plano de Teste de Componentes " + 0 + " Subcategorias");

  useEffect(() => {
    totalTable && totalTable[0].subCategoryVds && (setTotalVdsTestsTitle("Plano de Teste de Veículos " + totalTable[0].subCategoryVds + " Subcategorias"));
    totalTable && totalTable[0].subCategoryComponent && (setTotalComponentTestsTitle("Plano de Teste de Componentes " + totalTable[0].subCategoryComponent + " Subcategorias"));
  }, [totalTable]);

  const columnsPlan: GridColDef[] = [
    {
      field: "totalAdministrative",
      headerName: "Administrativo",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalAdministrative).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "totalTravels",
      headerName: "Viagens",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalTravels).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "totalExternalTests",
      headerName: "Testes Externos",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalExternalTests).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "totalVdsTests",
      headerName: totalVdsTestsTitle,
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalVdsTests).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "totalComponentTests",
      headerName: totalComponentTestsTitle,
      flex: 2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalComponentTests).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      field: "totalAll",
      headerName: "Soma de Todos os Custos",
      flex: 1.2,
      minWidth: 100,
      valueGetter: (params: any) => {
        return Number(params.row.totalAll).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpenAlert(false);
  };

  return (
    <Grid container>
      {loading ? <LoadingComponente /> : null}
      <ModalComponent
        description={messageAlert}
        isOk={true}
        open={isError}
        close={() => { setIsError(false) }}
        onCancel={() => { setIsError(false) }}
        onNext={() => setIsError(false)}
      />
      <SnackbarComponent isTab={true} text={"Plano de Teste salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
      <Grid container>
        <Grid item xs={12} sm={12} md={7.5} lg={7.5}>
          <Grid
            item
            sx={{ borderBottom: "1px solid #0000001f", padding: "16px 0" }}
          >
            <LabelComponent text="Dados " fontSize="24px" />
            <LabelComponent
              text="do Projeto"
              fontSize="24px"
              fontWeight="normal"
            />
          </Grid>
          <Grid
            container
            sx={{
              padding: "74px 0",
            }}
          >
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextFieldComponent
                text="Nome do projeto"
                readOnly={true}
                value={phaseName ? phaseName : ""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1}>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <SelectComponent
                isFilter={true}
                text="Status do plano de teste"
                required={true}
                values={status}
                value={phaseStatus}
                onSelect={(event: any) => {
                  setPhaseStatus(event.target.value);
                  if (event.target.value) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                  fetchChangeStatus({
                    "testPlanId": id,
                    "status": event.target.value

                  })
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={0.5} lg={0.5}>
          {" "}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={4} lg={4}>
          <Grid item sx={{ padding: "16px 0" }}>
            <LabelComponent text="Lista dos " fontSize="24px" />
            <LabelComponent
              text="Engenheiros de teste"
              fontSize="24px"
              fontWeight="normal"
            />
          </Grid>
          <Grid
            container
            sx={{
              border: "1px solid #0000001f",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {listUser.map((item: any) => <Box sx={{ borderBottom: "1px solid #0000001f", padding: "16px" }}> {item.name}</Box>)}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "0 16px" }}>
              <SelectComponent
                text="Usuário"
                required={true}
                values={user}
                value={selectedUser}
                onSelect={(event: any) => {
                  setSelectedUser(event.target.value);
                }}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "8px 16px" }}>
              <ButtonComponent text="Adicionar"
                onClick={() => fetchSelectUser()} />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid container sx={{ marginBottom: "32px" }}>
        <Grid item sx={{ margin: "32px 0" }}>
          <LabelComponent text="Valor Total " fontSize="24px" />
          <LabelComponent
            text="do Plano de Teste"
            fontSize="24px"
            fontWeight="normal"
          />
        </Grid>
        {resume &&
          <DataTableCollapsibleComponent calculation={resume.calculation} />
        }
      </Grid>
      {totalTable &&
        <Grid container
          sx={{
            backgroundColor: "#dfe4e8",
            width: "500px",
            padding: "16px",
            color: "#001e50",
            fontWeight: "bold"
          }}>
          <Grid container>
            <Grid item xs={8}>Custos Administrativos</Grid>
            <Grid item xs={2}>
              {Number(totalTable[0].totalAdministrative).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>Custos Viagens</Grid>
            <Grid item xs={2}>
              {Number(totalTable[0].totalTravels).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>Custo de Testes Externos</Grid>
            <Grid item xs={2}>
              {Number(totalTable[0].totalExternalTests).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}
export default TestPlanPageTab5;

import { FormLabel, FormGroup, TextField } from "@mui/material";
import iconUpdate from "../../assets/images/magnifier-plus.svg";
import iconUpdateBlue from "../../assets/images/covered-sum.png";

export default function InputCars(props: ParamsField) {
  return (
    <FormGroup >
      <FormLabel
        sx={{
          fontFamily: props.fontFamily ? props.fontFamily : "VWHead",
          color: props.color ? props.color : "#001e50;",
          fontSize: props.fontSize ? props.fontSize : "20px",
          fontWeight: props.fontWeight ? props.fontWeight : "bold",
          fontStyle: props.fontStyle ? props.fontStyle : "normal",
          marginTop: props.marginTop ? props.marginTop : "0px",
          marginLeft: props.marginLeft ? props.marginLeft : "0px",
          width: props.width ? props.width : "208px",
          height: props.height ? props.height : "239px",
          alignItems: props.alignItems ? props.alignItems : "center",
          justifyContent: props.justifyContent
            ? props.justifyContent
            : "center",
          display: props.display ? props.display : "flex",
          flexDirection: props.flexDirection ? props.flexDirection : "column",
          border: props.border ? props.border : "2px dashed #dfe4e8",
          cursor: 'pointer'
        }}
      >
        <img src={props.imgIcon === "blue" ? iconUpdateBlue : iconUpdate } alt="icon for add images" />
        <span>{props.text}</span>
        <input
          type="button"
          name="button"
          onClick={props.onClick}
          style={{display: "none"}}
        />
      </FormLabel>
    </FormGroup>
  );
}
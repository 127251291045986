import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { minutesToHours } from '../../utils/utils';
import { Divider, Grid } from '@mui/material';

const thHead = {
    maxWidth: '150px',
    minWidth: '150px',
    padding: '0px',
    whiteSpace: 'nowrap',
    // textAlign: 'center',
    color: '#001e50',
    fontWeight: 'bold'
}

const thSubHead = {
    maxWidth: '150px',
    minWidth: '150px',
    padding: '0px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: '#001e50',
    fontWeight: 'bold'
}

const thNameTotal = {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    color: '#001e50',
    fontWeight: 'bold',
    maxWidth: '150px',
    minWidth: '150px',
    padding: "0px 0px 0px 32px"
}

const thCollapse1 = {
    maxWidth: '125px',
    minWidth: '125px',
    padding: '0px',
    whiteSpace: 'nowrap',
    height: "50px"
}

const thCollapse2 = {
    whiteSpace: 'nowrap',
    backgroundColor: "#F2F4F4",
}

const thName = {
    maxWidth: '150px',
    minWidth: '150px',
    padding: "0px 0px 0px 32px",
    textAlign: 'center'
}

const thNameCollapse = {
    maxWidth: '183px',
    minWidth: '183px',
    padding: "0px",
    textAlign: 'center'
}

const th = {
    maxWidth: '150px',
    minWidth: '150px',
    textAlign: 'center'
}

const thTotal = {
    maxWidth: '150px',
    minWidth: '150px',
    textAlign: 'center',
    color: '#001e50',
    fontWeight: 'bold',
}

function Row(props: { initials: string, supervisions: CalculationSupervision[] }) {

    const [open, setOpen] = useState(false);
    const [openVechicle, setOpenVechicle] = useState(false);
    const [openComponent, setOpenComponent] = useState(false);
    let tHoursV = 0;
    let tHeadV = 0;
    let tTotalV = 0;
    let tHoursC = 0;
    let tHeadC = 0;
    let tTotalC = 0;
    let tTotalG = 0;

    props.supervisions.map((supervision: any) => {
        if (supervision.component === 0) {
            tHoursV += supervision.sumHours;
            tHeadV += supervision.sumHead;
            tTotalV += supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial;
        } else {
            tHoursC += supervision.sumHours;
            tHeadC += supervision.sumHead;
            tTotalC += supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial;
        }
        tTotalG += supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial;
    });

    return (
        <React.Fragment>
            <TableRow>
                <TableCell sx={thCollapse1}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {props.initials}
                </TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
                <TableCell sx={thCollapse1}></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableRow sx={thCollapse2}>
                            <TableCell sx={thNameCollapse}>
                                <IconButton size="small" onClick={() => setOpenVechicle(!openVechicle)}>
                                    {openVechicle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                {"Custo do Plano de Teste de Veículos"}
                            </TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}>{minutesToHours(tHoursV).toString()}</TableCell>
                            <TableCell sx={th}>{Number(tHeadV).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                            <TableCell sx={th}>{Number(tTotalV).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                        </TableRow>
                        <Divider></Divider>
                        <Collapse in={openVechicle} timeout="auto" unmountOnExit>
                            <TableBody>
                                {
                                    props.supervisions.map((supervision) => {
                                        if (supervision.component === 0) {
                                            return <TableRow>
                                                <TableCell sx={thName}>{supervision.name}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursMonth).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueMonth).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursPart).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valuePart).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursThird).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueThird).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.experimentalMaterial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.sumHours).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.sumHead).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            </TableRow>
                                        }
                                    })}
                            </TableBody>
                        </Collapse>
                        <TableRow sx={thCollapse2}>
                            <TableCell sx={thNameCollapse}>
                                <IconButton size="small" onClick={() => setOpenComponent(!openComponent)}>
                                    {openComponent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                {"Custo do Plano de Teste de Componentes"}
                            </TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}>{minutesToHours(tHoursC).toString()}</TableCell>
                            <TableCell sx={th}>{Number(tHeadC).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                            <TableCell sx={th}>{Number(tTotalC).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                        </TableRow>
                        <Divider></Divider>
                        <Collapse in={openComponent} timeout="auto" unmountOnExit>
                            <TableBody>
                                {
                                    props.supervisions.map((supervision) => {
                                        if (supervision.component === 1) {
                                            return <TableRow>
                                                <TableCell sx={thName}>{supervision.name}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursMonth).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueMonth).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursPart).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valuePart).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.hoursThird).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueThird).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.experimentalMaterial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{minutesToHours(supervision.sumHours).toString()}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.sumHead).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                                <TableCell sx={th}>{Number(supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                            </TableRow>
                                        }
                                    })}
                            </TableBody>
                        </Collapse>
                        <TableRow sx={thCollapse2}>
                            <TableCell sx={thNameCollapse}>{"Total " + props.initials}</TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}></TableCell>
                            <TableCell sx={th}>{Number(tTotalG).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                        </TableRow>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function DataTableCollapsibleComponent(props: any) {
    let totals = 0.0;
    props.calculation.map((row: any) => (
        row.supervisions.map((supervision: any) => {
            totals += supervision.valueMonth + supervision.valuePart + supervision.valueThird + supervision.experimentalMaterial;
        })
    ))
    return (<TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table>
            <TableHead sx={{ backgroundColor: "#dfe4e8", padding: "0px" }}>
                <TableRow>
                    <TableCell sx={thHead}></TableCell>
                    <TableCell align='right' sx={thHead}>Mensalista</TableCell>
                    <TableCell sx={thHead}></TableCell>
                    <TableCell align='right' sx={thHead}>Horista</TableCell>
                    <TableCell sx={thHead}></TableCell>
                    <TableCell align='right' sx={thHead}>Terceiros</TableCell>
                    <TableCell sx={thHead}></TableCell>
                    <TableCell align='center' sx={thHead}>Experimental Material</TableCell>
                    <TableCell sx={thHead}></TableCell>
                    <TableCell align='center' sx={thHead}>Soma</TableCell>
                    <TableCell sx={thHead}></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={thSubHead}></TableCell>
                    <TableCell sx={thSubHead}>Hs</TableCell>
                    <TableCell sx={thSubHead}>R$</TableCell>
                    <TableCell sx={thSubHead}>Hs</TableCell>
                    <TableCell sx={thSubHead}>R$</TableCell>
                    <TableCell sx={thSubHead}>Hs</TableCell>
                    <TableCell sx={thSubHead}>R$</TableCell>
                    <TableCell sx={thSubHead}>R$</TableCell>
                    <TableCell sx={thSubHead}>Horas</TableCell>
                    <TableCell sx={thSubHead}>$ Head</TableCell>
                    <TableCell sx={thSubHead}>$ Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.calculation.map((row: any) => (
                    <Row key={row.initials} initials={row.initials} supervisions={row.supervisions} />
                ))}
            </TableBody>
            <TableBody>
                <TableRow sx={{ backgroundColor: "#dfe4e8", padding: "0px", height: "45px" }}>
                    <TableCell sx={thNameTotal}>Total</TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}></TableCell>
                    <TableCell sx={thTotal}> {Number(totals).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    })}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>);
}
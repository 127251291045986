import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

function ButtonComponent(props: ParamsField) {
    return (
        <Button variant="contained"
            startIcon={props.isAdd ? <AddIcon/> : <></>}
            sx={{
                marginLeft: props.marginLeft ? props.marginLeft : "0px",
                marginTop: props.marginTop ? props.marginTop : "0px",
                marginRight: props.marginRight ? props.marginRight : "0px",
                marginBottom: props.marginBottom ? props.marginBottom : "0px",
                borderColor: props.borderColor ? props.borderColor : props.disable === true ? "#001e50" : "#c2cacf",
                border: props.border ? props.border : props.disable === true ? "1px solid #001e50" : "1px solid #c2cacf",
                backgroundColor: props.backgroundColor ? props.backgroundColor : props.disable === true ? "transparent" : props.active ? "#001e50" : "#c2cacf",
                color: props.color ? props.color : props.disable === true ? "#001e50" : props.active ? "white" : "white",
                boxShadow: props.boxShadow ? props.boxShadow : "none",
                borderRadius: props.borderRadius ? props.borderRadius : "24px",
                width: props.width ? props.width : "125px",
                height: props.height ? props.height : "44px",
                textTransform: props.textTransform ? props.textTransform : "none"
            }}
            onClick={props.onClick}
            disabled={props.disabled}
        >{props.text}</Button>
    );
}

export default ButtonComponent;
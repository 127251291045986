import { useEffect, useState } from "react";
import { get, post, put } from "../../api/api";
import useRoute from "../route/useRoute";

const useSupervision = () => {
    const [supervision, setSupervision] = useState<Supervision>();
    const [supervisions, setSupervisions] = useState<Supervision[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [supervisionsActive, setSupervisionsActive] = useState<Supervision[]>([]);
    const [totalPagesSelectSupervision, setTotalPagesSelectSupervision] = useState<number>(0);
    const [supervisionsInitials, setSupervisionsInitials] = useState<Supervision[]>([]);
    const [totalPagesSelectInitials, setTotalPagesSelectInitials] = useState<number>(0);
    const [messageSave, setMessageSave] = useState<string>("");
    const [openAlert, setOpenAlert] = useState(false);
    const [read, setRead] = useState<boolean>(false);
    const [codeStatus, setCodeStatus] = useState<number | null>(null);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        fetchList("", "", "", "", 0, 5);
        fetchUsers();
        fetchSupervisions(0);
        fetchSupervisionsInitials(0);
        setAccess(fetchPage("supervisao"));
    }, []);

    const fetchList = (filter: string, filter1: string, filter2: string, filter3: string, page: number, pageSize: number) => {
        setLoading(true);
        setSupervisions([]);
        post('supervisions/all',
            {
                "param": filter,
                "name": filter1,
                "user": filter2,
                "initials": filter3,
                "page": page,
                "size": pageSize
            })
            .then((value) => {
                setSupervisions(value.content);
                setTotalElements(value.totalElements);
                setLoading(false);
            });
    };

    const fetchUsers = () => {
        get('users').then((value) => {
            setUsers(value);
        });
    }

    const fetchSupervisions = (pageSupervison: number) => {
        post<Supervision[]>("supervisions/all",
            {
                "page": pageSupervison,
                "size": 10
            }).then((value) => {
                setSupervisionsActive(value.content);
                setTotalPagesSelectSupervision(value.totalPages);
            });
    };

    const fetchSupervisionsInitials = (pageSupervison: any) => {
        post<Supervision[]>("supervisions/all",
            {
                "page": pageSupervison,
                "size": 10
            }).then((value) => {
                setSupervisionsInitials(value.content);
                setTotalPagesSelectInitials(value.totalPages);
            });
    };

    const fetchActiveOrDesactive = (id: number | null, active: boolean, filter: string, filter1: string, filter2: string, filter3: string, page: number, pageSize: number) => {
        put('supervisions/' + id + '/active/' + active)
            .then((value) => {
                fetchList(filter, filter1, filter2, filter3, page, pageSize);
                setCodeStatus(value.codeStatus);
            });
    }

    const fetchSubmit = (isEdit: boolean, body: any) => {
        if (!isEdit) {
            post<Supervision[]>("supervisions", body)
                .then((value: Supervision) => {
                    if (value.codeStatus === 201) {
                        setOpenAlert(true)
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setSupervision(value);
                        setMessageSave(value.errorMessage);
                    }
                });
        } else {
            put<Supervision[]>("supervisions", body)
                .then((value: Supervision) => {
                    if (value.codeStatus === 200) {
                        setOpenAlert(true)
                    } else {
                        if (value.errorMessage === 'Not Operation') {
                            setRead(true)
                        }
                        setSupervision(value);
                        setMessageSave(value.errorMessage);
                    }
                });
        }
    };

    const fetchCleanMessageError = () => {
        setMessageSave("");
    }

    useEffect(() => {
        fetchList('', '', '', '', 0, 5);
    }, []);

    return {
        supervision, supervisions, totalElements, loading,
        users, supervisionsActive, totalPagesSelectSupervision,
        supervisionsInitials, totalPagesSelectInitials, access,
        messageSave, openAlert, codeStatus, read, setRead,
        fetchList, fetchUsers, fetchSupervisions, fetchSupervisionsInitials,
        fetchCleanMessageError, fetchActiveOrDesactive, fetchSubmit
    };
}

export default useSupervision;
import { Box, Grid } from "@mui/material";
import HeaderComponent from "../../components/header/headerComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import DataTableComponent from "../../components/dataTable/dataTableComponent";
import { GridColDef } from "@mui/x-data-grid";
import SearchComponent from "../../components/search/searchComponent";
import { useState } from "react";
import ModalComponent from "../../components/modal/modalComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import { useNavigate } from "react-router-dom";
import useSupervision from "../../hooks/supervision/useSupervision";
import ModalReadPage from "../../components/modal/modalReadPage";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function SupervisionPage() {
  const [id, setId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [userId, setUerId] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filter1, setFilter1] = useState<string>("");
  const [filter2, setFilter2] = useState<string>("");
  const [filter3, setFilter3] = useState<string>("");
  const [errorDisable, setErrorDisable] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [filter, setFilter] = useState<string>("");
  const [valid, setValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [openDisableError, setOpenDisableError] = useState(false);
  const [pageSelectSupervision, setPageSelectSupervision] = useState<number>(0);
  const [pageSelectInitials, setPageSelectInitials] = useState<number>(0);
  const { supervision, supervisions, totalElements, loading, users, supervisionsActive, totalPagesSelectSupervision,
    supervisionsInitials, totalPagesSelectInitials, messageSave, openAlert, codeStatus,
    read, setRead, access,
    fetchList, fetchUsers, fetchSupervisions, fetchSupervisionsInitials,
    fetchCleanMessageError, fetchActiveOrDesactive, fetchSubmit
  } = useSupervision();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name", headerName: "Nome da supervisão", flex: 0.5, minWidth: 100,
    },
    { field: "initials", headerName: "Sigla", flex: 0.4, minWidth: 100 },
    {
      field: "user", headerName: "Responsável", flex: 0.4, minWidth: 100,
      valueGetter: (params) => {
        return params.row.user.name;
      }
    },
    {
      field: "action", headerName: "Ação", flex: 0.4, minWidth: 250,
      renderCell: (value) => {
        if (value.row.active) {
          return <Box flex={3}>
            <ButtonTextComponent text="Desativar" onClick={() => {
              setOpenDisable(true);
              setId(value.row.id);
            }} />
            <ButtonTextComponent text="Editar" onClick={() => {
              setId(value.row.id);
              setName(value.row.name);
              setInitials(value.row.initials);
              setUerId(value.row.user.id);
              setValid(true);
              setIsEdit(true)
            }} />
            <ButtonTextComponent text="Clonar" onClick={() => {
              setId(value.row.id);
              setName(value.row.name);
              setInitials(value.row.initials);
              setUerId(value.row.user.id);
              setValid(true);
              setIsEdit(false)
            }} />
          </Box>
        } else {
          return <Box flex={1}>
            <ButtonTextComponent text="Ativar" onClick={() => {
              fetchActiveOrDesactive(value.row.id, true, filter, filter1, filter2, filter3, page, pageSize);
            }} />
          </Box>
        }
      }
    },
  ];

  const filterList = (event: any) => {
    if (event.target.value === "") {
      setFilter("");
      setFilter1("");
      setFilter2("");
      setFilter3("");
      fetchList("", "", "", "", page, pageSize);

    } else {
      setFilter(event.target.value);
      fetchList(filter, filter1, filter2, filter3, page, pageSize);
    }
  }

  const handleSubmit = (e: any) => {
    if (valid) {
      fetchSubmit(isEdit, {
        "id": id,
        "initials": initials,
        "name": name,
        "userId": userId
      }
      );
    }
  };

  const handleCloseModal = (e: any) => {
    setOpenModal(false);
  };

  const handleCloseModalFilter = (e: any) => {
    setOpenFilter(false);
  };

  const handleCloseModalDisable = (e: any) => {
    setOpenFilter(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    window.location.reload();
  };

  return (
    access ?
      <Grid container>
        <HeaderComponent text="Cadastro de Sistema / Supervisões" access={access}/>
        <SnackbarComponent text={isEdit ? "Supervisão editada com sucesso" : "Supervisão salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
        <ModalComponent description="Ao desativar esta Supervisão, não será mais possível atribuir novas interações à ele."
          open={openDisable}
          close={handleCloseModalDisable}
          onCancel={() => { setOpenDisable(false) }}
          onNext={() => {
            fetchActiveOrDesactive(id, false, filter, filter1, filter2, filter3, page, pageSize);
            if (codeStatus === 200) {
              setId(null);
              setOpenDisable(false);
            } else {
              setOpenDisable(false);
              setErrorDisable("Não é possível desativar esta supervisão, pois existem laboratórios associados a ela.");
              setOpenDisableError(true);
            }
          }} />
        <ModalComponent
          description={errorDisable}
          textButton2={"Realocar"}
          open={openDisableError}
          close={handleCloseModalDisable}
          onCancel={() => { setOpenDisableError(false) }}
          onNext={() => navigate('/laboratorios')}
        />
        <ModalComponent text=""
          open={openModal}
          description={isEdit === true ? "Deseja realmente cancelar a edição de supervisão?" : "Deseja realmente cancelar a criação de uma nova supervisão?"}
          close={handleCloseModal}
          onCancel={() => { setOpenModal(false) }}
          onNext={() => {
            setName("");
            setInitials("");
            setUerId("");
            setValid(false);
            setOpenModal(false);
            setIsEdit(false)
          }} />
        <ModalFilterComponent
          open={openFilter}
          labelFilter1={"Supervisão"}
          isPagination1={true}
          value1={filter1}
          url1="supervisions/all"
          onSelect1={(paginationEvent: string) => {
            setFilter1(paginationEvent);
            setIsFilter(true);
          }}
          labelFilter2={"Responsável"}
          isPagination2={false}
          value2={filter2}
          filter2={users}
          onSelect2={(event: any) => {
            setFilter2(event.target.value);
            setIsFilter(true);
          }}
          labelFilter3={"Sigla"}
          isPagination3={true}
          value3={filter3}
          url3="supervisions/all"
          fieldInitials3={true}
          onSelect3={(paginationEvent: string) => {
            setFilter3(paginationEvent);
            setIsFilter(true);
          }}
          close={handleCloseModalFilter}
          onCancel={() => { setOpenFilter(false) }}
          onNext={() => {
            if (filter1 === "" && filter2 === "" && filter3 === "") {
              setPage(0);
              setPageSize(5);
            }
            fetchList(filter, filter1, filter2, filter3, page, pageSize);
            setOpenFilter(false);
          }} />
        <ModalReadPage open={read} onCancel={() => setRead(false)} />
        <Grid container sx={{ margin: "48px 48px 16px 48px" }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={12} lg={3}>
              <LabelComponent text={isEdit ? "Editar " : "Criar "} fontSize="28px" />
              <LabelComponent text="Supervisão" fontSize="28px" fontWeight="normal" />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "32px" }}>
            <Grid item xs={12} sm={6} md={12} lg={3} >
              <LabelComponent text="Dados da supervisão" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <TextFieldComponent text="Nome da supervisão"
                helperText={messageSave?.includes('Nome') ? messageSave :
                  supervision?.fields && name === "" ?
                    "Campo obrigatório" : ""}
                error={messageSave?.includes('Nome') || supervision?.fields && name === "" ? true : false}
                required={true}
                value={name}
                maxLength={100}
                onChange={(event: any) => {
                  setName(event.target.value);
                  fetchCleanMessageError()
                  if (event.target.value != "" && initials != "" && userId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <TextFieldComponent text="Sigla"
                helperText={messageSave?.includes('Sigla') ? messageSave :
                  supervision?.fields && initials === "" ?
                    "Campo obrigatório" : ""}
                error={messageSave?.includes('Sigla') || supervision?.fields && initials === "" ? true : false}
                required={true}
                value={initials}
                maxLength={50}
                onChange={(event: any) => {
                  setInitials(event.target.value);
                  fetchCleanMessageError();
                  if (event.target.value != "" && name != "" && userId != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2} sx={{ margin: "16px 56px 0px 0px" }}>
              <SelectComponent
                helperText={messageSave?.includes('Responsável') ? messageSave :
                  supervision?.fields && userId === "" ?
                    "Campo obrigatório" : ""}
                values={users}
                text="Responsável"
                value={userId}
                required={true}
                onSelect={(event: any) => {
                  setUerId(event.target.value);
                  if (event.target.value != "" && name != "" && initials != "") {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }}>
              <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
            </Grid>
            <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px" }} >
              <ButtonComponent text="Salvar" active={valid} onClick={handleSubmit} />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "54px" }}>
              <LabelComponent text="Lista " fontSize="28px" />
              <LabelComponent text="de Supervisões" fontWeight="normal" fontSize="28px" />
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
              <SearchComponent text=""
                value={filter}
                placeholder="Buscar por …"
                isClean={true}
                onChange={filterList}
                onClick={() => {
                  setOpenFilter(true)
                }}
                onCancel={() => {
                  setFilter("");
                  fetchList("", filter1, filter2, filter3, page, pageSize);
                }}
                onClickClean={() => {
                  setFilter("")
                  setFilter1("")
                  setFilter2("")
                  setFilter3("")
                  fetchList("", "", "", "", page, pageSize);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataTableComponent
              rows={supervisions}
              columns={columns}
              loading={loading}
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageSizeChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                fetchList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onPageChange={(page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                fetchList(filter, filter1, filter2, filter3, page, pageSize);
              }}
              onClick={filterList} />
          </Grid>
        </Grid>
      </Grid >
      : <NotAuthorizedPage />
  );
}

export default SupervisionPage;
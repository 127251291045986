import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonsCalendar from "../../components/button/buttonToggleCalendarComponent";
import CardCar from "../../components/card/cardCarComponent";
import CardTestPlanColor from "../../components/card/cardTestPlanColorComponent";
import HeaderComponent from "../../components/header/headerComponent";
import LabelComponent from "../../components/label/labelComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import ModalFilterComponent from "../../components/modal/modalFilterComponent";
import SearchComponent from "../../components/search/searchComponent";
import useVehicleList from "../../hooks/vehicleList/useVehicleList";
import NotAuthorizedPage from "../notAuthorized/notAuthorized";

function VehicleListPage() {

    const { qtTests, loading, vehicleList, familyCodes, access, projectNumbers, fetchList } = useVehicleList();
    const [filter, setFilter] = useState<string>("");
    const [filter1, setFilter1] = useState<string>("");
    const [filter2, setFilter2] = useState<string>("");
    const [openFilter, setOpenFilter] = useState(false);
    const navigate = useNavigate();

    const boxTest = {
        backgroundColor: 'white',
        padding: "16px 0px",
        border: "1px solid #e5e9ed",
        height: '310px',
        overflowY: "auto",
        alignItems: "center",
        minWidth: '230px',
        width: 'calc(100%/7)',
        display: 'flex',
        justifyContent: 'center',
    }

    const filterList = (event: any) => {
        if (event.target.value === "") {
            setFilter("");
            setFilter1("");
            setFilter2("");
            fetchList("", "", "");

        } else {
            setFilter(event.target.value);
            fetchList(event.target.value, filter1, filter2);
        }
    }

    const handleCloseModalFilter = (e: any) => {
        setOpenFilter(false);
    };

    return (
        access ?
            <Grid container>
                <HeaderComponent text="Consultas / Lista Veículos" access={access}/>
                <ModalFilterComponent
                    open={openFilter}
                    labelFilter1={"Código da família"}
                    isPagination1={false}
                    filter1={familyCodes}
                    value1={filter1}
                    onSelect1={(event: any) => {
                        setFilter1(event.target.value);
                    }}
                    labelFilter2={"Número do projeto"}
                    isPagination2={false}
                    filter2={projectNumbers}
                    value2={filter2}
                    onSelect2={(event: any) => {
                        setFilter2(event.target.value);
                    }}
                    close={handleCloseModalFilter}
                    onCancel={() => { setOpenFilter(false) }}
                    onNext={() => {
                        fetchList(filter, filter1, filter2);
                        setOpenFilter(false);
                    }}
                />

                <Grid container sx={{ marginTop: "32px", paddingRight: "16px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                        <SearchComponent text="" value={filter} placeholder="Buscar por …"
                            onChange={filterList}
                            onClick={() => {
                                setOpenFilter(true)
                            }}
                            onCancel={() => {
                                setFilter("");
                                fetchList("", filter1, filter2);
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container sx={{ backgroundColor: "#dfe4e8", overflowX: "auto" }}>

                    <Grid container sx={{
                        display: '-webkit-box',
                        flexWrap: 'initial',
                        height: '60px'
                    }}>

                        {qtTests ? <Grid item sx={{
                            height: '60px',
                            minWidth: '230px',
                            width: 'calc(100%/7)',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#F1F4F6'
                        }}></Grid> : ''}

                        {Array.from(Array(qtTests)).map((item, index) => {
                            return (
                                <Grid item sx={{
                                    minWidth: '230px',
                                    width: 'calc(100%/7)',
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#F1F4F6'
                                }}>
                                    <Box>
                                        <ButtonsCalendar text={'Teste ' + (index + 1)} backgroundColor={'transparent'} color={'#6A767D'} fontSize={'14px'} />
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Grid container sx={{ backgroundColor: '#DFE4E8', display: '-webkit-box', flexWrap: 'initial', borderBottom: '1px solid #e5e9ed', height: '28px', textAlign: 'center' }}>
                        <Grid item sx={{ backgroundColor: '#DFE4E8', minWidth: '230px', width: 'calc(100%/7)' }}>
                            <LabelComponent text="Veículos" fontSize="20px" fontWeight="bold" color="#001e50" />
                        </Grid>
                        <Grid item sx={{ backgroundColor: '#DFE4E8', minWidth: '230px', width: 'calc((100%/7)*' + qtTests + ')', textAlign: 'center' }}>
                            <LabelComponent text="Sinergia" fontSize="20px" fontWeight="bold" color="#001e50" />
                        </Grid>
                    </Grid>
                    {loading ? <LoadingComponente /> : null}
                    {vehicleList !== undefined ? vehicleList.map((vehicle: any) =>
                        vehicle.isEmpty === false ?
                            <Grid container
                                sx={{
                                    display: '-webkit-box',
                                    flexWrap: 'initial',
                                    borderBottom: "1px solid #e5e9ed",
                                    backgroundColor: '#FFF'
                                }}>
                                <Grid item sx={{
                                    height: '310px',
                                    minWidth: '230px',
                                    width: 'calc(100%/7)',
                                    backgroundColor: 'white',
                                    padding: '16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderBottom: "1px solid #e5e9ed",
                                }}>
                                    <CardCar
                                        id={vehicle.requestTestId}
                                        isCopy={false}
                                        text={vehicle.vds !== null ? 'VDS ' + vehicle.vds : ' ' + vehicle.positionVds}
                                        isButtonTitle={true}
                                        textPhase={vehicle.phase}
                                        textMotorization={vehicle.motorizationTransmission}
                                        textPosition={vehicle.positionVds}
                                        damagesPosition={vehicle.damagesAndPosition}
                                        onNavigate={() => navigate("/cadastro-veiculos/editar/" + vehicle.projectCarId)}
                                    />

                                </Grid>
                                {vehicle.tests ? vehicle.tests.map((tests: any) => tests.isEmpty === false ?
                                    <Grid item sx={boxTest} >
                                        <CardTestPlanColor
                                            id={tests.requestTestId}
                                            isCopy={false}
                                            text={tests.testName}
                                            isColor={true}
                                            color={tests.color}
                                            title2={'Plano de teste'}
                                            text2={tests.testPlanName}
                                            title3={tests.kw}
                                            text3={tests.year.toString()}
                                            title4={'Número do Teste'}
                                            text4={tests.testNumber}
                                            title5={'Finalidade do Teste'}
                                            text5={tests.reason}
                                            title6={'Responsável'}
                                            text6={tests.responsible}
                                            isDetails={true}
                                            onNavigate={() => navigate("/requisicao-teste/editar/" + tests.requestTestId)}
                                        />
                                    </Grid>
                                    :
                                    <Grid item sx={boxTest}></Grid>
                                ) : ""}
                            </Grid>
                            : null
                    ) : []}

                </Grid>
            </Grid >
            : <NotAuthorizedPage />
    );
}

export default VehicleListPage;
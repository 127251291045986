import { useEffect, useState } from "react";
import { get, post } from "../../api/api";
import useRoute from "../route/useRoute";

const useVehicleList = () => {

    const [qtTests, setQtTests] = useState<number>(0);
    const [vehicleList, setVehicleList] = useState<VehicleListAll[]>([]);
    const [familyCodes, setFamilyCodes] = useState<GeneralType[]>([]);
    const [projectNumbers, setProjectNumbers] = useState<GeneralType[]>([]);
    const [access, setAccess] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("lista-veiculos"));
        fetchList("", "", "");
        fetchSelect();
    }, []);

    const fetchList = (param: string, familyCode: string, projectNumber: string) => {
        setLoading(true);
        post('/requestTests/projectCar/all',
            {
                "param": param,
                "familyCode": familyCode,
                "projectNumber": projectNumber
            })
            .then((value) => {
                let aux: any = null;
                value.projectCars.map((car: any) => {
                    car.tests.map((test: any) => {
                        test.isEmpty = false;
                    });
                    if (aux === null) {
                        aux = car.tests.length;
                    } else {
                        if (aux < car.tests.length) {
                            aux = car.tests.length;
                        }
                    }
                })
                value.projectCars.map((test: any) => {
                    if (test.tests.length < aux) {
                        for (let i = test.tests.length; i < aux; i++) {
                            test.tests[i] = {
                                isEmpty: true
                            }
                        }
                    }
                });
                value.projectCars.map((car: any) => {
                    car.isEmpty = true;
                    car.tests.map((test: any) => {
                        if (test.isEmpty === false) {
                            car.isEmpty = false;
                        }
                    });
                });
                setQtTests(aux)
                setVehicleList(value.projectCars);
            }).finally(()=> setLoading(false));
    };

    const fetchSelect = () => {
        get('/requestTests/projectCar/all/data')
            .then((value) => {
                setFamilyCodes(value.familyCodes);
                setProjectNumbers(value.projectNumbers)
            });
    };

    return { qtTests, loading, vehicleList, access, familyCodes, projectNumbers, fetchList };
}

export default useVehicleList;
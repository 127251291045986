import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

function TabPanelComponent(props: TabPanel) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box key={props.value}
                    sx={{ paddingTop: 3, width: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanelComponent;
import React from 'react';
import styled from 'styled-components';
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
const DownloadFile = (props: any) => {
  return (
    <>
      <div
          onClick={props.onClick}
          style={{ alignItems: "center", borderRadius: "3px", boxShadow: "0px 0px 5px #ccc", color: "#00b0ef", cursor: "pointer", display: "flex", fontSize: "15px", fontWeight: "bold", padding: "25px 40px", width: "190px" }}
      >
          <img style={{ marginRight: "10px", transform: props.rotate ? "rotate(180deg)" : "" }} src={props.image} />
          {props.text}
      </div>
    </>
  );
};
export default DownloadFile;
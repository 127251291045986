import { FormLabel, FormGroup, TextField } from "@mui/material";

import iconUpdate from "../../assets/images/magnifier-plus.svg";

export default function InputImage(props: ParamsField) {
  return (
    <FormGroup>
      <FormLabel
        sx={{
          fontFamily: props.fontFamily ? props.fontFamily : "VWHead",
          color: props.color ? props.color : "#c2cacf;",
          fontSize: props.fontSize ? props.fontSize : "20px",
          fontWeight: props.fontWeight ? props.fontWeight : "bold",
          fontStyle: props.fontStyle ? props.fontStyle : "normal",
          marginTop: props.marginTop ? props.marginTop : "0px",
          marginLeft: props.marginLeft ? props.marginLeft : "0px",
          width: props.width ? props.width : "300px",
          height: props.height ? props.height : "190px",
          alignItems: props.alignItems ? props.alignItems : "center",
          justifyContent: props.justifyContent
            ? props.justifyContent
            : "center",
          display: props.display ? props.display : "flex",
          flexDirection: props.flexDirection ? props.flexDirection : "column",
          border: props.border ? props.border : "3px dashed #c2cacf",
        }}
      >
        <img src={iconUpdate} alt="icon for add images" />
        <span>{props.text}</span>
        <input
          type="file"
          name="file"
          onChange={props.onChange}
          style={{display: "none"}}
        />
      </FormLabel>
    </FormGroup>
  );
}

import axios from 'axios';
import { Base64 } from 'js-base64';
import { CONFIG } from '../utils/config';
import { initAuthenticatedSession, resetAuthenticatedSession } from '../utils/session';

export const sendAuthorizationRequest = () => {
    let authorizeRequest = `${CONFIG.AUTHORIZE_ENDPOINT}?response_type=${CONFIG.RESPONSE_TYPE}&client_id=${CONFIG.CLIENT_ID}&scope=${CONFIG.SCOPE}&redirect_uri=${CONFIG.REDIRECT_URI}`;
    window.location.href = authorizeRequest;
};

export const sendTokenRequest = (code: any) => {
    const body = new URLSearchParams();
    body.append('grant_type', `${CONFIG.GRANT_TYPE}`);
    body.append('code', code);
    body.append('redirect_uri', `${CONFIG.REDIRECT_URI}`);
    body.append('client_id', `${CONFIG.CLIENT_ID}`);
    body.append('client_secret', `${CONFIG.CLIENT_SECRET}`);
    return axios.post(`${CONFIG.TOKEN_ENDPOINT}`, body, getTokenRequestHeaders())
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(new Error('Invalid status code received in the token response: '
                    + response.status));
            }
            initAuthenticatedSession(response.data);
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
};

export const sendLogout = () => {
    const body = new URLSearchParams();
    body.append('client_id', `${CONFIG.CLIENT_ID}`);
    body.append('refresh_token', `${localStorage.getItem('refresh_token')}`);
    body.append('client_secret', `${CONFIG.CLIENT_SECRET}`);

    return axios.post(CONFIG.LOGOUT_URL, body, getTokenRequestHeaders())
        .then(response => {
            if (response.status !== 204) {
                return Promise.reject(new Error('Invalid status code received in the token response: '
                    + response.status));
            }
            localStorage.clear();
            resetAuthenticatedSession();
            sendAuthorizationRequest();
            return response.data;
        }).catch((error) => {
            return Promise.reject(error);
        });
};

const getTokenRequestHeaders = () => {
    return {
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Authorization': 'Basic ' + Base64.encode(CONFIG.CLIENT_ID + ':' + CONFIG.CLIENT_SECRET),
        }
    };
};

const getLogoutRequestHeaders = () => {
    return {
        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
};
type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post, deleteR } from "../../api/api";
import { calculaUltimaLinha, hourToMinutes, minutesToHours } from "../../utils/utils";
import useRoute from "../route/useRoute";

const useActivityTest = (state: Type) => {
    const [supervisionList, setSupervisionList] = useState([]);
    const [loadingSupervisionList, setLoadingSupervisionList] = useState(true);
    const [totalValue, setTotalValue] = useState<any>('00:00');
    const [rows, setRows] = useState<any>([]);
    const [activity, setActivity] = useState<LaborTypeTestActivities[]>([]);
    const [activityTest, setActivityTest] = useState<ActivitiesTest[]>([]);
    const [laboratories, setLaboratories] = useState<any[]>([]);
    const [categoryActivities, setCategoryActivities] = useState<CategoryActivities[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [testes, setTestes] = useState<any[]>([]);
    const [testeId, setTesteId] = useState<string>("");
    const [valid, setValid] = useState(false);
    const [activityTestDrop, setActivityTestDrop] = useState<Activities[]>([]);
    const [laborTypeTestId, setLaborTypeTestId] = useState<string>("");
    const [laboratoryId, setLaboratoryId] = useState<string>("");
    const [laboratory, setLaboratory] = useState<string>("");
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("associar-atividade"));
        fetchActivities();
        fetchActivitiesDrag("", "");
        fetchCategory();
        if (state !== null) {
            fetchEdit(state.laborTypeTestId, true);
        } else {
            fetchListLaboratoriesActive();
            fetchSupervisionList();
        }
    }, []);

    const fetchSupervisionList = () => {
        post('supervisions/active/true', { "size": 9999999, "initials": "", "name": "", "page": 0, "param": "", "user": "" }).then((value) => {
            setSupervisionList(value.content);
            createArrayForNewRegister(value.content);
            setLoadingSupervisionList(false);
        });
    };

    function createArrayForNewRegister(_supervisionList: any) {
        let newArray: any = [];
        newArray = _supervisionList.map((row: any) =>
            ({ id: row.id, name: row.name, hour: '00:00', month: '00:00', third: '00:00', totalRow: '00:00' })
        );
        setTotalValue({ id: 0, name: "Total", hour: '00:00h', month: '00:00h', third: '00:00h', totalRow: '00:00' });
        setRows(newArray);
    };

    const fetchActivities = () => {
        post<LaborTypeTestActivities[]>("laboratories/typeTests/activities",
            {
                "page": 0,
                "size": 9999
            }).then((value) => {
                setActivity(value.content);
            });
    };

    const fetchActivitiesDrag = (filter: any, filter1: any) => {
        post<ActivitiesTest[]>("activities/active/filter",
            {
                "page": 0,
                "size": 9999,
                "param": filter,
                "category": filter1
            }).then((value) => {
                value.content.map((activity: any) => {
                    activity.activities.map((value: any) => {
                        value.isSelect = false;
                    });
                });
                setActivityTest(value.content);
            })
    };

    const fetchListLaboratoriesActive = () => {
        get<Laboratory[]>("laboratories/active/true").then((value) => {
            setLaboratories(value);
        });
    };

    const fetchCategory = () => {
        get<CategoryActivities[]>("categoryActivities").then((value) => {
            setCategoryActivities(value);
        });
    };

    const fetchSubmit = (body: any) => {
        post("typeTests/activities", body).then((value: Supervision) => {
            if (value.codeStatus === 201) {
                setOpenAlert(true);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setMessageSave(value?.errorMessage);
            }
        });

    };

    const fetchEdit = (id: any, isAltra: boolean) => {
        get("laboratories/typeTests/activities/" + id).then((value) => {
            get("laboratories/active/true").then((valueLab) => {
                post('supervisions/active/true', { "size": 9999999, "initials": "", "name": "", "page": 0, "param": "", "user": "" }).then((supervisonList) => {
                    setIsEdit(true);
                    if (value.laborTypeTestActivities.length > 0) {
                        setValid(true);
                    } else {
                        setIsEdit(false);
                        setValid(false);
                    }
                    valueLab.map((lab: any) => {
                        if (lab.id === value.laborId) {
                            setTestes(lab.typesTests);
                            lab.typesTests.map((type: any) => {
                                if (type.laborTypeTestId === value.laborTypeTestId) {
                                    setTesteId(type.id);
                                }
                            });
                        }
                    });
                    rows.map((row: any) => {
                        row.hour = "00:00";
                        row.month = "00:00";
                        row.third = "00:00";
                        row.totalRow = "00:00";
                    });
                    if (isAltra) {
                        setSupervisionList(supervisonList.content);
                        let newArray: any = [];
                        newArray = supervisonList.content.map((row: any) =>
                            ({ id: row.id, name: row.name, hour: '00:00', month: '00:00', third: '00:00', totalRow: '00:00' })
                        );
                        setTotalValue({ id: 0, name: "Total", hour: '00:00h', month: '00:00h', third: '00:00h', totalRow: '00:00' });
                        value.laborTypeTestActivities.map((valueActivity: any) => {
                            newArray.map((supervison: any) => {
                                valueActivity.valueActivities.map((activity: any) => {
                                    if (supervison.id === activity.supervisionId) {
                                        supervison.hour =
                                            supervison.hour !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.hour) + activity.hoursPart) :
                                                minutesToHours(activity.hoursPart);
                                        supervison.month =
                                            supervison.month !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.month) + activity.hoursMonth) :
                                                minutesToHours(activity.hoursMonth);
                                        supervison.third =
                                            supervison.third !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.third) + activity.hoursThird) :
                                                minutesToHours(activity.hoursThird);
                                        supervison.totalRow =
                                            supervison.totalRow !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.totalRow) + activity.hoursPart + activity.hoursMonth + activity.hoursThird) :
                                                minutesToHours(activity.hoursPart + activity.hoursMonth + activity.hoursThird);
                                    }
                                });
                            });
                        });
                        setRows(newArray);
                        setTotalValue(calculaUltimaLinha(newArray));
                        setLoadingSupervisionList(false);
                    } else {
                        value.laborTypeTestActivities.map((valueActivity: any) => {
                            rows.map((supervison: any) => {
                                valueActivity.valueActivities.map((activity: any) => {
                                    if (supervison.id === activity.supervisionId) {
                                        supervison.hour =
                                            supervison.hour !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.hour) + activity.hoursPart) :
                                                minutesToHours(activity.hoursPart);
                                        supervison.month =
                                            supervison.month !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.month) + activity.hoursMonth) :
                                                minutesToHours(activity.hoursMonth);
                                        supervison.third =
                                            supervison.third !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.third) + activity.hoursThird) :
                                                minutesToHours(activity.hoursThird);
                                        supervison.totalRow =
                                            supervison.totalRow !== "00:00" ?
                                                minutesToHours(hourToMinutes(supervison.totalRow) + activity.hoursPart + activity.hoursMonth + activity.hoursThird) :
                                                minutesToHours(activity.hoursPart + activity.hoursMonth + activity.hoursThird);
                                    }
                                });
                            });
                        });
                        setRows(rows);
                        setTotalValue(calculaUltimaLinha(rows));
                    }
                    if (value.laborTypeTestActivities.length > 0) {
                        setValid(true)
                    }
                    setActivityTestDrop(value.laborTypeTestActivities);
                    setActivityTestDrop([...value.laborTypeTestActivities]);
                    setLaborTypeTestId(id);
                    setLaboratory(value.laborName);
                    setLaboratoryId(value.laborId);
                    setLaboratories(valueLab);
                });
            });
        });
    };

    const fetchDelete = (id: any) => {
        deleteR("laboratories/typeTests/activities/all/" + id).then((value) => {
            setOpenModalCancel(false);
            setIsDelete(true);
            setOpenAlert(true);
        })
    };

    return {
        supervisionList, setSupervisionList,
        loadingSupervisionList, setLoadingSupervisionList,
        totalValue, setTotalValue,
        rows, setRows,
        activity, setActivity,
        activityTest, setActivityTest,
        laboratories, setLaboratories,
        categoryActivities, setCategoryActivities,
        openAlert, setOpenAlert,
        messageSave, setMessageSave,
        isEdit, setIsEdit,
        testes, setTestes,
        testeId, setTesteId,
        valid, setValid,
        activityTestDrop, setActivityTestDrop,
        laborTypeTestId, setLaborTypeTestId,
        laboratoryId, setLaboratoryId,
        laboratory, setLaboratory,
        openModalCancel, setOpenModalCancel,
        isDelete, setIsDelete, read, setRead, access,
        fetchSupervisionList, fetchActivities, fetchActivitiesDrag,
        fetchListLaboratoriesActive, fetchCategory, fetchSubmit,
        fetchEdit, fetchDelete
    };
}

export default useActivityTest;
import { Avatar, Dialog, DialogActions, DialogTitle, Grid } from "@mui/material";
import logo from "../../assets/images/alert.png";
import ButtonComponent from "../button/buttonComponent";
import LabelComponent from "../label/labelComponent";

function ModalComponent(props: ParamsField) {
    return (
        <Dialog
            open={props.open ? props.open : false}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                sx={{
                    padding: "40px 40px 0px 40px"
                }}
            >
                <Grid container>
                    <Grid container direction={"row"}>
                        <Grid item xs={2}>
                            <img style={{
                                width: "60px",
                                height: "60px",
                            }} src={logo} />
                        </Grid>
                        <Grid item xs={10} sx={{ alignSelf: "center" }}>
                            <LabelComponent
                                text={props.text ? props.text : "Atenção!"}
                                fontFamily="VWText"
                                fontSize="24px"
                                color="#001e50"
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction={"row"} sx={{
                        minWidth: "300px"
                    }}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <LabelComponent
                                text={props.description}
                                fontFamily="VWText"
                                fontWeight="normal"
                                color="#001e50"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
            {props.isOk === true ?
                <DialogActions
                    sx={{
                        marginTop: "30px",
                        marginBottom: "30px",
                        justifyContent: "space-around"
                    }}>
                    <ButtonComponent
                        border="1px solid #00b0f0"
                        width="150px"
                        marginTop="0px"
                        text={"Prosseguir"}
                        color="#00b0f0"
                        disable={true}
                        onClick={props.onCancel} />
                </DialogActions> :
                <DialogActions
                    sx={{
                        marginTop: "30px",
                        marginBottom: "30px",
                        justifyContent: "space-around"
                    }}>
                    <ButtonComponent
                        border="1px solid #00b0f0"
                        width="150px"
                        marginTop="0px"
                        text={props.textButton1 ? props.textButton1 : "Cancelar"}
                        color="#00b0f0"
                        disable={true}
                        onClick={props.onCancel} />
                    <ButtonComponent
                        width="150px"
                        marginTop="0px"
                        backgroundColor="#00b0f0"
                        text={props.textButton2 ? props.textButton2 : "Prosseguir"}
                        marginLeft="64px"
                        onClick={props.onNext} />
                </DialogActions>}
        </Dialog>
    );
}

export default ModalComponent;

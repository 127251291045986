import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from "@mui/material/Grid";

import { useState } from 'react';


export function TogglePanel(props: ToggleParamsField) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Grid sx={{ paddingY: 1, width: '100%' }}>
          {children}
        </Grid>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ButtonToggle(props: ToggleParamsField) {
  const handleChangeToggle = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== null) {
      props.onSelect(newValue);
    }
  };


  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={props.value}
        exclusive
        onChange={handleChangeToggle}
        sx={{
          backgroundColor: props.bgColor ? props.bgColor : 'white',
          borderRadius: '50px',
          border: 'none',
        }}
      >
        <ToggleButton
          {...a11yProps(0)}
          value={0}
          disabled={props.disabledBtn1 ? props.disabledBtn1 : false}
          sx={{
            color: props.textColor ? props.textColor : '#6a767d',
            borderRadius: '50px',
            border: 'none',
            lineHeight: 1.20,
            textTransform: 'initial',
            padding: props.padding ? props.padding : '10px',
            width: props.width ? props.width : '200px',
            height: props.height ? props.height : 'auto',
            minHeight: '44px',
            '&:hover': {
              backgroundColor: props.bgColor ? props.bgColor : 'white',
              color: props.textColor ? props.textColor : '#6a767d'
            },
            '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
              borderTopRightRadius: '50px',
              borderBottomRightRadius: '50px',
              border: 'none',
            },
            '&.Mui-selected': {
              backgroundColor: props.bgColor2 ? props.bgColor2 : '#021e51',
              color: props.textColor1 ? props.textColor1 : 'white',
              '&:hover': {
                backgroundColor: props.bgColor2 ? props.bgColor2 : '#021e51',
                color: props.textColor1 ? props.textColor1 : 'white',
              },
            }
          }}>
          <span style={{
            fontSize: props.fontSize ? props.fontSize : '14px',
            fontFamily: props.fontFamily ? props.fontFamily : "VWHead"
          }}>
            {props.textBtn1}
          </span>
        </ToggleButton>
        <ToggleButton
          {...a11yProps(1)}
          value={1}
          disabled={props.disabledBtn2 ? props.disabledBtn2 : false}
          sx={{
            color: props.textColor ? props.textColor : '#6a767d',
            borderRadius: '50px',
            border: 'none',
            lineHeight: 1.20,
            textTransform: 'initial',
            width: props.width ? props.width : '200px',
            '&:hover': {
              backgroundColor: props.bgColor ? props.bgColor : 'white',
              color: props.textColor ? props.textColor : '#6a767d'
            },
            '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
              borderTopLeftRadius: '50px',
              borderBottomLeftRadius: '50px',
              border: 'none',
            },
            '&.Mui-selected': {
              color: props.textColor1 ? props.textColor1 : 'white',
              backgroundColor: props.bgColor2 ? props.bgColor2 : '#021e51',
              '&:hover': {
                backgroundColor: props.bgColor2 ? props.bgColor2 : '#021e51',
                color: props.textColor1 ? props.textColor1 : 'white',
              },
            }
          }}>
          {props.textBtn2}
        </ToggleButton>
      </ToggleButtonGroup >
    </>
  );
}

import { useEffect, useState } from "react";
import { post } from "../../api/api";

const useMenu = () => {

    const [screens, setScreens] = useState<Screen[]>([]);

    useEffect(() => {
        fetchMenu();
    }, []);

    const fetchMenu = () => {
        post<User>('users/screen')
            .then((value: User) => {
                localStorage.setItem("screens", JSON.stringify(value.screens));
                setScreens(value.screens);
            });
    }
    return { screens, fetchMenu };
}

export default useMenu;
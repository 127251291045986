type Type = /*unresolved*/ any;
import { useEffect, useState } from "react";
import { get, post, deleteR } from "../../api/api";
import useRoute from "../route/useRoute";

const useResourceTest = (state: Type) => {
    const [resource, setResource] = useState<ResourceTestDetails[]>([]);
    const [resourceTest, setResourceTest] = useState<ResourcesTest[]>([]);
    const [laboratories, setLaboratories] = useState<any[]>([]);
    const [categoryResources, setCategoryResources] = useState<CategoryResources[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageSave, setMessageSave] = useState<string>("");
    const [totalCostResource, setTotalCostResource] = useState<number>(0);
    const [resourceTestDrop, setResourceTestDrop] = useState<Resources[]>([]);
    const [laboratory, setLaboratory] = useState<string>("");
    const [laborTypeTestId, setLaborTypeTestId] = useState<string>("");
    const [laboratoryId, setLaboratoryId] = useState<string>("");
    const [testes, setTestes] = useState<any[]>([]);
    const [testeId, setTesteId] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [valid, setValid] = useState(false);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [read, setRead] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);
    const { fetchPage } = useRoute();

    useEffect(() => {
        setAccess(fetchPage("associar-recurso"));
        fetchResource();
        fetchResourcesDrag("", "");
        fetchCategory();
        if (state !== null) {
            fetchEdit(state.laborTypeTestId);
        } else {
            fetchTypeTests();
        }
    }, []);

    const fetchResource = () => {
        post<ResourceTestDetails[]>("laboratories/typeTests/resources",
            {
                "page": 0,
                "size": 9999
            }).then((value) => {
                setResource(value.content);
            });
    };

    const fetchResourcesDrag = (filter: any, filter1: any) => {
        post<ResourcesTest[]>("resources/active/filter",
            {
                "page": 0,
                "size": 9999,
                "param": filter,
                "category": filter1
            }).then((value) => {
                value.content.map((resource: any) => {
                    resource.resources.map((value: any) => {
                        value.isSelect = false;
                    });
                });
                setResourceTest(value.content);
            });
    };

    const fetchTypeTests = () => {
        get("laboratories/active/true").then((value) => {
            setLaboratories(value);
        });
    };

    const fetchCategory = () => {
        get<CategoryResources[]>("categoryResources").then((value) => {
            setCategoryResources(value);
        });
    }

    const fetchSubmit = (body: any) => {
        post("typeTests/resources", body).then((value: Supervision) => {
            if (value.codeStatus === 201) {
                setOpenAlert(true);
            } else {
                if (value.errorMessage === 'Not Operation') {
                    setRead(true)
                }
                setMessageSave(value.errorMessage);
            }
        });
    };

    const fetchEdit = (id: any) => {
        get("laboratories/typeTests/resources/" + id).then((value) => {
            get("laboratories/active/true").then((valueLab) => {
                setIsEdit(true);
                if (value.laborTypeTestResources.length > 0) {
                    setValid(true);
                    let totalCost: number = 0;
                    value.laborTypeTestResources.map((labor: any) => {
                        totalCost += Number(labor.totalCost);
                    });
                    setTotalCostResource(totalCost);
                } else {
                    setIsEdit(false);
                    setValid(false);
                }
                valueLab.map((lab: any) => {
                    if (lab.id === value.laborId) {
                        setTestes(lab.typesTests);
                        lab.typesTests.map((type: any) => {
                            if (type.laborTypeTestId === value.laborTypeTestId) {
                                setTesteId(type.name);
                            }
                        });
                    }
                });
                setLaboratories(valueLab);
                setResourceTestDrop(value.laborTypeTestResources);
                setResourceTestDrop([...value.laborTypeTestResources]);
                setLaborTypeTestId(id);
                setLaboratory(value.laborName);
                setLaboratoryId(value.laborId);
            });
        });
    }

    const fetchDelete = (id: any) => {
        deleteR("laboratories/typeTests/resources/all/" + id).then((value) => {
            setOpenModalCancel(false);
            setIsDelete(true);
            setOpenAlert(true);
        })
    };

    return {
        access,
        resource, setResource,
        resourceTest, setResourceTest,
        laboratories, setLaboratories,
        categoryResources, setCategoryResources,
        totalCostResource, setTotalCostResource,
        laborTypeTestId, setLaborTypeTestId,
        resourceTestDrop, setResourceTestDrop,
        laboratoryId, setLaboratoryId,
        laboratory, setLaboratory,
        testeId, setTesteId,
        testes, setTestes,
        messageSave, setMessageSave,
        openAlert, setOpenAlert,
        isEdit, setIsEdit,
        isDelete, setIsDelete,
        valid, setValid, read, setRead,
        openModalCancel, setOpenModalCancel,
        fetchResource, fetchResourcesDrag, fetchTypeTests, fetchCategory,
        fetchSubmit, fetchEdit, fetchDelete
    };
}

export default useResourceTest;
import { Accordion, AccordionDetails, Grid } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AccordionComponent from "../../components/accordion/accordionComponent";
import ButtonAddComponent from "../../components/button/buttonAddComponent";
import LabelComponent from "../../components/label/labelComponent";
import LoadingComponente from "../../components/loading/loadingComponente";
import StepPhaseComponent from "../../components/step/stepPhaseComponent";
import TestPlanPSEComponent from "../../components/tests/testPlanPSEComponent";
import TestPlanPageTab3Modal from "./testPlanPageTab3Modal";

import useTestPlanTab3 from "../../hooks/testPlan/useTestPlanTab3";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ModalComponent from "../../components/modal/modalComponent";

function TestPlanPageTab3() {

    const { id } = useParams();
    const [expanded, setExpanded] = useState<string | false>(false);
    const [activePhase, setActivePhase] = useState(0);
    const [currentModal, setCurrentModal] = useState<string>();
    const [categoryId, setCategoryId] = useState<number>();
    const [typePhase, setTypePhase] = useState<string>("");

    const { loading,
        category,
        testSubCategory,
        phase,
        dataStep,
        phaseName,
        lastYear,
        firstYear,
        phaseStatus, messageAlert, isError, setIsError,
        fetchComponentTest,
        componentTest,
        openAlert, setOpenAlert,
        openModal, setOpenModal,
        suggestDate,
        fetchStartModal,
        fetchTestSubCategory,
        fetchSuggestDate,
        fetchDeactive
    } = useTestPlanTab3({ id });

    const handleChange = (panel: string, modal: string, categoryId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (isExpanded) {
            setCategoryId(categoryId);
            fetchComponentTest({
                "categoryId": categoryId,
                "phase": dataStep[activePhase].type,
                "testPlanId": id,
                "weekNumber": dataStep[activePhase].id,
                "year": dataStep[activePhase].year
            });
        }
        setExpanded(isExpanded ? panel : false);
        setCurrentModal(modal);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenAlert(false);
    };

    return (
        <Grid container>
            <SnackbarComponent isTab={true} text={"Requisição de teste salva com sucesso"} open={openAlert} closeSnackbar={handleClose} />
            {loading ? <LoadingComponente /> : null}
            <ModalComponent
                description={messageAlert}
                isOk={true}
                open={isError}
                close={() => { setIsError(false) }}
                onCancel={() => { setIsError(false) }}
                onNext={() => setIsError(false)}
            />
            <TestPlanPageTab3Modal
                isLabor={false}
                fetchSuggestDate={fetchSuggestDate}
                suggestDate={suggestDate}
                openModal={openModal}
                category={category}
                testSubCategory={testSubCategory}
                phase={phase}
                isChildren={true}
                fetchTestSubCategory={fetchTestSubCategory}
                fetchSubmitModal={(event: any, requestTests: any) => {
                    setOpenModal(false);
                    setOpenAlert(true);
                    fetchComponentTest({
                        "categoryId": requestTests.categoryId,
                        "phase": requestTests.testPhase,
                        "testPlanId": requestTests.testPlanId,
                        "weekNumber": dataStep[activePhase].id,
                        "year": dataStep[activePhase].year
                    });
                }}
                onCancel={(event: any) => setOpenModal(false)}
            />
            <Grid item xs={12}
                sx={{
                    marginY: '25px',
                    paddingY: '20px',
                    paddingX: '20px',
                    backgroundColor: '#dfe4e8',
                    borderRadius: '4px',
                    display: { xs: 'block', sm: 'block', md: 'block', lg: 'flex', xl: 'flex' },
                    alignItems: 'stretch'
                }}>
                <Grid item xs={12} sm={12} md={12} lg={3.8} xl={3.8}
                    sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' } }}>
                    <Grid item xs={6}
                        sx={{
                            paddingX: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}>
                        <LabelComponent text={phaseName} fontSize="20px" />
                        <LabelComponent
                            text={"Status: " + phaseStatus}
                            fontSize="16px"
                            fontWeight="normal"
                            color="#001e50"
                        />
                    </Grid>
                    <Grid item xs={6}
                        sx={{
                            paddingTop: '8px',
                            paddingRight: '8px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'stretch',
                        }}>
                        <LabelComponent
                            text={firstYear}
                            fontSize="16px"
                            fontWeight="normal"
                            color="#6a767d"
                        />
                    </Grid>
                </Grid>
                {
                    dataStep?.length > 0
                        ?
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                            <StepPhaseComponent
                                phases={dataStep}
                                activeStep={activePhase}
                                setActiveStep={setActivePhase}
                                onClick={(item: any) => {
                                    setTypePhase(item.type);
                                    if (categoryId !== undefined) {
                                        fetchComponentTest({
                                            "categoryId": categoryId,
                                            "phase": item.type,
                                            "testPlanId": id,
                                            "weekNumber": item.id,
                                            "year": item.year
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        :
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LabelComponent
                                text={'Lista de Fases não cadastrada'}
                                fontSize="16px"
                                fontWeight="normal"
                                color="#001e50"
                                display="flex"
                            />
                        </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={3.8} xl={3.8}
                    sx={{ display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' } }}>
                    <Grid item xs={12} sm={6} md={12} lg={3} xl={4.5}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingTop: '8px',
                            alignItems: 'flex-start'
                        }}>
                        <LabelComponent
                            text={lastYear}
                            fontSize="16px"
                            fontWeight="normal"
                            color="#6a767d"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "40px" }}>
                <ButtonAddComponent onClick={(event: any) => {
                    fetchStartModal();
                    setOpenModal(true);
                }
                } />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "32px" }}>
                {category.length > 0 && category.map((value: Category) => {
                    return (
                        <Accordion
                            expanded={expanded === 'panel' + value.id}
                            onChange={handleChange('panel' + value.id, value.name, value.id)}
                            sx={{ boxShadow: "none" }}>
                            <AccordionComponent text={value.name}></AccordionComponent>
                            <AccordionDetails>
                                {componentTest.map((value => {
                                    return (
                                        <TestPlanPSEComponent
                                            id={value.id}
                                            text1={'PSE Principal'}
                                            text2={` ${value.testNumber} - ${value.subCategory} - ${value.kwWeek}`}
                                            hasChildren={value.children.length > 0}
                                            children={value.children}
                                            fetchDeactive={fetchDeactive}
                                            bodyDeactive={
                                                {
                                                    "categoryId": categoryId,
                                                    "phase": dataStep[activePhase].type,
                                                    "testPlanId": id,
                                                    "weekNumber": dataStep[activePhase].id,
                                                    "year": dataStep[activePhase].year,
                                                    "testPhase": typePhase
                                                }
                                            }
                                        />
                                    )
                                }))}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Grid>
        </ Grid>
    );
}

export default TestPlanPageTab3;
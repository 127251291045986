type Type = /*unresolved*/ any;
import React, { useState } from 'react';
import { Grid, } from "@mui/material";
import ButtonToggle, { TogglePanel } from "../../components/button/buttonToggleComponent";
import ScheduleProgSinergyTab from "../../pages/programing/scheduleProgSinergyTab";
import ScheduleProgListSinergy from "./scheduleProgPageListSinergy";
import HeaderComponent from "../../components/header/headerComponent";
import { useLocation } from "react-router-dom";

function ScheduleProgSinergyPage() {
  const location = useLocation();
  const state = location.state as Type;
  const [value, setValue] = useState(0);

  return (
    <>
      <HeaderComponent text={"Programação de Laboratórios / Agenda Sinergia / " + state.testPlanName} access={true}/>
      <Grid container sx={{ padding: "48px 48px 16px 48px", display: "flex" }}>
        <Grid item sx={{ margin: "auto" }}>
          <ButtonToggle
            value={value}
            textBtn1={'Sinergia'}
            textBtn2={'Lista'}
            bgColor={'#dfe4e8'}
            textColor={'#6a767d'}
            bgColor2={'#021e51'}
            textColor1={'white'}
            width={'10vw'}
            onSelect={(event: any) => setValue(event)}
            disabledBtn1={false}
            disabledBtn2={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={3}
        sx={{ flexDirection: "row" }}>
        <TogglePanel value={value} index={0} >
          <ScheduleProgSinergyTab />
        </TogglePanel>
        <TogglePanel value={value} index={1} >
          <ScheduleProgListSinergy />
        </TogglePanel>
      </Grid>
    </>
  );
}

export default ScheduleProgSinergyPage;
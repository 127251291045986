import React, { useEffect, useState } from "react";
import { Box, Fade, Grid, Modal, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import LabelComponent from "../../components/label/labelComponent";
import SelectComponent from "../../components/select/selectComponent";
import TextFieldComponent from "../../components/field/textFieldComponent";
import DateFieldComponent from "../../components/field/dateFieldComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import useTestPlanTab3 from "../../hooks/testPlan/useTestPlanTab3";
import moment, { now } from "moment";
import { format } from "../../utils/utils";
import { useParams } from "react-router-dom";
import SelectPaginationComponent from "../../components/select/selectPaginationComponent";
import { get, post } from "../../api/api";
import ModalComponent from "../../components/modal/modalComponent";
import ModalReadPage from "../../components/modal/modalReadPage";

const style = {
    borderRadius: "8px",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#ffffff',
    boxShadow: 24,
    p: 4,
};

function TestPlanPageTab3Modal(params: ModalParamsField) {

    const { id } = useParams();
    const [message, setMessage] = useState<boolean>(false);
    const [subCategory, setSubCategory] = useState<SubCategory[]>([]);
    const [read, setRead] = useState<boolean>(false);
    const [requestTests, setRequestTests] = useState<any>({
        testPlanId: "",
        testDate: moment(now()).format('YYYY-MM-DD'),
        quantityChildren: 0,
        testNumber: "",
        typeTestId: "",
        supervisionId: "",
        carId: ""
    })

    const {
        messageSave, setMessageSave,
        openModal, setOpenModal,
        setOpen,
        setLoading
    } = useTestPlanTab3({ "id": undefined, "modal": params.openModal });

    const handleClose = () => setOpen(false);

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={params.openModal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={params.openModal}>
                <Box sx={style}>
                    <ModalComponent text=""
                        open={openModal}
                        description={"Deseja realmente cancelar a criação de um novo Plano de teste de componentes?"}
                        close={handleCloseModal}
                        onCancel={() => { setOpenModal(false) }}
                        onNext={() => {
                            setRequestTests({
                                ...requestTests,
                                testPlanId: params.isLabor === true ? "" : id,
                                testDate: moment(now()).format('YYYY-MM-DD'),
                                quantityChildren: 0,
                                testNumber: "",
                                typeTestId: "",
                                supervisionId: "",
                                categoryId: "",
                                subCategoryId: "",
                                testPhase: ""
                            });
                            setOpenModal(false);
                            setMessageSave("")
                            params.onCancel(event);
                        }} />
                    <ModalReadPage open={read} onCancel={() => setRead(false)} />
                    <LabelComponent text={"Nova "} fontSize="24px" />
                    <LabelComponent
                        text={params.isLabor === true ? "requisição de teste" : "requisição de teste de componentes"}
                        fontSize="24px"
                        fontWeight="normal"
                    />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <TextFieldComponent text="Número do teste"
                                helperText={messageSave !== "" ? messageSave : ""}
                                error={messageSave !== "" ? true : false}
                                required={true}
                                value={requestTests?.testNumber ? requestTests.testNumber : ""}
                                onChange={(value) => {
                                    setMessageSave("");
                                    setRequestTests({ ...requestTests, testNumber: value.target.value });
                                }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectComponent
                                text="Categoria"
                                required={true}
                                values={params.category}
                                value={requestTests?.categoryId ? requestTests.categoryId : ""}
                                onSelect={(event: any) => {
                                    if (event.target.value !== "") {
                                        params?.category && params.category.map((category: any) => {
                                            if (category.id === event.target.value) {
                                                setSubCategory(category.subCategories);
                                            }
                                        });
                                        setRequestTests({ ...requestTests, categoryId: event.target.value })
                                    } else {
                                        setSubCategory([]);
                                        params.fetchTestSubCategory("");
                                        setRequestTests({
                                            ...requestTests,
                                            categoryId: "",
                                            subCategoryId: "",
                                            typeTestId: ""
                                        })
                                    }

                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectComponent
                                text="Sub-categoria"
                                required={true}
                                values={subCategory}
                                value={requestTests?.subCategoryId ? requestTests.subCategoryId : ""}
                                onSelect={(event: any) => {
                                    params.fetchTestSubCategory(event.target.value);
                                    setRequestTests({ ...requestTests, subCategoryId: event.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectComponent
                                text="Nome do teste"
                                required={true}
                                value={requestTests?.typeTestId ? requestTests.typeTestId : ""}
                                values={params.testSubCategory}
                                onSelect={(value: any) => {
                                    setRequestTests({ ...requestTests, typeTestId: value.target.value });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectPaginationComponent
                                text={"Nome do modelo"}
                                url="cars/active/true"
                                required={true}
                                isModel={true}
                                value={requestTests?.carId ? requestTests.carId : ""}
                                onSelect={(event: any) => {
                                    setRequestTests({ ...requestTests, carId: event });
                                }}
                            />
                        </Grid>
                        <Grid item hidden={!params.isChildren} xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <TextFieldComponent text="Quantidade de testes"
                                maxLength={2}
                                required={true}
                                helperText={message ? "Campo não pode ser zero." : ""}
                                error={message}
                                value={requestTests?.quantityChildren ?
                                    requestTests.quantityChildren <= 0 ? 0 : requestTests.quantityChildren : ""}
                                onChange={(event) => {
                                    setRequestTests({ ...requestTests, quantityChildren: event.target.value });
                                    if (event.target.value === '0') {
                                        setMessage(true);
                                    } else {
                                        setMessage(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item hidden={params.isChildren} xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectPaginationComponent
                                text={"Plano de teste"}
                                url="testPlans/select/all"
                                status="Aprovado - Draft"
                                required={true}
                                value={requestTests?.testPlanId ? requestTests.testPlanId : ""}
                                onSelect={(event: any) => {
                                    setRequestTests({ ...requestTests, testPlanId: event });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectComponent
                                text="Fase do teste"
                                isFilter={true}
                                required={true}
                                values={params.phase}
                                value={requestTests?.testPhase ? requestTests.testPhase : ""}
                                onSelect={(event: any) => {
                                    if (event.target.value !== "") {
                                        get('testPlans/tab3/phase/date/' + id + '/' + event.target.value)
                                            .then((value) => {
                                                if (value.suggestDate !== "") {
                                                    setRequestTests({
                                                        ...requestTests,
                                                        testDate: value.suggestDate,
                                                        testPhase: event.target.value
                                                    });
                                                } else {
                                                    setRequestTests({ ...requestTests, testPhase: event.target.value });
                                                }
                                            });
                                    } else {
                                        setRequestTests({ ...requestTests, testPhase: event.target.value });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <DateFieldComponent
                                disabled={params.isLabor === true ? true : false}
                                text="Data do Teste"
                                value={params.isLabor === true ? new Date(params.testDate + "T00:00:00") :
                                    requestTests?.testDate ? new Date(requestTests.testDate + "T00:00:00") : ""}
                                required={true}
                                onChangeDate={(newValue: Date) => {
                                    if (newValue !== null) {
                                        if (newValue.toString() !== "Invalid Date") {
                                            setRequestTests({ ...requestTests, testDate: format(newValue) });
                                        } else {
                                            setRequestTests({ ...requestTests, testDate: "" });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ padding: '24px' }}>
                            <SelectPaginationComponent
                                text={"Supervisão responsável"}
                                url="supervisions/active/true"
                                disabled={params.isLabor === true ? true : false}
                                required={true}
                                value={
                                    params.isLabor === true ? params.supervisionId :
                                        requestTests?.supervisionId ? requestTests.supervisionId : ""}
                                onSelect={(value: string) => {
                                    setRequestTests({ ...requestTests, supervisionId: value })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '32px',
                        paddingBottom: '8px',
                    }}>
                        <ButtonComponent
                            border="1px solid #00b0f0"
                            width="150px"
                            marginTop="0px"
                            text="Cancelar"
                            color="#00b0f0"
                            marginLeft="32px"
                            marginRight="32px"
                            disable={true}
                            onClick={(event: any) => {
                                setOpenModal(true);
                            }} />
                        <ButtonComponent
                            width="150px"
                            marginTop="0px"
                            backgroundColor={
                                (requestTests.testNumber &&
                                    requestTests.categoryId &&
                                    requestTests.subCategoryId &&
                                    requestTests.typeTestId &&
                                    requestTests.testPhase &&
                                    requestTests.quantityChildren &&
                                    requestTests.testDate.toString().replaceAll('-', '').length == 8 &&
                                    requestTests.supervisionId &&
                                    message === false && params.isLabor === false) ||
                                    (requestTests.testNumber &&
                                        requestTests.categoryId &&
                                        requestTests.subCategoryId &&
                                        requestTests.typeTestId &&
                                        requestTests.testPhase &&
                                        message === false && params.isLabor === true)
                                    ? "#00b0f0" : "#c2cacf"
                            }
                            text="Salvar"
                            marginRight="32px"
                            onClick={() => {
                                if (requestTests.testNumber &&
                                    requestTests.categoryId &&
                                    requestTests.subCategoryId &&
                                    requestTests.testPhase &&
                                    requestTests.typeTestId &&
                                    requestTests.quantityChildren &&
                                    requestTests.carId &&
                                    requestTests.testDate.toString().replaceAll('-', '').length == 8 &&
                                    requestTests.supervisionId &&
                                    message === false
                                    ? true : false) {
                                    requestTests.testPlanId = id;
                                    requestTests.quantityChildren = requestTests.quantityChildren - 1;
                                    setLoading(true);
                                    post("testPlans/tab3/component", requestTests)
                                        .then((value) => {
                                            if (value.codeStatus === 201) {
                                                params.fetchSubmitModal(true, requestTests);
                                            } else {
                                                if (value.errorMessage === 'Not Operation') {
                                                    setRead(true)
                                                }
                                                setMessageSave(value.errorMessage);
                                            }
                                        }).finally(() => setLoading(false));
                                } else if (requestTests.testNumber &&
                                    requestTests.categoryId &&
                                    requestTests.subCategoryId &&
                                    requestTests.testPhase &&
                                    requestTests.typeTestId &&
                                    requestTests.carId &&
                                    params.supervisionId && 
                                    params.testDate &&
                                    message === false
                                    ? true : false) {
                                    requestTests.supervisionId = params.supervisionId;
                                    requestTests.testDate = params.testDate;
                                    setLoading(true);
                                    post("testPlans/tab3/component", requestTests)
                                        .then((value) => {
                                            if (value.codeStatus === 201) {
                                                params.fetchSubmitModal(true, requestTests);
                                            } else {
                                                if (value.errorMessage === 'Not Operation') {
                                                    setRead(true)
                                                }
                                                setMessageSave(value.errorMessage);
                                            }
                                        }).finally(() => setLoading(false));
                                }
                            }} />
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}

export default TestPlanPageTab3Modal;
import { Box, Grid } from "@mui/material";
import TextFieldComponent from "../../components/field/textFieldComponent";
import SelectComponent from "../../components/select/selectComponent";
import LabelComponent from "../../components/label/labelComponent";
import ButtonComponent from "../../components/button/buttonComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import ModalComponent from "../../components/modal/modalComponent";
import SnackbarComponent from "../../components/snackbar/snackbarComponent";
import ButtonTextComponent from "../../components/button/buttonTextComponent";
import { useNavigate, useParams } from "react-router-dom";
import useRequestTestTab5 from "../../hooks/requestTest/useRequestTestTab5";
import TextareaAutosizeComponent from "../../components/field/textareaAutosize";
import DataTableGridComponent from "../../components/dataTable/dataTableGridComponent";
import ModalReadPage from "../../components/modal/modalReadPage";

function RequestTestPageTab5() {
    const { type, id } = useParams();
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [errorDisable, setErrorDisable] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [openModal, setOpenModal] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);
    const [openDisableError, setOpenDisableError] = useState(false);
    const {
        isEdit, setIsEdit,
        isAdd, setIsAdd,
        part, setPart,
        selectedParts,
        loading,
        allSelectsList,
        openAlert, read, setRead,
        messageAlert, isError, setIsError,
        setOpenAlert,
        fetchList,
        fetchActiveOrDesactive,
        fetchSubmit
    } = useRequestTestTab5({ id });
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        { field: "description", headerName: "Descrição", flex: 0.2, minWidth: 100, },
        { field: "position", headerName: "Posição", flex: 0.2, minWidth: 100, },
        { field: "partNumber", headerName: "Número da peça", flex: 0.2, minWidth: 100, },
        { field: "provider", headerName: "Fornecedor", flex: 0.2, minWidth: 100, },
        { field: "amount", headerName: "Quantidade", flex: 0.2, minWidth: 100, },
        { field: "sampleLevel", headerName: "Nível amostra", flex: 0.2, minWidth: 100, },
        { field: "partStatus", headerName: "Status peça", flex: 0.2, minWidth: 100, },
        { field: "performance", headerName: "Desempenho", flex: 0.2, minWidth: 100, },
        {
            field: "action", headerName: "Ação", flex: 0.2, minWidth: 150,
            renderCell: (value) => {
                return <Box flex={3}>
                    <ButtonTextComponent text="Editar" onClick={() => {
                        setSelectedId(value.row.id);
                        setPart(value.row);
                        setIsEdit(true);
                        setIsAdd(true);
                    }} />
                    <ButtonTextComponent text="Excluir" onClick={() => {
                        setOpenDisable(true);
                        setSelectedId(value.row.id);
                        setIsAdd(false);
                    }} />
                </Box>
            }
        },
    ];

    const handleSubmit = () => {
        fetchSubmit({ ...part, requestTestId: id });
    };

    const handleCloseModal = (e: any) => {
        setOpenModal(false);
    };

    const handleCloseModalDisable = (e: any) => {
        setOpenFilter(false);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        fetchList(id);
        setIsEdit(false);
        setOpenAlert(false);
    };

    return (
        <Grid container>
            <SnackbarComponent isTab={true} text={"Requisição de teste editada com sucesso"} open={openAlert} closeSnackbar={handleClose} />
            <ModalComponent description={"Deseja realmente remover este item da lista?"}
                open={openDisable}
                close={handleCloseModalDisable}
                onCancel={() => { setOpenDisable(false) }}
                onNext={() => {
                    fetchActiveOrDesactive(selectedId);
                    setOpenDisable(false);
                }}
            />
            <ModalComponent
                description={errorDisable}
                textButton2={"Ok"}
                open={openDisableError}
                close={handleCloseModalDisable}
                onCancel={() => setOpenDisableError(false)}
                onNext={() => setOpenDisableError(false)}
            />
            <ModalComponent text=""
                open={openModal}
                description={type === "editar" ? "Deseja realmente cancelar essa edição? As informações que não foram salvas serão perdidas" : "Deseja realmente cancelar a criação de uma nova Requisição de teste?"}
                close={handleCloseModal}
                onCancel={() => { setOpenModal(false) }}
                onNext={() => {
                    setOpenModal(false);
                    setPart({});
                    setIsAdd(!isAdd);
                }}
            />
            <ModalComponent
                description={messageAlert}
                isOk={true}
                open={isError}
                close={() => { setIsError(false) }}
                onCancel={() => { setIsError(false) }}
                onNext={() => setIsError(false)}
            />
            <ModalReadPage open={read} onCancel={() => setRead(false)} />
            <Grid container>
                <Grid container>
                    <Grid container>
                        <Grid item>
                            <LabelComponent text="Lista de " fontSize="24px" />
                            <LabelComponent text="Peças" fontSize="24px" fontWeight="normal" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "16px" }}>
                        <DataTableGridComponent
                            rows={selectedParts}
                            columns={columns}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container>
                        <Grid item sx={{ marginTop: "24px" }}>
                            <ButtonComponent
                                width={"160px"}
                                text={"Adicionar novo"}
                                border={"1px dashed #c2cacf"}
                                backgroundColor={"#fff"}
                                borderRadius={"4px;"}
                                color={"#00b0f0"}
                                isAdd={true}
                                onClick={() => {
                                    setIsAdd(!isAdd);
                                    setPart({});
                                }}
                            />
                        </Grid>
                    </Grid>
                    {isAdd &&
                        <>
                            <Grid container marginTop={"24px"} spacing={2}>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent
                                        text="Posição"
                                        isFilter={true}
                                        values={allSelectsList?.positions}
                                        value={part?.position ? part.position : ""}
                                        onSelect={(event: any) => setPart({ ...part, position: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent text="Número da peça"
                                        value={part?.partNumber ? part.partNumber : ""}
                                        maxLength={100}
                                        onChange={(event) => setPart({ ...part, partNumber: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent text="Fornecedor"
                                        value={part?.provider ? part.provider : ""}
                                        maxLength={100}
                                        onChange={(event) => setPart({ ...part, provider: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent text="Quantidade"
                                        type="number"
                                        value={part?.amount ? part.amount : ""}
                                        maxLength={100}
                                        onChange={(event) => setPart({ ...part, amount: Number(event.target.value) })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent text="Nível amostra"
                                        value={part?.sampleLevel ? part.sampleLevel : ""}
                                        maxLength={100}
                                        onChange={(event) => setPart({ ...part, sampleLevel: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent text="Status peça"
                                        value={part?.partStatus ? part.partStatus : ""}
                                        maxLength={100}
                                        onChange={(event) => setPart({ ...part, partStatus: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent
                                        text="Desempenho"
                                        isFilter={true}
                                        values={allSelectsList?.performances}
                                        value={part?.performance ? part.performance : ""}
                                        onSelect={(event: any) => setPart({ ...part, performance: event.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextareaAutosizeComponent
                                        width="10px"
                                        value={part?.description ? part.description : ""}
                                        placeholder={"Descrição"}
                                        readOnly={false}
                                        onChangeArea={(event) => setPart({ ...part, description: event.target.value })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }}>
                                    <ButtonComponent text="Cancelar" disable={true} onClick={() => { setOpenModal(true); }} />
                                </Grid>
                                <Grid item xs={5} sm={4} md={2} lg={2} sx={{ marginTop: "40px", textAlign: "center" }} >
                                    <ButtonComponent text="Salvar" active={part?.description || part?.position || part?.partNumber || part?.provider || part?.amount || part?.sampleLevel || part?.partStatus || part?.performance ? true : false} onClick={handleSubmit} />
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid >
    );
}

export default RequestTestPageTab5;